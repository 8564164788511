<template>
  <div class="custom-input">
    <div class="custom-input__content-wrapper">
      <gmap-autocomplete
        :id="name"
        ref="gMapAutocomplete"
        class="input-field"
        :class="{
          'input-field--filled': isFilled,
          'input-field--error': isError,
        }"
        :placeholder="isActive ? placeholder : ''"
        :options="autocompleteOptions"
        @focusin="toggleActiveInput(true)"
        @focusout="toggleActiveInput(false)"
        @place_changed="onAutoCompleteSelection"
      />
      <label
        :for="name"
        class="label"
        :class="{
          'label--filled': isFilled,
          'label--active': isActive,
          'label--error': isError,
        }"
      >
        {{ label }}
      </label>
    </div>
    <div
      v-if="isError"
      class="custom-input__error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>

import { CITY_BOUNDARIES } from '@/common/constants';

export default {
  name: 'CustomAutocomplete',
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      active: false,
      autocompleteOptions: {
        componentRestrictions: {
          country: 'br',
        },
        strictBounds: true,
        bounds: CITY_BOUNDARIES['ALL_COVERED_CITIES'],
      },
    };
  },
  watch: {
    value (value) {
      if (!value) {
        this.$refs.gMapAutocomplete.$refs.input.value = '';
      }
    }
  },
  computed: {
    isActive () {
      return this.active;
    },
    isError () {
      return this.error;
    },
    isFilled () {
      return this.value?.address_components && this.value?.address_components?.length;
    },
    innerVal: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    toggleActiveInput (value) {
      this.active = value;
    },
    onAutoCompleteSelection (val) {
      this.innerVal = val;
    },
  },
};

</script>

<style lang="scss" scoped>

.custom-input {
  @apply flex flex-col;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;

  &__error-message {
    font-size: 12px;
    line-height: 12px;
    color: #B75757;
    margin-left: 8px;
  }

  &__content-wrapper {
    position: relative;
  }

  .input-field {
    width: 100%;

    position: relative;
    top: 0;
    left: 0;

    background: rgba(126, 146, 206, 0.1);
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 18.5px 14px;

    font-size: 16px;
    line-height: 19px;
    color: #707070;
    text-align: left;

    transition: box-shadow .2s ease-out;

    &::placeholder {
      color: #707070;
      opacity: 1;
    }

    &:focus {
      outline: none;
      background: rgba(126, 146, 206, 0.1);
      box-shadow: inset 0 0 0 2px #4663B9;
      transition: box-shadow .2s ease-out;

      & ~ .label {
        top: -6px;

        font-size: 12px;
        line-height: 12px;
        color: #4663B9;
        background-color: #FFFFFF;

        transition: all .2s ease-out;
      }
    }

    &--filled {
      color: #555555;
      box-shadow: inset 0 0 0 1px #4663B9;
      transition: box-shadow .2s ease-out;

      & ~ .label {
        top: -6px;
        color: #707070;
        font-size: 12px;
        line-height: 12px;
        background-color: #FFFFFF;
      }
    }

    &--error {
      box-shadow: inset 0 0 0 2px #B75757;
      transition: box-shadow .2s ease-out;
      background-color: #FFFFFF;

      & ~ .label {
        top: -6px;
        font-size: 12px;
        line-height: 12px;
        color: #B75757;
        background-color: #FFFFFF;
      }
    }

    &:disabled {
      background: #F5F5F5;
      border: 1px solid #E8E8E8;
      color: #CCCCCC;
      box-shadow: none;

      & ~ .label {
        top: -6px;
        font-size: 12px;
        line-height: 12px;
        color: #CCCCCC;
        background-color: #FFFFFF;
      }
    }
  }

  .label {
    position: absolute;
    top: 20px;
    left: 14px;

    font-size: 16px;
    line-height: 19px;
    color: #707070;

    background-color: #f2f4fa;
    border-radius: 3px;
    padding: 0 3px;

    transition: all .2s ease-out;
    z-index: 10;
  }
}

</style>
