<template>
  <h3 
    :class="secondary ? 'title--secondary' : 'title--primary'"
    :style="{fontSize: h3FontSize}"
  >
    {{ title }}
  </h3>
</template>

<script>
export default {
  name: "SimpleTitle",
  props: {
    title: {
      required: true,
      type: String,
    },
    primary:{
      type: Boolean
    },
    secondary:{
      type: Boolean
    },
    size: {
      type: String,
      default: 'medium'
    },
  },
  computed: {
    h3FontSize () {
      return this.size === 'medium' ? '18px' : '16px'
    },
  },
};
</script>

<style lang="scss">

h3{
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  &.title--primary{
    color: #4663B9;
  }
  &.title--secondary{
      color:  #5A7FED;
  }
}
</style>