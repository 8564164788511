<template>
  <aside class="sidebar-wrapper">
    <div class="clients-search sidebar flex flex-col relative h-full">
      <div class="flex flex-col w-full h-full">
        <div class="client-search-filters flex flex-col w-full pb-24 px-8">
          <div class="pt-6 pb-8">
            <Typography
              tag="h1"
              variant="header-xl-secondary"
              color="brand-dark"
              align="center"
            >
              {{ NAVBAR_MENU_ITEMS.PERSON_DATA.label }}
            </Typography>
          </div>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <div class="row no-gutters mt-3">
                  <div class="col-12 col-md-7">
                    <h3 class="typer">
                      Digite o&nbsp;
                      <vue-typer
                        :text="searchLabel"
                        :pre-erase-delay="500"
                        :pre-type-delay="1000"
                        :erase-delay="2000"
                        caret-animation="smooth"
                        @erased="rotateLabel"
                      />
                    </h3>
                  </div>
                  <div class="col-12 col-md-5 flex">
                    <h3
                      class="search-type my-auto ml-auto"
                      @click="switchSearchType"
                    >
                      <span
                        v-if="searchBy === 'person'"
                        class="flex"
                      >
                        <span class="my-auto flex">
                          <v-icon>mdi-map-marker-outline</v-icon>
                        </span>
                        <span class="my-auto">Busca por endereço</span>
                      </span>
                      <span
                        v-else
                        class="flex"
                      >
                        <span class="my-auto flex"><v-icon>mdi-magnify</v-icon></span>
                        <span class="my-auto">Busca principal</span>
                      </span>
                    </h3>
                  </div>
                </div>
              </v-row>
              <v-row align="end">
                <v-text-field
                  v-if="searchBy === 'person'"
                  v-model="searchText"
                  class="search-input"
                  @keyup.enter="search"
                />
                <div
                  v-else
                  class="v-input search-input theme--light v-text-field v-text-field--is-booted primary--text"
                  :class="{ 'v-input--is-focused': vInputIsFocused }"
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <gmap-autocomplete
                          ref="addressAutocomplete"
                          placeholder=""
                          :options="autocompleteOptions"
                          @keyup="onAutocompleteInputChange"
                          @place_changed="onAutoCompleteSelection"
                          @focusin="vInputIsFocused = true"
                          @focusout="vInputIsFocused = false"
                        />
                      </div>
                    </div>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper" />
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>
              <v-row>
                <consulta-dados-quota />
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <ButtonBar
        class="mb-3"
        :tooltip="false"
        :on-confirm="search"
        :on-clean="clearSearch"
        :is-loading="isLoading"
        :disabled="!userHasQuotaAvailable || !searchText"
      />
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </aside>
</template>

<script>

import base from '@/api/base';
import api from '@/api';

import { VueTyper } from 'vue-typer';
import { mapActions, mapGetters } from 'vuex';

import consultaDadosQuota from '@/components/user/consultaDadosQuota.vue';
import ButtonBar from '@/components/sidebar/ButtonBar.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import Typography from '@/components/base/Typography';

import {
  NAVBAR_MENU_ITEMS,
  CITY_BOUNDARIES,
} from '@/common/constants';

export default {
  name: 'ConsultaDadosSidebar',
  components: {
    VueTyper,
    consultaDadosQuota,
    ButtonBar,
    GoogleTagManager,
    Typography,
  },
  props: ['searchFor'],
  data: () => ({
    gtmEvent: null,
    searchByAddressPayload: null,
    searchBy: 'person',
    typeIndex: 0,
    vInputIsFocused: false,
    searchText: '',
    searchLabelPerson: [
      'número do CPF',
      'número do CNPJ',
      'e-mail',
      'nome completo',
    ],
    searchLabelAddress: ['endereço'],
    autocompleteOptions: {
      componentRestrictions: {
        country: 'br',
      },
      strictBounds: true,
      bounds: CITY_BOUNDARIES['ALL_COVERED_CITIES'],
    },
  }),
  computed: {
    ...mapGetters('user', ['consultaDadosQuota']),
    ...mapGetters('person', [
      'isLoading',
      'personQuery',
    ]),
    searchLabel () {
      const list = this.getCurrentLabelArray();

      return list[this.typeIndex];
    },
    userHasQuotaAvailable () {
      return this.consultaDadosQuota && this.consultaDadosQuota.available;
    },
  },
  created () {
    this.NAVBAR_MENU_ITEMS = NAVBAR_MENU_ITEMS;
  },
  async mounted () {
    if (this.$route.query.data) {
      await this.decryptData();
    }

    if (this.searchFor || this.personQuery) {
      this.searchText = this.searchFor || this.personQuery;
      this.search();
    }
  },
  methods: {
    ...mapActions('person', [
      'setPersonQuery',
      'setLoading',
    ]),
    ...mapActions('user', ['fetchConsultaDadosQuota']),
    async decryptData () {
      try {
        const domain = 'person';
        const dataToDecrypt = this.$route.query.data;
        const decryptedData = (await api.person.decrypt(domain, dataToDecrypt)).decrypted;

        this.setPersonQuery(decryptedData);
      }
      catch (e) {
        // ...
      }
    },
    onAutocompleteInputChange (event) {
      this.searchText = event.target.value;
    },
    onAutoCompleteSelection (data) {
      const { address_components } = data;
      const payload = {};

      address_components.forEach(component => {
        component.types.forEach(type => {
          switch (type) {
          case 'route':
            payload.Street = component.long_name;
            break;
          case 'administrative_area_level_1':
            payload.State = component.short_name;
            break;
          case 'street_number':
            payload.AddressNumber = component.long_name;
            break;
          case 'administrative_area_level_2':
            payload.City = component.long_name;
            break;
          case 'postal_code':
            payload.ZipCode = component.long_name.replace(/\D/g, '');
            break;
          default:
            break;
          }
        });
      });
      this.searchByAddressPayload = payload;
    },
    getCurrentLabelArray () {
      const list = this.searchBy === 'person' ? this.searchLabelPerson : this.searchLabelAddress;

      if (this.typeIndex >= list.length) {
        this.typeIndex = 0;
      }

      return list;
    },
    switchSearchType () {
      this.clearSearch();
      this.searchBy = this.searchBy === 'person' ? 'address' : 'person';
    },
    clearSearch () {
      this.searchText = '';
      this.searchByAddressPayload = null;
      this.$emit('searchResponse');

      if (this.$refs.addressAutocomplete) {
        this.$refs.addressAutocomplete.$refs.input.value = '';
      }
    },
    rotateLabel () {
      this.typeIndex++;
    },
    async search () {
      this.gtmEvent = {
        event: 'search_person',
        type: this.searchBy === 'address' ? 'endereço' : 'outros',
      };
      await this.fetchConsultaDadosQuota();

      if (!this.userHasQuotaAvailable) return;

      this.setLoading(true);

      const defaultParams = {
        size: 300,
        page: 1,
      };

      const searchByAddressParams = {
        ...this.searchByAddressPayload,
        ...defaultParams,
        PersonType: 0,
      };
      const searhByTextParams = {
        Query: this.searchText,
        ...defaultParams,
      };

      const payload = this.searchByAddressPayload ? searchByAddressParams : searhByTextParams;

      this.setPersonQuery('');

      let response;

      base
        .post('person-data/search/', payload)
        .then(data => {
          response = data;
        })
        .catch(() => {
          response = {
            data: {
              result: [],
            },
          };
        })
        .finally(() => {
          this.$emit('searchResponse', response);
          this.setLoading(false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  @apply bg-white;
  width: 390px;
  position: inherit;
  top: 0;
  box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%) !important;
  .search {
    &-type {
      font-size: 14px;
      font-weight: 900;
      color: #4b88ff;
      cursor: pointer;
      .v-icon {
        font-size: 18px;
        color: #4b88ff;
      }
    }
    &-input {
      font-size: 14px;
    }
  }
  .typer {
    font-weight: 700;
    font-size: 16px;
    ::v-deep.vue-typer {
      * {
        color: #4663b9;
        background: #e3e8f5;
      }
    }
  }
}
</style>
