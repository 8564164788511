<template>
  <div class="skeleton-results">
    <div class="flex w-full">
      <div class="w-6/12">
        <div class="flex">
          <v-skeleton-loader class="mt-1" width="20%" type="text" />
          <v-skeleton-loader class="mt-1" width="20%" type="text" style="margin-left: 40%" />
        </div>
        <div class="flex justify-space-between">
          <v-skeleton-loader class="mt-1" width="40%" type="text" />
          <v-skeleton-loader class="mt-1" width="40%" type="text" />
        </div>
      </div>
      <div class="flex">
        <v-skeleton-loader class="my-auto ml-auto skeleton-btn" width="117" height="34" type="button" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonClientAnalysis',
};

</script>

<style lang="scss" scoped>

.skeleton-results {
  @apply mt-4 flex flex-col justify-start items-center w-full;
  ::v-deep {
    .v-skeleton-loader__button {
      @apply rounded-full w-full h-full;
    }
  }
}

</style>
