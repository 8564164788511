import { css } from '@/core/theme';

const root = css({
  display: 'flex',
  flexDirection: 'column',
  placeItems: 'center',

  '& > h3': {

    '& > svg': {
      display: 'inline-block',
      width: '$medium',
      height: '$medium',
    },
  },

  '&.person-data-footer > button': {
    marginTop: '8px',
  },

  '& > button': {
    marginTop: '$small',
  },
});

export const styles = {
  root,
};
