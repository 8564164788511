import { FILTER_MODE, SELLER_AREA_RANGE_PERCENT } from '@/common/constants';

import {
  clearFilters,
  clearSearch,
  fetchAddresses,
  fetchClientsByFilter,
  fetchCondoSuggestions,
  fetchRegionSuggestions,
  filterByOfferType,
  getCharacteristics,
  getClientByDocument,
  getCondoById,
  getRegionById,
  search,
  setMapSearchResult,
  setListSearchResult,
  setShowWithoutOffers,
  searchByDraw,
} from '../utils';

const initialState = {
  error: null,
  isLoading: false,
  filterMode: FILTER_MODE.RADIUS,
  sellerAreaRangePercent: SELLER_AREA_RANGE_PERCENT,
  lastSearch: null,
  filter: null,
  showWithoutOffers: true,
  result: null,
  originalResult: null,
  searchByEnterKey: false,
  searchByDraw: false,
  mapPins: [],
  offersToExclude: [],
  regionSelected: null
};

const getters = {
  error: state => state.error,
  isLoading: state => state.isLoading,
  filterMode: state => state.filterMode,
  filter: state => state.filter,
  result: state => state.result,
  originalResult: state => state.originalResult,
  searchByEnterKey: state => state.searchByEnterKey,
  searchByDraw: state => state.searchByDraw,
  lastSearch: state => state.lastSearch,
  showWithoutOffers: state => state.showWithoutOffers,
  offersToExclude: state => state.offersToExclude,
  regionSelected: state => state.regionSelected
};

const mutations = {
  SET_FILTER_MODE (state, mode) {
    state.filterMode = mode;
  },
  SET_REGION_SELECTED (state, regionSelected) {
    state.regionSelected = regionSelected;
  },
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_FILTER (state, filter) {
    state.filter = filter;
  },
  SET_ORIGINAL_SEARCH_RESULT (state, result = []) {
    state.originalResult = result;
  },
  setMapSearchResult,
  SET_LIST_SEARCH_RESULT (state, result) {
    state.result = result;
  },
  CLEAR_LIST_SEARCH_RESULT (state) {
    state.result = null;
    state.mapResult = null;
    state.flagged = null;
  },
  SET_LAST_SEARCH_PARAMETERS (state, request) {
    state.lastSearch = request;
  },
  FETCH_ADDRESSESS_SUCCESS () {},
  FETCH_ADDRESSESS_ERROR (state, error) {
    state.error = error;
  },
  FETCH_ADDRESSESS_LIST_SUCCESS () {},
  FETCH_ADDRESSESS_LIST_ERROR (state, error) {
    state.error = error;
  },
  SEARCH_BY_ENTER_KEY (state) {
    state.searchByEnterKey = !state.searchByEnterKey;
  },
  SEARCH_BY_DRAW (state, payload) {
    state.searchByDraw = payload;
  },
  SET_SHOW_WITHOUT_OFFERS (state, payload) {
    state.showWithoutOffers = payload;
  },
  FILTER_BY_OFFER_TYPE (state, payload = []) {
    state.offersToExclude = payload;
  },
};

const actions = {
  clearFilters,
  clearSearch,
  fetchAddresses,
  fetchClientsByFilter,
  fetchCondoSuggestions,
  fetchRegionSuggestions,
  filterByOfferType,
  getCharacteristics,
  getClientByDocument,
  getCondoById,
  getRegionById,
  search,
  setListSearchResult,
  setShowWithoutOffers,
  searchByDraw,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
