import { FILTER_MODE } from '@/common/constants';

import {
  clearFilters,
  clearSearch,
  fetchAddresses,
  fetchRegionSuggestions,
  getCharacteristics,
  getRegionById,
  search,
  setMapSearchResult,
  setListSearchResult,
  searchByDraw,
} from './utils';

const initialState = {
  error: null,
  isLoading: false,
  filterMode: FILTER_MODE.RADIUS,
  lastSearch: null,
  filter: null,
  autocompleteData: null,
  result: null,
  mapResult: null,
  flagged: null,
  originalResult: null,
  searchByEnterKey: false,
  searchByDraw: false,
};

const getters = {
  error: state => state.error,
  isLoading: state => state.isLoading,
  filterMode: state => state.filterMode,
  mapResult: state => state.mapResult,
  filter: state => state.filter,
  autocompleteData: state => state.autocompleteData,
  flagged: state => state.flagged,
  result: state => state.result,
  originalResult: state => state.originalResult,
  searchByEnterKey: state => state.searchByEnterKey,
  searchByDraw: state => state.searchByDraw,
  lastSearch: state => state.lastSearch,
};

const mutations = {
  SET_FILTER_MODE (state, mode) {
    state.filterMode = mode;
  },
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_FILTER (state, filter) {
    state.filter = filter;
  },
  SET_AUTOCOMPLETE_DATA (state, data) {
    state.autocompleteData = data;
  },
  SET_ORIGINAL_SEARCH_RESULT (state, result = []) {
    state.originalResult = result;
  },
  setMapSearchResult,
  SET_LIST_SEARCH_RESULT (state, result) {
    state.result = result;
  },
  CLEAR_LIST_SEARCH_RESULT (state) {
    state.result = null;
    state.mapResult = null;
    state.flagged = null;
  },
  SET_LAST_SEARCH_PARAMETERS (state, request) {
    state.lastSearch = request;
  },
  FETCH_ADDRESSESS_SUCCESS () {},
  FETCH_ADDRESSESS_ERROR (state, error) {
    state.error = error;
  },
  FETCH_ADDRESSESS_LIST_SUCCESS () {},
  FETCH_ADDRESSESS_LIST_ERROR (state, error) {
    state.error = error;
  },
  SEARCH_BY_ENTER_KEY (state) {
    state.searchByEnterKey = !state.searchByEnterKey;
  },
  SEARCH_BY_DRAW (state, payload) {
    state.searchByDraw = payload;
  },
};

const actions = {
  clearFilters,
  clearSearch,
  fetchAddresses,
  fetchRegionSuggestions,
  getCharacteristics,
  getRegionById,
  search,
  setListSearchResult,
  searchByDraw,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
