<template>
  <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.37472 0C3.21447 0 2.10174 0.460907 1.28132 1.28133C0.460906 2.10174 0 3.21447 0 4.37472V19.5908C0 19.9072 0.125702 20.2107 0.349452 20.4344C0.573203 20.6582 0.876673 20.7839 1.1931 20.7839C1.50954 20.7839 1.81301 20.6582 2.03676 20.4344C2.26051 20.2107 2.38621 19.9072 2.38621 19.5908V4.37472C2.38621 3.27706 3.27706 2.38621 4.37472 2.38621H19.4874C20.585 2.38621 21.4759 3.27706 21.4759 4.37472V11.6813C22.2283 11.3488 23.0508 11.204 23.8621 11.247V4.37472C23.8621 3.21447 23.4012 2.10174 22.5808 1.28133C21.7603 0.460907 20.6476 0 19.4874 0H4.37472Z" fill="currentColor"/>
    <path d="M25.9395 13.716L25.7343 13.549L25.7295 13.5442C25.0368 13.0276 24.1814 12.7775 23.3196 12.8394C22.4577 12.9013 21.6468 13.2712 21.0351 13.8814L14.9852 20.0378C14.6098 20.4196 14.3362 20.8794 14.1851 21.3821L13.2513 24.4873C12.4056 24.6203 11.5442 24.6171 10.6996 24.4778C10.6906 24.4764 10.6821 24.4729 10.6746 24.4677C10.6672 24.4624 10.6611 24.4555 10.6567 24.4475C10.6524 24.4395 10.65 24.4306 10.6497 24.4215C10.6494 24.4124 10.6513 24.4033 10.6551 24.3951L10.7092 24.2805L10.7155 24.2694C10.8189 24.0499 11.0019 23.6617 11.0607 23.2624C11.0925 23.0397 11.1037 22.7311 10.9828 22.4034C10.8581 22.0635 10.6213 21.7762 10.3115 21.5889C9.79603 21.2723 9.15494 21.2485 8.60452 21.2803C8.05337 21.3205 7.50548 21.397 6.9644 21.5093C6.22945 21.6525 5.53108 21.9802 4.83431 22.3079C3.91801 22.7406 3.00488 23.1701 2.01699 23.1701C1.66224 23.1701 1.32181 23.1129 1.00206 23.0047C0.80639 22.9411 0.591631 23.0986 0.640946 23.299C0.731622 23.6633 0.898657 24.1549 1.26454 24.5319C1.48641 24.7639 1.76879 24.9292 2.07974 25.009C2.39068 25.0889 2.71777 25.0801 3.02397 24.9837C5.337 24.3044 6.87372 23.9337 7.87275 23.7683C8.08274 23.7333 8.23864 23.9449 8.175 24.1485C8.07478 24.4667 7.93161 25.0664 8.22909 25.6757C8.55043 26.3359 9.18357 26.6127 9.73081 26.7256C11.5491 27.0994 13.1192 27.0501 15.0377 26.5474C15.1205 26.5331 15.2048 26.514 15.2875 26.487L15.4736 26.4265C15.7559 26.3447 16.0364 26.2572 16.3152 26.164C16.3585 26.1495 16.401 26.1325 16.4424 26.1131L18.6489 25.3989C19.1866 25.2255 19.6718 24.928 20.0599 24.5319L26.097 18.3914C27.3474 17.1171 27.3426 15.1541 26.1257 13.8957L25.9395 13.716Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DesignIcon',
};
</script>
