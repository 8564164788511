import { css } from '@/core/theme';

const root = css({
  width: '100%',
  height: '100%',
});

const wrapper = css({ });

const overlay = css({ });

export const styles = {
  root,
  wrapper,
  overlay,
};
