<template>
  <div
    class="w-full flex flex-column px-4 py-5 border-data income-data"
    v-if="data.Income && Object.keys(data.Income).length > 0"
  >
    <div class="flex flex-row gap-2 mb-4">
      <Typography
        color="neutral-white"
        variant="title-large"
        tag="span"
      >
        Renda e Classe Social
      </Typography>
    </div>
    <div class="flex align-center justify-center mb-4">
      <div class="income-separator" />
    </div>
    <div class="flex flex-row align-items justify-center gap-4 mb-4">
      <div class="flex flex-col items-start justify-between gap-3">
        <Typography
          color="neutral-white"
          variant="title-large"
          tag="span"
        >
          Renda Presumida
        </Typography>
        <Typography
          color="feedback-success"
          variant="body-table"
          tag="span"
        >
          {{ formatCurrency(data?.Income?.Presumed) || '-' }}
        </Typography>
      </div>
      <div class="flex flex-row align-center p-2 social-class-data social-class-width">
        <Typography
          color="neutral-white"
          variant="body-xs"
          tag="span"
        >
          Classe social pessoal
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ CLASS_SOCIAL[data?.Income?.PersonalClass] || '-' }}
        </Typography>
      </div>
      <div class="flex flex-row align-center p-2 social-class-data social-class-width">
        <Typography
          color="neutral-white"
          variant="body-xs"
          tag="span"
        >
          Classe social familiar
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ CLASS_SOCIAL[data?.Income?.FamilyClass] || '-' }}
        </Typography>
      </div>  
    </div>
    <div class="flex flex-row align-items">
      <div class="flex flex-col align-items justify-center gap-2">
        <Typography
          color="neutral-white"
          variant="body-medium"
          tag="span"
        >
          Renda Aposentadoria
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ formatCurrency(data?.Income?.Retired) || '-' }}
        </Typography>
      </div>
      <div class="flex flex-col align-items justify-center gap-2">
        <Typography
          color="neutral-white"
          variant="body-medium"
          tag="span"
        >
          Renda Individual
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ formatCurrency(data?.Income?.Personal) || '-' }}
        </Typography>
      </div>
      <div class="flex flex-col align-items justify-center gap-2">
        <Typography
          color="neutral-white"
          variant="body-medium"
          tag="span"
        >
          Renda Familiar
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ formatCurrency(data?.Income?.Family) || '-' }}
        </Typography>
      </div>
      <div class="flex flex-col align-items justify-center gap-2">
        <Typography
          color="neutral-white"
          variant="body-medium"
          tag="span"
        >
          Renda Empresarial
        </Typography>
        <Typography
          color="neutral-white"
          variant="body-table"
          tag="span"
        >
          {{ formatCurrency(data?.Income?.Partner) || '-' }}
        </Typography>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Não há dados para serem exibidos.</p>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils";
import { CLASS_SOCIAL } from "@/common/constants";
import Typography from "../base/Typography";

export default {
  name: "Income",
  components: { Typography },
  props: ["data"],
  data() {
    return {
      formatCurrency,
      CLASS_SOCIAL,
    };
  },
};
</script>

<style lang="scss" scoped>
.border-data {
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}
.income-data {
  background: var(--colors-brand-dark);
  width: 564px;
}
.income-separator {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--colors-neutral-grey-4);
}
.social-class-data {
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
}
.social-class-width {
  max-width: 105px;
}
</style>
