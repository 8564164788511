<template>
  <div
    v-if="owners"
    id="owners-block-desktop"
    class="w-full py-5 px-0"
  >
    <div
      v-if="owners.isLoading"
      class="w-full flex flex-col justify-center"
    >
      <v-skeleton-loader
        class="mx-auto"
        width="100%"
        type="list-item-three-line"
      />
    </div>
    <div v-else>
      <template v-if="owners.length === 0">
        <div class="text-center" data-test="no-owners">
          Proprietários não encontrados.
        </div>
      </template>
      <template v-else>
        <div class="w-full my-3">
          <div class="w-2/3">
            <v-text-field
              v-model="search"
              label="Pesquisar unidade, nome ou número de contribuinte"
              single-line
              hide-details
            />
          </div>
        </div>
        <v-data-table
          v-model:expanded="expanded"
          :headers="headers"
          :search="search"
          :items="owners"
          :items-per-page="5"
          class="w-full owners-data-table"
          :single-expand="true"
          item-key="tableId"
        >
        <template v-slot:item="{ item }">
          <tr v-if="item.details">
            <td>
              <div class="flex align-center">
                <Typography
                    color="neutral-grey-5"
                    variant="body-medium-secondary"
                    tag="span"
                    data-test="unit"
                    class="white-space"
                  >
                  {{ item && item.unit ? treatUnit(item.unit) : '-' }}
                </Typography>
              </div>
            </td>
            <td>
              <div class="flex flex-column items-center justify-center gap-2 py-3">
                <Typography
                  href="https://duc.prefeitura.sp.gov.br/iptu/consulta_iptu/frm07_SelecaoIPTU.aspx"
                  target="_blank"
                  color="neutral-grey-5"
                  variant="title-small-secondary"
                  tag="a"
                  data-test="tax-payer-number"
                  class="table-text tax-payer-text"
                >
                    {{ item && item.taxPayerNumber || '-' }}
                </Typography>
                <div class="border-data" v-if="item?.details?.length === 0" data-test="registry-number">
                  <Typography
                    variant="label-small"
                    color="neutral-grey-5"
                  >
                    Matrícula:
                  </Typography>
                  <Typography
                    variant="label-small"
                    color="neutral-grey-5"
                  >
                    {{ item?.transaction && item?.transaction[0]?.registryNumber || '-' }}
                  </Typography>
                </div>
            </div>
            </td>
            <td class="name">
              <div class="flex flex-column gap-3">
                <div
                  v-for="(person, x) in item.details"
                  v-if="x < 2"
                  :key="x"
                  class="text-xs flex items-center justify-between"
                >
                  <div v-if="person && !person.isBlocked" class="name-div">
                    <small class="table-text">
                      {{ person && person.order || '-' }}º
                    </small>
                    <v-icon
                      small
                      class="mr-2"
                    >
                      mdi-account
                    </v-icon>
                    <span class="text-capitalize w-full table-text" data-test="name">{{ item.details[x].name && item.details[x].name.toLowerCase()||'-' }}</span>
                  </div>
                  &nbsp;
                  <template v-if="person">
                    <router-link
                      v-if="!person.isBlocked"
                      :key="x"
                      to="/person-data"
                      target="_blank"
                      @click.native="setPersonQuery(chooseDocumentOrName(person))"
                      data-test="not-blocked"
                    >
                      <Button
                        variant="primaryXs"
                        class="buttons-width"
                      >
                        Consultar dados
                      </Button>
                    </router-link>
                    <blocked-info-message
                      data-test="blocked"
                      v-if="person.isBlocked"  
                      class="w-full"
                      message="Dados bloqueados a pedido do titular de acordo com a Lei Geral de Proteção de Dados (LGPD)"
                    />
                  </template>
                </div>
              </div>
              <div v-if="item.details && item.details.length === 0" class="flex items-center justify-between">
                <div>
                  <Typography
                    variant="body-xs"
                    color="neutral-grey-5"
                    tag="span"
                    data-test="no-owner-data"
                  >
                    Proprietário não localizado pois o imóvel foi transacionado em {{ item?.transaction && item?.transaction[0]?.month }}/{{ item?.transaction && item?.transaction[0]?.year }}.
                    <br>
                    <Typography
                      variant="body-xs"
                      color="neutral-grey-5"
                      tag="span"
                    >
                      Utilize o contribuinte ou matrícula para localizá-lo.
                    </Typography>
                    <br>
                    <Typography
                      variant="body-xs"
                      color="neutral-grey-5"
                      tag="span"
                    >
                      Encontrou? 
                    </Typography>
                    <Typography
                      variant="body-xs"
                      color="neutral-grey-5"
                      tag="a"
                      :href="`mailto:${email}?subject=%5B${item?.taxPayerNumber}%5D%20%7C%20Indica%C3%A7%C3%A3o%20de%20propriet%C3%A1rio%20do%20empreendimento%20${condoName}&body=Dados%3A%20%0D%0A%0D%0AEndere%C3%A7o%3A%20${address}%0D%0A%0D%0ANome%20do%20propriet%C3%A1rio%201%3A%20%20%0D%0ACPF%20do%20propriet%C3%A1rio%201%3A%20%0D%0A%0D%0ANome%20do%20propriet%C3%A1rio%202%3A%20%20%0D%0ACPF%20do%20propriet%C3%A1rio%202%3A%20`"
                      target="_blank"
                      data-test="no-owner-data-email"
                      style="text-decoration: underline;"
                    >
                      Informar proprietário
                    </Typography>  
                  </Typography>                 
                </div>
                <div class="flex flex-column items-end justify-end gap-4 buttons-width">
                  <a
                    :href="`https://www.google.com/search?q=${item?.transaction[0]?.registryOffice}`"
                    target="_blank"
                    v-if="item?.transaction"
                  >
                    <Button
                      variant="primaryXs"
                    >
                      
                      Consultar cartório
                    </Button>
                  </a>
                  <a
                    href="https://www.prefeitura.sp.gov.br/cidade/secretarias/fazenda/servicos/certidoes/index.php?p=2395"
                    target="_blank"
                  >
                    <Button
                      variant="primaryXs"
                      class="buttons-width"
                      data-test="no-owner-iptu"
                    >
                      Consultar IPTU
                    </Button>
                  </a>
                </div>
              </div>
            </td>
            <td>
              <div class="text-xs table-text flex align-center">
                <div data-test="reference-year">
                  <v-icon x-small>
                    mdi-calendar
                  </v-icon>
                  {{ item && item.referenceYear || '-' }}
                </div>
              </div>
            </td>
          </tr>
        </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BlockedInfoMessage from '../../components/LGPD/BlockedInfoMessage.vue';
import Analytics from '../../components/icons/Analytics.vue';
import Button from '../../components/base/Button';
import Typography from '../../components/base/Typography';
import { noOwners } from '../../../tests/__mocks__/PropertyOwners';

export default {
  components: {
    BlockedInfoMessage,
    Analytics,
    Typography,
    Button
},
  props: {
    owners: {
      type: [
        Array,
        Object,
      ],
      default: () => [],
    },
    condoName: {
      type: String
    },
    address: {
      type: String
    }
  },
  data () {
    return {
      email: 'suportecidadevirtual@bossanovasir.com.br',
      expanded: noOwners,
      search: '',
      outrosImoveisSearch: '',
      headers: [
        {
          text: 'Unidade',
          value: 'unit',
          width: '15%',
          sortable: false,
        },
        {
          text: 'Contribuinte',
          value: 'taxPayer',
          width: '5%',
          sortable: false,
        },
        {
          text: 'Proprietário',
          value: 'name',
          width: '55%',
          sortable: false,
        },
        {
          text: 'Atualizado',
          value: 'date',
          width: '15%',
          sortable: false,
        },
      ],
      outrosimoveis: [
        {
          text: 'Ano',
          value: 'year',
        },
        {
          text: 'Complemento',
          value: 'complementNumber',
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setPersonQuery: 'person/SET_PERSON_QUERY',
    }),
    chooseDocumentOrName (person) {
      if (person.document.indexOf('XXXX') === -1) {
        return person.document;
      }

      return person.name;
    },
    treatUnit (unit) {
      return unit.replace('|', '\n')
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-button {
  width: 16px;
}
.white-space {
  white-space: pre-line;
  word-wrap: break-word;
}
.v-btn {
  text-transform: unset;
  font-weight: 700;
  width: 128px;
}
#owners-block-desktop {
  .text-start {
    padding: 1em .4em;
    vertical-align: top;
  }
}
.button-text {
  font-size: 10px;
}

.tax-payer-text {
  font-size: 12px;
}
.table-text {
  color: $colors-neutral-grey-5
}
.border-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
  min-width: 90px;
  min-height: 36px;
}
.buttons-width {
  min-width: 131px;
  width: 131px;
}
:deep(.v-data-table thead tr th) {
  height: 30px!important;
}
:deep(.v-data-table tbody tr td) {
  height: 36px!important;
  vertical-align: middle;
}
:deep(.v-data-table tbody tr td.name) {
  padding: 10px 0 !important;

}
:deep(.v-data-table thead tr th span) {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left; 
  color: #4663B9;
}
:deep(.v-data-table tbody tr.v-data-table__expanded__content) {
  box-shadow: none;
  background: #4e69ba;
  color: #fff;
}
:deep(.v-data-table__expanded .theme--light.v-data-table) {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
</style>