<template>
  <BaseCard>
    <div class="flex flex-column gap-1">
      <Typography
        variant="header-large"
        color="neutral-grey-6"
      >
        Telefones
      </Typography>
      <Typography
        variant="body-xs"
        color="neutral-grey-5"
      >
        Números nacionais e internacionais registrados
      </Typography>
    </div>
    <v-data-table
      v-if="phones"
      class="table"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="phones"
      sort-by="Status"
      :sort-desc="true"
    >
      <template #[`item.FormattedNumber`]="{ item }">
        <div>
          <div class="flex align-center">
            <img
              v-if="item.IsMobile"
              class="inline mr-1"
              height="18px"
              src="@/assets/images/icon-cellphone-light.svg"
            >
            <img
              v-else
              class="inline"
              height="18px"
              src="@/assets/images/icon-phone-light.svg"
            >
            <span class="ml-2">{{ item.FormattedNumber }}</span>
          </div>
        </div>
      </template>
      <template #[`item.isProcon`]="{ item }">
        <div class="flex align-center justify-center">
          <v-tooltip v-if="item.IsProcon" top >
            <template #activator="{ on }">
              <Badge
                variant="altColorIconProcon"
                v-on="on"
              >
                Procon
              </Badge>
            </template>
            <span>Bloqueado Procon</span>
          </v-tooltip>
          <div class="button-invisible" v-else />
        </div>
      </template>
      <template #[`item.IsWhatsapp`]="{ item }">
        <div class="flex align-center justify-end">
          <v-tooltip v-if="item.IsWhatsapp" top >
            <template #activator="{ on }">
              <Button
                variant="alternativeGreen"
                v-on="on"
                @click="startWhatsappChat(item)"
              >
                WhatsApp
              </Button>
            </template>
            <span>Abrir no WhatsApp</span>
          </v-tooltip>
          <div class="button-invisible" v-else />
        </div>
      </template>
      <template #[`item.Ranking`]="{ item }">
        {{ formatYear(item.Ranking) }}
      </template>
    </v-data-table>
    <div
      v-else
      class="noResult"
    >
      Não há dados para serem exibidos.
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/cards/base-card';
import { formatYear } from '@/utils';
import Typography from '../base/Typography';
import Badge from '../base/Badge';
import Button from '../base/Button';

export default {
  name: 'PhonesTable',
  components: { BaseCard, Typography, Badge, Button },
  props: {
    phones: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Telefone',
        value: 'FormattedNumber',
        width: 170,
      },
      {
        text: '',
        value: 'isProcon',
        sortable: false,
        width: 80,
        align: 'center',
      },
      {
        text: '',
        value: 'IsWhatsapp',
        sortable: false,
        width: 120,
        align: 'center',
      },
      {
        text: 'Última atualização',
        value: 'Ranking',
        width: 200,
        align: 'center',
      },
    ],
  }),
  methods: {
    whatsappLink(phone) {
      return encodeURI(`https://api.whatsapp.com/send?phone=55${phone.AreaCode}${phone.Number}`);
    },
    startWhatsappChat(phone) {
      window.open(this.whatsappLink(phone), '_blank');
    },
    formatYear,
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}

::v-deep th {
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #6879A6 !important;
}

::v-deep td {
  font-family: Lato !important;
  font-size: 14px !important;
}

::v-deep tbody tr {
  height: 68px!important;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child) {
  color: var(--colors-neutral-grey-5);
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:first-child)){
  color: #777;
  text-align: center;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:deep(.v-data-table .v-data-footer__select .v-select) {
  margin: 13px 0 13px 16px;
}
:deep(.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon) {
  font-size: 32px;
}

:deep(.v-data-table .v-data-footer__icons-before .v-btn:last-child){
  margin-right: 0px;
}

:deep(.v-data-table .v-data-footer__icons-after .v-btn:first-child){
  margin-left: 0px;
}

:deep(.v-data-table .v-data-footer__pagination) {
  margin: 0px 16px;
}

:deep(.v-data-table .v-data-footer) {
  display: flex;
  align-items: center;
  border-top: none!important;
  height: 32px;
  margin-top: 16px;
}

:deep(.v-data-table .v-data-footer .v-data-footer__select) {
  height: 32px;
  margin-right: 0px!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th) {
  border-bottom: none!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th i){
  margin-bottom: 5px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child)){
  margin: 15px 0px;
}

:deep(.v-data-table .v-data-table__wrapper) {
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}

.button-invisible {
  background-color: var(--colors-highlight-lighter)!important;
  box-shadow: none;
  cursor: none;
  pointer-events: none;
  padding: 0!important;
}
</style>
