<script setup>

import { computed, ref } from 'vue';

import { styles } from './HomeContent.styles';
import Typography from '@/components/base/Typography';
import HomeCardAction from './HomeCardAction.vue';
import HomeCardTools from './HomeCardTools.vue';
import HomeCardInfo from './HomeCardInfo.vue';

import { useHomeStore } from '@/pinia/homeStore';

const homeStore = useHomeStore();

const css = selector => styles[selector]().className;
const hovered = ref('');

const props = defineProps({
  tabs: {
    type: Array,
    default: () => {},
  },
  quotas: {
    type: Object,
    default: () => {},
  },
  permissions: {
    type: Object,
    default: () => {},
  },
});

const selected = computed(() => homeStore.getSelected);

const isHovered = id => hovered.value === id ? 'title-large-secondary' : 'tab-large'; 

const typographyVariant = id => id === selected.value ? 'title-large-secondary' : isHovered(id);
const typographyColor = id => id === selected.value ? 'highlight-light' : 'neutral-white';
const cssClass = id => id === selected.value ? 'selectedItem': 'defaultItem';

const actionCardContent = computed(() => {
  return {
    ...props.tabs[props.tabs.findIndex(el => el.id === selected.value)].action,
    label: props.tabs[props.tabs.findIndex(el => el.id === selected.value)].label,
    id: props.tabs[props.tabs.findIndex(el => el.id === selected.value)].id,
    permissions: props.permissions,
  };
});

const statsVisibility = computed(() => {
  return props.tabs[props.tabs.findIndex(el => el.id === selected.value)].showInfo;
});

const toolsCardContent = computed(() => props.tabs[props.tabs.findIndex(el => el.id === selected.value)].tools);

function changeSelected (id) {
  homeStore.setSelected(id);
}

function setHovered (id) {
  hovered.value = id;
}

</script>

<template>
  <div :class="css('container')">
    <div :class="css('menu')">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :class="css(cssClass(tab.id))"
        @click="changeSelected(tab.id)"
      >
        <Typography
          tag="h2"
          :variant="typographyVariant(tab.id)"
          :color="typographyColor(tab.id)"
          @mouseover="setHovered(tab.id)"
          @mouseout="setHovered('')"
        >
          {{ tab.label }}
        </Typography>
      </div>
    </div>

    <div :class="css('content')">
      <div>
        <HomeCardAction :card-data="actionCardContent" />
        <HomeCardInfo
          v-if="statsVisibility"
          :quotas="quotas"
        />
      </div>
      <div>
        <HomeCardTools :tools="toolsCardContent" />
      </div>
    </div>
  </div>
</template>
