<script setup>

import { styles } from './HomeHeader.styles';
import Typography from '@/components/base/Typography';
import HomePinIcon from '@/components/icons/HomePin';

import { useHomeStore } from '@/pinia/homeStore';
import { useUpdatedStore } from '@/pinia/updatedStore';

const homeStore = useHomeStore();
const updatedStore = useUpdatedStore();

const css = selector => styles[selector]().className;

const props = defineProps({
  userName: {
    type: String,
    default: '-',
  },
  userEmail: {
    type: String,
    default: '-',
  },
});

updatedStore.fetchUpdated();

</script>

<template>
  <div :class="css('container')">
    <div :class="css('updatedContainer')">
      <Typography
        tag="p"
        variant="body-small"
        color="brand-lighter"
      >
        Atualizado em {{ updatedStore.updatedAt }}
      </Typography>
    </div>
    <div :class="css('content')">
      <div :class="css('userInfo')">
        <div :class="css('greeting')">
          <Typography
            tag="h2"
            variant="header-large-secondary"
            color="neutral-white"
          >
            Olá
          </Typography>
          <Typography
            tag="h2"
            variant="header-large"
            color="neutral-white"
          >
            {{ userName }}
          </Typography>
        </div>
        <div :class="css('email')">
          <Typography
            tag="h2"
            variant="title-small-secondary"
            color="neutral-white"
          >
            {{ userEmail }}
          </Typography>
        </div>
      </div>
      <div :class="css('pins')">
        <HomePinIcon :class="`large ${homeStore.getSelected}-tab`" />
        <HomePinIcon :class="`small ${homeStore.getSelected}-tab`" />
        <HomePinIcon :class="`large ${homeStore.getSelected}-tab`" />
        <HomePinIcon :class="`small ${homeStore.getSelected}-tab`" />
      </div>
    </div>
  </div>
</template>