<template>
  <div class="analysis-container" data-test-id="analysis-companies">
    <Typography
      variant="body-bold"
      color="highlight-default"
    >
      Empresas
    </Typography>
    <div class="analysis-table" v-if="score?.shareCapital">
      <div class="income-header">
        <div class="flex items-center justify-between">
          <Typography
            variant="body-bold"
            color="brand-darker"
            class="w-3/12"
          >
            Status
          </Typography>
          <div class="w-2/12 flex justify-end">
            <Typography
              variant="body-bold"
              color="brand-darker"
            >
              Valor médio
            </Typography>
          </div>
          <div class="w-7/12"></div>
        </div>
      </div>
      <div class="income-body" data-test-id="success">
        <div class="flex items-center justify-bewteen">
          <Typography
            variant="label-medium"
            color="neutral-grey-5"
            class="my-0 w-3/12"
          >
            Ativa
          </Typography>
          <div class="my-0 w-2/12 flex justify-end">
            <Typography
              variant="body-medium"
              color="neutral-grey-5"
              data-test-id="share-capital-value"
            >
              {{ score.shareCapital | money }}
            </Typography>
          </div>
          
          <div class="flex justify-end w-7/12">
            <Button
              variant="primary"
              data-test-id="details-button"
              @click="setActiveTab('PartnerShips')"
              class="button-height"
            >
              Ver detalhes
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else data-test-id="data-error-message">
      Não há dados disponíveis.
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Typography from '../base/Typography';
import Button from '../base/Button';

export default {
    name: 'AnalysisCompanies',
    computed: {
        ...mapState('person', ['score']),
    },
    methods: {
        ...mapActions('person', ['setActiveTab']),
    },
    components: { Typography, Button }
};
</script>

<style lang="scss" scoped>
.analysis-container {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}

.analysis-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.income-body {
  display: flex;
  flex-direction: column;
}

hr {
  height: 0px;
  align-self: stretch;
  stroke-width: 1px;
  stroke: #E8E8E8;
  margin: 16px 0px;
}

.button-height {
  height: 32px;
  min-width: 110px!important;
  width: 110px!important;
}
</style>
