<template>
  <v-card class="skeleton-card">
    <v-skeleton-loader
        width="100%"
        height="120px"
        type="image"
    ></v-skeleton-loader>
    <div class="p-3">
      <div class="mt-1">
        <div class="flex mb-3">
          <v-skeleton-loader class="text" width="40%" type="text" />
          <v-skeleton-loader class="text ml-auto" width="20%" type="text" />
        </div>
        <div class="grid grid-cols-6">
          <div class="col-span-4">
            <v-skeleton-loader class="text" width="100%" type="text" />
            <v-skeleton-loader class="text mt-2" width="100%" type="text" />
            <v-skeleton-loader class="text mt-2" width="100%" type="text" />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-3 mt-3 pr-5">
        <div class="col-span-3">
          <v-skeleton-loader width="100%" height="24px" type="button" />
        </div>
        <div class="">
          <v-skeleton-loader width="100%" height="24px" type="button" />
        </div>
        <div class="">
          <v-skeleton-loader width="100%" height="24px" type="button" />
        </div>
        <div class="">
          <v-skeleton-loader width="100%" height="24px" type="button" />
        </div>
      </div>
      <div class="mt-3">
        <v-skeleton-loader class="text mb-1" width="60%" type="text" />
        <div class="grid grid-cols-6 mb-3">
          <div class="col-span-4">
            <v-skeleton-loader class="text mt-2" width="100%" type="text" />
            <v-skeleton-loader class="text mt-2" width="100%" type="text" />
            <v-skeleton-loader class="text mt-2" width="100%" type="text" />
          </div>
          <div class="col-span-2 flex">
            <v-skeleton-loader class="ml-auto mt-auto" width="24px" height="24px" type="button" />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'SkeletonCard',
};

</script>

<style lang="scss" scoped>
  ::v-deep.skeleton-card {
    @apply overflow-hidden border border-solid;
    border-radius: 13px;
    border-color: #cccccc;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
    .v-skeleton {
      &-loader {
        &__text {
          @apply rounded-none;
        }
        &__button {
          @apply w-full;
        }
      }
    }
    .text {
      height: 10px;
    }
  }
</style>
