export default function setShowWithoutOffers(
  { commit, dispatch, rootState },
  payload
) {
  const {
    route: { name }
  } = rootState;

  const {
    lastSearch: [params, attributesFilter]
  } = rootState[name];

  commit('SET_SHOW_WITHOUT_OFFERS', payload);
  dispatch(
    'summary/fetchSummary',
    { attributesFilter, params },
    { root: true }
  );
}
