import { css } from '@/core/theme';

const _appearance = {
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
};

const _focusOrNotPlaceholderShownLabel = {
  transform: 'translate(14px, -7px)',
  backgroundColor: '$neutral-white',
  color: '$highlight-default',
  fontSize: '$body-small',
  padding: '2px 3px',
  borderRadius: '3px',
};

const _autofill = {
  color: '$brand-dark',
  fontFamily: '$font-family-primary',
  fontSize: '$body-large',
};

const _autofillFirstLine = {
  color: '$brand-dark',
  fontFamily: '$font-family-primary',
  fontSize: '$body-large',
};

const _inputPlaceholder = {
  opacity: 0,
  transition: 'inherit',
};

const root = css({
  position: 'relative',
});

const label = css({
  transition: 'all .25s',
  touchAction: 'manipulation',
  position: 'absolute',
  top: 0,
  left: 0,
  lineHeight: '$default',
  userSelect: 'none',
});

const suffix = css({
  position: 'absolute',
  top: '14px',
  right: '14px',
  padding: '2px',
  borderRadius: '2px',
  fontSize: '$body-large',
  backgroundColor: '$highlight-lighter',
  color: '$highlight-default',
  cursor: 'text',
  transition: 'all .25s',
  opacity: 0,
});

const input = css({
  fontSize: '$body-large',
  border: '1px solid $neutral-grey-2',
  borderRadius: '8px',
  backgroundColor: '$highlight-lighter',
  padding: '0 14px',
  cursor: 'text',
  transition: 'all .25s',
  touchAction: 'manipulation',
  width: '100%',
  height: '56px',
  color: '$brand-dark',
  ..._appearance,

  '&::-moz-placeholder': {
    ..._inputPlaceholder,
  },

  '&::-webkit-input-placeholder': {
    ..._inputPlaceholder,
  },

  '&:disabled': {
    backgroundColor: '$neutral-grey-2',
    color: '$neutral-grey-4',
    cursor: 'not-allowed',
    userSelect: 'none',
    borderColor: '$neutral-grey-3',
  },

  '&:hover:not([disabled])': {
    borderColor: '$neutral-grey-5',
  },

  '&:invalid': {
    borderColor: '$feedback-error',
    boxShadow: 'inset 0 0 0 1px $colors$feedback-error',

    [`& + .${label}`]: {
      color: '$feedback-error',
    },
  },

  '&:focus': {
    outline: 0,
    borderColor: '$highlight-default',
    boxShadow: 'inset 0 0 0 1px $colors$highlight-default',

    '&::-moz-placeholder': {
      opacity: 1,
    },

    '&::-webkit-input-placeholder': {
      opacity: 1,
    },
  },
  
  [`&:placeholder-shown + .${label}`]: {
    cursor: 'text',
    width: 'auto',
    maxWidth: '85%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transformOrigin: 'left bottom',
    transform: 'translate(14px, 18px)',
    fontSize: '$body-large',
    color: '$neutral-grey-5',
    backgroundColor: 'transparent',
  },

  [`&:not(placeholder-shown) + .${label}`]: {
    ..._focusOrNotPlaceholderShownLabel,
  },

  [`&:focus + .${label}`]: {
    ..._focusOrNotPlaceholderShownLabel,
  },

  [`&:focus + .${label} + .${suffix}`]: {
    opacity: 1,
  },

  '&:-moz-autofill': {
    ..._autofill,
  },

  '&:-webkit-autofill': {
    ..._autofill,
  },

  '&:-moz-autofill::first-line': {
    ..._autofillFirstLine,
  },

  '&:-webkit-autofill::first-line': {
    ..._autofillFirstLine,
  },

  [`&:-moz-autofill + .${label}`]: {
    ..._focusOrNotPlaceholderShownLabel,
  },

  [`&:-webkit-autofill + .${label}`]: {
    ..._focusOrNotPlaceholderShownLabel,
  },
});

const error = css({
  fontSize: '$body-small',
  color: '$feedback-error',
  padding: '$nano $small 0',
  lineHeight: '$default',
});

export const styles = {
  root,
  label,
  suffix,
  input,
  error,
};
