<script setup>

import {
  ref,
  computed,
  watch,
} from 'vue';

import { mask as vMask } from 'vue-the-mask';

/**
 * Constants
 */
import {
  NAVBAR_MENU_ITEMS,
  OPENSEARCH_CITIES,
  OPENSEARCH_PORTALS,
  OPENSEARCH_NEGOTIATION_TYPES,
  OPENSEARCH_SELLER_TYPES,
  OPENSEARCH_PROPERTY_TYPES,
} from '@/common/constants';

/**
 * Utils
 */
import toggleStringSequence from '@/utils/toggleStringSequence';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import Badge from '@/components/base/Badge';
import Button from '@/components/base/Button';
import TextField from '@/components/base/TextField';
import CurrencyField from '@/components/base/CurrencyField';
import Select from '@/components/base/Select';
import MultiSelect from '@/components/forms/MultiSelect.vue';
import Autocomplete from '@/components/forms/Autocomplete.vue';

/**
 * Styles
 */
import { styles } from './OpenSearchSidebar.styles';

/**
 * Props
 */
const props = defineProps({

  loading: {
    type: Boolean,
    default: false,
  },

  data: {
    type: Object,
    default: () => ({ }),
  },

  attributes: {
    type: Object,
    default: () => ({
      data: [],
      loading: false,
    }),
  },

  districts: {
    type: Object,
    default: () => ({
      data: [],
      loading: false,
    }),
  },

  sellers: {
    type: Object,
    default: () => ({
      data: [],
      loading: false,
    }),
  },
});

/**
 * Emits
 */
const emit = defineEmits([
  'submit',
  'clear',
  'seller-search',
]);

/**
 * Data
 */
const form = ref({ ...props.data });

/**
 * Computed
 */
const areaType = computed(() => {

  if (form.value.propertyTypeId === 'Apartamento'
    || form.value.propertyTypeId === 'Cobertura') {

    return 'útil';
  }

  if (form.value.propertyTypeId === 'Casa') {
  
    return 'constr.';
  }
  
  return '';
});

const sortedAttributes = computed(() => (props.attributes.data ? [...props.attributes.data] : [])
  .sort((a, b) => a?.description.localeCompare(b?.description)),
);

const districtsByCity = computed(() => {

  const city = (props.districts.data || []).find(item => item.cityName === form.value.city);

  return (city && city.districts) || [];
});

/**
 * Watch
 */
watch(() => props.data, data => form.value = { ...data });

watch(() => form.value.city, () => {

  form.value.district = '';

  sellerSearchInput();
});

/**
 * Methods
 */
const sellerSearchInput = value => emit('seller-search', {
  city: form.value.city,
  value,
});

const attributeClick = attributeId => 
  form.value.attributeIds = toggleStringSequence(attributeId, form.value.attributeIds);

const clear = () => emit('clear');

const submit = () => emit('submit', form.value);

</script>

<template>
  <div :class="styles.root().className">
    <Typography
      tag="h1"
      variant="header-xl-secondary"
      color="brand-dark"
      align="center"
    >
      {{ NAVBAR_MENU_ITEMS.OPEN_SEARCH.label }}
    </Typography>

    <div :class="styles.fields().className">
      <div :class="styles.city().className">
        <MultiSelect
          v-model="form.city"
          :default-selection="form.city"
          :buttons="OPENSEARCH_CITIES"
        />
      </div>

      <div :class="styles.district().className">
        <Autocomplete
          v-model="form.district"
          :options="districtsByCity"
          name="district"
          label="Bairro"
          placeholder="Bairro"
        />
      </div>

      <div :class="styles.business().className">
        <Select
          v-model="form.negotiationType"
          name="negotiationType"
          label="Tipo de negócio"
          placeholder="Tipo de negócio"
          :items="OPENSEARCH_NEGOTIATION_TYPES"
        />

        <Select
          v-model="form.sellerType"
          name="sellerType"
          label="Tipo de anunciante"
          placeholder="Tipo de anunciante"
          :items="OPENSEARCH_SELLER_TYPES"
        />

        <Autocomplete
          v-model="form.sellerIds"
          remote
          :multiple="{
            label: 'name',
            value: 'id',
          }"
          :options="sellers.data"
          :loading="sellers.loading"
          name="sellerIds"
          label="Imobiliária(s)"
          placeholder="Imobiliária(s)"
          @search-input="sellerSearchInput"
        />

        <Select
          v-model="form.portal"
          name="portal"
          label="Portal"
          placeholder="Portal"
          :items="OPENSEARCH_PORTALS"
        /> 
      </div>

      <div :class="styles.typology().className">
        <div data-column>
          <Select
            v-model="form.propertyTypeId"
            name="propertyTypeId"
            label="Tipo de imóvel"
            placeholder="Tipo de imóvel"
            :items="OPENSEARCH_PROPERTY_TYPES"
          />
        </div>

        <div data-column>
          <CurrencyField
            v-model="form.valueMin"
            name="valueMin"
            label="Valor mínimo"
            placeholder="Valor mínimo"
            type="text"
            suffix="R$"
          />
       
          <CurrencyField
            v-model="form.valueMax"
            name="valueMax"
            label="Valor máximo"
            placeholder="Valor máximo"
            type="text"
            suffix="R$"
          />
        </div>

        <div data-column>
          <TextField
            v-model="form.areaMin"
            v-mask="'#########'"
            name="areaMin"
            :label="`Área ${areaType} mínima`"
            :placeholder="`Área ${areaType} mínima`"
            type="text"
            suffix="m²"
          />

          <TextField
            v-model="form.areaMax"
            v-mask="'#########'"
            name="areaMax"
            :label="`Área ${areaType} máxima`"
            :placeholder="`Área ${areaType} máxima`"
            type="text"
            suffix="m²"
          />
        </div>

        <div data-column>
          <TextField
            v-model="form.bedroomsMin"
            v-mask="'#########'"
            name="bedroomsMin"
            label="Dorms. (mínimo)"
            placeholder="Dorms. (mínimo)"
            type="text"
          />

          <TextField
            v-model="form.bedroomsMax"
            v-mask="'#########'"
            name="bedroomsMax"
            label="Dorms. (máximo)"
            placeholder="Dorms. (máximo)"
            type="text"
          />
        </div>

        <div data-column>
          <TextField
            v-model="form.suitesMin"
            v-mask="'#########'"
            name="suitesMin"
            label="Suítes (mínimo)"
            placeholder="Suítes (mínimo)"
            type="text"
          />

          <TextField
            v-model="form.suitesMax"
            v-mask="'#########'"
            name="suitesMax"
            label="Suítes (máximo)"
            placeholder="Suítes (máximo)"
            type="text"
          />
        </div>

        <div data-column>
          <TextField
            v-model="form.parkingLotsMin"
            v-mask="'#########'"
            name="parkingLotsMin"
            label="Vagas (mínimo)"
            placeholder="Vagas (mínimo)"
            type="text"
          />

          <TextField
            v-model="form.parkingLotsMax"
            v-mask="'#########'"
            name="parkingLotsMax"
            label="Vagas (máximo)"
            placeholder="Vagas (máximo)"
            type="text"
          />
        </div>
        
        <Typography
          tag="h2"
          variant="title-medium"
          color="brand-dark"
          align="left"
        >
          Selecione as características
        </Typography>

        <div data-attributes>          
          <template v-if="attributes.loading">
            <Typography
              tag="span"
              variant="title-small"
              color="highlight-dark"
              align="left"
            >
              Carregando características...
            </Typography>
          </template>

          <template v-else-if="!attributes.loading && !sortedAttributes.length">
            <Typography
              tag="span"
              variant="title-small"
              color="highlight-dark"
              align="left"
            >
              Não há nenhuma característica.
            </Typography>
          </template>

          <template v-else>
            <Badge
              v-for="(attribute, attributeIndex) in sortedAttributes"
              :key="`attributeIndex${attributeIndex}`"
              :active="form.attributeIds.includes(`${attribute.id}`)"
              @click="() => attributeClick(attribute.id)"
            >
              {{ attribute.description }}
            </Badge>
          </template>
        </div>
      </div>
    </div>

    <div :class="styles.footer().className">
      <Button
        variant="secondary"
        :disabled="loading"
        @click="clear"
      >
        Limpar dados
      </Button>

      <Button
        variant="primary"
        :disabled="loading"
        @click="submit"
      >
        {{ loading ? 'Aguarde...' : 'Confirmar' }}
      </Button>
    </div>
  </div>
</template>
