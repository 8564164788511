var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row items-center gap-2"},[(_vm.isDesktop)?_c('div',[(_vm.title !== '')?_c('div',{staticClass:"legacy-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.offersInfo && _vm.offersInfo.title)?_c('span',{staticClass:"offers-info",attrs:{"id":"previous-offers"}},[_c('span',{staticClass:"offers-title offers-border-gray"}),_c(_setup.Typography,{staticClass:"type-offer",attrs:{"color":"neutral-grey-5","variant":"title-medium","tag":"span"}},[_vm._v(" "+_vm._s(_vm.offersInfo.title)+" ")]),_c('span',{staticClass:"pipe"}),_c(_setup.Typography,{staticClass:"type-offer",attrs:{"color":"neutral-grey-5","variant":"body-medium","tag":"span"}},[_vm._v(" "+_vm._s(_vm.offersInfo.description)+" ")])],1):_vm._e()]):_c('div',[(_vm.offersInfo && _vm.offersInfo.title)?_c('div',[_c(_setup.Typography,{attrs:{"color":"neutral-grey-6","variant":"title-large-secondary","tag":"div"}},[_vm._v(" "+_vm._s(_vm.offersInfo.title)+" ")])],1):_vm._e(),(_vm.title !== '')?_c(_setup.Typography,{attrs:{"color":"brand-dark","variant":"title-large-secondary","tag":"div"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_vm._m(0),_c('div',{attrs:{"id":"collapse-chevron"},on:{"click":() => {
        _vm.$emit('panel-expand')
        if (!_setup.isOpen) {
          _vm.sendEvent()
        }
      }}},[(!_vm.isDesktop)?_c(_setup.ExpandAccordionIcon,{staticClass:"rotatable",style:(_setup.iconRotation)}):_c(_setup.ExpandAccordionIconLegacy,{staticClass:"rotatable",style:(_setup.iconRotation),attrs:{"offerType":_vm.offerType}})],1)]),(!_vm.isDesktop)?_c('div',{staticClass:"mb-4"},[(_vm.offersInfo && _vm.offersInfo.description)?_c(_setup.Typography,{attrs:{"color":"neutral-grey-6","variant":"body-medium","tag":"div"}},[_vm._v(" "+_vm._s(_vm.offersInfo.description)+" ")]):_vm._e()],1):_vm._e(),(_setup.isOpen)?_c('div',{class:!_vm.isDesktop ? 'mobile-flex' : ''},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-grow w-0 h-0"},[_c('div',{staticClass:"border-t border-solid",staticStyle:{"border-color":"#D3D6DE"}})])
}]

export { render, staticRenderFns }