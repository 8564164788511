import { css } from '@/core/theme';

const wrapper = css({
  maxHeight: '77px',
  transition: 'max-height .2s linear 0',
});

const wrapperHovered = css({
  maxHeight: '200px',
  transition: 'max-height .2s linear 0',
});

const container = css({
  padding: '$nano $small',
  display: 'flex',
  gap: '$small',
  borderRadius: '1px',
  cursor: 'pointer',
  transition: 'all .2s ease-out',
  overflow: 'hidden',
  
  '> svg': {
    minWidth: '62px',
    marginTop: '8px',
    overflow: 'hidden',
  },
});

const title = css({
  marginBottom: '12px',
});

const textContent = css({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const textTruncated = css({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',  
  overflow: 'hidden',
});

const defaultBackground = css({
  backgroundColor: 'rgba(243, 245, 251, 0)',
});

const hoveredBackground = css({
  backgroundColor: 'rgba(243, 245, 251, 1)',
});


export const styles = {
  wrapper,
  wrapperHovered,
  container,
  title,
  textContent,
  defaultBackground,
  hoveredBackground,
  textTruncated,
};
