/**
 * Colors
 */
export default {

  // Brand
  'brand-lighter': '#C7D0EA',
  'brand-light': '#A5B2D9',
  'brand-default': '#768AC6',
  'brand-dark': '#303A56',
  'brand-darker': '#1B2644',

  // Hightlight
  'highlight-lighter': '#ECEFF8',
  'highlight-light': '#6B8CEF',
  'highlight-default': '#4663B9',
  'highlight-dark': '#3D56A1',
  'highlight-darker': '#334887',

  // Neutral
  'neutral-white': '#FFFFFF',
  'neutral-grey-1': '#F4F4F4',
  'neutral-grey-2': '#DDDDDD',
  'neutral-grey-3': '#D3D6DE',
  'neutral-grey-4': '#A2A5AB',
  'neutral-grey-5': '#707070',
  'neutral-grey-6': '#555555',
  'neutral-grey-7': '#212121',
  'neutral-black': '#000000',

  // Feedback
  'feedback-success': '#24C196',
  'feedback-error': '#C12424',
  'feedback-warning': '#E3D450',

  // Additional
  'additional-blue-1': '#E3E8F5',
  'additional-blue-2': '#E1E8FC',
  'additional-blue-3': '#BDCCF8',
  'additional-blue-4': '#9CB0EB',

  // Map pins
  'map-pin-blue': '#6B8CEF',
  'map-pin-green': '#24C196',
  'map-pin-red': '#EF6B6B',
  'map-pin-yellow': '#E3D450',
  'map-pin-darkblue': '#334887',
};
