<template>
  <div class="main-filters mx-auto w-full bg-white ">
    <Typography
      variant="title-medium"
      color="highlight-default"
    >
      Buscar por
    </Typography>

    <div
      id="filter-mode"
      class="mt-4"
    >
      <ul>
        <li
          :class="[
            'p-1',
            'w-1/3',
            'text-center',
            filterMode === FILTER_MODE.RADIUS && 'active',
          ]"
        >
          <a
            :tabindex="1"
            class="flex flex-col w-full"
            @click.stop="setFilterMode(FILTER_MODE.RADIUS)"
          >
            <div class="icon-wrapper-mobile">
              <img 
                class="svg-icon"
                src="@/assets/images/location.svg"
                v-if="filterMode === FILTER_MODE.RADIUS"
              >
              <img 
                class="svg-icon"
                src="@/assets/images/location-disabled.svg"
                v-if="filterMode !== FILTER_MODE.RADIUS"
              >
            </div>
            <Typography
              variant="label-small"
              color="brand-dark"
            >
              Localização
              <span
                v-if="filter && filter.radius"
                class="mx-2 text-xs"
              >
                ({{ ((filter.radius * 2) / 1000).toFixed(2) }}Km)
              </span>
            </Typography>
          </a>
        </li>
        <li
          :class="[
            'p-1',
            'w-1/3',
            'text-center',
            filterMode === FILTER_MODE.REGION && 'active',
          ]"
        >
          <a
            :tabindex="1"
            class="flex flex-col w-full"
            @click.stop="setFilterMode(FILTER_MODE.REGION)"
          >
            <div class="icon-wrapper-mobile">
              <img 
                class="svg-icon"
                src="@/assets/images/region.svg"
                v-if="filterMode === FILTER_MODE.REGION"
              >
              <img 
                class="svg-icon"
                src="@/assets/images/region-disabled.svg"
                v-if="filterMode !== FILTER_MODE.REGION"
              >
            </div>
            <Typography
              variant="label-small"
              color="brand-dark">
                Região
            </Typography>
          </a>
        </li>
      </ul>
    </div>
    <div class="mt-4">
      <GoogleMapAutocomplete
        v-if="filterMode === FILTER_MODE.RADIUS"
        v-model="gMapAutocomplete"
        :tabindex="3"
        @select="onAutoCompleteSelection"
      />
      <RegionAutocomplete
        v-else-if="filterMode === FILTER_MODE.REGION"
        v-model="regionModel"
        :tabindex="3"
        :current-city="currentCity"
        @region="onRegion"
      />
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations, mapGetters } from 'vuex';
import { FILTER_MODE, MAP_OPTIONS } from '@/common/constants';

import GoogleMapAutocomplete from '@/components/autocomplete/GoogleMapAutocomplete';
import RegionAutocomplete from '@/components/autocomplete/RegionAutocomplete';
import Typography from '@/components/base/Typography';

export default {
  components: {
    RegionAutocomplete,
    GoogleMapAutocomplete,
    Typography,
  },
  data: () => ({
    FILTER_MODE,
    gMapAutocomplete: null,
    regionModel: null,
  }),
  computed: {
    ...mapGetters('map', ['currentCity']),
    ...mapState({
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`]; 
      },
      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`]; 
      },
    }),
  },
  watch: {
    filter: {
      deep: true,
      handler (filter) {
        if (!filter) {
          this.gMapAutocomplete = null;
          this.regionModel = null;
        }
      },
    },
  },

  methods: {
    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setAutocompleteData (commit, payload) {
        return commit(`${this.$route.name}/SET_AUTOCOMPLETE_DATA`, payload); 
      },
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),
    onAutoCompleteSelection (value) {
      this.setAutocompleteData(value);

      if (!(value && value.geometry && value.geometry.location)) return;

      const { geometry: { location: { lat, lng } } } = value;
      const center = [
        lat(),
        lng(),
      ];

      this.setMapCenter(center);
      this.setFilter({
        position: center,
        radius: MAP_OPTIONS.DEFAULT_RADIUS,
      });
    },
    onRegion (value) {
      if (!value) return;

      const { center: point, coordinates } = value;

      this.setFilter({
        coordinates: [
          coordinates.map(([
            lat,
            lng,
          ]) => [
            lng,
            lat,
          ]),
        ],
        point,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-filters{
  border-bottom: none;
}

#filter-mode {
  ul {
    align-items: flex-start;
    align-self: stretch;
    display: flex ;
    justify-content: space-between;
    padding: 0px 80px;
  }

  li {
    .icon-wrapper-mobile {
      display: flex;
      margin: auto;
      border-radius: 9999px;
      margin-bottom: 8px;
      justify-content: center;
      border: 1px solid #e2e8f0;
      width: 52px;
      height: 52px;
      background-color: #F3F5FB;
    }

    &.active {
      .icon-wrapper-mobile {
        background-color: $colors-highlight-default;
      }
    }
  }
}
</style>
