<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0C2.91049 0 0 2.91049 0 6.5C0 10.0895 2.91049 13 6.5 13C10.0895 13 13 10.0895 13 6.5C13 2.91049 10.0895 0 6.5 0ZM6.03571 3.36607C6.03571 3.30223 6.08795 3.25 6.15179 3.25H6.84821C6.91205 3.25 6.96429 3.30223 6.96429 3.36607V7.3125C6.96429 7.37634 6.91205 7.42857 6.84821 7.42857H6.15179C6.08795 7.42857 6.03571 7.37634 6.03571 7.3125V3.36607ZM6.5 9.75C6.31776 9.74628 6.14423 9.67127 6.01666 9.54107C5.88909 9.41087 5.81764 9.23585 5.81764 9.05357C5.81764 8.87129 5.88909 8.69627 6.01666 8.56607C6.14423 8.43587 6.31776 8.36086 6.5 8.35714C6.68224 8.36086 6.85577 8.43587 6.98334 8.56607C7.11091 8.69627 7.18236 8.87129 7.18236 9.05357C7.18236 9.23585 7.11091 9.41087 6.98334 9.54107C6.85577 9.67127 6.68224 9.74628 6.5 9.75Z" fill="#E74C3C"/>
  </svg>
</template>

<script>

export default {
  name: 'AlertIcon',
}

</script>