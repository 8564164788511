<template>
  <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5385 0C11.0255 0 8.17789 2.97475 8.17789 6.64453C8.17789 9.61137 12.335 15.0188 13.9276 16.9815C14.2502 17.3791 14.8272 17.3791 15.1493 16.9815C16.7419 15.0188 20.899 9.61137 20.899 6.64453C20.899 2.97475 18.0514 0 14.5385 0ZM14.5385 8.85938C13.3673 8.85938 12.4183 7.86797 12.4183 6.64453C12.4183 5.42109 13.3673 4.42969 14.5385 4.42969C15.7096 4.42969 16.6587 5.42109 16.6587 6.64453C16.6587 7.86797 15.7096 8.85938 14.5385 8.85938ZM1.01567 11.388C0.715907 11.5132 0.458936 11.7294 0.277901 12.0087C0.0968668 12.2879 7.17941e-05 12.6175 0 12.9547L0 26.1552C0 26.7521 0.576995 27.1603 1.10755 26.9388L8.07692 23.625V11.3337C7.63067 10.491 7.2657 9.67043 7.00421 8.88574L1.01567 11.388ZM14.5385 18.967C13.8282 18.967 13.1563 18.6411 12.6954 18.0726C11.703 16.8492 10.6474 15.4559 9.69231 14.0268V23.6245L19.3846 26.9995V14.0273C18.4295 15.4559 17.3745 16.8497 16.3815 18.0731C15.9206 18.6411 15.2487 18.967 14.5385 18.967ZM27.9694 8.49867L21 11.8125V27L28.0613 24.0495C28.3611 23.9243 28.6181 23.7081 28.7991 23.4289C28.9802 23.1496 29.0769 22.8201 29.0769 22.4828V9.2823C29.0769 8.68535 28.4999 8.27719 27.9694 8.49867Z" fill="currentColor"/>
    <circle cx="14.5" cy="6.5" r="2.5" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'MapIcon',
};
</script>
