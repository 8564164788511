<script setup>

import { computed, inject, ref } from 'vue';
import { useUpdatedStore } from '@/pinia/updatedStore';
import { useRouter } from 'vue-router/composables';

import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';
import MobilePersonDataIcon from '@/components/icons/MobilePersonDataIcon.vue';
import MobileCondominiumIcon from '@/components/icons/MobileCondominiumIcon.vue';

import Quota from '@/views/mobile/Quota.vue';
import QuotaNotification from '@/views/mobile/QuotaNotification.vue';

const updatedStore = useUpdatedStore();
const vuexStore = inject('vuex-store');
const router = useRouter();

const quotaNotificationDisplay = ref(false);

const quota = computed(() => vuexStore.getters['user/consultaDadosQuota']);

async function logout () {
  await vuexStore.dispatch('user/logout', { router });
}

function tryPersonDataSearch () {
  if (parseInt(quota.value.available)) {
    return router.push('/mobile/person-data/search');
  }

  quotaNotificationDisplay.value = true;
}

function quotaNotificationAction () {
  quotaNotificationDisplay.value = false;
}

vuexStore.dispatch('user/fetchConsultaDadosQuota');
updatedStore.fetchUpdated();

</script>

<template>
  <div
    class="home"
  >
    <QuotaNotification
      :display="quotaNotificationDisplay"
      @quota-notification-click="() => quotaNotificationAction()"
    />
    <div class="flex flex-row justify-end w-full pt-4 px-4">
      <Typography
        color="highlight-light"
        variant="link"
        tag="span"
        class="cursor-pointer"
        @click="logout()"
      >
        Sair
      </Typography>
    </div>
    <Typography
      color="brand-lighter"
      variant="body-small"
      tag="div"
      class="my-4"
    >
      Atualizado em {{ updatedStore.updatedAt }}
    </Typography>

    <div class="flex flex-col gap-4 justify-center items-center">
      <Typography
        color="highlight-light"
        variant="title-large"
        tag="div"
      >
        Bem vindo ao
      </Typography>
      <Typography
        color="highlight-light"
        variant="header-xxl"
        tag="div"
      >
        Cidade Virtual
      </Typography>
    </div>

    <div class="flex-grow">
      <Typography
        color="brand-lighter"
        variant="header-xl"
        tag="h2"
        class="mb-8 mt-16"
      >
        O que vamos fazer hoje?
      </Typography>

      <Button
        variant="primary"
        size="medium"
        :full-width="true"
        class="w-full mx-auto px-4"
        style="gap: 4px !important"
        @click="tryPersonDataSearch()"
      >
        <template #left-icon>
          <MobilePersonDataIcon />
        </template>
        Consultar Dados
      </Button>
      <router-link
        to="/mobile/condominium/search"
        class="w-full mx-auto px-4"
      >
        <Button
          variant="primary"
          size="medium"
          :full-width="true"
          style="gap: 4px !important"
        >
          <template #left-icon>
            <MobileCondominiumIcon />
          </template>
          Buscar imóvel
        </Button>
      </router-link>
    </div>
    <Quota />
  </div>
</template>

<style lang="scss" scoped>
.home {
  position: absolute;
  top: 0;
  left: 0;

  min-width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background-color: #1b2644;

  z-index: 999;
}
</style>