<template>
  <base-card>
    <v-row
      v-if="Object.keys(data).length > 0"
      class="center"
    >
      <v-col class="pa-1  text-center">
        <div class="badge ">
          <v-btn 
            id="btn-houses"
            class="button"
            color="#DEDFDF"
            fab
            outlined
            small 
            @click="setActiveTab('Addresses')"
          >
            <img src="@/assets/images/icon-house.svg">
          </v-btn>
          <div class="label text-left">
            <small>Imóveis</small>
            <h2 v-if="data.Properties && data.Properties.length > 0">
              {{ data.Properties.length }}
            </h2>                       
            <h2 v-else>
              N/D
            </h2>
          </div>
        </div>
      </v-col>

      <v-col class="pa-1 text-center">
        <div class="badge">
          <v-btn
            id="btn-emails"
            class="button"
            color="#DEDFDF"
            fab
            outlined
            small
            @click="setActiveTab('Emails')"
          >
            <img src="@/assets/images/icon-mail.svg">
          </v-btn>
          <div class="label text-left">
            <small>Emails</small>
            <h2 v-if="data.Emails && data.Emails.length > 0">
              {{ data.Emails.length }}
            </h2>
            <h2 v-else>
              N/D
            </h2>
          </div>
        </div>
      </v-col>

      <v-col class="pa-1 text-center">
        <div class="badge">
          <v-btn
            id="btn-phones"
            class="button"
            color="#DEDFDF"
            fab
            outlined
            small
            @click="setActiveTab('Phones')"
          >
            <img src="@/assets/images/icon-phone.svg">
          </v-btn>
          <div class="label text-left">
            <small>Telefones</small>
            <h2 v-if="data.Phones && data.Phones.length > 0">
              {{ data.Phones.length }}
            </h2>
            <h2 v-else>
              N/D
            </h2>
          </div>
        </div>
      </v-col>

      <v-col class="pa-1 text-center">
        <div class="badge">
          <v-btn
            id="btn-addresses"
            class="button"
            color="#DEDFDF"
            fab
            outlined
            small 
            @click="setActiveTab('Addresses')"
          >
            <img src="@/assets/images/icon-pin.svg">
          </v-btn>
          <div class="label text-left">
            <small>Endereços</small>
            <h2 v-if="data.Addresses && data.Addresses.length > 0">
              {{ data.Addresses.length }}
            </h2>
            <h2 v-else>
              N/D
            </h2>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-else>
      <p>Não há dados para serem exibidos.</p>
    </div>
  </base-card>
</template>

<script>
import { mapActions } from 'vuex';
import baseCard from '../cards/base-card.vue';

export default {
  name: 'Counter',
  components: {
    baseCard,
  },
  props: ['data'],
  methods: {
    ...mapActions('person', ['setActiveTab']),
  },
};
</script>

<style lang="scss"scoped>
.badge {
  padding: 16px 0;
  div {
    width: 5rem;
    display: inline-block;
    vertical-align: middle;
    min-height: 52px;

    color: #c3c4c4;
    margin-left: 8px;

    h2 {
      font-size: 21px;
      padding: 0;
      margin-top: 4px;
      font-weight: 900;
    }
    .btn:hover {
      background: red;
    }
    .icon {
      min-height: 52px;
      padding-top: 17px;
    }
    margin-left: 9px;
    .label {
      padding: 10px 5px;
    }
    small{
          font-size: 12px;
    line-height: 14px;
    }
  }
  .v-btn:hover {
    &:before {
      background: #4663b9;
      opacity: 1;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>