import { css } from '@/core/theme';
import { NAVBAR_HEIGHT } from '@/common/constants';

const root = css({
  paddingTop: '$medium',
  height: '100%',

  '& > h1': {
    paddingBottom: '$large',
  },
});

const fields = css({
  padding: '0 $medium $medium',
  maxHeight: `calc(100% - ${NAVBAR_HEIGHT} - 86px)`, // header height - sidebar footer height
  overflowX: 'auto',
});

const city = css({
  marginBottom: '$large',
});

const district = css({
  borderBottom: '1px solid $neutral-grey-2',
  marginBottom: '$medium', 
  paddingBottom: '$medium',
});

const business = css({
  borderBottom: '1px solid $neutral-grey-2',
  marginBottom: '$medium',
  paddingBottom: '$medium',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$small',
});

const typology = css({

  '& > h2': {
    marginTop: '$medium',
  },

  '& > div[data-column]': {
    display: 'flex',
    columnGap: '$small',
    marginBottom: '$small',
  },

  '& > div[data-attributes]': {
    display: 'flex',
    rowGap: '$nano',
    columnGap: '$nano',
    flexWrap: 'wrap',
    marginTop: '$small',
  },
});

const footer = css({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '96px',
  boxShadow: '$medium',
  backgroundColor: '$neutral-white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$small',
});

export const styles = {
  root,
  fields,
  city,
  district,
  business,
  typology,
  footer,
};
