/* eslint-disable no-throw-literal */
import base from './base';

const RESULT_LIMIT = 5000;

export default {
  handleLimitErrors (result, app = null) {
    if (!app) {
      console.log('no app argument');
    }

    if (result && result.data && result.data.response) {
      if (result.data.response.length > RESULT_LIMIT) {
        app.$eventBus.$emit(
          'alert:show',
          `A consulta retornou mais de ${RESULT_LIMIT} registros (${result.data.response.length}).
          Por favor, refine a sua busca e clique novamente em [confirmar] `,
        );
        throw { message: 'Limit exceeded', name: 'Exceed exception' };
      }
    }
  },
  async fetchSummary (filter, attributes) {
    const response = await base.post('offers/summary', {
      filter,
      attributes,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async fetchSellerSummary (filter, attributes, options) {
    const response = await base.post('search/sellersummary', {
      filter,
      attributes,
      options,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  // @TODO: Integrate with client search using CV's API
  async searchByRadius (filter, attributes) {
    try {
      const response = await base.post(`${filter.routeName}/search/coords`, {
        filter,
        attributes,
      });

      if (response.status !== 200) {
        throw new Error(response?.status);
      }

      return response && response.data;
    } catch (e) {
      return e;
    }
  },
  async searchByRegion (filter, attributes, app = null) {
    try {
      const response = await base.post(`${filter.routeName}/search/coords`, {
        filter,
        attributes,
      });

      if (response.status !== 200) {
        throw new Error(response?.status);
      }

      return response && response.data;
    } catch (e) {
      return e;
    }
  },
  async getCharacteristics (params, app) {
    const { domain } = params;
    const response = await base.get(`${domain}/search/characteristics`);

    this.handleLimitErrors(response, app);

    return response && response.data;
  },
  async searchByShape (filter, attributes, app = null) {
    try {
      const response = await base.post(`${filter.routeName}/search/coords`, {
        filter,
        attributes,
      });

      if (response.status !== 200) {
        throw new Error(response?.status);
      }

      return response && response.data;
    } catch (e) {
      return e;
    }
  },
  async searchByLocation (filter, attributes, app = null) {
    try {
      const response = await base.post(`${filter.routeName}/search/coords`, {
        filter,
        attributes,
      });

      if (response.status !== 200) {
        throw new Error(response?.status);
      }

      return response && response.data;
    } catch (e) {
      return e;
    }
  },
  async searchSeller (filter, attributes) {
    const response = await base.post(`${filter.routeName}/search/coords`, {
      filter,
      attributes,
    });

    return response && response.data;
  },
  async resultHasFac (phones, emails) {
    const response = await base.post('person-data/fac', {
      phones,
      emails,
    }).catch(() => {});

    return response && response.data;
  },
  async getPersonScore (document, sequentialId) {
    const response = await base.get(`person/${document}/score`, {
      params: { sequentialId },
    }).catch(() => {});

    return response && response.data;
  },
};
