var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{class:_setup.styles.root({
    css: {
      height: `${_vm.height}px`,
      width: _setup.computedWidth,
      borderRadius: `${_vm.radius}px`,
    },
  }).className,attrs:{"data-skeleton":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }