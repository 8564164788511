<template>
  <div
    v-show="!hidden"
    class="filter-area flex flex-col container-h"
  >
    <div class="overflow-auto h-full">
      <slot name="header" />
      <slot name="location-selector" />
      <div class="filter-container">
        <slot name="filters" />
        <slot name="additional-filters" />
      </div>
    </div>
    <slot name="button-area" />
  </div>
</template>

<script>

export default {
  name: 'FilterAreaMobile',
  props: {
    hidden: {
      type: [
        Boolean,
        Error,
      ],
      default: false,
    },
  },
};

</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}
</style>