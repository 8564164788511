import { keyframes } from '@stitches/core';
import { css } from '@/core/theme';

const backdropFadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const backdropFadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const modalFadeIn = keyframes({
  '0%': { transform: 'scale(0.9)' },
  '100%': { transform: 'scale(1)' },
});

const modalFadeOut = keyframes({
  '0%': { transform: 'scale(1)' },
  '100%': { transform: 'scale(0.9)' },
});

const backdrop = css({
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  zIndex: 100,
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${backdropFadeIn} .25s`,
  animationFillMode: 'forwards',
  willChange: 'opacity',

  '@mobile': {
    width: '100%',
    height: '100%',
    right: 'initial',
    bottom: 'initial',
  },
});

const backdropClosing = css({
  animation: `${backdropFadeOut} .25s`,
  animationFillMode: 'forwards',
  willChange: 'opacity',
});

const fullScreenModal = {
  maxWidth: '80%',
  height: '96%',
  boxShadow: 'none !important',
  overflow: 'auto',
};

const modal = css({
  maxWidth: '1140px',
  width: '100%',
  backgroundColor: '$neutral-white',
  padding: '$large',
  position: 'relative',
  boxShadow: '$medium',
  borderRadius: '20px',
  animation: `${modalFadeIn} .25s`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0, 0.55, 0.45, 1)',
  willChange: 'transform',

  '@mobile': {
    ...fullScreenModal,
  },

  '@media (max-height: 760px)': {
    ...fullScreenModal,
  },
});

const modalClosing = css({
  animation: `${modalFadeOut} .25s`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'cubic-bezier(0, 0.55, 0.45, 1)',
  willChange: 'transform',
});

const close = css({
  position: 'absolute',
  top: '$medium',
  right: '$medium',
  border: 'none',
  backgroundColor: 'transparent',
  
  '&:hover': {
    cursor: 'pointer',
  },
});

export const styles = {
  backdrop,
  backdropClosing,
  modal,
  modalClosing,
  close,
};
