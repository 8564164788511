import { css } from '@/core/theme';
import { NAVBAR_HEIGHT } from '@/common/constants';

const wrapper = css({

  variants: {

    layout: {

      'home': {
        display: 'block',
      },

      'sidebar': {
        gridTemplateColumns: '474px 1fr',
      },

      'no-sidebar': {
        gridTemplateColumns: '1fr',
      },

      'full': {
        gridTemplateColumns: '0 1fr',
      },

      'results': {
        gridTemplateColumns: '540px 1fr',
      },
    },
  },

  width: '100%',
  height: '100vh',
  display: 'grid',
  transition: 'grid-template-columns .25s',
});

const sidebar = css({
  height: '100vh',
  paddingTop: NAVBAR_HEIGHT,
  borderRight: '1px solid #DDD',
});

const content = css({

  variants: {

    type: {
      
      'with-map': {
        paddingTop: 'initial',
        overflow: 'auto',
      },

      'without-map': {
        paddingTop: NAVBAR_HEIGHT,
      },
    },
  },

  position: 'relative',
});

export const styles = {
  wrapper,
  sidebar,
  content,
};
