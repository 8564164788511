<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.185 6.66331L11.9345 3.41277C11.7245 3.20281 11.7245 2.8624 11.9345 2.65244L14.2068 0.380165C14.4167 0.170206 14.7571 0.170206 14.9671 0.380165L18.2176 3.6307C18.4276 3.84066 18.4276 4.18108 18.2176 4.39103L15.9454 6.66331C15.7354 6.87327 15.395 6.87327 15.185 6.66331Z"
      fill="white"
    />
    <path
      d="M0.402222 17.4624V14.4075C0.402222 14.2649 0.458865 14.1281 0.559691 14.0273L10.3916 4.19538C10.6016 3.98542 10.942 3.98542 11.152 4.19538L14.4025 7.44592C14.6125 7.65588 14.6125 7.99629 14.4025 8.20625L4.76621 17.8425C4.66539 17.9434 4.52864 18 4.38605 18H0.939856C0.642929 18 0.402222 17.7593 0.402222 17.4624Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon',
};
</script>
