<template>
  <div :class="styles.container().className">
    <div :class="styles.headline().className">
      <Typography
        :color="isMobile ? 'neutral-white' : textColor"
        variant="body-medium-secondary"
      >
        {{ title }}
      </Typography>
      <Typography
        :color="isMobile ? 'neutral-white' : textColor"
        variant="header-xl-secondary"
      >
        {{ available }}
      </Typography>
      <Typography
        :color="isMobile ? 'neutral-white' : textColor"
        variant="body-small"
      >
        disponíveis
      </Typography>
    </div>

    <progress
      :class="styles.progress().className"
      :value="progressValue"
      :max="isMobile ? total : 100"
    />

    <div :class="styles.row().className">
      <div>
        <Typography
          :color="isMobile ? 'neutral-white' : textColor"
          variant="label-current"
        >
          Você utilizou {{ used }} de {{ total }}
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>

import Typography from '@/components/base/Typography';

import { styles } from './ProgressBar.styles';

export default {

  name: 'ProgressBar',

  components: {
    Typography,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    available: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },

    used: {
      type: Number,
      default: 0,
    },

    current: {
      type: [
        String,
        Number,
      ],
      default: 0,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

    textColor () {
      return !this.disabled ? 'brand-dark' : 'neutral-grey-4';
    },

    progressValue () {
      return !this.disabled ? this.current : '';
    },
  },

  created () {
    this.styles = styles;
  },
};

</script>
