<template>
  <div class="search-result">
    <template v-if="hasResults">
      <ul class="search-result__container">
        <PropertyItem
          v-for="(item, index) in searchResults"
          :key="index"
          :property="item"
          disable-flagging
          disable-chart
          offer-text="anúncio"
          :class="['max-w-lg', 'w-1/4', index > 3 && 'mt-5']"
        />
      </ul>
    </template>

    <template v-else>
      <div class="search-result__container--message">
        A sua busca não retornou nenhum resultado :(
      </div>
    </template>
  </div>
</template>

<script>

import PropertyItem from '@/components/property/PropertyItem.vue';

export default {
  components: {
    PropertyItem,
  },
  props: {
    searchResults: {
      type: Array,
      default: () => null,
    },
  },
  computed: {
    hasResults () {
      return this.searchResults && this.searchResults.length;
    },
  },
};

</script>

<style lang="scss" scoped>

.search-result {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    max-width: 1108px;
    width: 100%;
    margin: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    padding: 1.25rem 1.5rem;

    &--message {
      display: flex;
      justify-content: center;
      padding: 2.5rem;
    }
  }
}

:deep(.attributesList li span) {
  font-size: 9px;
  font-weight: bolder !important;
}

:deep(.property .divider) {
  margin: 0 0.3em;
  height: 15px;
  border-right: 1px solid #777;
}

:deep(.property:last-child) {
  margin-right: auto;
}

:deep(.property a) {
  cursor: default
}


</style>
