<template>
  <div>
  <accordion v-if="item" :id="item.title" :disabled="disabled" :hide-actions="true">
    <template v-slot:header>
      <div 
        class="sidebar-item-header"
        :class="{ 'active-sidebar-item-header': open }"
        @click="handleOpen()"
        >
        <Typography
          variant="title-large"
          color="highlight-dark"
          data-test-id="simple-title"
        >
          {{item.title}}
        </Typography>
        <div class="flex align-center">
          <img
            v-if="open"
            class="ml-auto"
            src="@/assets/images/Chevron-up.svg"
          >
          <img
            v-else
            class="ml-auto"
            src="@/assets/images/Chevron-down.svg"
          >
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div
        v-for="(data, id) in item.content"
        :key="id"
        :data-test-id="`accordion-item-${id}`"
        @click="handleItemClick(data)"
        class="flex flex-column align-center justify-center"
      >
        <hr>
        <div
          class="sidebar-item cursor-pointer"
          :class="{ 'active-sidebar-item': activeTab === data.tabName }"
        >
          <div class="flex flex-column row-gap-2">            
            <Typography
              variant="body-table"
              :color="activeTab === data.tabName ? 'neutral-white': 'highlight-light'"
            >
              {{ data.title }}
            </Typography>
            <Typography
              variant="label-xs"
              :color="activeTab === data.tabName ? 'neutral-white': 'neutral-grey-4'"
            >
              {{ data.lastUpdate }}
            </Typography>
          </div>
          <div class="flex align-center">
            <img
              v-if="activeTab === data.tabName"
              class="ml-auto"
              src="@/assets/images/Arrow-right-white.svg"
            >
            <img
              v-else
              class="ml-auto"
              src="@/assets/images/Arrow-right.svg"
            >
          </div>
        </div>
      </div>
    </template>
  </accordion>
  <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SimpleTitle from '@/components/search/SimpleTitle.vue';
import Accordion from '@/components/accordion/Accordion.vue';
import BaseCard from '@/components/cards/base-card.vue';
import InfoMarkerCard from '@/components/cards/info-marker-card.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import Typography from '../base/Typography';

export default {
  name: 'ContactInfo',
  components: {
    GoogleTagManager,
    Accordion,
    BaseCard,
    InfoMarkerCard,
    SimpleTitle,
    Typography
},
  props: {
    item: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    gtmEvent: null,
    open: false
  }),
  watch: {
    activeTab(newV, oldV) {
      if(newV && newV !== oldV && this.checkTabName(newV)) {
        return this.open = true
      }
    }
  },
  computed: {
    ...mapGetters('person', ['activeTab']),
  },
  methods: {
    ...mapActions('person', ['setActiveTab']),
    sendViewListEvent(type) {
      this.gtmEvent = {
        event: 'view_list',
        type,
      };
    },
    handleItemClick(item) {
      this.activateTab(item.tabName);
      this.sendViewListEvent(item.clickEvent);
    },
    activateTab(tabName) {
      this.setActiveTab(tabName);
    },
    handleOpen () {
      this.open = !this.open
    },
    checkTabName (tabName) {
      return this.item.content.some(data => tabName === data.tabName)
    }
  },
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
    ::v-deep .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 10px 20px;
    }
    ::v-deep .v-expansion-panel-header {
      padding: 0 !important;
    }
    ::v-deep .v-expansion-panel-header__icon {
      background-color: inherit;
    }
    ::v-deep .v-expansion-panel-header__icon .v-icon::before {
      color: inherit;
    }
    ::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
      min-height: 20px;
    }
}
.clickable {
  cursor: pointer;
}

.sidebar-item-header {
  display: flex;
  width: 325px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: var(--colors-neutral-grey-1);
    border-radius: 10px;
  }
}
.active-sidebar-item-header, .active-sidebar-item-header:hover {
  padding: 0px 20px!important;
}
.sidebar-item {
  display: flex;
  width: 274px;
  height: 48px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  row-gap: 7px;

  &:hover {
    border-radius: 10px;
    background: var(--colors-neutral-grey-1);
  }
}
.active-sidebar-item, .active-sidebar-item:hover {
  border-radius: 10px;
  background: var(--colors-brand-default);
}

hr {
  display: block;
  width: 284px;
  height: 0px;
  background-color: #D9D9D9;
  margin: 8px 0;
}
</style>
