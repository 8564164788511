import { css } from '@/core/theme';

const root = css({
  paddingTop: '$xs',
  paddingBottom: '$nano',
  paddingLeft: '$nano',
  paddingRight: '$nano',
});

const container = css({
  boxShadow: '$small',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
});

const header = css({
  backgroundColor: '$brand-dark',
  textAlign: 'center',
  padding: '$xs',
  userSelect: 'none',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
});

const result = css({
  backgroundColor: '$neutral-white',
});

const filter = css({

  variants: {

    state: {

      'collapsed': {
        maxHeight: '512px',
      },

      'uncollapsed': {
        maxHeight: 0,
      },
    },
  },

  transition: 'max-height .25s',
  backgroundColor: '$neutral-white',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  overflow: 'hidden',
});

const footer = css({
  borderTop: '1px solid $neutral-grey-2',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
});

const toggle = css({

  variants: {

    state: {

      'collapsed': {
        
      },

      'uncollapsed': {
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
  },

  backgroundColor: '$neutral-white',
  cursor: 'pointer',
  textAlign: 'center',
  transition: 'background-color .25s',
  padding: '$nano',
  userSelect: 'none',

  '&:hover': {
    backgroundColor: '$neutral-grey-1',

    '& > span': {
      color: '$brand-default',
      
      '& > svg': {
        
        '& > path': {
          transition: 'fill .25s',
          fill: '$brand-default',
        },
      },
    },
  },

  '& > span': {
    transition: 'color .25s',

    '& > svg': {
      display: 'inline-block',
      marginLeft: '$nano',
    },
  },
});

export const styles = {
  root,
  container,
  header,
  result,
  filter,
  footer,
  toggle,
};
