<script setup>

import {
  onMounted,
  onBeforeUnmount,
} from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import Skeleton from '@/components/base/Skeleton';
import Pagination from '@/components/base/Pagination';
import OpenSearchSidebar from '@/components/OpenSearchSidebar';
import OpenSearchSorter from '@/components/OpenSearchSorter';
import OpenSearchCard from '@/components/OpenSearchCard';

/**
 * Stores
 */
import { useOpenSearchStore } from '@/pinia/openSearch';

/**
 * Styles
 */
import { styles } from './OpenSearch.styles';

/**
 * Use stores
 */
const openSearchStore = useOpenSearchStore();

/**
 * Methods
 */
const onSearchSubmit = data => openSearchStore.filter(data);

const onSearchSort = data => openSearchStore.filter(data);

const onSearchClear = () => openSearchStore.clear();

const onPageChange = page =>  openSearchStore.filter({ page });

const onSellerSearch = event => openSearchStore.getSellers(event.city, event.value || undefined);

/**
 * Mounted
 */
onMounted(() => openSearchStore.init());

onBeforeUnmount(() => openSearchStore.clear());

/**
 * Misc
 */
const formatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 0,
});

const perPage = 20;

</script>

<template>
  <div :class="styles.wrapper().className">
    <div :class="styles.sidebar().className">
      <OpenSearchSidebar
        :loading="openSearchStore.listings.loading"
        :data="openSearchStore.sidebar"
        :attributes="openSearchStore.attributes"
        :districts="openSearchStore.districts"
        :sellers="openSearchStore.sellers"
        @submit="onSearchSubmit"
        @clear="onSearchClear"
        @seller-search="onSellerSearch"
      />
    </div>

    <div :class="styles.content().className">
      <div :class="styles.sorter().className">
        <OpenSearchSorter
          :loading="openSearchStore.listings.loading"
          :data="openSearchStore.sorter"
          @change="onSearchSort"
        />

        <hr :class="styles.divider({ owner: 'sorter' }).className">

        <Typography
          tag="span"
          variant="title-small"
          color="neutral-grey-5"
        >
          <template v-if="openSearchStore.listings.loading">
            Por favor, aguarde enquanto reunimos os resultados...
          </template>

          <template v-else>
            <template v-if="!openSearchStore.listings.results.length">
              Desculpe, nenhum resultado foi encontrado.
            </template>
            
            <template v-else>
              Foram encontrados {{ formatter.format(openSearchStore.listings.totalAds) }} anúncios no total:
            </template>
          </template>
        </Typography>
      </div>

      <div :class="styles.listings().className">
        <template v-if="openSearchStore.listings.loading">
          <div
            v-for="skeletonIndex in 20"
            :key="skeletonIndex"
            :class="styles.skeleton().className"
          >
            <Skeleton
              width="99%"
              :height="148"
              :radius="5"
            />

            <hr
              v-if="skeletonIndex !== 20"
              :class="styles.divider().className"
            >
          </div>
        </template>
        
        <template v-else>
          <div
            v-for="(listing, listingIndex) in openSearchStore.listings.results"
            :key="`openSearchListing${listingIndex}`"
            :class="styles.card().className"
          >
            <OpenSearchCard
              :data="listing"
            />

            <hr
              v-if="listingIndex !== (openSearchStore.listings.results.length - 1)"
              :class="styles.divider().className"
            >
          </div>
        </template>
      </div>

      <div
        v-if="!openSearchStore.listings.loading && openSearchStore.listings.results.length"
        :class="styles.pagination().className"
      >
        <Pagination
          :total="openSearchStore.listings.totalAds"
          :per-page="perPage"
          :current-page="openSearchStore.search.page"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>
