<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#E64A52"/>
    <path d="M20.6869 10.2502C19.9361 10.236 19.3215 10.8596 19.3215 11.6044V14.5H19.0536V8.64928C19.0536 7.90454 18.439 7.28092 17.6882 7.29516C16.9606 7.30897 16.375 7.8982 16.375 8.62305V14.5H16.1072V7.35436C16.1072 6.60962 15.4926 5.986 14.7417 6.00024C14.0141 6.01405 13.4286 6.60327 13.4286 7.32813V14.5H13.1607V8.68249C13.1607 7.93774 12.5461 7.31412 11.7953 7.32836C11.0677 7.34218 10.4822 7.9314 10.4822 8.65626V16.4919L9.42251 15.047C8.98747 14.4538 8.14988 14.3226 7.55162 14.754C6.95346 15.1854 6.82121 16.0161 7.25624 16.6093L11.4616 22.3436C11.6107 22.5469 11.8062 22.7124 12.0321 22.8265C12.258 22.9405 12.5079 23 12.7614 23H19.3771C20.1229 23 20.7707 22.4912 20.9416 21.7713L21.8291 18.0306C21.9426 17.5521 22 17.0621 22 16.5705V11.5781C22 10.8533 21.4145 10.2641 20.6869 10.2502Z" fill="white"/>
  </svg>
</template>

<script>

export default {
  name: 'NotAllowedIcon',
}

</script>