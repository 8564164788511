import { css } from '@/core/theme';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  borderRadius: '5px',
  border: '1px solid rgba(189, 204, 248, 1)',
  backgroundColor: 'rgba(243, 245, 251, 1)',
  marginTop: '$medium',
  gap: '32px',
});

const title = css({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '24px',
  borderBottom: '1px solid $neutral-grey-2',
});

export const styles = {
  container,
  title,
};
