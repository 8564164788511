export const LGPDRoutes = {
  '/search/lead': 'lead',
  '/person-data': 'person-data',
  '/person-data/detail': 'person-data',
  '/mobile/condominium': 'lead',
  '/mobile/condominium/search': 'lead',
  '/mobile/condominium/list': 'lead',
  '/mobile/condominium/details': 'lead',
  '/mobile/person-data': 'person-data',
  '/mobile/person-data/search': 'person-data',
  '/mobile/person-data/list': 'person-data',
  '/mobile/person-data/details': 'person-data',
};
