<script setup>

import { computed } from 'vue';

import { styles } from './HomeCardAction.styles';
import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';
import ArrowButton from '@/components/icons/ArrowButton';

const css = selector => styles[selector]().className;

const props = defineProps({
  cardData: {
    type: Object,
    default: () => {},
  },
});

const buttonIsVisible = computed(() => { 
  const { permissions, id } = props.cardData;

  return Object.keys(permissions).includes(id) ? permissions[id] : true;
});

</script>

<template>
  <div :class="css('container')">
    <div :class="css('title')">
      <component :is="cardData.icon" />
      <Typography
        tag="h2"
        variant="header-xl-secondary"
        color="brand-dark"
      >
        {{ cardData.label }}
      </Typography>
    </div>
    <div :class="css('description')">
      <Typography
        tag="span"
        variant="body-table"
        color="brand-dark"
      >
        Descubra as possibilidades dessa vertical
      </Typography>
    </div>
    <div :class="css('items')">
      <ul>
        <li
          v-for="item in cardData.items"
          :key="item"
        >
          <Typography
            tag="span"
            variant="body-large-secondary"
            color="brand-dark"
          >
            {{ item }}
          </Typography>
        </li>
      </ul>
    </div>
    <div
      v-if="buttonIsVisible"
      :class="css('actions')"
    >
      <router-link :to="cardData.id === 'developer' ? `${cardData.route}` : `/search${cardData.route}`">
        <Button
          variant="primaryLarge"
          show-right-icon-on-hover
        >
          Ir para a página
          
          <template #right-icon>
            <ArrowButton />
          </template>
        </Button>
      </router-link>
    </div>
  </div>
</template>
