<template>
  <svg width="24" height="25" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65.5763 34.2537C65.5763 32.0483 65.3808 29.9546 65.0458 27.9167H33.5V40.5071H51.5621C50.7525 44.6388 48.3796 48.1283 44.8621 50.5013V58.8763H55.6379C61.9471 53.0417 65.5763 44.4433 65.5763 34.2537Z" fill="#4285F4"/>
    <path d="M33.5001 67C42.5451 67 50.1104 63.985 55.6379 58.8763L44.8621 50.5013C41.8471 52.5113 38.0226 53.7395 33.5001 53.7395C24.7622 53.7395 17.3642 47.8491 14.7122 39.8929H3.60132V48.5191C9.1009 59.4625 20.4072 67 33.5001 67Z" fill="#34A853"/>
    <path d="M14.7122 39.8929C14.0142 37.8829 13.6512 35.7333 13.6512 33.5C13.6512 31.2667 14.0421 29.1171 14.7121 27.1071V18.4808H3.60125C1.31208 23.0033 0 28.0842 0 33.5C0 38.9158 1.31215 43.9966 3.60132 48.5191L14.7122 39.8929Z" fill="#FBBC05"/>
    <path d="M33.5001 13.2604C38.4413 13.2604 42.8522 14.9633 46.3417 18.2854L55.8892 8.73792C50.1105 3.32209 42.5451 0 33.5001 0C20.4072 0 9.10083 7.53749 3.60125 18.4808L14.7121 27.1071C17.3642 19.1508 24.7622 13.2604 33.5001 13.2604Z" fill="#EA4335"/>
  </svg>
</template>

<script>

export default {
  name: 'SocialLoginGoogle',
}

</script>
