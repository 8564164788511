var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _setup.css('wrapper'),
    _setup.hoverHeight
  ]},[_c('router-link',{attrs:{"to":_vm.values.route}},[_c('div',{class:[
        _setup.css('container'),
        _setup.css(_setup.hoverDecoration)
      ],on:{"mouseover":function($event){return _setup.setHovered(true)},"mouseleave":function($event){return _setup.setHovered(false)}}},[_c(_vm.values.icon,{tag:"component",attrs:{"hovered":_setup.hovered}}),_c('div',{class:_setup.css('textContent')},[_c(_setup.Typography,{class:_setup.css('title'),attrs:{"tag":"h3","variant":"title-large-secondary","color":"brand-dark"}},[_vm._v(" "+_vm._s(_vm.values.label)+" ")]),_c(_setup.Typography,{class:_setup.textTruncated,attrs:{"tag":"span","variant":"body-medium","color":"brand-dark"}},[_vm._v(" "+_vm._s(_vm.values.content)+" ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }