<template>
  <div
    id="attributes"
    class="w-full py-0 px-0"
  >
    <h2 class="attributes-title">
      Características
    </h2>
    <ul
      v-if="property && property.amenitiesCondominium && property.amenitiesCondominium.length"
      class="flex flex-wrap space-between mt-4 flex-list"
    >
      <li
        v-for="attribute in property.amenitiesCondominium"
        :key="attribute.id"
        class="m-1"
      >
        <Typography
          align="left"
          class="attribute"
          variant="body-medium"
          color="brand-dark"
          tag="span"
        >
          <v-icon
            class="check-icon"
            color="#4663B9"
          >
            mdi-check
          </v-icon> {{ attribute }}
        </Typography>
      </li>
    </ul>
    <div
      v-else
      class="flex justify-center py-10 w-full"
    >
      Não encontramos características para serem exibidas deste imóvel :(
    </div>
  </div>
</template>

<script>
import Typography from '@/components/base/Typography';

export default {
  components: {
    Typography,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.caracter {
  border: 0 !important;
  border-radius: 0 !important;
  border-left: 1px solid #CCC;
}

.flex-list {
  position: relative;
  margin: 1em 1em 1em 0;
  overflow: hidden;
  padding-left: 0 !important;
  column-gap: 4px;
}

.flex-list::after {
  content: "";
  flex-basis: 20em;
}

.flex-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -1px;
}

.flex-list li {
  flex-grow: 1;
  flex-basis: auto;
  margin: .25em 0;
  text-align: left;
  background-color: #fff;
}

.attributes-title {
  @apply font-black;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 22.5px;
  line-height: 22px;
  color: #3366CC;
  margin-bottom: 8px;
}

.attribute {
  display: flex;
  align-items: flex-start;
  color: #384159;
}

.attribute > .check-icon {
  font-size: 22.5px;
}
</style>
