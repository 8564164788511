<script setup>

import { computed, ref } from 'vue';
import { styles } from './HomeCardTool.styles';
import Typography from '@/components/base/Typography';

const props = defineProps({
  values: {
    type: Object,
    default: () => {},
  },
});

const css = selector => styles[selector]().className;

const hovered = ref(false);

const hoverDecoration = computed(() => hovered.value ? 'hoveredBackground' : 'defaultBackground');
const hoverHeight = computed(() => hovered.value ? css('wrapperHovered') : '');
const textTruncated = computed(() => hovered.value ? '' : css('textTruncated'));

function setHovered (value) {
  hovered.value = value;
}

</script>

<template>
  <div
    :class="[
      css('wrapper'),
      hoverHeight
    ]"
  >
    <router-link :to="values.route">
      <div
        :class="[
          css('container'),
          css(hoverDecoration)
        ]"
        @mouseover="setHovered(true)"
        @mouseleave="setHovered(false)"
      >
        <component
          :is="values.icon"
          :hovered="hovered"
        />
        <div :class="css('textContent')">
          <Typography
            tag="h3"
            variant="title-large-secondary"
            color="brand-dark"
            :class="css('title')"
          >
            {{ values.label }}
          </Typography>
          <Typography
            tag="span"
            variant="body-medium"
            color="brand-dark"
            :class="textTruncated"
          >
            {{ values.content }}
          </Typography>
        </div>
      </div>
    </router-link>
  </div>
</template>