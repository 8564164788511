<script>

export default {
  inheritAttrs: false,
};

</script>

<script setup>

import { watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

/**
 * Components
 */
import TextField from '../TextField';

/**
 * Props
 */
const props = defineProps({

  label: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  value: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  error: {
    type: [
      String,
      Boolean,
    ],
    default: null,
  },

  suffix: {
    type: String,
    default: null,
  },

  options: {
    type: Object,
    default: () => ({
      locale: 'pt-BR',
      currency: 'BRL',
      currencyDisplay: 'hidden',
      precision: 0,
      hideGroupingSeparatorOnFocus: false,
    }),
  },

  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  readOnly: {
    type: Boolean,
    default: false,
  },
});

/**
 * Refs
 */
const {
  inputRef,
  setOptions,
  setValue,
} = useCurrencyInput(props.options);

/**
 * Watch
 */
watch(() => props.value, value => setValue(value));

watch(() => props.options, options => setOptions(options));

</script>

<template>
  <TextField
    :id="id"
    ref="inputRef"
    :label="label"
    :error="error"
    :suffix="suffix"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :read-only="readOnly"
  />
</template>
