import base from './base';
import address from './address';
import auth from './auth';
import clients from './clients';
import core from './core';
import locations from './locations';
import search from './search';
import user from './user';
import avm from './avm';
import person from './person';
import offers from './offers';

export default {
  address,
  auth,
  base,
  clients,
  core,
  locations,
  search,
  user,
  avm,
  person,
  offers,
};
