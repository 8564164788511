var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-container"},[_c('v-btn',{class:{
      'btn--active': _vm.current === 'left',
      'mobile-button': _vm.mobile,
    },attrs:{"outlined":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.onClickHandler('left')}}},[_vm._v(" "+_vm._s(_vm.labels[0])+" ")]),_c('v-btn',{class:{
      'btn--active': _vm.current === 'right',
      'mobile-button': _vm.mobile,
    },attrs:{"outlined":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.onClickHandler('right')}}},[_vm._v(" "+_vm._s(_vm.labels[1])+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }