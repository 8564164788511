<template>
  <div class="estudos">
    <Modal
      v-bind="$attrs"
      v-on="$listeners"
      :closeOnEscape="false"
      transactionsModal
    >
      <div class="modal-container">
        <div class="text-container">
          <Typography
            variant="display-xl"
            color="brand-dark"
            >
            ATENÇÃO!
          </Typography>
          <div class="subtitle-container">
            <Typography
              variant="label-current"
              color="brand-dark"
              >
              A área de estudo de mercado está passando por melhorias.
            </Typography>
            <Typography
              variant="label-current"
              color="brand-dark"
            >
              Aguarde novidades.
            </Typography>
          </div>
        </div>
        <div class="button-container">
          <Button
            variant="alternative"
            @click="sendToHome"
            class="button-width"
          >
            <Typography
              variant="button-large"
              color="neutral-white"
            >
              Ok, entendi
            </Typography>
          </Button>
        </div>
        <div class="footer-container">
          <Typography
            variant="label-current"
            color="brand-dark"
          >
            Precisa de informações sobre o mercado? Entre em contato clicando
            <a
              href="mailto:dghiu@bnsir.com.br?subject=Estudo%20de%20Mercado&body=Ol%C3%A1%20Denise%2C%20estou%20buscando%20dados%20de%20estudo%20de%20mercado%20poderia%20me%20ajudar%3F"
              target="_blank"
              class="button-link"
            >
              aqui.
            </a>
          </Typography>
        </div>
      </div>
    </Modal>
    <iframe
      class="iframe"
      :src="workspacePowerBi"
      frameborder="0"
    />
  </div>
</template>

<script>
import Button from '../components/base/Button';
import Modal from '../components/base/Modal';
import Typography from '../components/base/Typography';

export default {
  name: 'Studies',
  components: {
    Button,
    Modal,
    Typography
  },
  data () {
    return {
      workspacePowerBi: process.env.VUE_APP_WORKSPACE_POWER_BI,
    };
  },
  methods: {
    async sendToHome () {
      await this.$router.push('/home');
    },
  
  },
};
</script>

<style lang="scss" scoped>
.estudos {
  width: 100%;
  height: calc(100% - 75px);
  background-color: #fff;
  position: absolute;
  top: 75px;
  z-index: 4;
  .iframe {
    padding-top: 16px;
    width: 100%;
    height: 100%;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 855px;
  height: 438px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.19), 0px 2px 21px 0px rgba(0, 0, 0, 0.15);
}
.text-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.button-container, .subtitle-container, .footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.button-container {
  margin-top: 50px;
  margin-bottom: 69px;
}
.button-width {
  width: 174px
}
.button-link {
  color: var(--colors-highlight-default);
  text-decoration-line: underline;
}
</style>
