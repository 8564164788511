<template>
  <div class="price">
    <p class="price-title">Valores:</p>
    <p> {{ formattedValue }} </p>
  </div>
</template>

<script>

export default {
  name: 'Prices',
  props: {
    price: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    minPrice() {
      const minPriceValue = this.price[0];
      const notDefaultValue = minPriceValue && minPriceValue !== 0;

      return notDefaultValue ? minPriceValue : '-';
    },
    maxPrice() {
      const maxPriceValue = this.price[1];
      const notDefaultValue = maxPriceValue && maxPriceValue !== 0;

      return notDefaultValue ? maxPriceValue : '-';
    },
    formattedValue() {
      const pricesNotProvided = this.priceNotProvided(this.minPrice) &&
          this.priceNotProvided(this.maxPrice);

      if (pricesNotProvided) return 'Não informado';
      if (!Number(this.maxPrice) && Number(this.minPrice)) return `A partir de ${this.$options.filters.money(this.minPrice)}`;
      if (Number(this.maxPrice) && !Number(this.minPrice)) return `Até ${this.$options.filters.money(this.maxPrice)}`;

      return `De ${this.$options.filters.money(this.minPrice)} Até ${this.$options.filters.money(this.maxPrice)}`;
    },
  },
  methods: {
    priceNotProvided(price) {
      return price === '-' || price < 1;
    },
  },
};

</script>

<style lang="scss" scoped>

.price {
  @apply text-left;

  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;

  letter-spacing: normal;
  line-height: 1.25;

  color: #707070;

  margin-bottom: 13px;

  &-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

</style>
