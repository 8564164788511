<script setup>

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import store from '../../store/index';

/**
 * Constants
 */
import { NAVBAR_MENU } from '@/common/constants';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';

/**
 * Icons
 */
import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
import NavbarPanelBuyerIcon from '@/components/icons/NavbarPanelBuyerIcon';
import NavbarPanelSellerIcon from '@/components/icons/NavbarPanelSellerIcon';
import NavbarPanelRenterIcon from '@/components/icons/NavbarPanelRenterIcon';
import NavbarPanelConsultantIcon from '@/components/icons/NavbarPanelConsultantIcon';
import NavbarPanelInvestorIcon from '@/components/icons/NavbarPanelInvestorIcon';
import NavbarPanelDeveloperIcon from '@/components/icons/NavbarPanelDeveloperIcon';
import NavbarPanelTerrainsIcon from '@/components/icons/NavbarPanelTerrainsIcon';

/**
 * Styles
 */
import { styles } from './NavbarPanel.styles';

/**
 * Props
 */
const props = defineProps({
  
  data: {
    type: Object,
    default: () => ({ }),
  },

  bnsirBroker: {
    type: Boolean,
    default: false,
  },
});

/**
 * Emits
 */
const emit = defineEmits(['navigate']);

/**
 * Router
 */
const route = useRoute();

/**
 * Computed
 */
const activeNavbarPanelMenus = computed(() => (props.data?.items || []).filter(menu => 
  props.bnsirBroker ? !menu.hidden : (!menu.hidden && !menu.bnsir),
));

const userLeadPermit = computed(() => store.getters['user/leadPermit']);

const permissions = ref({
  lead: userLeadPermit,
});

/**
 * Methods
 */
const onNavigate = (navigate, event, item) => emit('navigate', navigate, event, item);

</script>

<template>
  <div :class="styles.root().className">
    <div :class="styles.head().className">
      <Typography
        tag="h2"
        variant="header-xl-secondary"
        color="brand-dark"
      >
        <NavbarPanelBuyerIcon v-if="data.label === NAVBAR_MENU[0].label" />
        <NavbarPanelSellerIcon v-else-if="data.label === NAVBAR_MENU[1].label" />
        <NavbarPanelRenterIcon v-else-if="data.label === NAVBAR_MENU[2].label" />
        <NavbarPanelConsultantIcon v-else-if="data.label === NAVBAR_MENU[3].label" />
        <NavbarPanelInvestorIcon v-else-if="data.label === NAVBAR_MENU[4].label" />
        <NavbarPanelDeveloperIcon v-else-if="data.label === NAVBAR_MENU[5].label" />
        <NavbarPanelTerrainsIcon v-else-if="data.label === NAVBAR_MENU[6].label" />
      
        {{ data?.label }}
      </typography>

      <router-link
        v-if="data?.permission ? permissions[data.permission] : true"
        v-slot="{ navigate }"
        :to="data?.href"
      >
        <Button
          variant="primaryLarge"
          @click="onNavigate(navigate, $event, data)"
        >
          Página principal

          <template #right-icon>
            <ArrowRightIcon />
          </template>
        </Button>
      </router-link>
    </div>

    <div :class="styles.body().className">
      <Typography
        tag="h3"
        variant="title-large-secondary"
        color="neutral-grey-4"
      >
        {{ data?.subtitle }}
      </typography>

      <ul :class="styles.list().className">
        <li
          v-for="(item, itemIndex) in activeNavbarPanelMenus"
          :key="`navbarPanelItem_${itemIndex}_${item?.label}`"
          :class="styles.item({
            active: route.path === item.href,
          }).className"
          style="transition: all 0ms ease 0s"
        >
          <router-link
            v-slot="{ href, navigate }"
            style="transition: all 0ms ease 0s"
            :to="item?.href"
          >
            <Typography
              variant="body-medium-secondary"
              color="brand-dark"
              tag="a"
              :href="href"
              @click="onNavigate(navigate, $event, item)"
            >
              <span>{{ item?.label }}</span>

              <ArrowRightIcon />
            </Typography>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
