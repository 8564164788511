var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visibleRoute)?_c('div',{class:{
    'map-has-active-item': _vm.highlightedItem,
    'map-is-drawing': _vm.desenhoControl,
  },attrs:{"id":"map-wrapper"}},[_c('MapDrawPanel',{attrs:{"is-draw-finished":_vm.isDrawFinished,"is-drawable":_vm.isDrawable,"is-clearable":_vm.isClearable,"is-result-page":_vm.isResultPage},on:{"draw":_vm.drawControl,"clear":_vm.clearMap}}),(_vm.pins.length || _vm.sellerCondo)?_c('div',{staticClass:"map-pin-legend"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),_c('l-editable-map',{ref:"map",staticStyle:{"height":"100%","width":"100%","z-index":"3"},attrs:{"center":_vm.MAP_OPTIONS.DEFAULT_CENTER,"options":_vm.MAP_OPTIONS.mapOptions,"editable":!_vm.pins.length,"zoom":_vm.zoomLevel,"max-zoom":_vm.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL,"min-zoom":_vm.MAP_OPTIONS.DEFAULT_ZOOM_LEVEL},on:{"update:center":_vm.onMapCenterChange,"update:zoom":_vm.onMapZoomLevelChange,"click":_vm.addMarkerOnClick}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),(_vm.circleCenter && _vm.sellerCondo)?_c('l-circle',{attrs:{"color":"#6B8CEF","fill-color":"#6B8CEF","fill-opacity":0.2,"options":_vm.MAP_OPTIONS.circleOptions,"lat-lng":_vm.circleCenter,"radius":_vm.circleRadius}}):_vm._e(),(_vm.freedrawingCler &&
        _vm.useMapFilter &&
        _vm.filterMode === _vm.FILTER_MODE.AREA &&
        _vm.desenhoControl)?[(_vm.showDrawSuccessTooltip)?_c('div',{ref:"drawSuccessTooltip",staticClass:"draw-success-tooltip"},[_c('Typography',{attrs:{"tag":"div","variant":"body-medium-secondary","color":"brand-dark","align":"center"}},[_vm._v(" Desenho aplicado com sucesso! Prossiga com os filtros à esquerda. ")])],1):_vm._e(),_c('l-free-drawing',{ref:"freeDraw",on:{"update:polygons":_vm.onFreeDrawChanged}})]:_vm._e(),(_vm.useMapFilter && _vm.filterMode === _vm.FILTER_MODE.REGION && _vm.regionGeoJSON)?_c('l-geo-json',{ref:"region",attrs:{"geojson":_vm.regionGeoJSON}}):_vm._e(),(_vm.clickMap
        && _vm.useMapFilter
        && _vm.lastCircleCenter
        && _vm.circleCenter
        && _vm.filterMode === _vm.FILTER_MODE.RADIUS)?[_c('l-editable-circle',{ref:"circle",attrs:{"color":"#6B8CEF","fill-color":"#6B8CEF","fill-opacity":0.2,"editable":!_vm.pins.length,"draggable":!_vm.pins.length,"options":_vm.MAP_OPTIONS.circleOptions,"lat-lng":Array.from(_vm.circleCenter),"radius":_vm.MAP_OPTIONS.DEFAULT_RADIUS},on:{"editable:vertex:drag":_vm.onCircleMarkerUpdated}}),_c('l-marker',{ref:"circleMarker",attrs:{"visible":!_vm.pins.length,"lat-lng":_vm.circleCenter}},[_c('l-icon',{attrs:{"icon-url":require('@/assets/images/map-pin-default.svg'),"icon-size":[
            _vm.iconSize.width,
            _vm.iconSize.height
          ]}})],1)]:_vm._e(),(_vm.sellerCondo && _vm.sellerCondo.geolocation && !_vm.pins.length)?_c('l-marker',{ref:"circleMarker",attrs:{"lat-lng":_vm.sellerCondo.geolocation}},[_c('l-icon',{attrs:{"icon-url":require('@/assets/images/map-pin-green.svg'),"icon-size":[
          _vm.iconSize.width,
          _vm.iconSize.height
        ]}})],1):_vm._e(),(_vm.pins.length)?_c('l-marker-cluster',{ref:"cluster",attrs:{"options":_vm.MAP_OPTIONS.clusterOptions},on:{"clusterclick":_vm.onClusterClick}},_vm._l((_vm.pins),function({ addressId, geolocation, offerType, offersCount, valueSqrtMeter },index){return _c('l-marker',{key:index,attrs:{"lat-lng":geolocation,"options":{ riseOnHover: true, addressId, index }},on:{"ready":_vm.onMarkerReady}},[_c('l-icon',{attrs:{"class-name":`icon ${
              _vm.highlightedItem && _vm.highlightedItem.addressId === addressId
                ? 'active'
                : _vm.highlightedItem
                  ? 'inactive'
                  : ''
            }`,"icon-size":[
            _vm.iconSize.width * 3,
            _vm.iconSize.height,
          ]}},[_c('span',[_c('img',{staticClass:"mx-auto",attrs:{"src":require(`@/assets/images/map-pin-${offerType ? _vm.getOfferTypeColor(offerType) : !offersCount ? 'red' : 'blue'}.svg`),"width":_vm.iconSize.width,"height":_vm.iconSize.height}})]),(_vm.pins && valueSqrtMeter && valueSqrtMeter !== '-')?_c('l-tooltip',{attrs:{"class-name":"tooltip","options":_vm.tooltipOptions}},[_c('div',{class:{
                'pin-tooltip': true,
                [`is-${offerType ? _vm.getOfferTypeColor(offerType) : !offersCount ? 'red' : 'blue'}`]: true,
              }},[_vm._v(" "+_vm._s(valueSqrtMeter)+"/m² ")])]):_vm._e()],1),_c('l-popup',[(_vm.pins)?_c('PropertyItem',{staticClass:"property__popup",attrs:{"property":_vm.pins[index],"allow-highlighting":false},on:{"click":function($event){return _vm.navigateToProperty(_vm.pins[index])}}}):_vm._e()],1)],1)}),1):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(" "+_vm._s(_vm.sidebarIsCollapsed)+" "+_vm._s(_vm.pathChange)+" ")]),_c('GoogleTagManager',{attrs:{"track-event":_vm.gtmEvent}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/images/map-pin-blue.svg"),"width":"18px"}}),_c('span',[_vm._v(" Outras imobiliárias ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/images/map-pin-green.svg"),"width":"18px"}}),_c('span',[_vm._v(" Ofertas BNSIR ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/images/map-pin-red.svg"),"width":"18px"}}),_c('span',[_vm._v(" Sem ofertas ")])])
}]

export { render, staticRenderFns }