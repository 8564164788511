<template>
  <div>
    <div v-if="$route.path.includes('mobile')">
      <router-view />
    </div>
    <div v-if="!$route.path.includes('mobile')">
      <Layout
        data-app
      >
        <ForbiddenModal />
        <PaymentRequiredModal v-if="loggedIn" />

        <router-view />

        <v-snackbar
          v-model="successSnackbarActive"
          multi-line
          vertical
          center
          :timeout="3000"
          color="success"
        >
          <span class="text-center">{{ successMessage }}</span>
        </v-snackbar>

        <template v-if="showHelpButton">
          <HelpButton :key="$route.name" />
        </template>
      </Layout>
    </div>
  </div>
</template>

<script>

import { ROUTES_WITHOUT_HELP } from '@/common/constants';

import Layout from '@/components/base/Layout';

import HelpButton from '@/components/HelpButton';

import ForbiddenModal from '@/components/modals/ForbiddenModal.vue';
import PaymentRequiredModal from '@/components/modals/PaymentRequiredModal.vue';

export default {

  name: 'AppLayout',

  components: {
    Layout,
    HelpButton,

    ForbiddenModal,
    PaymentRequiredModal,
  },

  data () {

    return {
      successMessage: null,
      successSnackbarActive: false,
    };
  },

  computed: {

    loggedIn () {
      return !!this.$store.getters['user/idToken'];
    },

    showHelpButton () {
      return !ROUTES_WITHOUT_HELP.includes(this.$route.name);
    },
  },

  created () {

    this.$eventBus.$on('alert:success:show', message => {
      this.successMessage = message;
      this.successSnackbarActive = true;
    });
  },
};

</script>

<style lang="scss">

body > div .v-application .search-summary > button {
    background-color: #4562b9 !important;
    color: #fff !important;
    margin: 0px 0px 15px auto;
}

* {
  outline: none !important;
}

.notificationCenter {
  z-index: 1000 !important;
}

.v-application--wrap {
  min-height: 100dvh !important;
}

</style>
