var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.computedTag,_vm._g(_vm._b({tag:"component",class:_setup.styles.root({
    variant: _vm.variant,
    size: _vm.size,
    fullWidth: _vm.fullWidth,
    showLeftIconOnHover: _vm.showLeftIconOnHover,
    showRightIconOnHover: _vm.showRightIconOnHover,
    state: _vm.state,
  }).className,attrs:{"disabled":_vm.disabled,"type":_setup.computedType}},'component',_vm.$attrs,false),_vm.$listeners),[(!!_vm.$slots['left-icon'])?_c('span',{attrs:{"data-icon":""}},[_vm._t("left-icon")],2):_vm._e(),(_setup.hasLogo)?_c('img',{class:{
      'disabledLogoGoogle': (_vm.disabled && _vm.variant === 'alternativeGoogle'),
      'disabledLogo123i': (_vm.disabled && _vm.variant === 'alternative123i'),
    },attrs:{"width":_setup.computedLogoWidth,"src":_setup.computedLogo}}):_vm._e(),(_vm.variant === 'icon' || _vm.variant === 'iconXs')?_vm._t("default"):_vm._e(),(_vm.variant !== 'icon' && _vm.variant !== 'iconWhatsApp' && _vm.variant !== 'iconXs')?_c(_setup.Typography,{class:{
      'ml-1': _vm.showRightIconOnHover,
      'mr-1': _vm.showLeftIconOnHover
    },attrs:{"variant":_setup.computedTypography,"color":"inherit","tag":"span"}},[_vm._t("default")],2):_vm._e(),(!!_vm.$slots['right-icon'])?_c('span',{attrs:{"data-icon":""}},[_vm._t("right-icon")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }