import { css } from '@/core/theme';

const image = css({
  position: 'relative',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  backgroundColor: '$neutral-grey-1',
  overflow: 'hidden',
  minHeight: '148px',
  maxHeight: '148px',
  height: '100%',
  
  '& > img': {
    width: '128px',
    height: '100%',
    objectFit: 'cover',
  },

  '& > div': {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '$small',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(118, 138, 198, 0.75)',
    transition: 'opacity .25s',
    opacity: 0,
  },
});

const root = css({
  display: 'grid',
  gridTemplateColumns: '1fr 126px',
  borderRadius: '10px',
  transition: 'box-shadow .25s, padding .25s',
  userSelect: 'none',

  '&:hover': {
    boxShadow: '$small',
    cursor: 'pointer',

    [`& .${image} > div`]: {
      opacity: 1,
    },
  },
});

const details = css({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$quarck',
  padding: '$small',
});

const features = css({

  '& > span[data-bullet]': {
    color: '$additional-blue-1',
    margin: '0 $nano',
  },

  '& > span[data-feature]': {
    marginRight: '$nano',
  },
});

const pricing = css({

  '& > span[data-price]': {
    marginRight: '$small',
  },
});

const attributes = css({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '$quarck',
  rowGap: '$quarck',
});

const badge = css({

  variants: {

    style: {

      'inverted': {
        border: '1px solid $additional-blue-3',
        backgroundColor: '$neutral-white',
        transition: 'background-color .25s',

        '&:hover': {
          backgroundColor: '$additional-blue-3',
        },
      },
    },
  },

  backgroundColor: '$additional-blue-1',
  borderRadius: '25px',
  padding: '$quarck $nano',
});

export const styles = {
  root,
  details,
  features,
  pricing,
  attributes,
  badge,
  image,
};
