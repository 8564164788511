export const constants = {

  condominiumCount: { },

  hasOffers: {
    label: 'Com oferta',
  },

  hasNoOffers: {
    label: 'Sem oferta',
  },

  offers: { },

  realEstate: {
    label: 'Imobiliárias com essas ofertas',
  },

  tip: {
    label: '*Imóveis com anúncios ativos',
    value: '',
  },
};

export const dynamics = {

  condominiumCount: [
    {
      key: 'value',
      rawKey: 'condominiumCount',
      fn: data => `${data}`,
    },
    {
      key: 'label',
      rawKey: 'propertyDescription',
      fn: data => `${data === 'casa' ? 'Imóveis' : 'Condomínios'} encontrados`,
    },
  ],

  hasOffers: [
    {
      key: 'value',
      rawKey: 'condoWithOfferCount',
      fn: data => `${data}`,
    },
  ],

  hasNoOffers: [
    {
      key: 'value',
      rawKey: 'condoWithNoOffersCount',
      fn: data => `${data}`,
    },
  ],

  offers: [
    {
      key: 'value',
      rawKey: 'offerCount',
      fn: data => `${data}`,
    },
    {
      key: 'label',
      rawKey: 'propertyDescription',
      fn: data => 'Ofertas',
    },
  ],
  
  realEstate: [
    {
      key: 'value',
      rawKey: 'sellerCount',
      fn: data => `${data}`,
    },
  ],
};
