import { css, theme } from '@/core/theme';
import { createColorVariants } from '@/core/theme/helpers';

const color = createColorVariants(theme.colors);

const root = css({

  variants: {

    color: {
  
      ...color,

      inherit: {
        color: 'inherit',
      },
    },

    transform: {

      lowercase: {
        textTransform: 'lowercase',
      },
      
      uppercase: {
        textTransform: 'uppercase',
      },
    },

    align: {

      left: {
        textAlign: 'left',
      },
      
      center: {
        textAlign: 'center',
      },

      right: {
        textAlign: 'right',
      },
    },

    variant: {
    // Display
      'display-xl': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$display-xl',
        lineHeight: '$line-height-default',
      },

      'display-xxl': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$display-xxl',
        lineHeight: '$line-height-default',
      },

      'display-black': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-black',
        fontSize: '$display-black',
        lineHeight: '$line-height-default',
      },

      'display-large-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$display-large-secondary',
        lineHeight: '$line-height-default',
      },

      'display-xl-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$display-xl-secondary',
        lineHeight: '$line-height-default',
      },

      // Header
      'header-large': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-medium',
        fontSize: '$header-large',
        lineHeight: '$line-height-default',
      },

      'header-xl': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$header-xl',
        lineHeight: '$line-height-default',
      },

      'header-xxl': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$header-xxl',
        lineHeight: '$line-height-default',
      },

      'header-black': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-black',
        fontSize: '$header-black',
        lineHeight: '$line-height-default',
      },

      'header-large-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-regular',
        fontSize: '$header-large-secondary',
        lineHeight: '$line-height-default',
      },

      'header-xl-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$header-xl-secondary',
        lineHeight: '$line-height-default',
      },

      // Title
      'title-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$title-small',
        lineHeight: '$line-height-default',
      },

      'title-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$title-medium',
        lineHeight: '$line-height-medium',
      },

      'title-large': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$title-large',
        lineHeight: '$line-height-default',
      },

      'title-small-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-regular',
        fontSize: '$title-small-secondary',
        lineHeight: '$line-height-default',
      },

      'title-medium-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$title-medium-secondary',
        lineHeight: '$line-height-default',
      },

      'title-large-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$title-large-secondary',
        lineHeight: '$line-height-default',
      },

      // Body
      'body-xs': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$body-xs',
        lineHeight: '$line-height-medium',
      },

      'body-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$body-small',
        lineHeight: '$line-height-medium',
      },

      'body-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$body-medium',
        lineHeight: '$line-height-medium',
      },

      'body-medium-bold': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$body-medium-bold',
        lineHeight: '$line-height-medium',
      },

      'body-large': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$body-large',
        lineHeight: '$line-height-medium',
      },

      'body-bold': {
        fontFamily: '$font-family-primary',
        fontSize: '$body-bold',
        fontWeight: '$font-weight-bold',
        lineHeight: '$line-height-large',
      },

      'body-small-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$body-small-secondary',
        lineHeight: '$line-height-medium',
      },

      'body-medium-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$body-medium-secondary',
        lineHeight: '$line-height-medium',
      },

      'body-large-secondary': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$body-large-secondary',
        lineHeight: '$line-height-large',
      },

      'body-bold-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$body-bold-secondary',
        lineHeight: '$line-height-medium',
      },

      'body-table': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$body-table',
        lineHeight: '$line-height-default',
      },

      // Placeholder
      placeholder: {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$placeholder',
        lineHeight: '$line-height-default',
      },

      // Button
      'button-large': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$button-large',
        lineHeight: '$line-height-default',
      },

      'button-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$button-medium',
        lineHeight: '$line-height-default',
      },

      'button-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$button-small',
        lineHeight: '$line-height-default',
      },

      // Link
      'link': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$link',
        lineHeight: '$line-height-default',
      },

      'link-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$link-small',
        lineHeight: '$line-height-default',
      },

      'link-menu': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$link-menu',
        lineHeight: '$line-height-default',
      },

      // Select
      'select-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$select-medium',
        lineHeight: '$line-height-default',
      },

      'select-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$select-small',
        lineHeight: '$line-height-default',
      },

      // Label
      'label-xxs': {
        fontFamily: '$font-family-primary',
        fontSize: '$label-xxs',
        fontWeight: '$font-weight-bold',
        lineHeight: '$line-height-default',
      },

      'label-xs': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-xs',
        lineHeight: '$line-height-default',
      },

      'label-small': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-small',
        lineHeight: '$line-height-default',
      },

      'label-small-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-medium',
        fontSize: '$label-small-medium',
        lineHeight: '$line-height-small',
      },

      'label-small-bold': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-small-bold',
        lineHeight: '$line-height-small',
      },

      'label-bold': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-bold',
        lineHeight: '$line-height-default',
      },

      'label-regular': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$label-regular',
        lineHeight: '$line-height-default',
      },

      'label-current': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$label-current',
        lineHeight: '$line-height-default',
      },

      'label-status': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-status',
        lineHeight: '$line-height-default',
      },

      'label-medium': {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-medium',
        lineHeight: '$line-height-default',
      },

      'label-small-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$label-small-secondary',
        lineHeight: '$line-height-medium',
      },

      'label-medium-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$label-medium-secondary',
        lineHeight: '$line-height-medium',
      },

      'label-large-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-medium',
        fontSize: '$label-large-secondary',
        lineHeight: '$line-height-medium',
      },

      'label-bold-secondary': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-bold',
        fontSize: '$label-bold-secondary',
        lineHeight: '$line-height-medium',
      },

      // Helper
      helper: {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$helper',
        lineHeight: '$line-height-medium',
      },

      // Caption
      caption: {
        fontFamily: '$font-family-primary',
        fontWeight: '$font-weight-regular',
        fontSize: '$caption',
        lineHeight: '$line-height-medium',
      },

      // Tab
      'tab-large': {
        fontFamily: '$font-family-secondary',
        fontWeight: '$font-weight-regular',
        fontSize: '$tab-large',
        lineHeight: '$line-height-default',
      },
    },
  },
});

export const styles = {
  root,
};
