import { css } from '@/core/theme';

const container = css({
  background: `$brand-darker url('${require('@/assets/images/home-1280.jpg')}') no-repeat top right`,
  minHeight: '284px',

  '@media (min-width: 1366px)': {
    background: `$brand-darker url('${require('@/assets/images/home-1366.jpg')}') no-repeat top right`,
  },

  '@media (min-width: 1920px)': {
    background: `$brand-darker url('${require('@/assets/images/home-1920.jpg')}') no-repeat top right`,
  },
});

const content = css({
  maxWidth: '1070px',
  margin: '0 auto',
  display: 'flex',
});

const updatedContainer = css({
  width: '100%',
  maxWidth: '1070px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'end',
  paddingTop: '$small',
});

const userInfo = css({
  transform: 'translateY(62px)',
});

const greeting = css({
  display: 'flex',
  gap: '$quarck',
});

const email = css({
  marginTop: '$small',
});

const pins = css({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'end',
  paddingBottom: '69px',
  flexGrow: 1,
  minHeight: '284px',

  '> .small': {
    width: '22px',
  },

  '> .large': {
    width: '33px',
  },

  '> .buyer-tab:nth-child(1)': {
    transform: 'translate(-38px, -104px)',
  },

  '> .buyer-tab:nth-child(2)': {
    transform: 'translate(-181px, -62px)',
  },

  '> .buyer-tab:nth-child(3)': {
    transform: 'translate(-287px, -116px)',
  },

  '> .buyer-tab:nth-child(4)': {
    transform: 'translate(-537px, -83px)',
  },

  '> .seller-tab:nth-child(1)': {
    transform: 'translate(-38px, -104px)',
  },

  '> .seller-tab:nth-child(2)': {
    transform: 'translate(-101px, -62px)',
  },

  '> .seller-tab:nth-child(3)': {
    transform: 'translate(-287px, -57px)',
  },

  '> .seller-tab:nth-child(4)': {
    transform: 'translate(-434px, -83px)',
  },

  '> .rent-tab:nth-child(1)': {
    transform: 'translate(-112px, -104px)',
  },

  '> .rent-tab:nth-child(2)': {
    transform: 'translate(-181px, -62px)',
  },

  '> .rent-tab:nth-child(3)': {
    transform: 'translate(-405px, -116px)',
  },

  '> .rent-tab:nth-child(4)': {
    transform: 'translate(-534px, -134px)',
  },

  '> .lead-tab:nth-child(1)': {
    transform: 'translate(-125px, -104px)',
  },

  '> .lead-tab:nth-child(2)': {
    transform: 'translate(-284px, -62px)',
  },

  '> .lead-tab:nth-child(3)': {
    transform: 'translate(-379px, -80px)',
  },

  '> .lead-tab:nth-child(4)': {
    transform: 'translate(-453px, -141px)',
  },

  '@media (min-width: 1600px)': {
    '> .buyer-tab:nth-child(2)': {
      transform: 'translate(-141px, -62px)',
    },

    '> .buyer-tab:nth-child(3)': {
      transform: 'translate(-207px, -116px)',
    },

    '> .buyer-tab:nth-child(4)': {
      transform: 'translate(-317px, -83px)',
    },

    '> .seller-tab:nth-child(2)': {
      transform: 'translate(-61px, -62px)',
    },

    '> .seller-tab:nth-child(3)': {
      transform: 'translate(-207px, -57px)',
    },

    '> .seller-tab:nth-child(4)': {
      transform: 'translate(-287px, -83px)',
    },

    '> .rent-tab:nth-child(2)': {
      transform: 'translate(-121px, -62px)',
    },

    '> .rent-tab:nth-child(3)': {
      transform: 'translate(-235px, -116px)',
    },

    '> .rent-tab:nth-child(4)': {
      transform: 'translate(-277px, -134px)',
    },

    '> .lead-tab:nth-child(2)': {
      transform: 'translate(-188px, -62px)',
    },

    '> .lead-tab:nth-child(3)': {
      transform: 'translate(-259px, -80px)',
    },

    '> .lead-tab:nth-child(4)': {
      transform: 'translate(-313px, -141px)',
    },
  },
});

export const styles = {
  container,
  updatedContainer,
  content,
  userInfo,
  greeting,
  email,
  pins,
};
