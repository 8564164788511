const initialState = {
  resultList: null,
  seen: []
};

const getters = {
  resultList: state => state.resultList,
  seen: state => state.seen,
};

const mutations = {
  SET_RESULTLIST(state, resultList) {
    state.resultList = resultList;
  },
  SET_SEEN(state, seen) {
    state.seen = seen;
  },
}

const actions = {
  setResultList({ commit }, resultList) {
    commit('SET_RESULTLIST', resultList);
  },
  setSeen({ commit }, seen) {
    commit('SET_SEEN', seen);
  },
}

export default {
  namespaced: true,
  actions,
  state: initialState,
  getters,
  mutations,
};