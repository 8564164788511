<template>
  <div :class="styles.root().className">
    <Typography
      color="brand-dark"
      variant="body-large"
      tag="h3"
    >
      Para acessar novamente estas informações, clique no ícone <QuestionMarkCircleIcon /> no canto inferior direito do mapa.
    </Typography>

    <Button
      variant="alternative"
      size="large"
      @click="onUnderstandClick"
    >
      Ok, entendi
    </Button>
  </div>
</template>

<script>

import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';

import QuestionMarkCircleIcon from '@/components/icons/QuestionMarkCircleIcon';

import { styles } from './HelpModalFooter.styles';

export default {

  name: 'HelpModalFooter',

  components: {
    Typography,
    Button,

    QuestionMarkCircleIcon,
  },

  created () {
    this.styles = styles;
  },

  methods: {

    onUnderstandClick () {
      this.$emit('understand');
    },
  },
};

</script>
