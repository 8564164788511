import api from '@/api';

export default async function fetchCondoSuggestions({ commit }, data) {
  try {
    const { query, domain } = data;
    commit('SET_LOADING', true);
    const { response } = await api.locations.fetchCondoSuggestions(query, domain);
    return response;
  } catch (error) {
    console.error(error);
  } finally {
    commit('SET_LOADING', false);
  }

  return [];
}
