<template>
  <div class="show-more-button" @click="showInformation(accordionID, activeTabID)">
    <div class="h3">
      <slot />
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'ShowMoreButton',
  props: {
    accordionID: {
      type: String,
      required: true,
    },
    activeTabID: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('person', ['setActiveTab']),
    openAccordion(accordion) {
      const element = document.querySelector(`#${accordion} button`);
      const isOpen = element.classList.value.includes('v-expansion-panel-header--active');
      if (!isOpen) element.click();
    },
    showInformation(accordion, activeTab) {
      this.setActiveTab(activeTab);
      this.openAccordion(accordion);
    },
  },
};
</script>

<style lang="scss" scoped>

.show-more-button {
  .h3 {
    font-family: Lato, sans-serif;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    color: rgba(70, 99, 185, 1);
  }
}
</style>
