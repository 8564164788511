<template>
  <div
    id="offers"
    class="w-full py-0 px-0"
  >
    <div class=" w-full flex justify-center offer-tags mb-6 d-flex">
      <v-select
        v-if="!isBuyer"
        v-model="filter.realty"
        :items="realties"
        item-text="label"
        item-value="id"
        outlined
        class="hidden mx-2 mr-0 max-w-sm"
        dense
        clearable
        hide-details
        placeholder="Imobiliária"
      />
    </div>

    <div v-if="filteredOffers && filteredOffers.length">
      <ul class="d-flex flex-wrap pl-0 mx-n3 p-0 justify-between">
        <div class="d-flex flex-wrap pl-0 p-0 justify-between w-full">
          <p
            v-if="filteredOffers.find(element => element.class === offersType.bossaOffers)"
            class="px-2 mx-3 pl-0 py-3 w-full offers-blue"
          >
            <span
              id="bossa-offers"
              class="offers-info"
            >
              <span class="offers-title offers-border-blue" />
              <Typography
                color="#044DA1"
                variant="title-medium"
                tag="span"
                class="type-offer"
              >
                {{ offersInfo.bossaOffers.title }}
              </Typography>
              <span class="pipe"/>
              <Typography
                color="#044DA1"
                variant="body-medium"
                tag="span"
                class="type-offer"
              >
                {{ offersInfo.bossaOffers.description }}
              </Typography>
            </span>
          </p>
          <PropertyOffer
            v-for="(item, index) in filteredOffers.filter((element) => element.class === offersType.bossaOffers)"
            v-if="index < offersMax.bossaOffers"
            :key="index"
            :property="item"
            data-test="bossaOffer"
            class="w-1/2 mt-0 mb-5"
            disable-flagging
            @click="openOfferDetail(item)"
          />
          <div 
            v-if="filteredOffers.find(element => element.class === offersType.bossaOffers)"
            class="d-flex align-center justify-center w-full mb-8"
          >
            <v-btn
              v-if="filteredOffers.filter(element => element.class === offersType.bossaOffers).length > offersMax.bossaOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-more-bossa"
              @click="increaseMax('bossaOffers')"
            >
              <span class="button-text">Ver mais {{ offersInfo.bossaOffers.title }}</span>
            </v-btn>
            <v-btn
              v-if=" offersMax.bossaOffers > offersMin && filteredOffers.filter(element => element.class === offersType.bossaOffers).length <= offersMax.bossaOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-less-bossa"
              @click="collapseAll('bossaOffers')"
            >
              <span class="button-text">Ver menos {{ offersInfo.bossaOffers.title }}</span>
            </v-btn>
          </div>
        </div>

        <div class="d-flex flex-wrap pl-0 p-0 justify-between w-full">
          <p
            v-if="filteredOffers.find(element => element.class === offersType.newOffers)"
            class="px-2 mx-3 pl-0 py-3 w-full offers-green"
          >
            <span
              id="new-offers"
              class="offers-info"
            >
              <span class="offers-title offers-border-green" />
              <Typography
                color="#006141"
                variant="title-medium"
                tag="span"
                class="type-offer"
              >
                {{ offersInfo.newOffers.title }}
              </Typography>
              <span class="pipe"/>
              <Typography
                color="#006141"
                variant="body-medium"
                tag="span"
                class="type-offer"
              >
                {{ offersInfo.newOffers.description }}
              </Typography>
            </span>
          </p>
          <PropertyOffer
            v-for="(item, index) in filteredOffers.filter(element => element.class === offersType.newOffers)"
            v-if="index < offersMax.newOffers"
            :key="index"
            :property="item"
            data-test="newOffer"
            class="w-1/2 mt-0 mb-5"
            disable-flagging
            @click="openOfferDetail(item)"
          />
          <div 
            v-if="filteredOffers.find(element => element.class === offersType.newOffers)"
            class="d-flex align-center justify-center w-full mb-8"
          >
            <v-btn
              v-if="filteredOffers.filter(element => element.class === offersType.newOffers).length > offersMax.newOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-more-new"
              @click="increaseMax('newOffers')"
            >
              <span class="button-text">Ver mais ofertas em {{ offersInfo.newOffers.title }}</span>
            </v-btn>
            <v-btn
              v-if="offersMax.newOffers > offersMin && filteredOffers.filter(element => element.class === offersType.newOffers).length <= offersMax.newOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-less-new"
              @click="collapseAll('newOffers')"
            >
              <span class="button-text">Ver menos em {{ offersInfo.newOffers.title }}</span>
            </v-btn>
          </div>
        </div>

        <div class="d-flex flex-wrap pl-0 p-0 justify-between w-full">
          <p
            v-if="filteredOffers.find(element => element.class === offersType.lastMonthOffers)"
            class="px-2 mx-3 pl-0 py-3 w-full offers-black"
          >
            <span
              id="last-month-offers"
              class="offers-info"
            >
              <span class="offers-title offers-border-black" />
              <Typography
                color="neutral-black"
                variant="title-medium"
                tag="span"
                class="type-offer"
              >
                {{ offersInfo.lastMonthOffers.title }}
              </Typography>
              <span class="pipe"/>
              <Typography
                color="neutral-black"
                variant="body-medium"
                tag="span"
                class="type-offer"
              >
              {{ offersInfo.lastMonthOffers.description }}
              </Typography>
            </span>
          </p>
          <PropertyOffer
            v-for="(item, index) in filteredOffers.filter((element) => element.class === offersType.lastMonthOffers)"
            v-if="index < offersMax.lastMonthOffers"
            :key="index"
            :property="item"
            data-test="lastMonthOffer"
            class="w-1/2 mt-0 mb-5"
            disable-flagging
            @click="openOfferDetail(item)"
          />
          <div 
            v-if="filteredOffers.find(element => element.class === offersType.lastMonthOffers)"
            class="d-flex align-center justify-center w-full mb-8"
          >
            <v-btn
              v-if="filteredOffers.filter(element => element.class === offersType.lastMonthOffers).length > offersMax.lastMonthOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-more-last-month"
              @click="increaseMax('lastMonthOffers')"
            >
              <span class="button-text">
                Ver mais ofertas em {{ offersInfo.lastMonthOffers.title }}
              </span>
            </v-btn>
            <v-btn
              v-if="offersMax.lastMonthOffers > offersMin && filteredOffers.filter(element => element.class === offersType.lastMonthOffers).length <= offersMax.lastMonthOffers"
              depressed
              outlined
              rounded
              color="#3566C9"
              data-test="see-less-last-month"
              @click="collapseAll('lastMonthOffers')"
            >
              <span class="button-text">Ver menos em {{ offersInfo.lastMonthOffers.title }}</span>
            </v-btn>
          </div>
        </div>

        <p
          v-if="filteredOffers.find(element => element.class === offersType.potentialOffers)" 
          class="px-2 mx-3 pl-0 py-0 w-full offers-gray-6"
        >
          <ExpandablePanel
            id="potential-offers"
            :open="panel.potentialOffers.open"
            :is-desktop="true"
            offer-type="potentialOffers"
            :offers-info="offersInfo.potentialOffers"
            @panel-expand="() => changePanelVisibility('potentialOffers', false)"
          >
            <div class="d-flex flex-wrap pl-0 p-0 mt-4 justify-between">
              <PropertyOffer
                v-for="(item, index) in filteredOffers.filter(element => element.class === offersType.potentialOffers)"
                :key="index"
                :property="item"
                data-test="potentialOffer"
                class="w-1/2 mt-0 mb-5"
                disable-flagging
                @click="openOfferDetail(item)"
              />
            </div>
          </ExpandablePanel>
        </p>

        <p
          v-if="filteredOffers.find(element => element.class === offersType.previousOffers)"
          class="px-2 mx-3 pl-0 py-0 w-full offers-gray"
        >
          <ExpandablePanel
            id="previous-offers"
            :open="panel.previousOffers.open"
            :is-desktop="true"
            offer-type="previousOffers"
            :offers-info="offersInfo.previousOffers"
            @panel-expand="() => changePanelVisibility('previousOffers', false)"
          >
            <div class="d-flex flex-wrap pl-0 p-0 mt-4 justify-between">
              <PropertyOffer
                v-for="(item, index) in filteredOffers.filter(element => element.class === offersType.previousOffers)"
                :key="index"
                :property="item"
                data-test="previousOffer"
                class="w-1/2 mt-0 mb-5"
                disable-flagging
                @click="openOfferDetail(item)"
              />
            </div>
          </ExpandablePanel>
        </p>
      </ul>
    </div>
    <div
      v-else
      class="flex justify-center py-10 w-full"
    >
      Não encontramos ofertas para serem exibidas :(
    </div>
    <PropertyModal
      v-if="!!highlightedOffer || dialogLoading"
      :dialog-loading-status="dialogLoading"
      :property="highlightedOfferSync"
      :graph-data="graphData"
      :ads-ids="highlightedOfferAdsIds"
      @update:visibility="updateVisibility()"
    />
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PropertyOffer from '@/components/property/PropertyOffer.vue';
import PropertyModal from './PropertyModal.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import ExpandablePanel from '@/components/accordion/ExpandablePanel';
import Typography from '../../components/base/Typography';

export default {
  components: {
    ExpandablePanel,
    GoogleTagManager,
    PropertyModal,
    PropertyOffer,
    Typography
},
  props: {
    property: {
      type: Object,
      required: true,
    },
    offers: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      highlightedOffer: null,
      highlightedOfferAdsIds: [],
      filter: {
        period: null,
        realty: null,
      },
      dialogLoading: false,
      gtmEvent: null,
      firedEvents: {
        bossaOffers: false,
        newOffers: false,
        lastMonthOffers: false,
        previousOffers: false,
      },
      panel: {
        previousOffers: {
          open: false,
        },
        potentialOffers: {
          open: false
        }
      },
      offersInfo: {
        bossaOffers: {
          title: 'Ofertas Bossa Nova Sotheby\'s',
          description: 'Ofertas Bossa Nova disponíveis no último mês',
        },
        lastMonthOffers: {
          title:'Permaneceram',
          description: 'Ofertas que aparecem no último e no penúltimo mês',
        },
        newOffers: {
          title: 'Novidades',
          description: 'Ofertas do último mês que não apareceram no mês anterior',
        },
        previousOffers: {
          title: 'Ofertas anteriores',
          description: 'Ofertas que não apareceram no último mês',
        },
        potentialOffers: {
          title: 'Possíveis ofertas',
          description: 'Ofertas na mesma rua com características semelhantes'
        }
      },
      offersType: {
        bossaOffers: 0,
        newOffers: 10,
        lastMonthOffers: 20,
        previousOffers: 30,
        potentialOffers: 40,
      },
      offersMax: {
        bossaOffers: 4,
        newOffers: 4,
        lastMonthOffers: 4,
      },
      offersMin: 4,
    };
  },
  computed: {
    ...mapState('route', ['path']),
    ...mapState({
      lastSearch (state, getters) {
        return getters[`${this.$route.name}/lastSearch`]; 
      },
    }),
    isFavorite () {
      return this.favorites && this.favorites.includes(this.property.id);
    },
    isBuyer () {
      return this.path.includes('buyer');
    },
    isSeller () {
      return this.path.includes('seller');
    },
    isLead () {
      return this.path.includes('lead');
    },
    realties () {
      const reducedSellers
        = this.offers
        && this.offers.length
        && this.offers
          .map(offer => offer.seller || { id: offer.sellerId, name: offer.sellerName })
          .reduce(
            (acc, { id, name }) => ({
              ...acc,
              [id]: name,
            }),
            {},
          );

      return (
        (reducedSellers && Object.entries(reducedSellers).map(([
          id,
          label,
        ]) => ({ id, label })))
        || []
      );
    },
    filteredOffers () {
      const { realty } = this.filter;

      return (
        (this.offers
          && this.offers.filter(offer => {
            return !realty || offer.sellerId === parseInt(realty, 10);
          }))
        || []
      );
    },
    periods () {
      const reducedPeriods
        = this.offers
        && this.offers.length
        && this.offers
          .map(offer => offer.yearMonth)
          .reduce((acc, value) => ({ ...acc, [value]: value }), {});
      let periods
        = (reducedPeriods && Object.entries(reducedPeriods).map(([
          id,
          label,
        ]) => ({ id, label })))
        || [];

      periods = periods.sort((a, b) => {
        if (a.id < b.id) return 1;

        if (a.id > b.id) return -1;

        return 0;
      });

      return periods;
    },
    highlightedOfferSync () {
      return this.highlightedOffer ? this.highlightedOffer.offer[0] : null;
    },
    graphData () {
      return this.highlightedOffer ? this.highlightedOffer.graphData : null;
    },
  },
  watch: {
    offers () {
      this.offersMax.bossaOffers = 4;
      this.offersMax.lastMonthOffers = 4;
      this.offersMax.newOffers = 4;
    },
  },
  created () {
    const property = document.getElementById('property');

    property.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroyed () {
    const property = document.getElementById('property');

    property.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      fetchOffer: 'property/fetchOffer',
    }),
    async openOfferDetail (item) {
      this.dialogLoading = true;
      this.highlightedOfferAdsIds = item.adsIds;
      this.gtmEvent = {
        event: 'view_offer_item',
        item_id: item.offerId,
      };
      this.highlightedOffer = await this.fetchOffer({
        offerId: item.offerId,
        context: this.$route.name,
        adsIds: item.adsIds,
      });
      this.dialogLoading = false;
    },
    updateVisibility () {
      this.dialogLoading = false;
      this.highlightedOffer = null;
    },
    handleScroll () {
      const translatedBlockName = {
        previousOffers: 'anteriores',
        lastMonthOffers: 'permaneceram',
        newOffers: 'novidades',
        bossaOffers: 'bossa',
      };

      const blocks = {
        previousOffers: document.querySelector('#previous-offers') || null,
        bossaOffers: document.querySelector('#bossa-offers') || null,
        newOffers: document.querySelector('#new-offers') || null,
        lastMonthOffers: document.querySelector('#last-month-offers') || null,
      };

      const parentBlockSize = document.querySelector('#property').getBoundingClientRect().height;

      const isVisible = block => {
        return blocks[block] ? blocks[block].getBoundingClientRect().top < (parentBlockSize * 0.8) : false;
      };

      for (const block in blocks) {
        if (isVisible(block) && !this.firedEvents[block]) {
          this.firedEvents[block] = true;
          this.gtmEvent = {
            event: 'view_offer',
            item_id: this.property.condoId,
            type: translatedBlockName[block],
          };
        }
      }
    },
    changePanelVisibility (panelName, button) {
      switch (button) {
      case true:
        for (const panelKey of Object.keys(this.panel.value)) {
          this.panel[panelKey].open = false;
        }

        this.panel[panelName].open = true;
        break;
      
      default:
        this.panel[panelName].open = !(this.panel[panelName].open);
        break;
      }
    },
    increaseMax (offerType) {
      switch (offerType) {
      case 'bossaOffers':
        this.offersMax.bossaOffers += 4;
        break;
      case 'newOffers':
        this.offersMax.newOffers += 4;
        break;
      case 'lastMonthOffers':
        this.offersMax.lastMonthOffers += 4;
        break;
      }
    },
    collapseAll (offerType) {
      switch (offerType) {
      case 'bossaOffers':
        this.offersMax.bossaOffers = 4;
        break;
      case 'newOffers':
        this.offersMax.newOffers = 4;
        break;
      case 'lastMonthOffers':
        this.offersMax.lastMonthOffers = 4;
        break;
      }
    },
  },
};
</script>

<style scoped>
:deep .property-container .attributes ul span {
  font-size: 1em;
}

:deep .property-container .price {
  font-size: 1em;
  padding: 1em 0;
}

:deep .property-container .priceTrend {
  margin-top: -0.5em;
}

:deep .property .divider {
  margin: 0 0.3em;
  height: 20px;
  border-right: 1px solid #777;
}

:deep .property:last-child {
  margin-right: auto;
}
</style>

<style lang="scss" scoped>
.offers-info {
  align-items: center;
  display: flex;
  gap: 9px;
}
.pipe {
  border: 0;
  border-left: 1px solid #6B6B6B;
  height: 20px;

}
.offers-title {
  display: inline-block;
  width: 5px;
  height: 23px;

  &.offers-border-blue {
    background-color: #044DA1;
  }

  &.offers-border-green {
    background-color: #006141;
  }

  &.offers-border-black {
    background-color: #000000;
  }
}

.offers-blue {
  color: #044DA1;
}

.offers-green {
  color: #006141;
}

.offers-black {
  color: #000000;
}

.offers-gray {
  color: #6B6B6B;
}

.offers-gray-6 {
  color: $colors-neutral-grey-6
}

.button-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: none;
}
</style>
