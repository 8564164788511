import api from '@/api';
import { FILTER_MODE, MAP_OPTIONS } from '@/common/constants';

export default async function search (
  { commit, state, rootState, dispatch },
  filter,
) {
  try {
    commit('SET_ERROR', null);
    commit('SET_LOADING', true);

    const routeName = rootState.route.name === 'MobileCondominium' ? 'buyer' : rootState.route.name;

    const { attributesFilter = {}, params = {} } = filter;

    const { filterMode, filter: sharedFilter } = state;

    let request = null;

    if (filterMode === FILTER_MODE.RADIUS) {
      const { position, radius } = sharedFilter;
      const [
        lat,
        lng,
      ] = position;

      request = [
        {
          filterMode,
          radius,
          coordinates: [
            lat,
            lng,
          ],
          routeName,
        },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.REGION) {
      const { coordinates } = sharedFilter;

      request = [
        { filterMode, coordinates, routeName },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.AREA) {
      const { coordinates } = sharedFilter;

      request = [
        { filterMode, coordinates, routeName },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.CONDO) {
      const { address } = filter;

      request = [
        {
          filterMode,
          radius: MAP_OPTIONS.DEFAULT_RADIUS,
          coordinates: address.info.geolocation,
          routeName,
          addressId: address.info.addressId,
          condoId: address.info.condoId,
          selectedAttributes: address.info.selectedAttributes,
          yearOfConstruction: address.info.yearOfConstruction,
        },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.ADDRESS) {
      const { address } = filter;

      request = [
        {
          filterMode,
          radius: MAP_OPTIONS.DEFAULT_RADIUS,
          coordinates: sharedFilter.geolocation,
          routeName,
          addressId: address.info.addressId,
        },
        attributesFilter,
        params,
      ];
    }

    const returnResponse = null;
    let response = null;

    if (filterMode === FILTER_MODE.RADIUS) {
      response = await api.search.searchByRadius(...request, this);
    } else if (filterMode === FILTER_MODE.REGION) {
      response = await api.search.searchByRegion(...request, this);
    } else if (filterMode === FILTER_MODE.AREA) {
      response = await api.search.searchByShape(...request, this);
    } else if (filterMode === FILTER_MODE.CONDO) {
      response = await api.search.searchSeller(...request);

      // normalizar coords
      const unique = {};

      response.map(block => {
        if (block && block.results && block.results.coords) {
          block.results.coords.map(i => {
            unique[i[0]] = i;
          });
        }
      });
      commit('SET_ORIGINAL_SEARCH_RESULT', response);

      // armazena resposta estruturada original em fullResults
      response = {
        response: Object.values(unique).sort((a, b) => (a[9] > b[9] ? 1 : -1)),
      };
    } else if (filterMode === FILTER_MODE.ADDRESS) {
      response = await api.search.searchByLocation(...request, this);
    }

    if (!response?.response) {
      throw new Error();
    }

    const { response: results = [] } = response || {};
    
    commit('SET_LAST_SEARCH_PARAMETERS', request);
    commit('setMapSearchResult', results);
    commit('property/SET_PROPERTY', null, { root: true });
    commit('FETCH_ADDRESSESS_SUCCESS');

    // ajustar a busca por paginacao de pois
    const firstPage = results.slice(0, 16).map(([addressId]) => addressId);

    dispatch('fetchAddresses', { routeName, firstPage });

    return returnResponse;
  } catch (error) {
    console.error(error);
    commit('SET_ERROR', error);
    commit('FETCH_ADDRESSESS_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }
}
