export class SummaryBlock {
  #items = {}

  constructor (rawData) {
    this.rawData = rawData;
  }

  addStaticData (key, value) {
    this.#items[key] = value;
  }

  addDynamicData (node, key, fn, rawDataKey) {
    this.#items[node][key] = fn(this.rawData[rawDataKey]);
  }

  get items () {
    return this.#items;
  }
}