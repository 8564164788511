var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"custom-input"},[_c('div',{staticClass:"custom-input__content-wrapper"},[_c('input',{ref:"input",class:{
        'input-field': true,
        'input-field--filled': _vm.isFilled,
        'input-field--error': _vm.isError,
      },attrs:{"id":_vm.name,"data-single-search":"","type":_vm.type,"placeholder":_vm.inputPlaceholder,"autocomplete":"off","disabled":_vm.disabled,"readonly":Boolean(_vm.multiple)},domProps:{"value":_vm.inputValue},on:{"focus":_vm.onFocus,"input":_vm.onInput}}),_c('label',{class:{
        'label': true,
        'label--filled': _vm.isFilled,
        'label--active': _vm.active,
        'label--error': _vm.isError,
      },attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],ref:"dropdown",staticClass:"options-list"},[(_vm.multiple)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search",attrs:{"data-multiple-search":"","placeholder":"Digite para filtrar..."},domProps:{"value":(_vm.search)},on:{"focus":_vm.onFocus,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('span',{staticClass:"selected-title"},[_vm._v("Selecionado(s):")]),_c('RecycleScroller',{staticClass:"scroller scroller--selected",attrs:{"emit-update":"","items":_vm.selected,"item-size":_vm.customItemHeight,"key-field":"id"},on:{"update":_vm.onScrollerUpdate},scopedSlots:_vm._u([(!_vm.selected.length)?{key:"before",fn:function(){return [_c('div',{staticClass:"scroller-item"},[_c('CheckboxCheckedIcon'),_c('span',[_vm._v("Todas")])],1)]},proxy:true}:null,{key:"default",fn:function({ item }){return [_c('div',{staticClass:"scroller-item",on:{"click":() => _vm.onSelect(item)}},[_c('CheckboxCheckedIcon'),_c('span',[_vm._v(_vm._s(_vm.multiple ? item[_vm.multiple.label] : item))])],1)]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"filtered-list"},[_c('RecycleScroller',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],class:{
            'scroller': true,
            'scroller--multiple': _vm.multiple,
          },attrs:{"emit-update":"","items":_vm.filtered,"item-size":_vm.customItemHeight,"key-field":"id"},on:{"update":_vm.onScrollerUpdate},scopedSlots:_vm._u([(!_vm.filtered.length)?{key:"before",fn:function(){return [_c('div',{staticClass:"scroller-item"},[(_vm.loading)?[_vm._v(" Carregando resultados... ")]:[_vm._v(" Nenhum resultado encontrado. ")]],2)]},proxy:true}:null,{key:"default",fn:function({ item }){return [_c('div',{staticClass:"scroller-item",style:({
                height: `${_vm.customItemHeight}px`,
              }),on:{"click":() => _vm.onSelect(item)}},[(_vm.customItemSlot)?_vm._t("default",null,{"item":item}):[(_vm.multiple)?_c('CheckboxUncheckedIcon'):_vm._e(),(_vm.multiple)?_c('span',[_vm._v(_vm._s(item[_vm.multiple.label]))]):(_vm.single)?_c('span',[_vm._v(_vm._s(item[_vm.single.label]))]):_c('span',[_vm._v(_vm._s(item))])]],2)]}}],null,true)})],1),(_vm.multiple)?_c('div',{staticClass:"footer"},[(_vm.loading)?[_vm._v(" Carregando resultados... ")]:[_vm._v(" "+_vm._s(_vm.formatter.format(_vm.remote ? _vm.options.length : _vm.filtered.length))+" resultado(s) disponívei(s). ")]],2):_vm._e()],2)]),(_vm.isError)?_c('div',{staticClass:"custom-input__error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }