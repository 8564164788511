<template>
  <v-dialog
    v-model="visible"
    content-class="w-7/12 mx-auto property-price-trend-dialog"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <div class="bg-white chart w-full mx-auto p-6">
      <h3
        class="w-full text-center font-bold text-gray-600"
        @click="handleEaster({ highlightedTypology }, $event)"
      >
        {{ highlightedTypology.title }}
      </h3>
      <GChart
        v-if="highlightedTypology && highlightedTypology.data"
        :settings="{ packages: ['corechart'], language: 'pt-BR' }"
        type="LineChart"
        :data="chartData"
        :options="chartOptions"
        :resize-debounce="500"
        @ready="chartIsReady = true"
      >
        Aguarde...
      </GChart>
      <div
        v-show="!chartIsReady && highlightedTypology && highlightedTypology.data"
        class="w-full justify-center"
      >
        <v-progress-circular
          class="mx-auto"
          color="rgba(70, 99, 185, 0.95)"
          indeterminate
        />
      </div>
      <div
        v-show="!(highlightedTypology && highlightedTypology.data)"
        class="bg-white flex flex-wrap justify-center py-10 px-10"
      >
        <p>Não foi possível exibir o histórico de preços para esta tipologia :(</p>
      </div>
    </div>
  </v-dialog>
</template>

<script>

import { GChart } from 'vue-google-charts';

export default {
  name: 'OfferPriceTrendPopup',
  components: {
    GChart,
  },
  props: {
    highlightedTypology: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      visible: false,
      chartIsReady: false,
      chartData: [],
      chartDataMaxItems: 6,
      chartOptions: {
        height: 250,
        vAxis: {
          format: 'R$ #,###.###',
        },
        curveType: 'function',
        pointSize: 5,
        legend: {
          position: 'none',
        },
        tooltip: {
          isHtml: true,
        },
        colors: [
          '#4663b9',
          '#4663b9',
          '#4663b9',
        ],
      },
    };
  },
  mounted () {
    this.visible = !!this.highlightedTypology;
    this.$watch('highlightedTypology', value => {
      this.visible = !!value;
      this.chartIsReady = false;
    });

    const source = this.highlightedTypology.data;
    const data = source.filter((d, i) => i > 0);

    this.chartData = [
      [
        'Data',
        'Preço',
        { type: 'string', role: 'tooltip', p: { html: true } },
      ],
      ...data.map((val, x) => [
        data[x][0],
        parseInt(data[x][1].f.replace(/\D/g, '')),
        `<div class="p-3 z-20">
          <div><b class="mr-1">${source[0][1]}:</b>${data[x][1].f}</div>
          <div><b class="mr-1">Máx.:</b>${data[x][2].f}</div>
          <div><b class="mr-1">Mín.:</b>${data[x][3].f}</div>
         </div>`,
      ]).filter((item, index) => index < this.chartDataMaxItems),
    ];
  },
  methods: {
    closeDialog () {
      this.visible = false;
      this.chartIsReady = false;
      this.$emit('update:visibility', this.visible);
    },
  },
};

</script>

<style scoped>

  :deep(.google-visualization-tooltip) {
    width: 200px;
  }

  :deep(.google-visualization-tooltip-item-list) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

</style>
