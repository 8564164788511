<template>
  <base-card>
    <div v-if="Object.keys(data).length > 0" class="flex flex-column gap-4">
      <div class="flex flex-row w-full">
        <div class="flex flex-col">
          <Typography
            variant="body-small"
            color="brand-darker"
            tag="span"
          >
            Nome:
          </Typography>
          <Typography
            variant="body-table"
            color="brand-darker"
            tag="span"
          >
            {{ data?.Name || '-' }}
          </Typography>
        </div>
        <div class="flex flex-col">
          <Typography
            variant="body-small"
            color="brand-darker"
            tag="span"
          >
            Nacionalidade:
          </Typography>
          <Typography
            variant="body-table"
            color="brand-darker"
            tag="span"
          >
            {{ getLabel(NATIONALITY, (data?.Person?.Nationality)) || '-' }}
          </Typography>
        </div>
        <div class="flex flex-col">
          <Typography
            variant="body-small"
            color="brand-darker"
            tag="span"
          >
            CPF:
          </Typography>
          <Typography
            variant="body-table"
            color="brand-darker"
            tag="span"
          >
            {{ data?.DocumentFormatted || '-'}}
          </Typography>
        </div>
        <div class="flex flex-col">
          <Typography
            variant="body-small"
            color="brand-darker"
            tag="span"
          >
            Idade:
          </Typography>
          <Typography
            variant="body-table"
            color="brand-darker"
            tag="span"
          >
            {{labelDateorAge(data?.BirthDate, true) || '-'}}
          </Typography>
        </div>
        <div class="flex justify-end">
          <div class="has-fac" v-if="hasFac">
            <Typography
              variant="title-large"
              color="highlight-dark"
              tag="span"
            >
              Possui FAC
            </Typography>
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full gap-2">
        <div class="flex flex-col border-data birth-date">
          <Typography
            variant="body-medium"
            color="neutral-grey-5"
            tag="span"
          >
            Nascimento
          </Typography>
          <Typography
            variant="body-medium"
            color="brand-dark"
            tag="span"
          >
            {{ labelDateorAge(data?.BirthDate, false) || '-'}}
          </Typography>
        </div>
        <div class="flex flex-col border-data education">
          <Typography
            variant="body-medium"
            color="neutral-grey-5"
            tag="span"
          >
            Escolaridade
          </Typography>
          <Typography
            variant="body-medium"
            color="brand-dark"
            tag="span"
          >
            {{ getLabel(EDUCATION_LEVEL,(data?.Person?.EducationLevel)) || '-'}}
          </Typography>
        </div>
        <div class="flex flex-col border-data marital-status">
          <Typography
            variant="body-medium"
            color="neutral-grey-5"
            tag="span"
          >
            Estado Civil
          </Typography>
          <Typography
            variant="body-medium"
            color="brand-dark"
            tag="span"
          >
            {{ getLabel(MARITAL_STATUS, (data?.Person?.MaritalStatus)) || '-'}}
          </Typography>
        </div>
        <div class="flex flex-col border-data gender">
          <Typography
            variant="body-medium"
            color="neutral-grey-5"
            tag="span"
          >
            Gênero
          </Typography>
          <Typography
            variant="body-medium"
            color="brand-dark"
            tag="span"
          >
          {{ getLabel(GENDER, data?.Gender) || '-' }}
          </Typography>
        </div>
        <div class="flex flex-col border-data dependents">
          <Typography
            variant="body-medium"
            color="neutral-grey-5"
            tag="span"
          >
            Nº Dependentes
          </Typography>
          <Typography
            variant="body-medium"
            color="brand-dark"
            tag="span"
          >
          {{ (data?.Person?.Dependents) || 0 }}
          </Typography>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Não há dados para serem exibidos.</p>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/cards/base-card.vue';
import moment from 'moment';
import {
  NATIONALITY,
  MARITAL_STATUS,
  EDUCATION_LEVEL,
  GENDER,
  DATE_TYPE
} from '@/common/constants';
import getLabel from '@/utils/getLabel.js';
import Typography from '../base/Typography';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BasicInfo',
  components: {
    BaseCard,
    Typography
},
  props: ['data'],
  data() {
    return {
      getLabel,
      NATIONALITY,
      MARITAL_STATUS,
      EDUCATION_LEVEL,
      GENDER,
      DATE_TYPE
    };
  },
  computed: {
    ...mapState('person', ['detail', 'hasFac']),
  },
  methods: {
    ...mapActions('person', ['fetchHasFac']),
    labelDateorAge(dateinput, isAge) {
      const utc = 0;
      const dateUtc = moment.utc(dateinput).utcOffset(utc);
      const formattedDate = dateUtc.format('YYYY-MM-DD');

      if (isAge) {
        const age = moment().utcOffset(utc).diff(formattedDate, 'years');
        return age;
      }
      const ptbrFormattedDate = dateUtc.format('DD/MM/YYYY');
      return ptbrFormattedDate;
    },

  },
  async mounted() {
    if (!this.data) return;
    if (this.hasFac === null) {
      if (this.$store.getters['user/idToken'].payload.real_estate_id === process.env.VUE_APP_BOSSA_REAL_ESTATE_ID) {
        this.fetchHasFac({
          phones: this.data.Phones ? this.data.Phones.map(phone => ({
            AreaCode: phone.AreaCode,
            Number: phone.Number,
          })) : [],
          emails: this.data.Emails ? this.data.Emails.map(email => email.Email) : [],
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.birth-date {
  max-width: 100px;
}
.education {
  max-width: 175px;
}

.marital-status {
  max-width: 145px;
}

.gender {
  min-width: 140px;
  max-width: fit-content;
}

.dependents {
  max-width: 140px;
}
.base-card {
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 6px;
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}
.has-fac {
  display: flex;
  width: 141px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--colors-brand-default);
}
.border-data{
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
}
</style>
