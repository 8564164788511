import {
  ref,
  computed,
} from 'vue';

import { defineStore } from 'pinia';

import qs from 'qs';

import router from '../router';

/**
 * Constants
 */
import {
  OPENSEARCH_CITIES,
  OPENSEARCH_SORTINGS,
} from '@/common/constants';

/**
 * Activity
 */
import Activity from '@/core/activity';

/**
 * Services
 */
import { useOpenSearchService } from '@/api/openSearch';

/**
 * Store
 */
export const useOpenSearchStore = defineStore('openSearch', () => {

  /**
   * Service
   */
  const openSearchService = useOpenSearchService();

  /**
   * Initial state
   */
  const initialState = {
    city: OPENSEARCH_CITIES[0].value, // default: "sao-paulo"
    district: '',
    negotiationType: '',
    sellerType: '',
    portal: '',
    propertyTypeId: '',
    valueMin: null,
    valueMax: null,
    areaMin: '',
    areaMax: '',
    bedroomsMin: '',
    bedroomsMax: '',
    suitesMin: '',
    suitesMax: '',
    parkingLotsMin: '',
    parkingLotsMax: '',
    attributeIds: '',
    sellerIds: '',
    orderBy: Object.keys(OPENSEARCH_SORTINGS)[0], // default: "updatedAt|desc"
    page: 1,
  };

  /**
   * State
   */
  const search = ref({ ...initialState });

  const listings = ref({
    results: [],
    totalAds: 0,
    loading: false,
  });

  const attributes = ref({
    data: [],
    loading: false,
  });

  const districts = ref({
    data: [],
    loading: false,
  });

  const sellers = ref({
    data: [],
    loading: false,
  });

  /**
   * Getters
   */
  const sidebar = computed(() => ({
    city: search.value.city,
    district: search.value.district,
    negotiationType: search.value.negotiationType,
    sellerType: search.value.sellerType,
    portal: search.value.portal,
    propertyTypeId: search.value.propertyTypeId,
    valueMin: search.value.valueMin,
    valueMax: search.value.valueMax,
    areaMin: search.value.areaMin,
    areaMax: search.value.areaMax,
    bedroomsMin: search.value.bedroomsMin,
    bedroomsMax: search.value.bedroomsMax,
    suitesMin: search.value.suitesMin,
    suitesMax: search.value.suitesMax,
    parkingLotsMin: search.value.parkingLotsMin,
    parkingLotsMax: search.value.parkingLotsMax,
    attributeIds: search.value.attributeIds,
    sellerIds: search.value.sellerIds,
  }));

  const sorter = computed(() => ({
    orderBy: search.value.orderBy,
  }));

  const payload = computed(() => Object.fromEntries(Object.entries({
    city: search.value.city || null,
    district: search.value.district || null,
    negotiationType: search.value.negotiationType || null,
    sellerType: search.value.sellerType || null,
    portal: search.value.portal || null,
    propertyTypeId: search.value.propertyTypeId || null,
    valueMin: parseInt(search.value.valueMin) || null,
    valueMax: parseInt(search.value.valueMax) || null,
    areaMin: parseInt(search.value.areaMin) || null,
    areaMax: parseInt(search.value.areaMax) || null,
    bedroomsMin: parseInt(search.value.bedroomsMin) || null,
    bedroomsMax: parseInt(search.value.bedroomsMax) || null,
    suitesMin: parseInt(search.value.suitesMin) || null,
    suitesMax: parseInt(search.value.suitesMax) || null,
    parkingLotsMin: parseInt(search.value.parkingLotsMin) || null,
    parkingLotsMax: parseInt(search.value.parkingLotsMax) || null,
    attributeIds: search.value.attributeIds || null,
    sellerIds: search.value.sellerIds || null,
    orderBy: search.value.orderBy || null,
    page: parseInt(search.value.page) || null,
  }).filter(([
    key,
    value,
  ]) => value)));

  /**
   * Methods
   */
  function init () {

    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    filter(params);

    getAttributes();
    getDistricts();
    getSellers(search.value.city);
  };

  function set (params = { }) {

    params.page = parseInt(params.page) || 1;

    search.value = {
      ...search.value,
      ...params,
    };
  };

  async function filter (params = { }) {

    if (listings.value.loading) {
      return;
    }

    set(params);

    push(payload.value);

    Activity.send({
      event: 'select_content',
      content_type: 'Busca de anúncios',
      ...payload.value,
    });

    listings.value.loading = true;

    openSearchService.listings(payload.value)
      .then(data => {

        listings.value = {
          ...listings.value,
          ...data,
        };

        Activity.send({
          event: 'view_result',
          has_result: Boolean(listings.value.totalAds),
        });
      })
      .catch(error => 
        console.error(error))
      .finally(() => 
        listings.value.loading = false);
  };

  function clear () {

    const initialParams = { ...initialState };

    delete initialParams.orderBy;

    set(initialParams);
  };

  function push (query) {

    window.document.body.scrollIntoView({ behavior: 'smooth' });

    router.push({
      query,
    }).catch(() => { });
  };

  async function getAttributes () {

    attributes.value.loading = true;

    openSearchService.attributes()
      .then(data => 
        attributes.value.data = data)
      .catch(error => 
        console.error(error))
      .finally(() => 
        attributes.value.loading = false);
  };

  async function getDistricts () {

    districts.value.loading = true;

    openSearchService.districts()
      .then(data => 
        districts.value.data = data)
      .catch(error => 
        console.error(error))
      .finally(() => 
        districts.value.loading = false);
  };

  async function getSellers (city, sellerSubstring) {

    sellers.value.loading = true;

    openSearchService.sellers(city, sellerSubstring)
      .then(data => 
        sellers.value.data = data)
      .catch(error => 
        console.error(error))
      .finally(() => 
        sellers.value.loading = false);
  };

  /**
   * Return
   */
  return {

    // State
    search,
    listings,
    attributes,
    districts,
    sellers,

    // Getters
    sidebar,
    sorter,
    payload,

    // Methods
    init,
    filter,
    clear,
    getSellers,
  };
});
