<template>
  <div v-if="!forbiddenError">
    <v-dialog
      v-model="accepted"
      content-class="lgpd-modal"
      :persistent="true"
    >
      <div class="wrapper">
        <div>
          <div class="text-right">
            <v-icon
              class="close"
              @click="accepted = false"
            >
              mdi-close
            </v-icon>
          </div>
          <div class="title mt-n4">
            <PaddockIcon class="paddock" />
            <div class="mt-4">
              Privacidade de Dados
            </div>
          </div>
        </div>
        <div class="description mx-auto">
          O uso destas informações deve estar de acordo com a Lei Geral de
          Proteção de Dados. Ao continuar, você concorda com condições
          descritas em nossa <a
            href="https://www.bnsir.com.br/politica-de-privacidade"
            target="_blank"
          >Política de Privacidade.</a>

          <div class="flex mt-5">
            <v-checkbox
              v-model="acceptTerms"
              class="accept-checkbox"
              :label="'Li e estou de acordo com essas condições.'"
              color="#3366CC"
            />
          </div>
        </div>
        <div class="buttons">
          <v-btn
            rounded
            depressed
            :disabled="!acceptTerms"
            color="#3366CC"
            class="accept-btn"
            dark
            @click="accepted = true"
          >
            Ok
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaddockIcon from '@/components/icons/PaddockIcon';

export default {
  name: 'LGPDModal',
  components: { PaddockIcon },
  data: () => ({
    acceptTerms: false,
    forbiddenError: false,
  }),
  computed: {
    ...mapState('user', ['LGPDAcceptance']),
    ...mapState('core', ['vertical']),
    accepted: {
      get () {
        return !this.LGPDAcceptance;
      },
      async set (response) {
        await this.setLGPDTermsAcceptance({
          vertical: this.vertical,
          accepted: !!response,
        });

        if (!response) {
          if (this.$route.path.includes('mobile')) {
            return await this.$router.push('/mobile/home');
          }

          await this.$router.push('/search/buyer');
        }
      },
    },
  },
  created () {
    this.$eventBus.$on('error:forbidden', () => {
      this.forbiddenError = true;
    });
  },
  methods: {
    ...mapActions('user', ['setLGPDTermsAcceptance']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .lgpd-modal {
  @apply flex p-0;
  font-family: 'Lato', sans-serif;
  background-color: white;
  font-style: normal;
  width: 464px;
  border-radius: 10px;
  min-height: 339px;

  .wrapper {
    @apply flex flex-col py-4 px-6;
    .accept-checkbox {
      @apply m-0 p-0;
      height: 25px;
      .v-icon {
        font-size: 27px;
      }
    }
    .title {
      @apply text-center mb-2;
      color: #3366CC;
      font-weight: 900;
      font-size: 22.5px !important;
      line-height: 27px;
    }
    .paddock {
      @apply inline;
      color: #E35050;
      height: 22px;
      width: 19px;
    }
    .close {
      color: #6B6B6B;
      font-size: 28px;
    }
    .description {
      @apply my-4 pr-3;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      max-width: 386px;
      color: #707070;
      a {
        color: #3366CC;
        font-weight: bold;
      }
    }
    .buttons {
      @apply mt-4 text-center mb-3;
      .accept-btn {
        min-width: 100px;
        &.v-btn--disabled {
          background-color: #DEDFDF !important;
          color: white !important;
        }
      }
    }
  }
}
</style>
