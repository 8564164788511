<template>
  <div class="w-full">
    <Tab
      mobile
      class="mb-4"
      :labels="['São Paulo', 'Rio de Janeiro']"
      :onTabClick="[() => onTabClick('SP'), () => onTabClick('RJ')]"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { CITY_CENTER, FILTER_MODE} from '@/common/constants';
import Tab from '@/components/Tab/Tab.vue';
import Typography from '@/components/base/Typography';

export default {
  name: 'LocationSelectorMobile',
  components: {
    Tab,
    Typography
  },
  data() {
    return {
      FILTER_MODE,
      CITY_CENTER
    };
  },
  created () {
    this.setCurrentCity('SP');
    this.changeCurrentCity('SP');
  },
  computed: {
    ...mapGetters('map', ['currentCity']),
  },
  methods: {
    ...mapActions('map', ['setCurrentCity']),
    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),
    onTabClick (city) {
      this.changeCurrentCity(city)
      this.setFilterMode(FILTER_MODE.RADIUS)
    },
    changeCurrentCity (key) {
      this.setCurrentCity(key);
      this.setMapCenter(CITY_CENTER[key]);
    },
  },
};
</script>