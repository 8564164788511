<template>
  <LoginLayout>
    <div class="login-form">
      <div
        class="title"
        data-test="title"
      >
        Cidade Virtual
      </div>
      <div
        class="description-label"
        data-test="label"
      >
        Cheque seu e-mail
      </div>

      <div
        class="description"
        data-test="description"
      >
        Enviamos um código com 6 dígitos para o e-mail <br><strong>{{ email }}</strong>.
      </div>

      <login-reset-step-two />
    </div>
  </LoginLayout>
</template>

<script>

import LoginLayout from '@/layouts/Login.vue';
import LoginResetStepTwo from '@/components/login/LoginConfirmPassword.vue';
import { VALIDATION } from '@/common/constants';

export default {
  name: 'LoginConfirmNewPassword',
  components: {
    LoginLayout,
    LoginResetStepTwo,
  },
  computed: {
    email () {
      const email = this.$store.getters['amplify/email'] || this.$route.query.userName;

      if (!VALIDATION.email.test(email)) return null;

      return email;
    },
    code () {
      return this.$store.getters['amplify/code'] || this.$route.query.confirmation_code;
    },
  },
  created () {
    if (this.code) {
      this.$store.commit('amplify/SET_CODE', this.code);
    }

    if (!this.email) {
      this.$router.push('/login');
    }
    else {
      this.$store.commit('amplify/SET_EMAIL', this.email);
    }
  },
};

</script>

<style lang="scss" scoped>
.login-form {
  @apply mb-3;
  min-width: 412px;
  background: #FFFFFF;
  border: 1px solid #8496cbbb !important;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 24px;

  .title {
    @apply text-center;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 25.5px !important;
    line-height: 100%;
    color: #384059;
  }

  .description-label {
    margin-top: 24px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22.5px;
    line-height: 27px;

    color: #38415A;
  }

  .description {
    margin-top: 24px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #707070;
    max-width: 364px;
  }
}

</style>
