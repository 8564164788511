<script>

export default {
  inheritAttrs: false,
};

</script>

<script setup>

import {
  ref,
  watch,
} from 'vue';

/**
 * Styles
 */
import { styles } from './TextField.styles';

/**
 * Props
 */
const props = defineProps({

  label: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  value: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  error: {
    type: [
      String,
      Boolean,
    ],
    default: null,
  },

  suffix: {
    type: String,
    default: null,
  },

  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  type: {
    type: String,
    default: 'text',
    validator: (value) => {
      return [
        'text',
        'password',
        'email',
        'url',
        'date',
        'time',
        'color',
        'number',
        'tel',
      ].indexOf(value) >= 0;
    },
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  readOnly: {
    type: Boolean,
    default: false,
  },

  autoComplete: {
    type: Boolean,
    default: true
  }
});

/**
 * Emits
 */
const emit = defineEmits([
  'input',
  'change',
  'click',
  'keydown',
  'keyup',
  'keypress',
  'focus',
  'blur',
]);

/**
 * Refs
 */
const inputRef = ref(null);

/**
 * Watch
 */
watch(() => props.error, error => inputRef.value.setCustomValidity(error || ''));

/**
 * Methods
 */
const onInput = event => emit('input', event.target.value);

const onChange = event => emit('change', event);

const onClick = event => emit('click', event);

const onKeyDown = event => emit('keydown', event);

const onKeyUp = event => emit('keyup', event);

const onKeyPress = event => emit('keypress', event);

const onFocus = event => emit('focus', event);

const onBlur = event => emit('blur', event);

const onSuffixClick = () => inputRef.value.focus();

</script>

<template>
  <div :class="styles.root().className">
    <input
      :id="id || name || undefined"
      ref="inputRef"
      :name="name || id || undefined"
      :class="styles.input().className"
      :value="value || undefined"
      :type="type || 'text'"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readOnly"
      :autocomplete="autoComplete ? 'on' : 'off'"
      @input="onInput"
      @change="onChange"
      @click="onClick"
      @keydown="onKeyDown"
      @keyup="onKeyUp"
      @keypress="onKeyPress"
      @focus="onFocus"
      @blur="onBlur"
    >

    <label
      v-if="label"
      :class="styles.label().className"
      :for="id || name || undefined"
    >
      {{ label }}
    </label>

    <div
      v-if="suffix"
      :class="styles.suffix().className"
      @click="onSuffixClick"
    >
      {{ suffix }}
    </div>

    <div
      v-if="error && typeof error === 'string'"
      :class="styles.error().className"
    >
      {{ error }}
    </div>
  </div>
</template>
