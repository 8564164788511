/**
 * playVideoSequence
 */
export default elements => {

  const listeners = [];

  if (elements.length) {

    const generateListener = (firstElement, nextElement) => {

      const elementToPlay = nextElement || firstElement;

      elementToPlay.play();
    };

    elements.forEach((element, elementIndex, elementArray) => {

      const firstElement = elementArray[0];
      const nextElement = elementArray[elementIndex + 1];
      const elementListener = () => generateListener(firstElement, nextElement);

      if (!elementIndex) {
        firstElement.play();
      }

      element.addEventListener('ended', elementListener);

      listeners.push(elementListener);
    });
  }

  return {

    destroy () {
      elements.forEach((element, elementIndex) => element.removeEventListener('ended', listeners[elementIndex]));
    },
  };
};
