import api from '../../api';
import {
  TOGGLE_EXPANDED_SIDEBAR_LAYOUT,
  TOGGLE_COLLAPSED_SIDEBAR_LAYOUT,
  TOGGLE_ADDITIONAL_FILTERS_VISIBILIY,
  SET_USE_MAP_FILTER,
  SET_CONFIG,
  SET_VERTICAL,
} from './mutationTypes';

const initialState = {
  config: null,
  expandedSidebar: false,
  collapsedSidebar: false,
  showAdditionalFilters: false,
  useMapFilter: false,
  vertical: null,
};

const getters = {
  showAdditionalFilters: state => state.showAdditionalFilters,
  expandedSidebar: state => state.expandedSidebar,
  collapsedSidebar: state => state.collapsedSidebar,
  useMapFilter: state => state.useMapFilter,
  config: state => state.config,
  vertical: state => state.vertical,
};

const mutations = {
  [TOGGLE_EXPANDED_SIDEBAR_LAYOUT]: state => {
    state.expandedSidebar = !state.expandedSidebar;
  },
  [TOGGLE_COLLAPSED_SIDEBAR_LAYOUT]: state => {
    state.collapsedSidebar = !state.collapsedSidebar;
  },
  [TOGGLE_ADDITIONAL_FILTERS_VISIBILIY]: state => {
    state.showAdditionalFilters = !state.showAdditionalFilters;
  },
  [SET_USE_MAP_FILTER]: (state, useMapFilter) => {
    state.useMapFilter = useMapFilter;
  },
  [SET_CONFIG]: (state, config) => {
    state.config = config;
  },
  [SET_VERTICAL]: (state, vertical) => {
    state.vertical = vertical;
  },
};

const actions = {
  disableMap({ dispatch, commit }) {
    dispatch('search/clearFilters', null, { root: true });
    dispatch('search/clearSearch', null, { root: true });
    commit(SET_USE_MAP_FILTER, false);
  },
  toggleExpandSidebar({ commit }) {
    commit(TOGGLE_EXPANDED_SIDEBAR_LAYOUT);
  },
  toggleCollapseSidebar({ commit }) {
    commit(TOGGLE_COLLAPSED_SIDEBAR_LAYOUT);
  },
  toggleAdditionalFilters({ commit }) {
    commit(TOGGLE_ADDITIONAL_FILTERS_VISIBILIY);
  },
  setUseMapFilter({ commit }, useMapFilter) {
    commit(SET_USE_MAP_FILTER, useMapFilter);
  },
  async fetchConfig({ commit }) {
    const { response: config } = await api.core.getConfig();
    commit(SET_CONFIG, config);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
