import api from '@/api';

export default async function fetchSellerAddresses(
  { state, commit, dispatch },
  domain
) {
  try {
    commit('SET_LOADING', true);
    const { attributesFilter, requestParams } = state.lastSearch;
    const { response: results } = await api.address.getAddresses(
      attributesFilter,
      requestParams,
      domain
    );
    dispatch('setListSearchResult', results);
    commit('FETCH_ADDRESSESS_LIST_SUCCESS');
  } catch (error) {
    commit('FETCH_ADDRESSESS_LIST_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }
}
