var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-filters mx-auto w-full bg-white"},[_c('Typography',{attrs:{"variant":"title-medium","color":"highlight-default"}},[_vm._v(" Buscar por ")]),_c('div',{staticClass:"mt-4",attrs:{"id":"filter-mode"}},[_c('ul',[_c('li',{class:[
          'p-1',
          'w-1/3',
          'text-center',
          _vm.filterMode === _vm.FILTER_MODE.RADIUS && 'active',
        ]},[_c('a',{staticClass:"flex flex-col w-full",attrs:{"tabindex":1},on:{"click":function($event){$event.stopPropagation();return _vm.setFilterMode(_vm.FILTER_MODE.RADIUS)}}},[_c('div',{staticClass:"icon-wrapper-mobile"},[(_vm.filterMode === _vm.FILTER_MODE.RADIUS)?_c('img',{staticClass:"svg-icon",attrs:{"src":require("@/assets/images/location.svg")}}):_vm._e(),(_vm.filterMode !== _vm.FILTER_MODE.RADIUS)?_c('img',{staticClass:"svg-icon",attrs:{"src":require("@/assets/images/location-disabled.svg")}}):_vm._e()]),_c('Typography',{attrs:{"variant":"label-small","color":"brand-dark"}},[_vm._v(" Localização "),(_vm.filter && _vm.filter.radius)?_c('span',{staticClass:"mx-2 text-xs"},[_vm._v(" ("+_vm._s(((_vm.filter.radius * 2) / 1000).toFixed(2))+"Km) ")]):_vm._e()])],1)]),_c('li',{class:[
          'p-1',
          'w-1/3',
          'text-center',
          _vm.filterMode === _vm.FILTER_MODE.REGION && 'active',
        ]},[_c('a',{staticClass:"flex flex-col w-full",attrs:{"tabindex":1},on:{"click":function($event){$event.stopPropagation();return _vm.setFilterMode(_vm.FILTER_MODE.REGION)}}},[_c('div',{staticClass:"icon-wrapper-mobile"},[(_vm.filterMode === _vm.FILTER_MODE.REGION)?_c('img',{staticClass:"svg-icon",attrs:{"src":require("@/assets/images/region.svg")}}):_vm._e(),(_vm.filterMode !== _vm.FILTER_MODE.REGION)?_c('img',{staticClass:"svg-icon",attrs:{"src":require("@/assets/images/region-disabled.svg")}}):_vm._e()]),_c('Typography',{attrs:{"variant":"label-small","color":"brand-dark"}},[_vm._v(" Região ")])],1)])])]),_c('div',{staticClass:"mt-4"},[(_vm.filterMode === _vm.FILTER_MODE.RADIUS)?_c('GoogleMapAutocomplete',{attrs:{"tabindex":3},on:{"select":_vm.onAutoCompleteSelection},model:{value:(_vm.gMapAutocomplete),callback:function ($$v) {_vm.gMapAutocomplete=$$v},expression:"gMapAutocomplete"}}):(_vm.filterMode === _vm.FILTER_MODE.REGION)?_c('RegionAutocomplete',{attrs:{"tabindex":3,"current-city":_vm.currentCity},on:{"region":_vm.onRegion},model:{value:(_vm.regionModel),callback:function ($$v) {_vm.regionModel=$$v},expression:"regionModel"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }