<template>
  <div class="avm">
    <div class="flex">
      <AvmSidebar />
      <AvmReport />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AvmSidebar from '@/views/avm/AvmSidebar.vue';
import AvmReport from '@/views/avm/AvmReport.vue';

export default {
  components: {
    AvmSidebar,
    AvmReport,
  },
  data () {
    return {
      gtmEvent: null,
    };
  },
  mounted () {
    this.disableMap();
  },
  methods: {
    ...mapActions({
      disableMap: 'core/disableMap',
    }),
  },
};
</script>

<style scoped lang="scss">
.avm {
  @apply flex w-screen;
  position: absolute;
  height: calc(100vh - 75px);
  top: 75px;
  background-color: $header-bg-color;
  background-size: cover;
}

</style>
