<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import FreeDraw, { ALL, DELETE, NONE } from 'leaflet-freedraw';
import { findRealParent, propsBinder } from 'vue2-leaflet';
import { DomEvent } from 'leaflet';

export default {
  name: 'LFreeDraw',
  data() {
    return {
      ready: false,
      mapObject: null,
    };
  },
  created() {
    this.mapObject = new FreeDraw({
      mode: ALL,
      maximumPolygons: 1,
      mergePolygons: false,
      strokeWidth: 3,
      leaveModeAfterCreate: false,
    });
  },
  mounted() {
    this.mapObject.on('markers', event => {
      if (event.eventType === 'create') {
        this.mapObject.mode(DELETE); // eslint-disable-line no-bitwise
        this.$emit('update:polygons', this.mapObject.all());
      } else if (['remove' || 'clear'].includes(event.eventType)) {
        this.mapObject.mode(ALL);
        this.$emit('update:polygons', null);
      }
    });
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$listeners);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this);
  },
  beforeDestroy() {
    if (this.mapObject) {
      this.mapObject.mode(DELETE);
      this.mapObject.clear.call({ map: this.parentContainer.mapObject });
      this.parentContainer.removeLayer(this);
      this.mapObject = null;
      // this.$emit('update:polygons', null);
    }
  },
};
</script>
