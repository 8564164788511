import { css } from '@/core/theme';

const container = css({
  transform: 'translateY(-100px)',
});

const menu = css({
  backgroundColor: 'rgba(0, 0, 0, 0)',
  maxWidth: '1070px',
  margin: '0 auto',
  display: 'flex',
  borderBottom: '1px solid rgba(225, 232, 252, 0.2)',
  gap: '$xxl',
  padding: '0 $nano',
});

const item = {
  paddingBottom: '$small',
  transform: 'translateY(1px)',
  borderRadius: '2px',
  cursor: 'pointer',
};

const defaultItem = css({
  ...item,
  letterSpacing: '0.4px',

  '& :hover': {
    letterSpacing: '0',
  },
});

const selectedItem = css({
  ...item,
  borderBottom: '4px solid $highlight-light',
});

const content = css({
  maxWidth: '1070px',
  margin: '0 auto',
  marginTop: '$large',
  backgroundColor: '$neutral-white',
  borderRadius: '5px',
  display: 'grid',
  gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
  gap: '$medium',
  padding: '$medium',
});

export const styles = {
  container,
  menu,
  defaultItem,
  selectedItem,
  content,
};
