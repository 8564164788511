var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-result-wrapper relative d-flex flex-col h-full justify-start"},[_c('div',{ref:"scrollElement",staticClass:"bg-white search-result"},[(!_vm.loading)?_c('div',[(_vm.totalItemCount)?_c('SearchSummary',{attrs:{"header-text":"Resumo dos resultados","footer-text":"Filtros utilizados"},scopedSlots:_vm._u([{key:"result",fn:function(){return [_c('SearchSummarySeller',{attrs:{"type":"result","data":_vm.summary}})]},proxy:true},{key:"filter",fn:function(){return [_c('SearchSummarySeller',{attrs:{"type":"filter","data":_vm.unitFilterSummary}})]},proxy:true}],null,false,2361682703)}):_vm._e(),_c('h2',{staticClass:"search-result-title font-extrabold text-2xl pt-4 pb-1 pl-3 text-center",on:{"click":function($event){return _vm.handleEaster({ summaryData: _vm.summaryData, searchResults: _vm.searchResults, filter: _vm.filter }, $event)}}},[_vm._v(" Condomínios ")])],1):_vm._e(),(_vm.noResults)?_c('div',{staticClass:"flex flex-wrap justify-center py-10 px-10"},[_c('p',[_vm._v("A sua busca não retornou nenhum resultado :(")])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-4 px-2"},[_c('SkeletonCard'),_c('SkeletonCard'),_c('SkeletonCard'),_c('SkeletonCard')],1):_c('ul',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(false /*loadMore*/),expression:"false /*loadMore*/"}],staticClass:"search-result-container d-flex flex-wrap justify-around px-3 py-3 mb-8",attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"10"}},_vm._l((_vm.searchResults &&
          _vm.searchResults.sort((first, second) => first.order - second.order)),function(item,index){return _c('PropertyItem',{key:index,class:[
          'max-w-lg',
          !_vm.expandedSidebar ? 'w-1/2' : 'w-1/4',
          _vm.expandedSidebar && index > 3 && 'mt-5',
          !_vm.expandedSidebar && index > 1 && 'mt-5',
        ],attrs:{"disable-flagging":item.addressId === _vm.condoId,"property":item},on:{"click:highlight":_vm.cancelNextItemClick,"click":function($event){return _vm.navigateToProperty(item)}}})}),1)]),_c('GoogleTagManager',{attrs:{"track-event":_vm.gtmEvent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }