import {
  getReport,
  generateReport,
} from './utils';

const initialState = {
  isLoading: false,
  relatorio: null,
};

const getters = {
  isLoading: (state) => state.isLoading,
};

const mutations = {
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading;
  },
  FETCH_ADDRESSESS_LIST_SUCCESS () {},
  SET_REPORT (state, relatorio) {
    state.relatorio = relatorio;
  },
};

const actions = {
  getReport,
  generateReport,
  setReport ({ commit }, report) {
    commit('SET_REPORT', report);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
