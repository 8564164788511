import { css } from '@/core/theme';

const root = css({
  listStyleType: 'none',
  paddingLeft: '0 !important', // Vuetify fix
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$quarck',
});

const item = css({
  display: 'inline-block',

  '&:first-child': {
    marginRight: '$quarck',
  },

  '&:last-child': {
    marginLeft: '$quarck',
  },

  '& > button': {
    color: '$brand-dark',
    minWidth: '30px',
    minHeight: '30px',
    padding: '$nano',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: '$additional-blue-1',
    },

    '&:disabled': {
      filter: 'opacity(0.25)',
    },
  },
});

const number = css({
  
  variants: {

    state: {

      'active': {
        backgroundColor: '$additional-blue-2',
        filter: 'opacity(1) !important',
      },

      'idle': { },
    },
  },
});

export const styles = {
  root,
  item,
  number,
};
