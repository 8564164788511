var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"inputRootRef",class:_setup.styles.root().className},[_c(_setup.TextField,{ref:"inputRef",class:_setup.styles.field({ variant: _vm.variant }).className,attrs:{"id":_vm.id,"read-only":"","label":_vm.label,"error":_vm.error,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"value":_setup.prefixValue},on:{"click":_setup.onFocus}}),(_setup.isDefaultVariant || _setup.isSortVariant)?_c('div',{class:_setup.styles.icon({
      variant: _vm.variant,
      listShowing: _setup.listShowing,
      disabled: _vm.disabled,
    }).className,on:{"click":_setup.onFocus}},[(_setup.isDefaultVariant)?_c(_setup.CaretDownIcon,{attrs:{"data-icon":""}}):(_setup.isSortVariant)?_c(_setup.CaretSortIcon,{attrs:{"data-icon":""}}):_vm._e()],1):_vm._e(),(_setup.listShowing)?_c('div',{class:_setup.styles.list({
      variant: _vm.variant,
      listShowing: _setup.listShowing,
    }).className},[_c('ul',[_vm._l((_vm.items),function(item,itemIndex){return _c('li',{key:`selectFieldIndex${itemIndex}`,class:_setup.styles.item().className,on:{"click":function($event){return _setup.onSelect(_setup.isItemsArray ? item : itemIndex)}}},[(_vm.$slots.item)?_vm._t("item",null,{"item":item}):[_vm._v(" "+_vm._s(item)+" ")]],2)}),(_setup.showNoOptions)?_c('li',{class:_setup.styles.item().className},[_vm._v(" Sem opções disponíveis. ")]):_vm._e()],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }