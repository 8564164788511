<template>
  <Portal>
    <div
      ref="ModalComponentBackdrop"
      :class="styles.backdrop().className"
    >
      <div
        v-if="!transactionsModal"
        ref="ModalComponentModal"
        :class="styles.modal().className"
      >
        <button
          v-if="!transactionsModal"
          :class="styles.close().className"
          @click="onClose"
        >
          <CloseIcon />
        </button>

        <slot />
      </div>
      <div v-else>
        <slot />
      </div>
    </div>
  </Portal>
</template>

<script>

import CloseIcon from '@/components/icons/CloseIcon';

import { Portal, setSelector } from '@linusborg/vue-simple-portal';

import { styles } from './Modal.styles';

export default {

  name: 'Modal',

  components: {
    Portal,
    CloseIcon,
  },

  props: {
    
    closeOnEscape: {
      type: Boolean,
      default: true,
    },

    transactionsModal: {
      type: Boolean,
      default: false,
    },
  },

  created () {
    this.styles = styles;

    setSelector('app-overlay');
  },

  mounted () {
    window.addEventListener('keydown', this.onKeyDown);
  },

  beforeUnmount () {
    window.removeEventListener('keydown', this.onKeyDown);
  },

  methods: {

    onKeyDown (event) {

      if (event.key !== 'Escape') {
        return;
      }

      if (this.closeOnEscape) {
        event.stopPropagation();
        this.onClose();
      }
    },

    onClose () {

      const backdropClosingAnimationClass = this.styles.backdropClosing().className;
      const modalClosingAnimationClass = this.styles.modalClosing().className;

      this.$refs.ModalComponentBackdrop.classList.add(backdropClosingAnimationClass);
      this.$refs.ModalComponentModal.classList.add(modalClosingAnimationClass);

      setTimeout(() => this.$emit('close'), 250);
    },
  },
};

</script>
