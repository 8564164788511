import api from '../../api';
import {
  SET_PROPERTY,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_ERROR,
  GET_OFFER_SUCCESS,
  GET_OFFER_ERROR,
  GET_TYPOLOGY_SUCCESS,
  GET_TYPOLOGY_ERROR,
  GET_OWERS_SUCCESS,
  GET_OWERS_ERROR,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_ERROR,
  SET_ADS,
} from './mutationTypes';

const initialState = {
  property: null,
  ads: null,
  priceHistoryByAddress: null,
  owners: null,
  transactions: null,
  transactionsLoading: false,
  typologies: null,
  offers: null,
  lastOfferTypology: null,
  mobilePropertyLoading: false,
  mobileTypologiesLoading: false,
  mobileOffersLoading: false,
  mobileOwnersLoading: false,
};

const getters = {
  property: state => state.property,
  offers: state => state.offers,
  ads: state => state.ads,
  priceHistoryByAddress: state => state.priceHistoryByAddress,
  owners: state => state.owners,
  transactions: state => state.transactions,
  transactionsLoading: state => state.transactionsLoading,
  typologies: state => state.typologies,
  lastOfferTypology: state => state.lastOfferTypology,
  mobilePropertyLoading: state => state.mobilePropertyLoading,
  mobileTypologiesLoading: state => state.mobileTypologiesLoading,
  mobileOffersLoading: state => state.mobileOffersLoading,
  mobileOwnersLoading: state => state.mobileOwnersLoading,
};

const mutations = {
  [SET_PROPERTY]: (state, property) => {
    state.property = property;
  },
  [SET_ADS]: (state, ads) => {
    state.ads = ads;
  },
  [GET_PROPERTY_SUCCESS]: () => {},
  [GET_PROPERTY_ERROR]: (state, error) => {
    state.error = error;
  },
  [GET_OFFER_SUCCESS]: () => {},
  [GET_OFFER_ERROR]: (state, error) => {
    state.error = error;
  },
  [GET_TYPOLOGY_SUCCESS]: () => {},
  [GET_TYPOLOGY_ERROR]: (state, error) => {
    state.error = error;
  },
  [GET_OWERS_SUCCESS]: () => {},
  [GET_OWERS_ERROR]: (state, error) => {
    state.error = error;
  },
  [GET_OFFERS_SUCCESS]: () => {},
  [GET_OFFERS_ERROR]: (state, error) => {
    state.error = error;
  },
  SET_PRICE_HISTORY_BY_ADDRESS: (state, priceHistoryByAddress) => {
    state.priceHistoryByAddress = priceHistoryByAddress;
  },
  SET_OWNERS (state, owners) {
    state.owners = owners;
  },
  SET_TRANSACTIONS (state, transactions) {
    state.transactions = transactions;
  },
  SET_TRANSACTIONS_LOADING (state, value) {
    state.transactionsLoading = value;
  },
  SET_TYPOLOGIES (state, typologies) {
    state.typologies = typologies;
  },
  SET_OFFERS (state, offers) {
    state.offers = offers;
  },
  SET_LAST_OFFER_TYPOLOGY (state, typology) {
    state.lastOfferTypology = typology;
  },
  SET_MOBILE_PROPERTY_LOADING (state, value) {
    state.mobilePropertyLoading = value;
  },
  SET_MOBILE_TYPOLOGIES_LOADING (state, value) {
    state.mobileTypologiesLoading = value;
  },
  SET_MOBILE_OFFERS_LOADING (state, value) {
    state.mobileOffersLoading = value;
  },
  SET_MOBILE_OWNERS_LOADING (state, value) {
    state.mobileOwnersLoading = value;
  },
};

const actions = {
  clearProperty ({ commit }) {
    commit(SET_PROPERTY, null);
  },
  async fetchProperty ({ commit }, { addressId, domain }) {
    try {
      commit(SET_PROPERTY, { isLoading: true });
      commit('SET_MOBILE_PROPERTY_LOADING', true);

      const {
        response: [property],
      } = await api.address.getAddress(addressId, domain);

      commit(SET_PROPERTY, property);
      commit(GET_PROPERTY_SUCCESS);
    } catch (error) {
      console.error(error);
      commit(GET_PROPERTY_ERROR, error);
    } finally {
      commit('SET_MOBILE_PROPERTY_LOADING', false);
    }
  },
  async fetchOffer ({ commit }, { offerId, adsIds, context }) {
    let offer = null;

    commit('SET_MOBILE_OFFERS_LOADING', true);

    try {
      const { response } = await api.address.getOffer(offerId, adsIds, context);

      offer = response;
      commit(GET_OFFER_SUCCESS);
    } catch (error) {
      console.error(error);
      commit(GET_OFFER_ERROR, error);
    } finally {
      commit('SET_MOBILE_OFFERS_LOADING', false);
    }

    return offer;
  },
  async fetchTypologies ({ commit }, { addressId, domain, attributes }) {
    let typologies = null;

    commit('SET_MOBILE_TYPOLOGIES_LOADING', true);

    try {
      const { result } = await api.address.getTypologies(addressId, domain, attributes);

      typologies = result;
      commit(GET_TYPOLOGY_SUCCESS);
    } catch (error) {
      commit(GET_TYPOLOGY_ERROR, error);
    } finally {
      commit('SET_MOBILE_TYPOLOGIES_LOADING', false);
    }

    commit('SET_TYPOLOGIES', typologies);

    return typologies;
  },
  async fetchOwners ({ commit }, { addressId, domain }) {
    let owners = null;

    commit('SET_MOBILE_OWNERS_LOADING', true);

    try {
      const { response } = await api.address.getOwners(addressId, domain);

      owners = response;
      commit(GET_OWERS_SUCCESS);
    } catch (error) {
      console.error(error);
      commit(GET_OWERS_ERROR, error);
    } finally {
      commit('SET_MOBILE_OWNERS_LOADING', false);
    }

    commit('SET_OWNERS', owners);

    return owners;
  },

  async fetchTransactions ({ commit }, { condominium, domain }) {
    commit('SET_TRANSACTIONS_LOADING', true);

    try {
      const transactions = (await api.address.getTransactions(condominium, domain)).response;

      commit('SET_TRANSACTIONS', transactions);
    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_TRANSACTIONS_LOADING', false);
    }
  },

  async fetchOffers ({ commit }, { addressId, context }) {
    let offers = null;

    try {
      const { response } = await api.address.getOffers(addressId, context);

      offers = response;
      commit('SET_OFFERS', offers);
      commit(GET_OFFERS_SUCCESS);
    } catch (error) {
      console.error(error);
      commit(GET_OFFERS_ERROR, error);
    }

    return offers;
  },
  async fetchPriceHistoryByAddress ({ commit }, data) {
    const priceHistoryByAddress = await api.address.getPriceHistoryByAddress(data);

    commit('SET_PRICE_HISTORY_BY_ADDRESS', priceHistoryByAddress);
  },
  async getAds ({ commit }, { offerId, adsIds, context }) {
    try {
      commit(SET_ADS, { isLoading: true });

      const { response } = await api.address.getAds(offerId, adsIds, context);

      commit(SET_ADS, response);

      return response;
    } catch (error) {
      commit(SET_ADS, null);
    }
  },
  resetAds ({ commit }) {
    commit(SET_ADS, null);
  },
  flagOffer ({ rootState }, data) {
    const {
      search: { lastSearch },
    } = rootState;

    return api.address.flagOffer({
      ...data,
      filter: lastSearch,
    });
  },
  async getRelatedCondos (_, { condoId, domain }) {
    const { response: result } = await api.locations.fetchRelatedCondos(condoId, domain);

    return result;
  },
  setLastOfferTypology ({ commit }, typology) {
    commit('SET_LAST_OFFER_TYPOLOGY', typology);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
