<script setup>

import { styles } from './SmallButton.styles';
import Typography from '@/components/base/Typography';

defineProps({
  icon: {
    type: Object,
    default: () => {},
  },
  label: {
    type: String,
    default: '',
    required: true,
  },
  variant: {
    type: String,
    default: '',
    required: false,
  },
});

const css = selector => styles[selector]().className;

const emit = defineEmits(['small-button-click']);

</script>

<template>
  <div
    :class="css('container')"
    @click="emit('small-button-click')"
  >
    <component :is="icon" />
    <Typography
      :color="variant && variant === 'consulta-dados' ? 'highlight-light' : 'neutral-white'"
      variant="link"
      tag="span"
      class="ml-2 select-none	"
    >
      {{ label }}
    </Typography>
  </div>
</template>
