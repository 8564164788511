<template>
  <div class="tooltip">
    <div class="tooltip__content" ref="customTooltip">
      {{ message }}
      <div class="tooltip__arrow" data-popper-arrow></div>
    </div>
    <span
      ref="customActivator"
      @mouseover="showTooltip()"
      @mouseout="hideTooltip()"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script>

import { createPopper } from '@popperjs/core';

export default {
  name: 'Tooltip',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tooltipInstance: null
    }
  },
  mounted() {
    this.createTooltip()
  },
  methods: {
    createTooltip() {
      const activator = this.$refs.customActivator;
      const tooltipContainer = this.$refs.customTooltip;
      const options = {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
          {
            name: 'offset',
            options: {
              offset: [10, 20],
            },
          },
        ],
      }

      this.tooltipInstance = createPopper(activator, tooltipContainer, options);
    },
    showTooltip() {
      this.$refs.customTooltip.setAttribute('data-show', '');
      this.tooltipInstance.update();
    },
    hideTooltip() {
      this.$refs.customTooltip.removeAttribute('data-show');
    }
  }
}

</script>

<style lang="scss" scoped>

.tooltip {
  &__content {
    @include typography-body-small;
    background-color: $colors-neutral-white;
    color: $colors-brand-dark;
    padding: $spacing-nano;
    box-shadow: $shadow-small;
    display: none;
    max-width: 116px;
    
    &[data-show] {
      display: block;
    }

    &[data-popper-placement^='top'] > .tooltip__arrow {
      bottom: -4px;
    }
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &__arrow {
    visibility: hidden;

    &::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }
}

</style>