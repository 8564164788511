<template>
  <div class="analysis-container">
    <Typography
      variant="body-bold"
      color="highlight-default"
      data-test-id="page-title"
    >
      Renda
    </Typography>
    <div class="analysis-table">
      <div class="income-header">
        <div class="flex items-center justify-between">
          <Typography
            variant="body-bold"
            color="brand-darker"
            class="w-3/12"
          >
            Fonte
          </Typography>
          <div class="my-0 w-2/12 flex justify-end">
            <Typography
              variant="body-bold"
              color="brand-darker"
            >
              Valor
            </Typography>
            </div>
          <div class="w-7/12"></div>
        </div>
      </div>
      <div class="income-body">
        <div class="flex items-center justify-bewteen">
          <Typography
            variant="label-medium"
            color="neutral-grey-5"
            class="my-0 w-3/12"
            data-test-id="personal-title"
          >
            Renda individual
          </Typography>
          <div class="my-0 w-2/12 flex justify-end">
            <Typography
              variant="body-medium"
              color="neutral-grey-5"
              data-test-id="personal-value"
            >
              {{ formatCurrency(detail.Income.Personal) || "Dado indisponível" }}
            </Typography>
          </div>
          <div class="w-7/12">
          </div>
        </div>
        <hr>
        <div class="flex items-center justify-between">
          <Typography
            variant="label-medium"
            color="neutral-grey-5"
            class="my-0 w-3/12"
            data-test-id="partner-title"
          >
            Renda empresarial
          </Typography>
          <div class="my-0 w-2/12 flex justify-end">
            <Typography
              variant="body-medium"
              color="neutral-grey-5"
              data-test-id="partner-value"
            >
              {{ formatCurrency(detail.Income.Partner) || "Dado indisponível" }}
            </Typography>
          </div>
          <div class="w-7/12"></div>
        </div>
        <hr>
        <div class="flex items-center justify-between">
          <Typography
            variant="label-medium"
            color="neutral-grey-5"
            class="my-0 w-3/12"
            data-test-id="presumed-title"
          >
            Renda presumida
          </Typography>
          <div class="my-0 w-2/12 flex justify-end">            
            <Typography
              variant="body-medium"
              color="feedback-success"
              data-test-id="presumed-value"
            >
              {{ formatCurrency(detail.Income.Presumed) || "Dado indisponível" }}
            </Typography>
          </div>
          <div class="w-7/12">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import { formatCurrency } from "@/utils";
import Typography from "../base/Typography";

export default {
    name: "AnalysisIncomeTable",
    data() {
        return {
            formatCurrency,
        };
    },
    computed: {
        ...mapState("person", ["detail"]),
    },
    components: { Typography }
};
</script>

<style lang="scss" scoped>
.analysis-container {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}

.analysis-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.income-body {
  display: flex;
  flex-direction: column;
}

hr {
  height: 0px;
  align-self: stretch;
  stroke-width: 1px;
  stroke: #E8E8E8;
  margin: 16px 0px;
}
</style>
