import { css } from '@/core/theme';

const root = css({

  variants: {

    type: {

      'result': {
        display: 'grid',
        rowGap: '$xs',
        gridTemplateColumns: '1fr 1fr',
      },

      'filter': {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '$xs',
        columnGap: '$xl',
      },
    },
  },

  padding: '$small',

  '& > div[data-column]': {

  },

  '& > div[data-column-double]': {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: '$small',
  },
});

const tooltip = css({
  position: 'relative',
  display: 'inline-block',
  color: '$brand-default',
  borderBottom: '1px dotted $brand-default',
  cursor: 'help',

  '&:hover div[data-tooltip-text]': {
    visibility: 'visible',
  },

  '& > div[data-tooltip-text]': {
    visibility: 'hidden',
    width: '120px',
    backgroundColor: '$neutral-white',
    color: '$brand-dark',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '$nano',
    position: 'absolute',
    zIndex: 1,
    bottom: '192%',
    left: '50%',
    marginLeft: '-60px',
    boxShadow: '$small',

    '&::after': {
      content: '',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: '$neutral-white transparent transparent transparent',
    },
  },
});

export const styles = {
  root,
  tooltip,
};
