var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"content-class":"w-1/3 mx-auto flagging-property-dialog"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"bg-white p-0"},[_c('div',{staticClass:"w-full flex flex-col relative p-10"},[_c('v-btn',{staticClass:"close-btn absolute",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h1',{staticClass:"font-extrabold text-xl my-3 w-full"},[_vm._v(" Denunciar imóvel: "),_c('span',{staticClass:"gray-500"},[_vm._v(_vm._s(_vm.property.shortAddress))])]),(_vm.submitStatus && _vm.submitStatus != 'SUCCESS')?_c('div',[_c('v-alert',{attrs:{"text":"","dismissible":"","type":"error"}},[_vm._v(" Ops! Aconteceu um erro ao tentar enviar. Por favor, tente novamente ")]),_c('br')],1):_vm._e(),_c('div',{staticClass:"w-full flex flex-col my-5"},[_c('label',[_vm._v("Quais os problemas encontrados com este imóvel?")]),_vm._l((_vm.reportReasons),function(attribute){return _c('div',{key:attribute,staticClass:"flex w-full"},[_c('v-checkbox',{attrs:{"value":attribute,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"text-sm w-full"},[_vm._v(_vm._s(attribute))])]},proxy:true}],null,true),model:{value:(_vm.model.reasons),callback:function ($$v) {_vm.$set(_vm.model, "reasons", $$v)},expression:"model.reasons"}})],1)})],2),_c('div',{staticClass:"w-full flex flex-col my-5"},[_c('label',[_vm._v("Observações adicionais")]),_c('v-textarea',{attrs:{"disabled":!!_vm.isSubmitting,"outlined":""},model:{value:(_vm.model.comment),callback:function ($$v) {_vm.$set(_vm.model, "comment", $$v)},expression:"model.comment"}})],1),_c('div',{staticClass:"w-full flex justify-center"},[_c('v-btn',{attrs:{"rounded":"","disabled":!(
              _vm.model &&
              _vm.model.reasons &&
              _vm.model.reasons.length &&
              _vm.model.comment &&
              _vm.model.comment.length > 3
            )},on:{"click":_vm.debouncedSubmit}},[_vm._v(" Enviar ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }