import api from '@/api';

import { FILTER_MODE, MAP_OPTIONS } from '@/common/constants';

export default async function fetchSummary ({ commit, rootState }, filter) {
  commit('SET_LOADING', true);

  let searchSummary;

  try {
    const {
      route: { name: routeName },
      flag: { flagged },
      map: { pins },
      search: { showWithoutOffers, offersToExclude },
    } = rootState;

    const { attributesFilter, params } = filter;
    const { filterMode, filter: sharedFilter } = rootState[routeName];

    const offersExcluded = offersToExclude ? offersToExclude : [];

    const excludePins = !showWithoutOffers
      ? pins.reduce((acc, pin) => {
        if (!pin.offersCount && !flagged.includes(pin.addressId)) {
          acc.push(pin.addressId);
        }

        return acc;
      }, [])
      : [];

    
    const exclude = [
      ...flagged,
      ...excludePins,
      ...offersExcluded
    ];

    let request = null;

    if (filterMode === FILTER_MODE.RADIUS) {
      const { position, radius } = sharedFilter;
      const [
        lat,
        lng,
      ] = position;

      request = [
        {
          filterMode,
          radius,
          coordinates: [
            lat,
            lng,
          ],
          routeName,
          exclude,
        },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.REGION) {
      const { coordinates } = sharedFilter;

      request = [
        { filterMode, coordinates, routeName, exclude },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.AREA) {
      const { coordinates } = sharedFilter;

      request = [
        { filterMode, coordinates, routeName, exclude },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.CONDO) {
      const { address } = filter;

      if (!address) {
        console.log('Address failure on summary');

        return;
      }

      request = [
        {
          filterMode,
          radius: MAP_OPTIONS.DEFAULT_RADIUS,
          coordinates: sharedFilter.geolocation,
          routeName,
          addressId: address.info.addressId,
          exclude,
        },
        attributesFilter,
        params,
      ];
    } else if (filterMode === FILTER_MODE.ADDRESS) {
      const { address } = filter;

      request = [
        {
          filterMode,
          radius: MAP_OPTIONS.DEFAULT_RADIUS,
          coordinates: sharedFilter.geolocation,
          routeName,
          addressId: address.info.addressId,
          exclude,
        },
        attributesFilter,
        params,
      ];
    }

    if (
      filterMode === FILTER_MODE.CONDO
      || filterMode === FILTER_MODE.ADDRESS
    ) {
      throw new Error('DEPRECATION');

    } else {
      const { result = [] } = await api.search.fetchSummary(...request);

      searchSummary = result;
    }

    commit('SET_SEARCH_SUMMARY', searchSummary);
    commit('FETCH_SEARCH_SUMMARY_SUCCESS');
  } catch (error) {
    console.error(error);
    commit('FETCH_SEARCH_SUMMARY_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }

  return searchSummary;
}
