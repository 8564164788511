<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    data-test-id="ads-data-table"
  >
    <template #item.sellerName="{ value }">
      <a
        :href="value.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ value.name }}
      </a>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'AdsDataTable',
  props: ['headers', 'items'],
};
</script>

<style lang="scss" scoped>

:deep(th),
:deep(td) {
  font-family: Lato !important;
}

:deep(th) {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #6879A6 !important;
}

:deep(td) {
  font-size: 14px !important;
  padding: 22px 16px !important;

  > a {
    text-decoration: underline;
  }
}

:deep(tr):hover td {
  background-color: #fff;
}

</style>
