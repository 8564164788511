<template>
  <div class="login-email">
    <div class="input-container-wrapper">
      <div class="input-container">
        <label
          for="email"
          class="label"
          :class="{'label--active': activeInput['email'], 'label--active--error': errorInput['email']}"
        >
          E-mail
        </label>
        <input
          id="email"
          v-model="email"
          class="input-field input-email"
          :class="{'input-field--error': errorInput['email']}"
          type="email"
          placeholder="Digite seu e-mail"
          autocomplete="off"
          @focus="toggleActiveInput('email', true)"
          @blur="toggleActiveInput('email', false)"
        />
      </div>
      <div v-if="errorInput['email']" class="front-error-message">
        Digite um endereço de e-mail válido.
      </div>
    </div>
    <button
      id="reset-password"
      class="btn-social-login btn-social-login__email"
      :disabled="loading"
      @click="resetPassword()"
    >
      Enviar
    </button>
    <router-link to="/login" class="return-link">
      <LeftArrowBlue class="mr-2" />
      <div>
        Voltar à tela de login
      </div>
    </router-link>

  </div>

</template>

<script>

import LeftArrowBlue from '@/components/icons/LeftArrowBlue.vue';
import { VALIDATION } from '@/common/constants';

export default {
  name: 'LoginEmail',
  components: {
    LeftArrowBlue,
  },
  data() {
    return {
      activeInputs: {
        email: false,
        password: false,
      },
      errorInputs: {
        email: false,
        password: false,
      },
      email: '',
      loading: false,
    };
  },
  computed: {
    activeInput() {
      return this.activeInputs;
    },
    errorInput() {
      return this.errorInputs;
    },
  },
  methods: {
    toggleActiveInput(input, state) {
      this.activeInputs[input] = state;
    },
    validateMailInput(input, value) {
      this.formError = false;
      const validate = VALIDATION.email.test(value);
      this.errorInputs[input] = !validate;

      if (!validate) {
        document.getElementById(input).focus();
        return false;
      }

      return true;
    },
    async resetPassword() {
      try {
        if (this.validateMailInput('email', this.email)) {
          const user = await this.$auth.forgotPassword(this.email);
          this.$store.commit('amplify/SET_USER', user);
          this.$store.commit('amplify/SET_EMAIL', this.email);
          await this.$router.push('/login/confirm-new-password');
        }
      } catch (error) {
        this.formError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>

.btn-social-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border-radius: 9999px; // rounded corners

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &__email {
    padding: 20px 86px;
    margin-top: 24px;

    background: #4662B9;
    color: #FFFFFF;
  }

  &__email:hover {
    background: #4662B9;
  }
}

input.input-field {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18.5px 14px;

  background: rgba(126, 146, 206, 0.1);
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  transition: box-shadow .2s ease-out;

  &::placeholder {
    color: #707070;
  }

  &:focus {
    outline: none;
    background: rgba(126, 146, 206, 0.1);
    box-shadow: inset 0 0 0 2px #4663B9;
    box-sizing: border-box;
    transition: box-shadow .2s ease-out;
  }

  &--error {
    box-shadow: inset 0 0 0 2px #B75757;
    transition: box-shadow .2s ease-out;
    background-color: #FFFFFF;

    &:focus {
      box-shadow: inset 0 0 0 2px #B75757;
      transition: box-shadow .2s ease-out;
      background-color: #FFFFFF;
    }
  }
}

.input-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 71px;
  margin-top: 24px;
}
.input-container {
  position: relative;
}

.label {
  position: absolute;
  top: 0px;
  left: 14px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: 0.0015em;

  color: #707070;
  background-color: #FFFFFF;
  border-radius: 3px;
  z-index: 10;
  padding: 0 3px;

  opacity: 0;

  transform: translateY(22px);
  transition: all .2s ease-out;

  &-password {
    transform: translateY(-32px);
  }

  &--active {
    color: #4663B9;
    transform: translateY(-6px);
    transition: all .2s ease-out;
    font-size: 12px;
    line-height: 12px;
    opacity: 1;

    &--error {
      color: #B75757;
    }

    &-password {
      transform: translateY(-62px);
      z-index: 999;
      transition: all .2s ease-out;
    }
  }
}

.input-email {
  position: absolute;
  top: 0;
  left: 0;
}

.front-error-message {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #B75757;
  margin-top: 8px;
  margin-left: 14px;
}

.return-link {
  @apply flex justify-center align-middle;

  margin-top: 24px;
  margin-bottom: 24px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #4663B9;
}

</style>
