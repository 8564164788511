import Vue from 'vue';
import Router from 'vue-router';

import Sidebar from '@/views/Sidebar.vue';
import Main from '@/views/Main';
import Home from '@/views/Home';
import Report from '@/views/report/Report.vue';
import Buyer from '@/views/Buyer.vue';
import Rent from '@/views/Rent.vue';
import Seller from '@/views/seller/Seller.vue';
import Lead from '@/views/Lead.vue';
import OpenSearch from '@/views/OpenSearch';
import PersonData from '@/views/PersonData.vue';
import ConsultaDadosDetail from '@/views/ConsultaDadosDetail.vue';
import Studies from '@/views/Studies.vue';
import Catalog from '@/views/Catalog.vue';
import EconomicIndex from '@/views/EconomicIndex.vue';
import Calculator from '@/views/Calculator.vue';
import Developer from '@/views/Developer.vue'

import AVM from '@/views/avm/Avm.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import LoginForm from '@/views/LoginForm.vue';
import LoginView from '@/views/LoginView.vue';
import LoginFirstAccess from '@/views/LoginFirstAccess.vue';
import LoginResetPassword from '@/views/LoginResetPassword.vue';
import LoginConfirmNewPassword from '@/views/LoginConfirmNewPassword.vue';

import MobileHome from '@/views/mobile/Home.vue';
import MobilePersonData from '@/views/mobile/PersonData.vue';
import MobileCondominium from '@/views/mobile/Condominium/Condominium.vue';
import MobileAds from '@/views/mobile/Ads.vue';

import { isMobile } from '@/utils/user-agent';

import before from '@/router/before.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'root',
      path: '/',
      component: Main,
      children: [
        {
          name: 'home',
          path: '/home',
          component: Home,
          beforeEnter (to, from, next) {
            if (isMobile()) {
              return next('/mobile/home');
            } else {
              return next();
            }
          },
        },
        {
          name: 'consultaDadosDetail',
          path: '/person-data/detail',
          component: ConsultaDadosDetail,
          props: true,
        },
        {
          name: 'personData',
          path: '/person-data/:searchFor?',
          component: PersonData,
          props: true,
        },
        {
          name: 'avm',
          path: '/avm',
          component: AVM,
          props: true,
        },
        {
          name: 'studies',
          path: '/estudos',
          component: Studies,
        },
        {
          name: 'catalog',
          path: '/catalogo',
          component: Catalog,
        },
        {
          name: 'developer',
          path: '/developer',
          component: Developer,
        },
        {
          name: 'economicIndex',
          path: '/indicadores-economicos',
          component: EconomicIndex,
        },
        {
          name: 'calculator',
          path: '/calculadora',
          component: Calculator,
        },
        {
          path: '/report/:hash',
          name: 'report',
          component: Report,
          props: true,
        },
        {
          path: '/search',
          name: 'search',
          component: Main,
          redirect: to => {
            if (to && (to.path === '/search' || to.path === '/search/')) return '/search/buyer';

            return to.path;
          },
          components: {
            sidebar: Sidebar,
          },
          children: [
            {
              name: 'buyer',
              path: 'buyer',
              component: Buyer,
            },
            {
              name: 'seller',
              path: 'seller',
              component: Seller,
            },
            {
              name: 'rent',
              path: 'rent',
              component: Rent,
            },
            {
              name: 'lead',
              path: 'lead',
              component: Lead,
            },
          ],
        },
        {
          name: 'openSearch',
          path: '/search/open',
          component: OpenSearch,
        },
        {
          path: '/politica-de-privacidade',
          name: 'privacy',
          component: PrivacyPolicy,
          props: true,
        },
        {
          path: '/login',
          component: LoginView,
          children: [
            {
              path: '/',
              name: 'login',
              component: LoginForm,
            },
            {
              path: 'first-access',
              name: 'firstAccess',
              component: LoginFirstAccess,
            },
            {
              path: 'reset-password',
              name: 'resetPassword',
              component: LoginResetPassword,
            },
            {
              path: 'confirm-new-password',
              name: 'confirmNewPasswod',
              component: LoginConfirmNewPassword,
            },
          ],
        },
        {
          path: '/mobile',
          component: LoginView,
          children: [
            {
              path: 'home',
              name: 'MobileHome',
              component: MobileHome,
            },
            {
              path: 'person-data/:step',
              name: 'MobilePersonData',
              component: MobilePersonData,
              props: true,
            },
            {
              path: 'condominium/:step',
              name: 'MobileCondominium',
              component: MobileCondominium,
              props: true,
            },
            {
              path: 'ads',
              name: 'MobileAds',
              component: MobileAds,
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const nextRoutePath = await before(to, from);
  const pathContent = nextRoutePath.length && nextRoutePath !== to.fullPath;

  pathContent ? next(nextRoutePath) : next();
});

export default router;
