import { render, staticRenderFns } from "./LoginGoogle.vue?vue&type=template&id=39c865a2&scoped=true"
import script from "./LoginGoogle.vue?vue&type=script&lang=js"
export * from "./LoginGoogle.vue?vue&type=script&lang=js"
import style0 from "./LoginGoogle.vue?vue&type=style&index=0&id=39c865a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c865a2",
  null
  
)

export default component.exports