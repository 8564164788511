<template>
  <div
    v-if="condominium"
    class="condominium"
  >
    <img
      class="condominium__image"
      :src="condominiumImage"
    >

    <div class="condominium__info">
      <div class="address-block">
        <Typography
          v-if="condominiumName"
          data-title
          tag="div"
          variant="body-table"
          color="brand-dark"
        >
          {{ condominiumName }}
        </Typography>

        <Typography
          v-if="address"
          data-address
          tag="div"
          variant="title-small-secondary"
          color="highlight-light"
        >
          <CondominiumCardPinIcon /> {{ address }}
        </Typography>

        <Typography
          v-if="district"
          data-district
          tag="div"
          variant="body-small"
          color="brand-dark"
        >
          {{ district }}
        </Typography>

        <Typography
          v-if="age"
          data-age
          tag="div"
          variant="label-xs"
          color="neutral-grey-4"
        >
          {{ age }} ano{{ ageGreaterThanOne }}
        </Typography>
      </div>

      <div
        v-if="showHR"
        class="horizontal-row"
      />

      <div class="offer-block">
        <div>
          <Typography
            tag="span"
            variant="body-table"
            color="brand-dark"
          >
            {{ units }}
          </Typography>

          <Typography
            tag="span"
            variant="title-small-secondary"
            color="brand-dark"
          >
            Domicílio{{ unitsGreaterThanOne }}
          </Typography>
        </div>

        <div>
          <Typography
            tag="span"
            variant="body-table"
            color="brand-dark"
            class="offer-block__emphasis"
          >
            {{ offers }}
          </Typography>

          <Typography
            tag="span"
            variant="title-small-secondary"
            color="brand-dark"
            class="offer-block__emphasis"
          >
            Oferta{{ offersGreaterThanOne }}
          </Typography>

          <span data-separator>|</span>

          <Typography
            tag="span"
            variant="body-table"
            color="brand-dark"
          >
            {{ sellers }}
          </Typography>

          <Typography
            tag="span"
            variant="title-small-secondary"
            color="brand-dark"
          >
            Imobiliária{{ sellersGreaterThanOne }}
          </Typography>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Typography from '@/components/base/Typography';

import CondominiumCardPinIcon from '@/components/icons/CondominiumCardPinIcon';

import default404 from '@/assets/images/404.jpeg';

export default {

  name: 'CondominiumCard',

  components: {
    Typography,
    CondominiumCardPinIcon,
  },

  props: {

    condominium: {
      type: Object,
      default: null,
    },
  },

  computed: {

    condominiumImage () {
      const containsAllValues = this.condominium && this.condominium.imageDefault;
      const image404 = default404;

      return `${containsAllValues ? this.condominium.imageDefault : image404}`;
    },

    condominiumName () {
      return this.condominium.condoName || false;
    },

    address () {
      return this.condominium.addressLine1 || false;
    },

    district () {
      return this.condominium.addressLine2 || false;
    },

    age () {
      const containsAllValues = this.condominium.yearOfConstruction && this.condominium.age;
      const { age } = this.condominium;

      return containsAllValues ? `${age}` : '';
    },

    ageGreaterThanOne () {
      const { age } = this.condominium;

      return this.greaterThanOne(age);
    },

    units () {
      const expectedPhase = this.condominium && this.condominium.phaseId === 1 && this.condominium.iptuDescription;
      const containsAllValues = this.condominium && this.condominium.iptuDescription && expectedPhase;
      
      return containsAllValues ? `${this.condominium.iptuDescription.unidades}` : 0;
    },

    unitsGreaterThanOne () {
      return this.greaterThanOne(this.condominium.iptuDescription.unidades);
    },

    sellers () {
      const expectedPhase = this.condominium && this.condominium.phaseId === 1;
      const containsAllValues = this.condominium && this.condominium.qtdeSellers && expectedPhase;
      const { qtdeSellers: sellers } = this.condominium;

      return containsAllValues ? `${sellers}` : 0;
    },

    sellersGreaterThanOne () {
      const { qtdeSellers: sellers } = this.condominium;

      return this.greaterThanOne(sellers);
    },

    offers () {
      const expectedPhase = this.condominium && this.condominium.phaseId === 1;
      const containsAllValues = this.condominium && this.condominium.qtdeOffers && expectedPhase;
      const { qtdeOffers: offers } = this.condominium;

      return containsAllValues ? `${offers}` : 0;
    },

    offersGreaterThanOne () {
      const { qtdeOffers: offers } = this.condominium;

      return this.greaterThanOne(offers);
    },

    showHR () {
      const expectedPhase = this.condominium && this.condominium.phaseId === 1;
      const sellers = this.condominium && this.condominium.qtdeSellers && expectedPhase;
      const offers = this.condominium && this.condominium.qtdeOffers && expectedPhase;

      return sellers || offers;
    },
  },

  methods: {

    greaterThanOne (value) {
      return value > 1 ? 's' : '';
    },
  },
};

</script>

<style lang="scss" scoped>

.condominium {
  width: 100%;
  border: 1px solid #E3E8F5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  &__image {
    display: flex;
    max-width: 92px;
    width: 100%;
    height: 155px;
    object-fit: cover;
  }

  &__info {
    width: 100%;
    height: 155px;
    font-size: 12px;
    font-weight: 600;
    position: relative;

    div[data-title] {
      margin-bottom: 12px;
    }

    div[data-address],
    div[data-district] {
      margin-bottom: 8px;

      > svg {
        display: inline-block;
      }
    }

    .address-block {
      padding: 8px;
    }

    .offer-block {
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: #F3F5FB;
      padding: 8px;

      span[data-separator] {
        color: #A5B2D9;
      }
    }

    .horizontal-row {
      border-top: 1px solid #DEDFDF;
      max-height: 1px;
    }
  }
}

</style>