import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import { provide } from 'vue';

import pinia from '@/plugins/vendors/Pinia';
import vuetify from '@/plugins/vendors/Vuetify';

import './plugins/vendors/GoogleMaps';
import './plugins/vendors/HotJar';
import './plugins/vendors/MaterialDesignIcons';
import './plugins/vendors/Leaflet';
import './plugins/vendors/VueSlider';
import './plugins/vendors/BrowserDetection';
import './plugins/vendors/VueNotification';
import './plugins/vendors/VueGTM';
import './plugins/vendors/AWSAmplify';

import './plugins/common/Activity';

import axios from 'axios';

import './filters/formatters';

import './styles/base.scss';

import router from './router';
import store from './store';
import App from './layouts/App.vue';
import axiosInstance from '@/api/base';
import api from './api';

if (process.env.NODE_ENV === 'development' && localStorage.mockServices === 'true') {
  const { worker } = require('./mocks/browser');

  worker.start();
}

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue();

Vue.prototype.$http = axios;

Vue.prototype.handleEaster = function (obj, event) {
  if (event.altKey && event.metaKey) {
    this.$eventBus.$emit('window:debug', obj);
  }
};

axiosInstance.interceptors.request.use(config => {
  if (window.location.pathname.split('/').includes('mobile')) {
    config['params'] = {
      ...config['params'],
      origin: 'mobile',
    };
  }

  return config;
}, error => {
  console.error('[Interceptor Error]', error);
});

axiosInstance.interceptors.response.use(res => res, err => {
  switch (err.response.status) {
  case 401:
    store.dispatch('user/logout', { router });
    break;
  case 402:
    Vue.prototype.$eventBus.$emit('error:paymentRequired');
    break;
  case 403:
    Vue.prototype.$eventBus.$emit('error:forbidden');
    break;
  default:
    break;
  }

  return Promise.reject(err);
});

// Add a response interceptor
api.base.interceptors.response.use(
  response => response,
  error => {
    Vue.prototype.$eventBus.$emit(
      'alert:show',
      (error && error.response && error.response.data && error.response.data.message)
          || 'Ocorreu um erro ao processar sua solicitação',
    );
  },
);

store.$eventBus = Vue.prototype.$eventBus;

sync(store, router);

new Vue({
  setup () {
    provide('vuex-store', store);
  },
  router,
  store,
  pinia,
  vuetify,
  render: h => h(App),
}).$mount('#app');
