<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5404 14.7508L11.9063 10.176C12.9261 8.93262 13.4236 7.35029 13.2964 5.75536C13.1691 4.16044 12.4267 2.67453 11.2223 1.60408C10.0179 0.533617 8.44328 -0.0397736 6.82322 0.00214668C5.20316 0.044067 3.66116 0.698102 2.51521 1.82938C1.36927 2.96065 0.706748 4.48291 0.664284 6.08222C0.62182 7.68154 1.20265 9.23597 2.28699 10.425C3.37133 11.6139 4.87651 12.3468 6.49212 12.4725C8.10773 12.5981 9.71058 12.1069 10.9701 11.1002L15.6042 15.675C15.6666 15.7364 15.7511 15.7708 15.8391 15.7708C15.9272 15.7708 16.0117 15.7364 16.074 15.675L16.5438 15.2112C16.6045 15.1494 16.6381 15.0664 16.6375 14.9803C16.6369 14.8942 16.602 14.8118 16.5404 14.7508ZM6.99988 11.1817C6.01375 11.1819 5.04972 10.8934 4.22971 10.3526C3.4097 9.81188 2.77055 9.04321 2.3931 8.14384C2.01565 7.24448 1.91684 6.2548 2.10919 5.3C2.30153 4.34519 2.77638 3.46814 3.47368 2.77977C4.17098 2.0914 5.0594 1.62263 6.02659 1.43275C6.99378 1.24287 7.99629 1.34041 8.90733 1.71303C9.81836 2.08565 10.597 2.71661 11.1448 3.52612C11.6925 4.33563 11.9848 5.28732 11.9846 6.26082C11.983 7.56545 11.4574 8.8162 10.5229 9.73872C9.58841 10.6612 8.32143 11.1802 6.99988 11.1817Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
};
</script>
