const formatStringToThousand = value => {
  let str = value.toString();

  let charCount = str.length;
  let dotIndex = Math.floor((charCount - 1) / 3) * 3;

  while (dotIndex > 0) {
    str = `${str.slice(0, charCount - dotIndex)}.${str.slice(charCount - dotIndex)}`;
    charCount++;
    dotIndex -= 3;
  }

  return str;
};

export default formatStringToThousand;
