<script setup>

import { computed } from 'vue';

const props = defineProps({
  hovered: {
    type: Boolean,
    default: false,
  },
});

const colors = {
  default: '#303A56',
  hovered: '#6B8CEF',
};

const color = computed(() => props.hovered ? colors.hovered : colors.default);

</script>

<template>
  <svg
    width="65"
    height="53"
    viewBox="0 0 65 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94337 47.7474H5.92264C5.73399 47.7474 5.58105 47.9003 5.58105 48.0889V51.176C5.58105 51.3646 5.73399 51.5176 5.92264 51.5176H63.103C63.2917 51.5176 63.4446 51.3646 63.4446 51.176V22.0683V7.79796V3.42874C63.4446 3.24009 63.2917 3.08715 63.103 3.08715H55.0496C54.8609 3.08715 54.708 3.24009 54.708 3.42874V6.11043C54.708 6.29908 54.5551 6.45201 54.3664 6.45201H48.6512H41.5901C41.4015 6.45201 41.2486 6.60495 41.2486 6.7936V20.2428C41.2486 20.4315 41.0956 20.5844 40.907 20.5844H36.2064C36.0177 20.5844 35.8648 20.4315 35.8648 20.2428V2.0828C35.8648 1.89414 35.7119 1.74121 35.5232 1.74121H21.401C21.2123 1.74121 21.0594 1.89414 21.0594 2.0828V19.9115V24.6223V29.3331V33.7015C21.0594 33.8901 20.9065 34.043 20.7178 34.043H8.62655C8.43789 34.043 8.28496 34.196 8.28496 34.3846V47.4058C8.28496 47.5944 8.13203 47.7474 7.94337 47.7474Z"
      fill="#E3E8F5"
    />
    <path
      d="M19.3826 28.3184V32.6868C19.3826 32.8755 19.2297 33.0284 19.0411 33.0284H6.94979C6.76113 33.0284 6.6082 33.1813 6.6082 33.37V46.3911C6.6082 46.5798 6.45527 46.7327 6.26661 46.7327H4.24588C4.05723 46.7327 3.9043 46.8856 3.9043 47.0743V50.857C3.9043 51.0457 4.05723 51.1986 4.24588 51.1986H62.3032H62.1094C61.9208 51.1986 61.7678 51.0457 61.7678 50.857V21.0537V6.78331V2.41409C61.7678 2.22544 61.6149 2.07251 61.4263 2.07251H53.3728C53.1842 2.07251 53.0312 2.22544 53.0312 2.41409V5.09578C53.0312 5.28443 52.8783 5.43737 52.6896 5.43737H46.9745H39.9134C39.7247 5.43737 39.5718 5.5903 39.5718 5.77895V19.2282C39.5718 19.4168 39.4189 19.5698 39.2302 19.5698H34.5296C34.341 19.5698 34.188 19.4168 34.188 19.2282V1.06815C34.188 0.879496 34.0351 0.726562 33.8464 0.726562H19.7242C19.5356 0.726562 19.3826 0.879496 19.3826 1.06815V18.8968V23.6076V28.3184Z"
      stroke="#A5B2D9"
      stroke-width="0.850023"
    />
    <path
      d="M63.7111 51.4111L0.863281 51.4111"
      stroke="#A5B2D9"
      stroke-width="1.27503"
      stroke-linecap="round"
    />
    <path
      d="M11.3486 37.3793V50.6304C11.3486 51.0616 11.6981 51.4111 12.1293 51.4111H38.0065C38.4376 51.4111 38.7871 51.0616 38.7871 50.6304V37.3679C38.7871 37.1201 38.6694 36.887 38.47 36.7398L25.8549 27.4296C25.5847 27.2302 25.2172 27.2259 24.9424 27.419L11.6804 36.7407C11.4724 36.8868 11.3486 37.1251 11.3486 37.3793Z"
      :fill="color"
    />
    <rect
      x="28.533"
      y="13.6639"
      width="28.1952"
      height="37.3929"
      rx="0.815209"
      fill="white"
      :stroke="color"
      stroke-width="0.702766"
    />
    <rect
      x="33.8359"
      y="24.8096"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.8359 24.8096)"
      :fill="color"
    />
    <path
      d="M43.3234 19.8027H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.4858 21.9111H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M44.9046 24.0195H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <rect
      x="33.8359"
      y="35.3926"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.8359 35.3926)"
      :fill="color"
    />
    <path
      d="M43.3234 30.3857H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.4858 32.4941H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M44.9046 34.6025H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <rect
      x="33.8359"
      y="45.9736"
      width="5.79782"
      height="5.79782"
      rx="0.234375"
      transform="rotate(-90 33.8359 45.9736)"
      :fill="color"
    />
    <path
      d="M43.3234 40.9668H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M46.4858 43.0752H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
    <path
      d="M44.9046 45.1836H40.688"
      :stroke="color"
      stroke-width="0.527075"
      stroke-linecap="round"
    />
  </svg>
</template>