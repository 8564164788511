import { css } from '@/core/theme';

const root = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: '16px',

  '& > h1': {
    marginBottom: '$small',
    textAlign: 'center',
  },

  '& > h2': {
    textAlign: 'center',
  },

  '& video': {
    border: '1px solid $neutral-white',
    marginBottom: '16px',
  },
});

const content = css({
  marginTop: '$medium',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: 'calc($large + $medium)',

  '@mobile': {
    gridTemplateColumns: '1fr',
    rowGap: '$large',
  },
});

const footer = css({
  marginTop: '$nano',
});

const list = css({
  listStyleType: 'disc',
  padding: '0 $nano',
  marginTop: '$xs',

  '& > li': {
    margin: '2px 0 2px 12px',
    paddingLeft: '4px',
  },
});

export const styles = {
  root,
  content,
  footer,
  list,
};
