<template>
  <div id="dialog-wrapper">
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      persistent
      width="300"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-2">
          <p class="text-center">
            Obtendo dados do imóvel...
          </p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="visible"
        content-class="w-5/6 mx-auto highlighted-property-dialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
    >
      <div class="bg-white p-0 h-full property-modal-content">
        <property-modal-table
          v-if="adsVisible"
          :property="property"
          @close-evt="closeAds"
        />
        <property-modal-content
          v-else
          :property="property"
          :graph-data="graphData"
          @close-evt="closeDialog"
          @show-table="showTable"
        />
      </div>
    </v-dialog>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import PropertyModalContent from './PropertyModalContent.vue';
import PropertyModalTable from './PropertyModalTable.vue';
import GoogleTagManager from '../../components/google/GoogleTagManager';

export default {
  name: 'PropertyModal',
  components: {
    GoogleTagManager,
    PropertyModalContent,
    PropertyModalTable,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    adsIds: {
      type: Array,
      default: null,
    },
    graphData: {
      type: Array,
      default: null,
    },
    dialogLoadingStatus: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      gtmEvent: null,
      visible: false,
      dialogLoading: false,
      adsVisible: false,
    };
  },
  computed: {
    ...mapState('route', ['path']),
    ...mapGetters('property', ['ads']),
    isBuyer() {
      return this.path.includes('buyer');
    },
    isSeller() {
      return this.path.includes('seller');
    },
    isLead() {
      return this.path.includes('lead');
    },
  },
  created() {
    this.visible = !!this.property;
    this.dialogLoading = true;
    this.$watch('property', value => {
      this.visible = !!value;
    });
    this.$watch('dialogLoadingStatus', value => {
      this.dialogLoading = value;
    });
  },
  methods: {
    ...mapActions('property', ['getAds', 'resetAds']),
    getFormattedDate(value) {
      const date = value.split('-').reverse();
      if (date.length === 2) date.unshift('01');
      return date.join('/');
    },
    closeDialog() {
      this.visible = false;
      this.$emit('update:visibility', this.visible);
      this.resetAds();
    },
    closeAds() {
      this.adsVisible = false;
    },
    async showTable() {
      try {
        await this.getAds({
          offerId: this.property.offerId,
          context: this.$route.name,
          adsIds: this.adsIds,
        });
        this.adsVisible = !!(this.ads && !this.ads.isLoading);
        this.gtmEvent = {
          event: 'view_ads',
          ads: this.ads.map(ad => ad.id),
        };
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
