<template>
  <base-card data-test-id="component">
    <div class="flex flex-column gap-1">
      <Typography
        variant="header-large"
        color="neutral-grey-6"
      >
        Análise do Cliente
      </Typography>
      <Typography
        variant="body-xs"
        color="neutral-grey-5"
      >
        Dados do cliente
      </Typography>
    </div>
    <SkeletonClientAnalysis v-if="!requestReady" data-test-id="skeleton-loader" />
    <div v-else-if="requestError" data-test-id="fetch-error-message">
      Tivemos um problema de conexão. Tente novamente mais tarde.
    </div>
    <div v-else-if="score" class="flex flex-col gap-4">
      <!-- <AnalysisProperties /> -->
      <AnalysisIncomeTable />
      <AnalysisCompanies />
    </div>
  </base-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseCard from '@/components/cards/base-card.vue';
import SkeletonClientAnalysis from '../skeleton/SkeletonClientAnalysis.vue';
import AnalysisIncomeTable from './AnalysisIncomeTable.vue';
import AnalysisCompanies from './AnalysisCompanies.vue';
import AnalysisProperties from './AnalysisProperties.vue';
import Typography from '../base/Typography';

export default {
  name: 'ClientAnalysis',
  components: {
    AnalysisCompanies,
    AnalysisIncomeTable,
    AnalysisProperties,
    BaseCard,
    SkeletonClientAnalysis,
    Typography
},
  data: () => ({
    requestReady: false,
    requestError: false,
  }),
  computed: {
    ...mapState('person', ['detail', 'score']),
  },
  async mounted() {
    if (!this.score) {
      await this.fetchScore({
        document: this.detail.Document,
        sequentialId: this.detail.SequencialId,
      });
    }
    if (this.score) {
      this.requestReady = true;
    }

    this.requestReady = true;
    if (!this.score) {
      this.requestError = true;
    }
  },
  methods: {
    ...mapActions('person', ['fetchScore', 'setActiveTab']),
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}
</style>
