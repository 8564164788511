import base from './base';

const updated = async () => {
  const response = await base.get('/offers/updated');

  return response && response.data;
};

export default {
  updated,
};
