<script setup>

import {
  ref,
  watch,
} from 'vue';

/**
 * Constants
 */
import { OPENSEARCH_SORTINGS } from '@/common/constants';

/**
 * Components
 */
import Select from '@/components/base/Select';

/**
 * Styles
 */
import { styles } from './OpenSearchSorter.styles';

/**
 * Props
 */
const props = defineProps({

  loading: {
    type: Boolean,
    default: false,
  },

  data: {
    type: Object,
    default: () => ({ }),
  },
});

/**
 * Emits
 */
const emit = defineEmits(['change']);

/**
 * Data
 */
const sorter = ref({ ...props.data });

/**
 * Watch
 */
watch(() => props.data, data => sorter.value = { ...data });

/**
 * Methods
 */
const change = () => emit('change', sorter.value);

</script>

<template>
  <div :class="styles.root({ state: loading ? 'loading' : 'idle' }).className">
    <Select
      v-model="sorter.orderBy"
      variant="sort"
      name="sortBy"
      prefix="Ordenar por: "
      :disabled="loading"
      :class="styles.by().className"
      :items="OPENSEARCH_SORTINGS"
      @input="change"
    />
  </div>
</template>
