<script setup>

import { computed } from 'vue';

/**
 * Styles
 */
import { styles } from './Skeleton.styles';

/**
 * Props
 */
const props = defineProps({

  maxWidth: {
    type: Number,
    default: 100,
  },

  minWidth: {
    type: Number,
    default: 80,
  },

  height: {
    type: Number,
    default: 10,
  },

  width: {
    type: String,
    default: null,
  },

  radius: {
    type: Number,
    default: 0,
  },
});

/**
 * Computed
 */
const computedWidth = computed(() => props.width || `${Math.floor((Math.random() * (props.maxWidth - props.minWidth)) + props.minWidth)}%`);

</script>

<template>
  <span
    data-skeleton
    :class="styles.root({
      css: {
        height: `${height}px`,
        width: computedWidth,
        borderRadius: `${radius}px`,
      },
    }).className"
  />
</template>