<script setup>

import { computed } from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon';

/**
 * Styles
 */
import { styles } from './Pagination.styles';

/**
 * Props
 */
const props = defineProps({

  maxVisibleButtons: {
    type: Number,
    default: 4,
    required: false,
  },

  total: {
    type: Number,
    required: true,
  },

  perPage: {
    type: Number,
    required: true,
  },

  currentPage: {
    type: Number,
    required: true,
  },
});

/**
 * Emits
 */
const emit = defineEmits(['change']);

/**
 * Computed
 */
const totalPages = computed(() => Math.floor((props.total - 1) / props.perPage + 1));

const startPage = computed(() => {

  if (props.currentPage === 1) {
    return 1;
  }

  if (props.currentPage === totalPages.value) { 
    return totalPages.value - props.maxVisibleButtons + 1;
  }

  return props.currentPage - 1;
});

const endPage = computed(() => Math.min(startPage.value + props.maxVisibleButtons - 1, totalPages.value));

const pages = computed(() => {

  const range = [];

  for (let i = startPage.value; i <= endPage.value; i += 1) {
  
    range.push({
      name: i,
      isDisabled: i === props.currentPage,
    });
  }

  return range;
});

const isInFirstPage = computed(() => props.currentPage === 1);

const isInLastPage = computed(() => props.currentPage === totalPages.value);

/**
 * Methods
 */
const onClickFirstPage = () => emit('change', 1);

const onClickPreviousPage = () => emit('change', props.currentPage - 1);

const onClickPage = page => emit('change', page);

const onClickNextPage = () => emit('change', props.currentPage + 1);

const onClickLastPage = () => emit('change', totalPages.value);

const isPageActive = page => props.currentPage === page;

/**
 * Misc
 */
const formatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 0,
});

</script>

<template>
  <ul :class="styles.root().className">
    <li :class="styles.item().className">
      <Typography
        tag="button"
        variant="label-medium"
        color="brand-dark"
        :disabled="isInFirstPage"
        type="button"
        @click="onClickPreviousPage"
      >
        <ChevronLeftIcon />
      </Typography>
    </li>

    <template v-if="(currentPage > (maxVisibleButtons / 2))">
      <li
        :class="styles.item().className"
      >
        <Typography
          tag="button"
          variant="label-medium"
          color="brand-dark"
          :disabled="isInFirstPage"
          type="button"
          @click="onClickFirstPage"
        >
          1
        </Typography>
      </li>

      <li :class="styles.item().className">
        <Typography
          tag="button"
          variant="label-medium"
          color="brand-dark"
          type="button"
        >
          ...
        </Typography>
      </li>
    </template>

    <template v-for="(page, pageIndex) in pages">
      <li
        v-if="page.name > 0"
        :key="`paginationPageIndex${pageIndex}`"
        :class="styles.item().className"
      >
        <Typography
          tag="button"
          variant="label-medium"
          color="brand-dark"
          :disabled="page.isDisabled"
          :class="styles.number({ state: isPageActive(page.name) ? 'active' : 'idle' }).className"
          type="button"
          @click="onClickPage(page.name)"
        >
          {{ formatter.format(page.name) }}
        </Typography>
      </li>
    </template>

    <template v-if="currentPage < (totalPages - maxVisibleButtons)">
      <li :class="styles.item().className">
        <Typography
          tag="button"
          variant="label-medium"
          color="brand-dark"
          type="button"
        >
          ...
        </Typography>
      </li>

      <li :class="styles.item().className">
        <Typography
          tag="button"
          variant="label-medium"
          color="brand-dark"
          :disabled="isInLastPage"
          type="button"
          @click="onClickLastPage"
        >
          {{ formatter.format(Math.ceil(totalPages)) }}
        </Typography>
      </li>
    </template>

    <li :class="styles.item().className">
      <Typography
        tag="button"
        variant="label-medium"
        color="brand-dark"
        :disabled="isInLastPage"
        type="button"
        @click="onClickNextPage"
      >
        <ChevronRightIcon />
      </Typography>
    </li>
  </ul>
</template>
