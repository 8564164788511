<script setup>

import { styles } from './HomeCardInfo.styles';
import ProgressBar from '@/components/base/ProgressBar';
import Typography from '@/components/base/Typography';

const props = defineProps({
  quotas: {
    type: Object,
    default: () => {},
  },
});

const css = selector => styles[selector]().className;

</script>

<template>
  <div :class="css('container')">
    <div :class="css('title')">
      <Typography
        tag="h2"
        variant="header-xl-secondary"
        color="brand-dark"
      >
        Seus créditos
      </Typography>
    </div>
    <ProgressBar
      :disabled="quotas.personData.disabled"
      :title="quotas.personData.title"
      :available="quotas.personData.available"
      :total="quotas.personData.total"
      :current="quotas.personData.current"
      :used="quotas.personData.used"
    />
    <ProgressBar
      :disabled="quotas.avm.disabled"
      :title="quotas.avm.title"
      :available="quotas.avm.available"
      :total="quotas.avm.total"
      :current="quotas.avm.current"
      :used="quotas.avm.used"
    />
    <Typography
      tag="span"
      variant="body-medium"
      color="brand-dark"
    >
      Os créditos são disponibilizados no início de cada mês.
    </Typography>
  </div>
</template>

<style lang="scss" scoped>
  // ...
</style>