<template>
  <v-dialog
    v-model="visible"
    content-class="w-1/3 mx-auto flagging-property-dialog"
    @click:outside="closeDialog"
  >
    <div class="bg-white p-0">
      <div class="w-full flex flex-col relative p-10">
        <v-btn class="close-btn absolute" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h1 class="font-extrabold text-xl my-3 w-full">
          Denunciar imóvel:
          <span class="gray-500">{{ property.shortAddress }}</span>
        </h1>
        <div v-if="submitStatus && submitStatus != 'SUCCESS'">
          <v-alert text dismissible type="error">
            Ops! Aconteceu um erro ao tentar enviar. Por favor, tente novamente
          </v-alert>
          <br />
        </div>
        <div class="w-full flex flex-col my-5">
          <label>Quais os problemas encontrados com este imóvel?</label>
          <div v-for="attribute in reportReasons" :key="attribute" class="flex w-full">
            <v-checkbox v-model="model.reasons" :value="attribute" hide-details>
              <template v-slot:label>
                <label class="text-sm w-full">{{ attribute }}</label>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="w-full flex flex-col my-5">
          <label>Observações adicionais</label>
          <v-textarea v-model="model.comment" :disabled="!!isSubmitting" outlined />
        </div>
        <div class="w-full flex justify-center">
          <v-btn
            rounded
            :disabled="
              !(
                model &&
                model.reasons &&
                model.reasons.length &&
                model.comment &&
                model.comment.length > 3
              )
            "
            @click="debouncedSubmit"
          >
            Enviar
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';
export default {
  name: 'PropertyFlaggingDialog',
  props: {
    isOffer: {
      type: Boolean,
      default: false,
    },
    property: {
      type: Object,
      default: null,
    },
    reason: {
      // 1 = property, 2 = typologies, 3 = offers
      type: Number,
      default: 1,
    },
    reportReasons: {
      type: Array,
      default: () => [
        'Valor AVM',
        'Valor Transação',
        'Valor Ofertas',
        'Características do condomínio',
        'Fotos',
        'Endereço',
      ],
    },
  },
  data() {
    return {
      reasonsDefaults: {
        // property
        1: [
          'Características do condomínio',
          'Fotos',
          'Endereço',
        ],
        // typologies
        2: [
          'Valor AVM',
          'Valor Transação',
        ],
        // offer
        3: [],
      },
      submitStatus: null,
      isSubmitting: false,
      visible: false,
      model: {
        reasons: [],
        comment: null,
      },
    };
  },
  created() {
    // console.log('crated');
    this.debouncedSubmit = debounce(this.submit.bind(this));
    this.model.reasons = this.reasonsDefaults[this.reason];
  },
  mounted() {
    this.visible = !!this.property;
    this.$watch('property', value => {
      this.visible = !!value;
    });
  },
  methods: {
    ...mapActions({
      flagOffer: 'property/flagOffer',
    }),
    closeDialog() {
      this.visible = false;
      this.$emit('update:visibility', this.visible);
    },
    async submit() {
      this.isSubmitting = true;
      await this.flagOffer({
        offerId: this.isOffer ? this.property.offerId : null,
        addressId: this.property.addressId,
        comment: this.model.comment,
        reasons: this.model.reasons,
        domain: this.$route.name,
      })
        .then(({ status }) => {
          if (status !== 200) throw Error('failed');
          this.submitStatus = 'SUCCESS';
          this.model.reasons = [];
          this.model.comment = null;
          this.closeDialog();
          this.$eventBus.$emit('alert:success:show', 'Denúncia registrada');
        })
        .catch(error => {
          console.error(error);
          this.submitStatus = 'ERROR';
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.close-btn {
  left: 90%;
  .mdi-close {
    font-size: 35px;
    font-weight: bolder;
  }
}
</style>
