var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-card',[_c('div',{staticClass:"flex flex-column gap-1"},[_c('Typography',{attrs:{"variant":"header-large","color":"neutral-grey-6"}},[_vm._v(" Endereços ")]),_c('Typography',{attrs:{"variant":"body-xs","color":"neutral-grey-5"}},[_vm._v(" Endereços registrados ")])],1),(_vm.detail.Properties)?_c('div',[_c('v-data-table',{staticClass:"property-owner",attrs:{"headers":_vm.propertiesAddressesHeaders,"items":_vm.propertiesAddresses,"items-per-page":_vm.itemsPerPage,"footer-props":{
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
      },"sort-by":"Ranking","sort-desc":true},scopedSlots:_vm._u([{key:`item.Area`,fn:function({ item }){return [_c('div',{staticClass:"py-4"},[(item)?_c('Button',{staticClass:"button-width",attrs:{"variant":"primary","size":"small"},on:{"click":function($event){return _vm.linkToMaps(item)}}},[_c('div',{staticClass:"flex gap-2"},[_c('img',{attrs:{"width":"12px","src":require("@/assets/images/map-button-pin.svg")}}),_vm._v(" Mapa ")])]):_vm._e()],1)]}},{key:`item.Year`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.Year || 'N/D')+" ")]}},{key:`item.Ranking`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatYear(item.Ranking))+" ")]}}],null,true)})],1):_vm._e(),(_vm.detail.Addresses)?_c('div',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.addressesHeader,"items":_vm.addresses,"items-per-page":_vm.itemsPerPage,"footer-props":{
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
      },"sort-by":"Status","sort-desc":true},scopedSlots:_vm._u([{key:`item.ShortAlias`,fn:function({ item }){return [_c('div',{staticClass:"py-4"},[(item)?_c('Button',{staticClass:"button-width",attrs:{"variant":"primary","size":"small"},on:{"click":function($event){return _vm.linkToMaps(item)}}},[_c('div',{staticClass:"flex gap-2"},[_c('img',{attrs:{"width":"12px","src":require("@/assets/images/map-button-pin.svg")}}),_vm._v(" Mapa ")])]):_vm._e()],1)]}},{key:`item.Ranking`,fn:function({ item }){return [_c('div',{staticClass:"flex align-center justify-center"},[_vm._v(" "+_vm._s(_vm.formatYear(item.Ranking))+" ")])]}}],null,true)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }