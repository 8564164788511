/**
 * Line heights
 */
export default {
  'line-height-default': 1,
  'line-height-small': 1.2,
  'line-height-medium': 1.5,
  'line-height-large': 1.8,
  'line-height-xl': 2,
};
