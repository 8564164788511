import { css } from '@/core/theme';

const container = css({
  padding: '$medium',
  borderRadius: '5px',
  border: '1px solid $neutral-grey-2',
  backgroundColor: '$neutral-white',
});

const title = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$small',
  paddingBottom: '$small',
  borderBottom: '1px solid $neutral-grey-2',
  minHeight: '70px',
});

const description = css({
  marginTop: '$medium',
});

const items = css({
  marginTop: '$nano',
  marginLeft: '-2px',

  '& > ul': {
    listStyle: `url('${require('@/assets/images/blue-check.svg')}')`,

    '& > li': {
      marginTop: '$nano',
    },
  },
});

const actions = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '$medium',
});

export const styles = {
  container,
  title,
  description,
  items,
  actions,
};
