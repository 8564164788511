<template>
  <div class="autocomplete" :class="{
        'autocomplete-mobile': isMobile,
        'focusout': !focus || !focused,
        'focusin': focus || focused,
        'disabled': disabled
      }">
    <SearchIcon class="search-icon" v-if="!isMobile" />
    <slot>
      <input v-model="searchInput"
       type="text"
       :disabled="disabled"
       :placeholder="placeholder"
       @focusin="onFocusIn"
       @focusout="onFocusOut"
       @change="onAutocompleteInputChange" />
    </slot>
    <button v-if="searchInput.length || resettable" class="clear-btn" @click="clearInputSearchValue">
      <v-icon>mdi-close</v-icon>
    </button>
  </div>
</template>

<script>
import SearchIcon from '@/components/icons/SearchIcon.vue';

export default {
  name: 'Autocomplete',
  components: { SearchIcon },
  props: {
    resettable: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchInput: '',
    focused: false,
  }),
  computed: {
    isMobile () {
      return this.$route.path.includes('mobile')
    },
  },
  
  methods: {
    onFocusIn(event) {
      this.focused = true;
      this.$emit('focusin', event);
    },
    onFocusOut(event) {
      this.focused = false;
      this.$emit('focusout', event);
    },
    onAutocompleteInputChange(event) {
      this.searchInput = event.target.value;
      this.$emit('change', event);
    },
    clearInputSearchValue() {
      this.focused = false;
      this.searchInput = '';
      this.$emit('clear');
    },
  },
};
</script>


<style lang="scss" scoped>
.autocomplete {
  @apply flex rounded-full px-2 border-2 border-transparent relative;
  background-color: #7E92CE1A;
  height: 35px;

  .search-icon {
    @apply my-auto mx-1;
    width: 20px;
    color: #768AC6;
  }

  .clear-btn {
    @apply flex mr-1;
    .v-icon {
      @apply m-auto;
      font-size: 18px;
    }
  }

  &.focusin {
    @apply border-blue-600 border-2;
    border-color: $primary-blue !important;

    &:hover {
      @apply border-blue-600;
    }

    .v-icon {
      color: $primary-blue;
    }
  }

  &.focusout {
    @apply border;
    border-color: #e8e8e8;

    &:hover {
      @apply border-gray-800;
    }
  }

  ::v-deep .v-input {
    @apply h-full;
    &__slot {
      @apply h-full;
      min-height: auto !important;
    }
    &__control {
      @apply h-full;
    }
    .v-select__slot {
      @apply h-full;
    }
    &__append-inner {
      @apply h-full;
      margin: 0 !important;
      padding: 0 !important;
      .v-input__icon {
        @apply m-auto;
      }
    }
  }

  ::v-deep input {
    @apply w-full h-full m-0 truncate;
    font-size: 12px!important;
    padding: 0!important;
    align-self: auto;
    &::placeholder {
      color: #777777;
    }
  }

  &.disabled {
    border-color: #e8e8e8 !important;
    opacity: .5;
  }

}

.autocomplete-mobile {
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--colors-neutral-grey-2);
  background: var(--colors-highlight-lighter);
}
</style>
