<template>
    <BaseCard>
      <div class="mb-4">
        <Typography
          variant="title-large"
          color="highlight-darker"
        >
          Endereços
        </Typography>
      </div>
      <div class="contact-card mb-4">
        <div class="w-full">
          <Typography
            variant="title-small"
            color="highlight-default"
          >
            Proprietário
          </Typography>
        </div>
        <VSimpleTable v-if="properties && properties.length" class="table-address">
          <tbody>
          <template v-for="(property, index) in slicer(properties, addressesToDisplay)">
            <tr>
              <td class="td-address">
                <div 
                  class="flex flex-row align-center justify-between gap-4"
                  :class="index === 0 ? 'pb-4' : 'py-4'"
                >
                  <Typography
                    variant="body-bold"
                    color="neutral-grey-5"
                    class="address"
                  >
                    {{ property.Address }}
                  </Typography>
                  <Button
                    variant="primary"
                    v-if="property"
                    size="small"
                    @click="linkToMaps(property)"
                    class="button-width"
                  >
                  <div class="flex gap-2">
                    <img
                      width="12px"
                      src="@/assets/images/map-button-pin.svg"
                    >
                      Mapa
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </VSimpleTable>
        <div v-else class="no-results my-8">
          Nenhum dado disponível para consulta.
        </div>
        <Button
          class="self-center"
          variant="alternativeFade"
          size="medium"
          @click="showInformation('Contato', 'Addresses')"
        >
          Ver Mais
        </Button>
      </div>
      <div class="contact-card">
        <div class="w-full">
          <Typography
            variant="title-small"
            color="highlight-default"
          >
            Correspondência
          </Typography>
        </div>
        <VSimpleTable v-if="addresses && addresses.length" class="table-address">
          <tbody>
          <template v-for="(address, index) in slicer(addresses, addressesToDisplay)">
            <tr>
              <td class="td-address">
                <div 
                  class="flex flex-row align-center justify-between gap-4"
                  :class="index === 0 ? 'pb-4' : 'py-4'">
                  <Typography
                    variant="body-bold"
                    color="neutral-grey-5"
                    class="address"
                  >
                    {{ address.Alias }}
                  </Typography>
                  <Button
                    variant="primary"
                    v-if="address"
                    size="small"
                    @click="linkToMaps(address)"
                    class="button-width"
                  >
                  <div class="flex gap-2">
                    <img
                      width="12px"
                      src="@/assets/images/map-button-pin.svg"
                    >
                      Mapa
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </VSimpleTable>
        <div v-else class="no-results my-8">
          Nenhum dado disponível para consulta.
        </div>
        <Button
          class="self-center"
          variant="alternativeFade"
          size="medium"
          @click="showInformation('Contato', 'Addresses')"
        >
          Ver Mais
        </Button>
      </div>
      <GoogleTagManager :track-event="gtmEvent" />
    </BaseCard>
</template>

<script>
import { mapActions } from 'vuex'

import BaseCard from '@/components/cards/base-card.vue';
import InfoMarkerCard from '@/components/cards/info-marker-card';
import ShowMoreButton from '../search/ShowMoreButton.vue';
import GoogleTagManager from '../google/GoogleTagManager';
import Typography from '../base/Typography';
import Button from '../base/Button';

export default {
  name: 'Properties',
  components: {
    GoogleTagManager,
    BaseCard,
    InfoMarkerCard,
    ShowMoreButton,
    Typography,
    Button
},
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    properties: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    addressesToDisplay: 4,
    gtmEvent: null,
  }),
  methods: {
    ...mapActions('person', ['setActiveTab']),
    openAccordion(accordion) {
      const element = document.querySelector(`#${accordion} button`);
      const isOpen = element.classList.value.includes('v-expansion-panel-header--active');
      if (!isOpen) element.click();
    },
    showInformation(accordion, activeTab) {
      this.setActiveTab(activeTab);
      this.openAccordion(accordion);
    },
    sendViewListEvent(type) {
      this.gtmEvent = {
        event: 'view_list',
        type,
      };
    },
    showMore (type, accordionID, activeTabID) {
      this.sendViewListEvent(type)
      this.showInformation(accordionID, activeTabID)
    },
    slicer (arr, amount) {
      return arr.slice(0, amount);
    },
    linkToMaps (property) {
      window.open(`https://www.google.com/maps/place/${property?.Alias ? property?.Alias : property?.Address}`, '_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  padding: 26px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
}

.contact-card {
  display: flex;
  padding: 16px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--colors-highlight-lighter);
}

.table-address, .td-address {
  background: var(--colors-highlight-lighter);
}
.address {
  width: 250px;
}
.no-results {
  font-family: Lato, sans-serif;
  color: #707070;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.map-button {
  width: 85px!important;
  height: 32px!important;
}

.td-address {
  height: 42px!important;
}

.button-width {
  min-width: 66px!important;
  height: 32px;
  padding: 10px 16px;
  gap: 10px;
}
</style>
