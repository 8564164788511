export const AD_PORTALS = {
  zap: 'Zap',
  vivareal: 'Viva Real',
  casamineira: 'Casa Mineira',
  bnsir: 'BNSIR',
  coelho_da_fonseca: 'Coelho da Fonseca',
  casasbacanas: 'Casas Bacanas',
  esquema: 'Esquema Imóveis',
  graziella: 'Graziella dos Imóveis',
  kaazaa: 'Kaazaa',
  lopes: 'Lopes'
};

export const FILTER_MODE = {
  RADIUS: 'radius',
  AREA: 'area',
  REGION: 'region',
  OWNER: 'owner',
  CONDO: 'condo',
  ADDRESS: 'address',
};

export const VALIDATION = {
  email: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,63}$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\^$*.\[\]{}()?\-"!@#%&\/\,><':;|_~`+=]).{4,}$/g,
};

export const MESSAGES = {
  providerPrivacyMessage: 'Por política interna do fornecedor, este usuário tem o perfil restrito.',
  LGPDPrivacyMessage: 'Dados bloqueados a pedido do titular de acordo com a Lei Geral de Proteção de Dados (LGPD)',
};

export const PROPERTY_TYPE = {
  APARTMENT: 1,
  HOUSE: 2,
  PENTHOUSE: 3,
};

export const PROPERTY_TYPE_NAME = {
  APARTMENT: 'Apartamento',
  HOUSE: 'Casa',
  PENTHOUSE: 'Cobertura',
};

export const PHONE_TYPE = {
  MOBILE: 'mobile',
  LANDLINE: 'landline',
  BUSINESS: 'business',
};

export const PHONE_TYPE_OPTIONS = [
  { label: 'Celular', value: PHONE_TYPE.MOBILE },
  { label: 'Residencial', value: PHONE_TYPE.LANDLINE },
  { label: 'Comercial', value: PHONE_TYPE.BUSINESS },
];

export const PROPERTY_ATTRIBUTES = [
  { label: 'Academia', value: 1475 },
  { label: 'Piscina', value: 1436 },
  { label: 'Playground', value: 1438 },
  { label: 'Quadra', value: 1474 },
  { label: 'Salão de festas', value: 1446 },
];

export const PROPERTY_TYPE_OPTIONS = [
  {
    label: PROPERTY_TYPE_NAME.APARTMENT,
    value: PROPERTY_TYPE.APARTMENT,
  },
  {
    label: PROPERTY_TYPE_NAME.HOUSE,
    value: PROPERTY_TYPE.HOUSE,
  },
  {
    label: PROPERTY_TYPE_NAME.PENTHOUSE,
    value: PROPERTY_TYPE.PENTHOUSE,
  },
];

export const UNIT_ATTRIBUTES = [
  { label: 'Churrasqueira', value: 11 },
  { label: 'Área gourmet', value: 649 },
  { label: 'Varanda', value: 1116 },
  { label: 'Ar-condicionado', value: 5 },
];

export const PROPERTY_STATUS = {
  READY: { label: 'Pronto', value: 1 },
  LAUNCHING: { label: 'Lançamento', value: 4 },
  BUILDING: { label: 'Construção', value: 6 },
};

export const CITY_CENTER = {
  SP: [
    -23.5744483,
    -46.6885769,
  ],
  RJ: [
    -22.98369,
    -43.21202,
  ],
};

export const PROPERTY_TABS = {
  MEDIA: 'MEDIA',
  PHOTOS: 'PHOTOS',
  MAP: 'MAP',
  LOCATION: 'LOCATION',
  BLUEPRINT: 'BLUEPRINT',
  COMMON_PLACES: 'COMMON_PLACES',
  COMMON_INTERNA: 'COMMON_INTERNA',
  COMMON_LIVING: 'COMMON_LIVING',
};

export const MAP_OPTIONS = {
  DEFAULT_RADIUS: 250,
  DEFAULT_CENTER: CITY_CENTER.SP,
  DEFAULT_ZOOM_LEVEL: 14,
  DEFAULT_MAX_ZOOM_LEVEL: 18,
  circleOptions: {
    editOptions: { zIndex: 2, skipMiddleMarkers: true },
    zoomControl: true,
    radius: 250,
  },
  mapOptions: {
    position: 'bottomright',
    editOptions: { zIndex: 2, skipMiddleMarkers: true },
    zoomControl: true,
    scrollWheelZoom: 'center',
  },
  reportMapOptions: {
    position: 'bottomright',
    zoomControl: true,
    scrollWheelZoom: false,
  },
  clusterOptions: {
    zoomToBoundsOnClick: true,
    removeOutsideVisibleBounds: false,
    disableClusteringAtZoom: 18,
    spiderfyOnMaxZoom: false,
  },
};

export const SELLER_AREA_RANGE_PERCENT = parseFloat(process.env.VUE_APP_SELLER_AREA_RANGE_PERCENT);
export const NATIONALITY = {
  10: 'Brasileiro',
  20: 'BrasileiroNaturalizado',
  21: 'Argentino',
  22: 'Boliviano',
  23: 'Chileno',
  24: 'Paraguaio',
  25: 'Uruguaio',
  26: 'Venezuelano',
  27: 'Colombiano',
  28: 'Peruano',
  29: 'Equatoriano',
  30: 'Alemao',
  31: 'Belga',
  32: 'Britanico',
  34: 'Canadense',
  35: 'Espanhol',
  36: 'NorteAmericano',
  37: 'Frances',
  38: 'Suico',
  39: 'Italiano',
  40: 'Haitiano',
  41: 'Japones',
  42: 'Chines',
  44: 'Russo',
  45: 'Portugues',
  46: 'Paquistanes',
  47: 'Indiano',
  48: 'LatinoAmericano',
  49: 'Asiatico',
  51: 'Europeu',
  60: 'Angolano',
  61: 'Congoles',
  62: 'SulAfricano',
  70: 'Africano',
};

export const MARITAL_STATUS = [
  'Solteiro',
  'Casado',
  'Divorciado',
  'Viúvo',
];

export const ZODIAC = [
  'Áries',
  'Touro',
  'Gêmeos',
  'Câncer',
  'Leão',
  'Virgem',
  'Libra',
  'Escorpião',
  'Sagitário',
  'Capricórnio',
  'Aquário',
  'Peixes',
];

export const EDUCATION_LEVEL = {
  1: 'Analfabeto',
  2: 'Primário Incompleto',
  3: 'Primário Completo',
  4: 'Fundamental Incompleto',
  5: 'Fundamental Completo',
  6: 'Médio Incompleto',
  7: 'Médio Completo',
  8: 'Superior Incompleto',
  9: 'Superior Completo',
  10: 'Superior Pós Graduado',
  11: 'Superior Mestrado',
  12: 'Superior Doutorado',
  13: 'Superior Pós Doutorado',
};

export const GENDER = {
  0: 'Desconhecido',
  1: 'Masculino',
  2: 'Feminino',
  10: 'PrivateCompany',
  11: 'PrivateCompanyLimited',
  12: 'PrivateCompanyEirely',
  13: 'PrivateCompanyMei',
  14: 'PrivateCompanySS',
  15: 'PrivateCompanySA',
  20: 'PublicCompany',
  30: 'NonGovernmentalOrganization',
};

export const CLASS_SOCIAL = {
  0: 'A1',
  1: 'B1',
  2: 'B2',
  3: 'C1',
  4: 'C2',
  5: 'D',
  6: 'E',
};
export const INFO_STATUS = {
  1: 'Ativo',
  2: 'Verificado',
  3: 'Quente',
};
export const DOCUMENT_TYPE = {
  0: 'CPF',
  1: 'CNPJ',
};
export const DATE_TYPE = {
  0: [
    'Nascimento',
    'Morte',
  ],
  1: [
    'Abertura',
    'Fechamento',
  ],
};

export const OFFER_GROUP = {
  0: 'Ofertas BNSIR',
  10: 'Novidades',
  20: 'Permaneceram',
  30: 'Ofertas anteriores',
};

export const CLEARABLE_ROUTES = [
  'buyer',
  'seller',
  'rent',
  'lead',
];

export const CITY_BOUNDARIES = {
  SAO_PAULO: {
    north: -23,
    south: -24,
    east: -46,
    west: -47,
  },
  RIO_DE_JANEIRO: {
    north: -22,
    south: -23,
    east: -41,
    west: -45,
  },
  ALL_COVERED_CITIES: {
    north: -22,
    south: -24,
    east: -41,
    west: -47,
  },
};

export const LAST_UPDATED_AT_MESSAGE = 'Última atualização em';

export const DATE_TIME_INTL_STANDARD = 'pt-BR';

export const ROUTES_WITHOUT_HELP = [
  'home',
  'report',
  'login',
  'economicIndex',
  'calculator',
  'catalog',
  'consultaDadosDetail',
];

export const SUMMARY_FILTER_MODE_LABEL = {
  radius: 'Localização',
  region: 'Região',
  area: 'Desenho',
};

export const SUMMARY_FILTER_TYPE = {
  casa: 'Casas',
  apartamento: 'Apartamentos',
  cobertura: 'Coberturas',
};

export const OPENSEARCH_CITIES =  [
  {
    value: 'sao-paulo',
    label: 'São Paulo',
  },

  {
    value: 'rio-de-janeiro',
    label: 'Rio de Janeiro',
  },
];

export const OPENSEARCH_PORTALS = {
  zap: 'Zap',
  vivareal: 'Viva Real',
  casamineira: 'Casa Mineira',
};

export const OPENSEARCH_NEGOTIATION_TYPES = {
  sell: 'Venda',
  rent: 'Locação',
  sellAndRent: 'Venda e locação',
};

export const OPENSEARCH_SELLER_TYPES = {
  realState: 'Imobiliária',
  realtor: 'Corretor',
};

export const OPENSEARCH_PROPERTY_TYPES = {
  1: 'Apartamento',
  3: 'Cobertura',
  2: 'Casa',
};

export const OPENSEARCH_SORTINGS = {
  'updatedAt|desc': 'Relevantes',
  'value|asc': 'Menor valor',
  'value|desc': 'Maior valor',
  'area|asc': 'Menor área',
  'area|desc': 'Maior área',
  'bedrooms|asc': 'Menor nº de dormitórios',
  'bedrooms|desc': 'Maior nº de dormitórios',
  'suites|asc': 'Menor nº de suítes',
  'suites|desc': 'Maior nº de suítes',
  'parkingLots|asc': 'Menor nº de vagas',
  'parkingLots|desc': 'Maior nº de vagas',
};

export const NAVBAR_MENU_ITEMS = {

  BUYER: {
    label: 'Comprador',
    href: '/search/buyer',
    gtm: 'comprador',
    bnsir: false,
  },

  SELLER: {
    label: 'Vendedor',
    href: '/search/seller',
    gtm: 'vendedor',
    bnsir: false,
  },

  RENTER: {
    label: 'Locador',
    href: '/search/rent',
    gtm: 'locador',
    bnsir: false,
  },

  CONSULTANT: {
    label: 'Consultor',
    href: '/search/lead',
    gtm: 'consultor',
    bnsir: false,
    permission: 'lead',
  },

  INVESTOR: {
    label: 'Investidor',
    href: '/',
    gtm: null,
    bnsir: false,
  },

  DEVELOPER: {
    label: 'Incorporador',
    href: '/developer',
    gtm: 'incorporador',
    bnsir: false,
  },

  TERRAINS: {
    label: 'Terrenos',
    href: '/',
    gtm: null,
    bnsir: false,
  },

  STUDIES: {
    label: 'Estudo de mercado',
    href: '/estudos',
    gtm: 'estudo_de_bairro',
    bnsir: true,
  },

  RELEASE_CATALOG: {
    label: 'Catálogo de lançamentos',
    href: '/catalogo',
    gtm: 'catalogo',
    bnsir: true,
  },

  OPEN_SEARCH: {
    label: 'Busca de anúncios',
    href: '/search/open',
    gtm: 'busca_de_anuncios',
    bnsir: false,
  },

  PERSON_DATA: {
    label: 'Consulta dados',
    href: '/person-data',
    gtm: 'consulta_dados',
    bnsir: false,
  },

  ECONOMICINDEX: {
    label: 'Indicadores Econômicos',
    href: '/indicadores-economicos',
    gtm: 'indicadores_economicos',
    bnsir: true,
  },

  CALCULATOR: {
    label: 'Calculadora de oportunidades',
    href: '/calculadora',
    gtm: 'calculadora_de_oportunidades',
    bnsir: true,
  },



  AVM: {
    label: 'Avaliação Mercadológica | AVM',
    href: '/avm',
    gtm: 'avaliacao_de_mercado',
    bnsir: false,
  },

  LOGOUT: {
    label: 'Sair',
    href: 'javascript:;',
    gtm: 'sair',
    bnsir: false,
  },
};

export const NAVBAR_MENU = [
  {
    ...NAVBAR_MENU_ITEMS.CONSULTANT,
    hidden: false,
    subtitle: 'Ferramentas',
    items: [
      NAVBAR_MENU_ITEMS.OPEN_SEARCH,
      NAVBAR_MENU_ITEMS.RELEASE_CATALOG,
      NAVBAR_MENU_ITEMS.STUDIES,
      NAVBAR_MENU_ITEMS.AVM,
      NAVBAR_MENU_ITEMS.ECONOMICINDEX,
      NAVBAR_MENU_ITEMS.CALCULATOR,
      NAVBAR_MENU_ITEMS.PERSON_DATA,
    ],
  },

  {
    ...NAVBAR_MENU_ITEMS.BUYER,
    hidden: false,
    subtitle: 'Ferramentas',
    items: [
      NAVBAR_MENU_ITEMS.STUDIES,
      NAVBAR_MENU_ITEMS.RELEASE_CATALOG,
      NAVBAR_MENU_ITEMS.OPEN_SEARCH,
    ],
  },

  {
    ...NAVBAR_MENU_ITEMS.SELLER,
    hidden: false,
    subtitle: 'Ferramentas',
    items: [
      NAVBAR_MENU_ITEMS.STUDIES,
      NAVBAR_MENU_ITEMS.CALCULATOR,
      NAVBAR_MENU_ITEMS.AVM,
    ],
  },

  {
    ...NAVBAR_MENU_ITEMS.RENTER,
    hidden: false,
    subtitle: 'Ferramentas',
    items: [NAVBAR_MENU_ITEMS.STUDIES],
  },

  {
    ...NAVBAR_MENU_ITEMS.INVESTOR,
    hidden: true,
    subtitle: 'Ferramentas',
    items: [],
  },

  {
    ...NAVBAR_MENU_ITEMS.DEVELOPER,
    hidden: false,
    subtitle: 'Ferramentas',
    items: [
      NAVBAR_MENU_ITEMS.RELEASE_CATALOG,
      NAVBAR_MENU_ITEMS.OPEN_SEARCH,
    ],
  },

  {
    ...NAVBAR_MENU_ITEMS.TERRAINS,
    hidden: true,
    subtitle: 'Ferramentas',
    items: [],
  },
];

export const NAVBAR_HEIGHT = '75px';

export const NOT_FOUND_MESSAGE = 'Não foram encontrados resultados para este endereço';
