<script setup>

import Typography from '@/components/base/Typography';
import { useUpdatedStore } from '@/pinia/updatedStore';

const updatedStore = useUpdatedStore();

</script>

<template>
  <!-- TODO componentizar Relatorios vendedor e outros -->
  <div class="report__wrapper w-full bg-white">
    <!-- modal isLoading relatorio \/ -->
    <div
      v-if="verRelatorio"
      class="w-full h-full flex justify-center align-center mx-auto py-5 flex-col fundoLoading"
    >
      <!-- card cental do modal \/ -->
      <div class="text-center contentLoading">
        <p class="cidadeVirtual mb-10">
          Cidade Virtual
        </p>
        <p
          v-if="hasReportError"
          class="textEmbreve mb-10"
        >
          Não foi possível gerar o relatório. <br>
          Por favor, tente novamente mais tarde.
        </p>
        <p
          v-else
          class="textEmbreve mb-10"
        >
          Aguarde estamos gerando um relatório especialmente para você...
        </p>

        <div>
          <v-progress-circular
            v-if="isLoading"
            class="mx-auto py-5"
            color="rgba(70, 99, 185, 0.95)"
            indeterminate
          />
        </div>
      </div>
      <!-- card cental do modal /\ -->
    </div>
    <!-- modal isLoading relatorio /\ -->

    <!-- relatorio \/ -->
    <div
      v-if="!verRelatorio"
      class="contentRelatorio"
    >
      <!-- nome do relatorio e data \/ -->
      <div
        class="contentheader"
        :class="{
          'mt-12': seller,
        }"
      >
        <h1>{{ titulo }}</h1>
        <p>
          {{ generated }}
        </p>
      </div>
      <!-- nome do relatorio e data /\ -->
      <!-- Dados do cliente e do corretor \/ -->
      <div class="contentDadosUserCliente">
        <div class="userCreate">
          <p class="titulo">
            Consultor (a)
          </p>
          <!-- nome do user logado \/ -->
          <p class="user">
            {{
              report.relatorio.user
                ? report.relatorio.user
                : "Não informado"
            }}
          </p>
        </div>
        <div class="divider" />
        <div class="userCreate">
          <p class="titulo">
            Email
          </p>
          <!-- email do user logado \/ -->
          <p class="user">
            {{
              report.relatorio.emailCorretor
                ? report.relatorio.emailCorretor
                : "Não informado"
            }}
          </p>
        </div>
      </div>
      <!-- Dados do cliente e do corretor /\ -->
      <!-- conteiner com dados de comprador captação e locação \/ ver para componentizar -->
      <div
        class="contentCardsDetalhes"
        :class="{ 'd-block': seller }"
      >
        <!-- card de resumo da pesquisa \/ -->
        <div
          v-if="!seller"
          class="ResumoDaPesquisa"
        >
          <p class="titulo">
            Resumo da pesquisa
          </p>
          <div class="contentDados">
            <!-- primeiros dados (tipo) (valores) (Fases) \/ -->
            <div class="dados">
              <!-- tipo do imovel \/ -->
              <div
                v-if="tipo"
                class="dado"
              >
                <p class="titulo">
                  Tipo:
                </p>
                <p class="text">
                  {{ tipo ? tipo : "Não informado" }}
                </p>
              </div>
              <prices :price="price" />
              <!-- fase do imovel buscada \/ -->
              <div class="dado">
                <p class="titulo">
                  Fase:
                </p>
                <p class="text">
                  <span class="faseImovel">
                    {{
                      report.attributes.propertyStatus.length
                        ? ""
                        : "Não informado"
                    }}
                  </span>
                  <span
                    v-for="(item, index) in report.attributes
                      .propertyStatus"
                    :key="index"
                    class="faseImovel"
                  >
                    {{ item === 1 ? "Pronto" + 
                      `${index !== report.attributes.propertyStatus.length - 1 ?
                      ", ": ""}`
                    : "" }}

                    {{ item === 4 ? "Lançamento" + 
                      `${index !== report.attributes.propertyStatus.length - 1 ?
                      ", ": ""}`
                    : "" }}
                    {{ item === 6 ? "Construção" + 
                      `${index !== report.attributes.propertyStatus.length - 1 ?
                      ", ": ""}`
                    : "" }}
                  </span>
                </p>
              </div>
            </div>
            <!-- primeiros dados (tipo) (valores) (Fases) /\ -->
            <div class="divider" />
            <!-- segunda parte dos dados (area) (idade) (Características) \/ -->
            <div class="dados">
              <!-- area da busca \/ -->
              <div class="dado">
                <p class="titulo">
                  Área:
                </p>
                <p class="text">
                  {{ areaFormat }}
                </p>
              </div>
              <!-- idade da busca \/ -->
              <div class="dado">
                <p class="titulo">
                  Idade:
                </p>
                <p class="text">
                  {{ idadeFormat }}
                </p>
              </div>
              <div class="dado">
                <p class="titulo">
                  Características:
                </p>
                <p class="text">
                  <span class="faseImovel">
                    {{
                      report.attributes.propertyAttributes.length
                        ? ""
                        : "Não informado"
                    }}
                  </span>
                  <span
                    v-for="(item, index) in report.attributes
                      .propertyAttributes"
                    :key="index"
                  >
                    {{ item === 1474 ? "Campo/Quadra de Esportes" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : "" }}
                    {{ item === 1447 ? "Fitness" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : ""}}
                    {{ item === 2143 ? "Gourmet" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : ""}}
                    {{ item === 30362 ? "Kids" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : "" }}
                    {{ item === 1436 ? "Piscina" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : "" }}
                    {{ item === 1434 ? "Quintal/Jardim" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : "" }}
                    {{ item === 1494 ? "SPA" + 
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : ""}}
                    {{ item === 1951 ? "Terraço/Varanda" +
                        `${index !== report.attributes.propertyAttributes.length - 1 ?
                        ", ": ""}`
                      : ""
                    }}
                  </span>
                </p>
              </div>
            </div>
            <div class="divider" />
            <!-- terceira parte dos dados (Dormitórios) (Suites) (Vagas) \/ -->
            <div class="dados">
              <!-- dormitorios \/ -->
              <div class="dado">
                <p class="titulo">
                  Dormitórios:
                </p>
                <p class="text">
                  {{ dorms }}
                </p>
              </div>
              <!-- Suites \/ -->
              <div class="dado">
                <p class="titulo">
                  Suítes:
                </p>
                <p class="text">
                  {{ suites }}
                </p>
              </div>
              <!-- Vagas \/ -->
              <div class="dado">
                <p class="titulo">
                  Vagas:
                </p>
                <p class="text">
                  {{ parkingLots }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- card de resumo da pesquisa /\ -->
        <!-- card Resumo da cidade \/-->
        <div
          v-if="!seller"
          class="resumoDaCidade"
        >
          <div class="flex justify-between items-center mb-4">
            <div class="titulo">
              Resumo da cidade
            </div>
            <Typography
              tag="div"
              variant="body-xs"
              color="neutral-grey-5"
            >
              Dados atualizados em {{ updatedStore.updatedAt }}
            </Typography>
          </div>
          <div class="">
            <div class="row no-gutters pb-4 text-center">
              <div class="col py-0">
                <v-chip class="px-4 grey--text text--darken-2 grey lighten-3">
                  <span class="text-capitalize">{{ propertyTypeLabel() }}:</span>
                  <b class="ml-1">
                    {{
                      report.summary.condominiumCount
                        ? report.summary.condominiumCount
                        : "-"
                    }}
                  </b>
                </v-chip>
              </div>
              <div class="col py-0">
                <v-chip class="px-4 grey--text text--darken-2 grey lighten-3">
                  Com anúncio:
                  <b class="ml-1">
                    {{
                      report.summary.condoWithOfferCount
                        ? report.summary.condoWithOfferCount
                        : "-"
                    }}
                  </b>
                </v-chip>
              </div>
              <div class="col py-0">
                <v-chip class="px-4 grey--text text--darken-2 grey lighten-3">
                  Sem anúncio:
                  <b class="ml-1">
                    {{
                      report.summary.condoWithNoOffersCount
                        ? report.summary.condoWithNoOffersCount
                        : "-"
                    }}
                  </b>
                </v-chip>
              </div>
            </div>
            <div
              class="ResumoDaPesquisa px-2 mt-0 pt-3"
              style="
                border: 0;
                border-top: 1px solid #e8e8e8;
                max-width: none;
                padding: 0;
              "
            >
              <b class="text-sm grey--text text--darken-2">Totais</b>
              <div class="contentDados px-10">
                <div class="dados">
                  <div class="dado">
                    <p class="text text-center mb-2">
                      Domicílios:
                    </p>
                    <p class="titulo text-center">
                      {{
                        report.summary.condominiumCount
                          ? report.summary.condominiumCount
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="divider"
                  style="height: 30px"
                />
                <div class="dados">
                  <div class="dado">
                    <p class="text text-center mb-2">
                      Anúncios:
                    </p>
                    <p class="titulo text-center">
                      {{
                        report.summary.offerCount
                          ? report.summary.offerCount
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="divider"
                  style="height: 30px"
                />
                <div class="dados">
                  <div class="dado">
                    <p class="text text-center mb-2">
                      Imobiliárias:
                    </p>
                    <p class="titulo text-center">
                      {{
                        report.summary.sellerCount
                          ? report.summary.sellerCount
                          : "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- card Resumo da cidade /\-->
        <!-- Resumo de valores \/ -->
        <div
          v-if="!seller"
          class="resumoValores"
        >
          <p class="d-flex titulo">
            <svg
              id="coin"
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18.36"
              height="18.36"
              viewBox="0 0 18.36 18.36"
            >
              <g
                id="Group_1795"
                data-name="Group 1795"
              >
                <g
                  id="Group_1794"
                  data-name="Group 1794"
                >
                  <path
                    id="Path_980"
                    data-name="Path 980"
                    d="M15.672,2.689A9.18,9.18,0,0,0,2.689,15.672,9.18,9.18,0,0,0,15.672,2.689ZM9.18,17.285a8.1,8.1,0,1,1,8.1-8.1A8.114,8.114,0,0,1,9.18,17.285Z"
                    fill="#36c"
                  />
                </g>
              </g>
              <g
                id="Group_1797"
                data-name="Group 1797"
                transform="translate(6.338 3.457)"
              >
                <g
                  id="Group_1796"
                  data-name="Group 1796"
                >
                  <path
                    id="Path_981"
                    data-name="Path 981"
                    d="M180.153,101.586H179a1.191,1.191,0,1,1,0-2.381h2.3a.538.538,0,1,0,0-1.076h-1.191V96.939a.538.538,0,0,0-1.076,0v1.191H179a2.266,2.266,0,1,0,0,4.533h1.152a1.191,1.191,0,0,1,0,2.381h-2.3a.538.538,0,1,0,0,1.076h1.19v1.191a.538.538,0,0,0,1.076,0v-1.191h.038a2.266,2.266,0,0,0,0-4.533Z"
                    transform="translate(-176.734 -96.401)"
                    fill="#36c"
                  />
                </g>
              </g>
            </svg>
            Resumo de valores
          </p>
          <div class="contentValoresMedio">
            <div class="ValoresMedio">
              <p class="titulo">
                Valor médio
              </p>
              <p class="valor">
                <span v-if="report.summary.avgSellValue">
                  {{ report.summary.avgSellValue | money }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="ValoresMedio">
              <p class="titulo">
                Valor médio m²
              </p>
              <p class="valor">
                <span v-if="report.summary.avgSqmeterValue">
                  {{ report.summary.avgSqmeterValue | money }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="ContentValorMaxMIn">
            <div class="ValorMaxMIn">
              <p class="titulo">
                Valor mínimo
              </p>
              <p class="valor">
                <span v-if="report.summary.minSellValue">
                  {{ report.summary.minSellValue | money }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="ValorMaxMIn">
              <p class="titulo">
                Valor máximo
              </p>
              <p class="valor">
                <span v-if="report.summary.maxSellValue">
                  {{ report.summary.maxSellValue | money }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
        </div>
        <!-- Resumo de valores /\ -->
        <div
          v-if="seller"
          class="mt-3"
        >
          <h1 class="mb-0 primary--text titulo">
            Imóvel pesquisado
          </h1>
          <div
            v-if="seller && condominioBusca"
            class="containerCondominioRelatorio ml-0 mt-2"
          >
            <div class="containerImgCondo">
              <img
                :src="condominioBusca.imageDefault"
                class="h-100"
                style="object-fit: cover"
                alt=""
              >
            </div>
            <div class="CondominioRelatorio p-3">
              <p class="titulo">
                {{ condominioBusca.condoName ? condominioBusca.condoName : "-" }}
                <OpenGoogleMaps
                  v-if="condominioBusca.condoName !== 'Casa'"
                  :property="{ ...condominioBusca, condoName: condominioBusca.condoName }"
                />
              </p>
              <p class="text">
                {{
                  condominioBusca.addressLine1
                    ? condominioBusca.addressLine1
                    : ""
                }}
                <br>
                {{
                  condominioBusca.addressLine2
                    ? condominioBusca.addressLine2
                    : ""
                }}
                <br>
                {{
                  condominioBusca.addressLine3
                    ? condominioBusca.addressLine3
                    : ""
                }}
              </p>
              <p class="text">
                Construção:
                {{
                  condominioBusca.yearOfConstruction
                    ? condominioBusca.yearOfConstruction
                    : "-"
                }}
              </p>
              <div class="conteinerDadosCondo">
                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.typology.utilArea"
                    class="valor text-left"
                  >
                    {{ condominioBusca.typology.utilArea[0] }} a
                    {{ condominioBusca.typology.utilArea[1] }} m²
                  </p>
                  <p
                    v-else
                    class="valor text-left"
                  >
                    N/I
                  </p>
                  <p class="label text-left">
                    Área útil
                  </p>
                </div>
                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.typology.bedRooms"
                    class="valor"
                  >
                    {{ condominioBusca.typology.bedRooms[0] }} a
                    {{ condominioBusca.typology.bedRooms[1] }}
                  </p>
                  <p
                    v-else
                    class="valor"
                  >
                    N/I
                  </p>
                  <p class="label">
                    Dormitórios
                  </p>
                </div>
                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.typology.suites"
                    class="valor"
                  >
                    {{ condominioBusca.typology.suites[0] }} a
                    {{ condominioBusca.typology.suites[1] }}
                  </p>
                  <p
                    v-else
                    class="valor"
                  >
                    N/I
                  </p>
                  <p class="label">
                    Suítes
                  </p>
                </div>
                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.typology.parkingLots"
                    class="valor"
                  >
                    {{ condominioBusca.typology.parkingLots[0] }} a
                    {{ condominioBusca.typology.parkingLots[1] }}
                  </p>
                  <p
                    v-else
                    class="valor"
                  >
                    N/I
                  </p>
                  <p class="label">
                    Vagas
                  </p>
                </div>

                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.avgValue"
                    class="valor"
                  >
                    {{ condominioBusca.avgValue }}
                  </p>
                  <p
                    v-else
                    class="valor"
                  >
                    N/I
                  </p>
                  <p class="label">
                    Valor
                  </p>
                </div>
                <div class="DadosCondo">
                  <p
                    v-if="condominioBusca.valueSqrtMeter"
                    class="valor"
                  >
                    {{ condominioBusca.valueSqrtMeter }}
                  </p>
                  <p
                    v-else
                    class="valor"
                  >
                    N/I
                  </p>
                  <p class="label">
                    Valor m²
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="seller && userLoggedIn"
          class="avmRelatorio mt-3"
        >
          <div
            v-if="avmShow"
            class="contentBtnAvm"
          >
            <v-btn
              v-if="!erroAvm"
              class="btnAvm"
              rounded
              color="primary"
              dark
              small
              @click="avaliacaoMercadoLogica"
            >
              Solicitar Avaliação de mercado
              <v-progress-circular
                v-if="isLoading"
                class="mx-auto py-5 ml-3 isLoadingAvm"
                color="#fff"
                indeterminate
              />
            </v-btn>
            <div
              v-if="erroAvm"
              class="d-flex justify-center flex-col align-center"
            >
              <p class="d-flex align-center errorText mb-2">
                <svg
                  class="mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.033"
                  height="33.99"
                  viewBox="0 0 36.033 33.99"
                >
                  <g
                    id="warning"
                    transform="translate(-0.001 -14.519)"
                  >
                    <g
                      id="Group_1893"
                      data-name="Group 1893"
                      transform="translate(0.001 14.519)"
                    >
                      <path
                        id="Path_982"
                        data-name="Path 982"
                        d="M35.3,40.565,22.559,17.105a5.285,5.285,0,0,0-9.087,0l-.021.037L.751,40.527a5.282,5.282,0,0,0,4.543,7.981H30.668a5.339,5.339,0,0,0,4.664-2.671A5.208,5.208,0,0,0,35.3,40.565Zm-19.4-15.3a2.113,2.113,0,0,1,4.227,0v8.454a2.113,2.113,0,0,1-4.227,0V25.261Zm2.113,19.021a3.17,3.17,0,1,1,3.17-3.17A3.174,3.174,0,0,1,18.016,44.282Z"
                        transform="translate(-0.001 -14.519)"
                        fill="#36c"
                      />
                    </g>
                  </g>
                </svg>
                Não foi possível concluir a solicitação no momento, por favor,
                tente mais tarde.
              </p>
              <v-btn
                class="btnAvm px-12"
                rounded
                color="primary"
                dark
                @click="resetAvm"
              >
                OK
              </v-btn>
            </div>
          </div>
          <div
            v-if="!avmShow"
            class="contentDadosAvaliacao"
          >
            <p class="titulo">
              Avaliação de mercado
            </p>
            <div class="dadosAvaliacao">
              <div class="avaliacao">
                <p class="tituloTipoAvm">
                  Valor de venda
                </p>
                <div class="avaliacaoValor">
                  <div class="valor">
                    <p class="avaliacaoTitulo">
                      Valor mínimo
                    </p>
                    <p class="valoresAVM">
                      {{ avm.MIN | money }}
                    </p>
                  </div>
                  <div class="valor">
                    <p class="avaliacaoTitulo">
                      Valor máximo
                    </p>
                    <p class="valoresAVM">
                      {{ avm.MAX | money }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="avaliacao">
                <p class="tituloTipoAvm">
                  Valor m²
                </p>
                <div class="avaliacaoValor">
                  <div class="valor">
                    <p class="avaliacaoTitulo">
                      Valor mínimo
                    </p>
                    <p class="valoresAVM">
                      {{ avm.MIN_M2 | money }}
                    </p>
                  </div>
                  <div class="valor">
                    <p class="avaliacaoTitulo">
                      Valor máximo
                    </p>
                    <p class="valoresAVM">
                      {{ avm.MAX_M2 | money }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="avaliacao">
                <p class="tituloTipoAvmDestaque">
                  Valor estimado
                </p>
                <div class="avaliacaoValor">
                  <div class="valor">
                    <p class="valoresAvmDestaque">
                      {{ avm.CENTRAL | money }}
                    </p>
                  </div>
                  <div class="valor">
                    <p class="valoresAvmDestaque">
                      {{ avm.CENTRAL_M2 | money }}/m²
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="mb-0 mt-2">
              *Estes dados consideram as características do imóvel informado, em
              relação aos imóveis semelhantes na mesma região.
            </p>
          </div>
        </div>
        <!-- TODO refatorar Card Do comdominio selecionado em vendedor \/  trocar !== por === achar dado quebrado -->
        <!-- refatorar /\ -->
      </div>
      <!-- conteiner com dados de comprador captação e locação /\ ver para componentizar -->

      <!-- TODO refatorar \/ tirar !== do primeiro card achar dado quebrado-->
      <div
        v-if="seller"
        class="resumoCidadeEValores"
      >
        <div
          class="containerResumoDaPerquisaVendedor flex flex-column px-0 mt-0"
        >
          <div
            v-if="report && report.attributes && report.filter"
            class="px-3 flex-1 mb-4 mr-6"
            style="border-right: 1px solid #c1c1c1"
          >
            <p class="titulo mb-8">
              Parâmetros de avaliação
            </p>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Tipología:
              </p>
              <p class="text">
                {{ report.attributes.typologyName || '-' }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Área:
              </p>
              <p class="text">
                {{ report.attributes.areaRange[2] || '-' }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Construção:
              </p>
              <p class="text">
                {{
                  report.filter.yearOfConstruction -
                    report.attributes.propertyAgeVar
                }}
                -
                {{
                  report.filter.yearOfConstruction +
                    report.attributes.propertyAgeVar
                }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Dormitórios:
              </p>
              <p class="text">
                {{ dorms }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Suites:
              </p>
              <p class="text">
                {{ report.attributes.suites + "+" }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Vagas:
              </p>
              <p class="text">
                {{ report.attributes.parkingLots + "+" }}
              </p>
            </div>
            <div class="conteinerResumoDados">
              <p class="titulo">
                Características:
              </p>
              <p class="text text-capitalize">
                {{ propertyAttributesNames || '-' }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="report && report.summary"
          class="resumoCidade flex flex-column px-0"
        >
          <div class="titulo mb-0">
            Resultado da pesquisa
          </div>
          <div class="flex justify-between items-center flex-grow-0">
            <div class="small-text">
              Base de ofertas
            </div>
            <div class="pr-6">
              <Typography
                tag="div"
                variant="body-xs"
                color="neutral-grey-5"
              >
                Dados atualizados em {{ updatedStore.updatedAt }}
              </Typography>
            </div>
          </div>
          <div class="contentTableCidade pr-6 mt-4 flex-grow-1">
            <table class="table">
              <thead class="tableThead">
                <tr>
                  <th />
                  <th class="text-capitalize">
                    {{ propertyTypeLabel() }}
                  </th>
                  <th>Valor médio de transação</th>
                  <th>Valor médio ofertado</th>
                  <th>Valor médio de AVM</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody class="tableTbody pr-6">
                <tr v-if="report.summary.street">
                  <td>{{ report.summary.street.limit }}</td>
                  <td>{{ report.summary.street.condominiumCount }}</td>
                  <td>{{ report.summary.street.avgTransactionValue | money }}</td>
                  <td>{{ report.summary.street.avgOfferValue | money }}</td>
                  <td>{{ report.summary.street.avgEvaluationValue | money }}</td>
                  <td />
                  <td />
                </tr>
                <tr v-if="report.summary.radius">
                  <td>{{ report.summary.radius.limit }}</td>
                  <td>{{ report.summary.radius.condominiumCount }}</td>
                  <td>{{ report.summary.radius.avgTransactionValue | money }}</td>
                  <td>{{ report.summary.radius.avgOfferValue | money }}</td>
                  <td>{{ report.summary.radius.avgEvaluationValue | money }}</td>
                  <td />
                  <td />
                </tr>
                <tr v-if="report.summary.district">
                  <td>{{ report.summary.district.limit }}</td>
                  <td>{{ report.summary.district.condominiumCount }}</td>
                  <td>{{ report.summary.district.avgTransactionValue | money }}</td>
                  <td>{{ report.summary.district.avgOfferValue | money }}</td>
                  <td>{{ report.summary.district.avgEvaluationValue | money }}</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td><b>Tamanho da amostra</b></td>
                  <td>
                    {{ result.length }}
                    <!-- 18 -->
                  </td>
                  <td>
                    <!-- 150 -->
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- refatorar /\ -->

      <div class="report__map-wrapper justify-center w-full">
        <ReportMap
          :results="result"
          :search-summary="report"
        />
      </div>
      <div class="ContentResult">
        <div class="result">
          <p class="tituloResult">
            <span
              v-if="!seller"
              class="font-weight-regular"
            >
              Estes são os {{ propertyTypeLabel() }} que possuem anúncios na área selecionada:
            </span>
            <span
              v-else
              class="font-weight-regular"
            >
              Estes são os {{ propertyTypeLabel() }} encontrados:
            </span>
          </p>
          <hr>
          <div class="flex justify-evenly pb-6">
            <div class="flex wrapper-btn-presentation">
              <div
                class="btn-presentation"
                @click="changeReportFormat('cards')"
              >
                <span :class="{ 'active' : (reportFormat === 'cards') }">
                  Ver cards dos {{ propertyTypeLabel() }}
                </span>
              </div>
              <div
                class="btn-presentation"
                @click="changeReportFormat('table')"
              >
                <span :class="{ 'active' : (reportFormat === 'table') }">
                  Ver tabela dos {{ propertyTypeLabel() }}
                </span>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="p-4 font-weight-regular">
                <a
                  id="exportAnchor"
                  href="javascript:;"
                  class="flex pr-13 mr-13"
                  @click="exportXLS()"
                >
                  <img
                    class="pr-2"
                    src="@/assets/images/icon-export-xls.svg"
                  >
                  Exportar para Excel
                </a>
              </div>
            </div>
          </div>
          <SearchResult
            v-if="reportFormat === 'cards'"
            :search-results="result"
          />
          <TableSearchResult
            v-else
            :search-results="result"
          />
        </div>
      </div>
    </div>
    <!-- relatorio /\ -->
  </div>
</template>

<script>

import { convert } from 'excellentexport';

import { mapActions } from 'vuex';
import Prices from '@/components/report/Prices.vue';
import { PROPERTY_TYPE } from '@/common/constants';
import api from '@/api';
import OpenGoogleMaps from '@/components/buttons/OpenGoogleMaps.vue';
import SearchResult from './SearchResult.vue';
import ReportMap from './Map.vue';
import TableSearchResult from './TableSearchResult.vue';

export default {
  name: 'Report',
  components: {
    SearchResult,
    ReportMap,
    TableSearchResult,
    Prices,
    OpenGoogleMaps,
  },
  data () {
    return {
      isLoading: false,
      result: null,
      report: null,
      verRelatorio: true,
      avmShow: true,
      avm: '',
      erroAvm: false,
      hasReportError: false,
      reportFormat: 'cards',
      titleDefault: 'Relatório da pesquisa',
    };
  },
  computed: {
    generated () {
      const generatedDate = this.report.relatorio.data ?? '-';

      return `Gerado em ${generatedDate}`;
    },
    userLoggedIn () {
      return this.$store.getters['user/idToken'];
    },
    titulo () {
      if (!this.report || !this.report.filter) return this.titleDefault;

      let titulo = 'Relatório ';

      switch (this.report.filter.routeName) {
      case 'seller':
        titulo += 'Vendedor';
        break;
      case 'buyer':
        titulo += 'Comprador';
        break;
      case 'rent':
        titulo += 'Locador';
        break;
      case 'lead':
        titulo += 'Consultor';
        break;
      default:
        titulo = this.titleDefault;
      }

      return titulo;
    },
    propertyAttributesNames () {
      const { report } = this;

      if (!(report && report.attributes) || !Array.isArray(report.attributes.propertyAttributesNames)) return '';

      return report.attributes.propertyAttributesNames.sort().join(', ').toLowerCase();
    },
    tipo () {
      if (this.report.attributes.propertyType) {
        if (this.report.attributes.propertyType === 1) {
          return 'Apartamento';
        }

        if (this.report.attributes.propertyType === 3) {
          return 'Cobertura';
        }

        if (this.report.attributes.propertyType === 2) {
          return 'Casa';
        }
      }

      return '';
    },
    areaFormat () {
      let format = null;

      const areaRangeMin = Number.isInteger(
        this.report.attributes.areaRange[0],
      )
        ? this.report.attributes.areaRange[0]
        : undefined;
      const areaRangeMax = Number.isInteger(
        this.report.attributes.areaRange[1],
      )
        ? this.report.attributes.areaRange[1]
        : undefined;

      if (areaRangeMin >= 0 && areaRangeMax >= 0) {
        format = `De ${areaRangeMin}m² - Até ${areaRangeMax}m²`;

        if (this.seller) {
          format = `${areaRangeMin} ~ ${areaRangeMax} m²`;
        }
      }

      if (areaRangeMin >= 0 && areaRangeMax === undefined) {
        format = `a partir de ${areaRangeMin}m²`;
      }

      if (areaRangeMin === undefined && areaRangeMax >= 0) {
        format = `Mais de ${areaRangeMax}m²`;
      }

      if(areaRangeMin === undefined && areaRangeMax === undefined){
        format = 'Não informado'
      }
      return format;
    },
    idadeFormat () {
      if (this.report.attributes.propertyAge) {
        const age = this.report.attributes.propertyAge;
        const minAge = `De ${age[0]}`;
        const maxAge = (age[1] === 30) ? `a ${age[1]}+ anos` : `a ${age[1]} anos`;

        return `${minAge} ${maxAge}`;
      }
    },
    condominioBusca () {
      return this.report.attributes.detailed;
    },
    seller () {
      if (!this.report) return false;

      return this.report.relatorio.url === '/search/seller';
    },
    price () {
      const priceMin = this.report.attributes.priceMin || 0;
      const priceMax = this.report.attributes.priceMax || 0;

      return [
        priceMin,
        priceMax,
      ];
    },
    phase () {
      const phase = this.report.attributes.propertyStatus.map((status) => {
        switch (status) {
          case 1:
            return 'Pronto'
          case 4:
            return 'Lançamento'
          case 6:
            return 'Construção'
        }
      })     
      
      const phaseString = phase.length < 1 ? 'Não informado' : phase.join(', ')

      return phaseString
    },
    attributes () {
      const attributes = this.report.attributes.propertyAttributes.map((attribute) => {
        switch (attribute) {
          case 1474:
            return 'Campo/Quadra de Esportes'
          case 1447:
            return 'Fitness'
          case 2143:
            return 'Gourmet'
          case 30362:
            return 'Kids'
          case 1436:
            return 'Piscina'
          case 1434:
            return 'Quintal/Jardim'
          case 1494:
            return 'SPA'
          case 1951:
            return 'Terraço/Varanda'
        }
      })     
      
      const attributesString = attributes.length < 1 ? 'Não informado' : attributes.join(', ')

      return attributesString
    },
    dorms () {
      return this.report.attributes.bedrooms ? this.report.attributes.bedrooms + "+" : "Não informado"
    },
    suites () {
      return this.report.attributes.suites ? this.report.attributes.suites + "+" : "Não informado"
    },
    parkingLots () {
      if(this.report.attributes.parkingLots === 0){
        return this.report.attributes.parkingLots
      } else if(this.report.attributes.parkingLots) {
        return this.report.attributes.parkingLots + "+"
      }

      return "Não informado"
    },
    prices () {
      const minPriceValue = this.report.attributes.priceMin || 0
      const notDefaultValueMin = minPriceValue && minPriceValue !== 0
      const minPrice = notDefaultValueMin ? minPriceValue : '-'

      const maxPriceValue = this.report.attributes.priceMax || 0
      const notDefaultValueMax = maxPriceValue && maxPriceValue !== 0
      const maxPrice = notDefaultValueMax ? maxPriceValue : '-'

      const pricesNotProvided = this.priceNotProvided(minPrice) &&
          this.priceNotProvided(maxPrice);

      if (pricesNotProvided) return 'Não informado';
      if (!Number(maxPrice) && Number(minPrice)) return `A partir de ${this.$options.filters.money(minPrice)}`;
      if (Number(maxPrice) && !Number(minPrice)) return `Até ${this.$options.filters.money(maxPrice)}`;

      return `De ${this.$options.filters.money(minPrice)} Até ${this.$options.filters.money(maxPrice)}`;
    }
  },
  methods: {
    ...mapActions({
      getReport (dispatch, payload) {
        return dispatch(`${this.$route.name}/getReport`, payload); 
      },
      getCondoById (dispatch, payload) {
        return dispatch(`${this.$route.name}/getCondoById`, payload); 
      },
    }),
    priceNotProvided(price) {
      return Boolean(price === '-' || price < 1)
    },
    propertyTypeLabel (plural = true) {
      if (!this.report || !this.report.attributes) return '';

      const type = this.report.attributes.propertyType;

      switch (type) {
      case PROPERTY_TYPE.HOUSE:
        return `imóve${plural ? 'is' : 'l'}`;
      default:
        break;
      }

      return `condomínio${plural ? 's' : ''}`;
    },
    async fetchData (hash) {
      try {
        this.isLoading = true;

        const { result, searchSummary } = await this.getReport(hash);

        if (!result) {
          this.hasReportError = true;

          return;
        }

        this.result = result;
        this.report = searchSummary;
        this.verRelatorio = false;
      } catch (error) {
        this.hasReportError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async avaliacaoMercadoLogica () {
      try {
        if (!this.avm) {
          this.isLoading = true;

          const {
            typology: buscaTypology,
            addressLine1,
            addressLine2,
            yearOfConstruction,
          } = this.condominioBusca;

          const bedrooms = Array.from(buscaTypology.bedRooms || []);
          const parkingLots = Array.from(buscaTypology.parkingLots || []);
          const addressGroup2 = addressLine2.split(' - ');
          const utilArea = buscaTypology.utilArea ? buscaTypology.utilArea[buscaTypology.utilArea.length - 1].toString() : '';
          const suites = buscaTypology.suites ? buscaTypology.suites[buscaTypology.suites.length - 1] : null;

          const data = {
            tipoavm: 'venda',
            endereco: addressLine1.split(',')[0],
            numero: addressLine1.replace(/\D/g, ''),
            bairro: addressGroup2[0],
            cidade: addressGroup2[1],
            estado: addressGroup2[2],
            tipologia: this.tipo,
            ano_construcao: yearOfConstruction,
            area_util: utilArea,
            dormitorios: bedrooms[bedrooms.length - 1],
            suites: suites,
            vagas: parkingLots[parkingLots.length - 1],
          };

          api.base.defaults.headers.Authorization = this.userLoggedIn.jwtToken;

          const response = (await api.avm.evaluation(data)).response;

          if (!response.content.avm.response) {
            throw new Error('Não foi possível obter a avaliação mercadológica.');
          }

          this.avm = response.content.avm.response;
          this.avmShow = !this.avmShow;
        }
      } catch (e) {
        this.erroAvm = true;
        console.error(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    resetAvm () {
      this.erroAvm = false;
    },
    changeReportFormat (format) {
      this.reportFormat = format;
    },
    exportXLS () {
      const splittedFullPath = this.$route.fullPath.split('/');
      const tabName = `cidadevirtual-${this.report.filter.routeName}-report`;
      const fileName = `${tabName}-${splittedFullPath[splittedFullPath.length - 1]}`;
      const documentConfig = {
        anchor: document.getElementById('exportAnchor'),
        format: 'xls',
        filename: fileName,
      };

      const headLine = [
        this.propertyTypeLabel(false),
        'Endereço',
        'Bairro',
        'Cidade',
        'Estado',
        'Área útil',
        'Dorms.',
        'Suítes',
        'Vagas',
        'Total de ofertas',
        'Ofertas Bossa',
        'Ofertas em outras imobiliarias',
        'Ofertas de acordo com o filtro',
      ].map(title => title.toUpperCase());

      const data = this.result.map(row => {
        const { condo, addressLine1, addressLine2, typology, offersCount, offers } = row;

        const utilArea = typology.utilArea ? `${this.minMaxTransformer(typology.utilArea)} m²` : '-';
        const bedRooms = typology.bedRooms ? this.minMaxTransformer(typology.bedRooms).toString() : '-';
        const suites = typology.suites ? this.minMaxTransformer(typology.suites).toString() : '-';
        const parkingLots = typology.parkingLots ? this.minMaxTransformer(typology.parkingLots).toString() : '-';
        const offerCount = typeof offersCount !== 'undefined' ? `${offersCount}` : 'N/D';
        const otherOffers = `${offers?.otherOffers}`;
        const bossaOffers = `${offers?.bossaOffers}`;
        const filterOffers = `${offers?.filterOffers}`;

        return [
          condo,
          addressLine1,
          addressLine2.split('-')[0],
          addressLine2.split('-')[1],
          addressLine2.split('-')[2],
          utilArea,
          bedRooms,
          suites,
          parkingLots,
          offerCount,
          otherOffers,
          bossaOffers,
          filterOffers,
        ];
      });

      const generatedDate = ['Gerado em:', this.report.relatorio.data]
      const lineBreak = ['\n', '\n']
      const filterTitle = ['Filtros utilizados:']
      const type = ['Tipo:', this.tipo ? this.tipo : "Não informado"]
      const values = ['Valores:', this.prices]
      const fase = ['Fase:', this.phase]
      const area = ['Área:', this.areaFormat]
      const age = ['Idade:', this.idadeFormat]
      const attributes = ['Características:', this.attributes]
      const dorms = ['Dormitórios:', this.dorms]
      const suites = ['Suítes:', this.suites]
      const parkingLots = ['Vagas:', this.parkingLots]

      const tabs = [
        {
          name: tabName,
          from: {
            array: [
              generatedDate,
              lineBreak,
              lineBreak,
              filterTitle,
              type,
              values,
              fase,
              area,
              age,
              attributes,
              dorms,
              suites,
              parkingLots,
              lineBreak,
              lineBreak,
              headLine,
              ...data,
            ],
          },
        },
      ];

      convert(documentConfig, tabs);
    },
    minMaxTransformer (arr) {
      const uniqueValues = new Set();

      arr.forEach(value => uniqueValues.add(value));

      const newArray = Array.from(uniqueValues);

      return (newArray.length >= 2) ? `${newArray[0]} a ${newArray[newArray.length - 1]}` : newArray[0];
    },
  },
  async created () {
    await this.fetchData(this.$route.params.hash).catch(console.log);
  },
};
</script>

<style scoped lang="scss">
.report__wrapper {
  position: absolute;
  height: calc(100vh - 75px);
  top: 75px;
  z-index: 3;
  background-size: cover;
}

.sidebar-wrapper {
  position: inherit;
  top: 0;
}

.report__search-filters,
.report__search-results {
  background-color: white;
}

h1 {
  font-weight: bolder;
  color: $bold-gray;
}

.primary--text {
  color: $primary-blue !important;
}

.report__search-filters {
  .primary--text {
    color: $primary-blue !important;
    caret-color: $primary-blue !important;
  }

  .v-btn {
    border: 2px solid $primary-blue;
    font-size: $button-font-size;
    font-weight: bold;
    color: $primary-blue;
    outline-color: $primary-blue !important;
  }

  .btn--active {
    background-color: $primary-blue;
    color: white;
  }

  .v-input__prepend-inner i.primary--text {
    color: $primary-blue !important;
    transform: none;
  }
}

.contentRelatorio {
  background-color: #fff;

  .contentheader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-bottom: solid 2px #e8e8e8;
    margin-top: 15px;

    h1 {
      font-size: 1.8em;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #3366cc;
      margin-bottom: 7px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
      margin-bottom: 15px;
    }
  }

  .contentDadosUserCliente {
    display: flex;
    align-items: center;
    max-width: 1255px;
    width: 100%;
    margin: auto;
    margin-top: 18px;

    .divider {
      width: 2;
      height: 41px;
      margin: 0 50px;
      background-color: #e8e8e8;
      display: block;
    }

    .cliente {
      .titulo {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 6px;
      }

      .nome {
        font-size: 20px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #3366cc;
        margin-bottom: 4px;
      }

      .email {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 0px;
      }
    }

    .userCreate {
      .titulo {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 8px;
      }

      .user {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 0px;
      }
    }
  }

  .contentCardsDetalhes {
    display: flex;
    justify-content: space-between;
    max-width: 1255px;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;

    .ResumoDaPesquisa {
      border-radius: 13px;
      border: solid 2px #e8e8e8;
      padding: 18px 15px 18px 15px;
      margin-top: 21px;
      width: 100%;
      max-width: 395px;
      .titulo {
        font-size: 17px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #3366cc;
        margin-bottom: 0px;
      }

      .contentDados {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 17px;

        .divider {
          margin: 0 7px 0 7px;
          width: 1px;
          height: 140px;
          background-color: #e8e8e8;
        }

        .dados {
          .dado {
            .titulo {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              margin-bottom: 5px;
            }

            .text {
              font-family: Lato;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              margin-bottom: 13px;
            }

            .ContentStatus {
              display: flex;
              justify-content: space-between;

              .faseImovel {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #707070;
              }
            }
          }
        }
      }
    }

    .resumoDaCidade {
      border-radius: 13px;
      border: solid 2px #e8e8e8;
      padding: 18px 28px 18px 22px;
      margin-top: 21px;
      width: 100%;
      margin-left: 10px;
      max-width: 508px;
      margin-right: auto;

      .titulo {
        font-size: 17px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #3366cc;
      }

      .tabelaCidade {
        thead {
          tr {
            td {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              color: #707070;
              min-width: 92px;
              padding-bottom: 20px;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              padding-bottom: 10px;
              padding-top: 10px;
            }

            .valor {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #3366cc;
            }
          }
        }
      }
    }
  }

  .resumoValores {
    border-radius: 13px;
    border: solid 2px #e8e8e8;
    padding: 18px 28px 18px 22px;
    margin-top: 21px;
    width: 100%;
    margin-left: 10px;
    max-width: 332px;
    margin-right: auto;
    background-color: #e8e8e8;

    .titulo {
      font-size: 17px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal;
      text-align: left;
      color: #3366cc;
      display: flex;
      align-items: center;
    }

    .contentValoresMedio {
      display: flex;
      border-radius: 13px;
      border: solid 1px #c4c4c4;
      padding: 12px 30px;
      justify-content: space-between;

      .ValoresMedio {
        .titulo {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #3366cc;
          margin-bottom: 5px;
        }

        .valor {
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          margin-bottom: 0px;
        }
      }
    }

    .ContentValorMaxMIn {
      display: flex;
      border-radius: 13px;
      border: solid 1px transparent;
      padding: 12px 30px;
      justify-content: space-between;
      margin-top: 30px;

      .ValorMaxMIn {
        .titulo {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          margin-bottom: 5px;
        }

        .valor {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          margin-bottom: 0px;
        }
      }
    }
  }

  .avmRelatorio {
    border-radius: 13px;
    border: solid 2px #e8e8e8;
    max-width: 1255px;
    width: 100%;
    margin: auto;
    padding: 9px 10px;
    display: flex;
    justify-content: center;

    .contentBtnAvm {
      .btnAvm {
        padding: 3px 30px;
        font-size: 0.7em;
        font-weight: bold;
        text-transform: uppercase;

        .isLoadingAvm {
          width: 17px !important;
          height: 17px !important;
        }
      }

      .errorText {
        font-size: 16px;
        color: #707070;
      }
    }

    .contentDadosAvaliacao {
      width: 100%;
      padding: 4px 6px 0px;

      .titulo {
        font-family: Lato;
        font-size: 17px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #3366cc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sair {
          width: 36px;
          height: 36px;
          padding: 10px 10px 10px 10px;
          border: solid 0.5px #e8e8e8;
          font-size: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6b6b6b;
          cursor: pointer;
        }
      }
    }

    .dadosAvaliacao {
      display: flex;
      width: 100%;
      align-items: flex-end;

      .avaliacao {
        display: flex;
        flex-direction: column;

        .tituloTipoAvm {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #3366cc;
          margin-bottom: 11px;
        }

        .avaliacaoValor {
          display: flex;

          .valor {
            margin-right: 30px;

            &:nth-last-child(1) {
              padding-right: 30px;
              border-right: 1px solid #ababab;
            }

            .avaliacaoTitulo {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              margin-bottom: 6px;
            }

            .valoresAVM {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.21;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              margin-bottom: 0px;
            }

            .valoresAvmDestaque {
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #707070;
              margin-bottom: 0px;
            }
          }
        }

        .tituloTipoAvmDestaque {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: left;
          color: #3366cc;
          margin-bottom: 27px;
        }
      }
    }
  }

  .resumoCidadeEValores {
    overflow: hidden;
    border-radius: 13px;
    border: solid 2px #e8e8e8;
    max-width: 1255px;
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: 10px;

    .resumoCidade {
      //margin-right: 10px;
      padding: 11px 18px 0px;
      //max-width: 916px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;

      .titulo {
        font-size: 17px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #3366cc;
      }

      .small-text {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #707070;
      }

      .contentTableCidade {
        position: relative;
        //left: -18px;
        //width: 914px;

        .table {
          width: 100%;

          .tableThead {
            tr {
              padding: 0px 18px;

              th {
                text-align: center;
                min-width: 111px;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                color: #707070;
                padding-bottom: 6px;
              }
            }
          }

          .tableTbody {
            tr {
              padding: 0px 18px;

              td {
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #3366cc;
                padding-bottom: 15px;
                padding-top: 15px;

                &:nth-child(1) {
                  font-weight: normal;
                  text-align: left;
                  color: #707070;
                  //padding-left: 18px;
                }
              }

              border-bottom: solid 1px #e8e8e8;

              &:nth-last-child(1) {
                border-bottom: solid 0px #e8e8e8;
              }
            }
          }

          .tableTfoot {
            &::after {
              content: "";
              width: 500px;
              height: 60px;
              background: #e8e8e8;
              position: absolute;
              left: 96%;
              bottom: 0;
            }
            background-color: #e8e8e8;

            tr {
              td {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
                color: #707070;
                padding-bottom: 10px;
                padding-top: 10px;

                &:nth-child(1) {
                  font-weight: bold;
                  text-align: center;
                  //padding-left: 18px;
                }
              }
            }
          }
        }
      }
    }
    .resumoValores {
      border: 0;
      .titulo {
        font-size: 1.2em;
        margin-bottom: 0;
        font-weight: 900;
      }
    }
  }

  .ContentResult {
    z-index: 1000;

    .result {
      background-color: #fff;
      margin-top: -75px;
      margin-bottom: 30px;
      padding-bottom: 20px;

      .tituloResult {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #6b6b6b;
        margin-bottom: 15px;
        margin-top: 30px;
        padding-left: 120px;

        span {
          font-weight: 800;
        }
      }
    }
  }
}

.fundoLoading {
  background-image: url("../../assets/images/cidadeVirtual.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .contentLoading {
    background-color: rgba(0, 0, 0, 0.338);
    padding: 40px 40px 30px;
    border-radius: 20px;

    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.7);

    .cidadeVirtual {
      font-family: "Montserrat", sans-serif;
      font-size: 2.5em;
      font-weight: bold;
      line-height: 0.9em;
      color: #fff;
    }

    .SeuProcimoImovel {
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: rgb(227, 225, 225);
    }

    .textEmbreve {
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: rgb(227, 225, 225);
    }

    .btnVerImovel {
      padding: 0 23px !important;
      height: 36px;
      min-width: 64px;
      font-weight: bold;
    }
  }
}

.containerResumoDaPerquisaVendedor {
  width: 320px;
  padding: 11px 18px 0px;
  margin-top: 21px;

  .titulo {
    font-size: 17px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: #3366cc;
    margin-bottom: 12px;
  }

  .conteinerResumoDados {
    width: 123px;

    .titulo {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
      margin-bottom: 6px;
    }

    .text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
      margin-bottom: 11px;
    }
  }
}

.containerCondominioRelatorio {
  border-radius: 13px;
  border: solid 2px #e8e8e8;
  margin-top: 21px;
  margin-left: 10px;
  display: flex;
  overflow: hidden;

  .containerImgCondo {
    height: 160px;
    img {
      width: 255px;
      border-right: solid 2px #e8e8e8;
    }
  }

  .CondominioRelatorio {
    padding: 10px 24px 0px;

    .titulo {
      font-size: 18px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #3366cc;
      margin-bottom: 5px;
    }

    .text {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
      margin-bottom: 5px;
    }

    .conteinerDadosCondo {
      display: flex;
      margin-top: 16px;

      .DadosCondo {
        padding-right: 16px;
        margin-right: 16px;
        border-right: 1px solid #ababab;

        &:nth-last-child(1) {
          padding-right: 0px;
          margin-right: 0px;
          border-right: 0px solid #ababab;
        }

        .label {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: center;
          color: #707070;
          margin-bottom: 1.5px;
        }

        .valor {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #3366cc;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.wrapper-btn-presentation {
  div:first-of-type {
    padding-left: 120px;
  }

  .btn-presentation {
    @apply p-4;
    font-family: Lato, sans-serif;
    font-weight: 800;
    font-size: 16px;
    color: #606473;
    cursor: pointer;

    span {
      &.active {
        border-bottom: solid 2px #5A7FED;
      }

    }
  }
}


</style>
<style lang="scss">
.v-application {
  background-color: #fff !important;
}

.contentRelatorio {
  .ContentResult {
    .result {
      z-index: 1000000;
      max-width: 1255px;
      width: 100%;
      margin: auto;
      border-radius: 15px;
      overflow: hidden;
      background-color: #fff;
      position: relative;

      & > div {
        z-index: 1000000;
        max-width: 1255px;
        width: 100%;
        margin: auto;
        border-radius: 15px;
        overflow: hidden;
      }
    }
  }
}
</style>
