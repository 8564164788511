
<template>
  <img
    class="property-image"
    :src="src"
    @error="replaceByDefault"
  >
</template>

<script>

import default404 from '@/assets/images/404.jpeg';
import default404Mobile from '@/assets/images/404-mobile.jpeg';
import { isMobile } from '@/utils/user-agent';
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      handled: false,
    };
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = isMobile ? default404Mobile : default404;
    },
  },
};
</script>