<template>
  <Autocomplete
    id="region-autocomplete"
    :focus="focus"
    :resettable="resettable"
    @clear="clearSearch"
  >
    <v-autocomplete
      id="regionName"
      v-model="regionLocation"
      class="region"
      :search-input.sync="regionSearchInput"
      no-filter
      dense
      borderless
      hide-details
      hide-no-data
      :full-width="true"
      return-object
      placeholder="Digite um bairro ou região"
      :items="regionSuggestions"
      item-text="subdistrict"
      :append-icon="icon"
      item-value="id"
      attach="#region-autocomplete"
      :menu-props="{ zIndex: 999 }"
      @change="onSelection"
      @update:search-input="fetchRegions"
      @focusin="focus = true"
      @focusout="focus = false"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Digite um bairro ou região...
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.subdistrict" />
          <v-list-item-subtitle
            v-text="`${item.district} - ${item.city}, ${item.state}`"
          />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </Autocomplete>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Autocomplete from './Autocomplete';

export default {
  name: 'RegionAutocomplete',
  components: { Autocomplete },
  model: {
    prop: 'model',
    event: 'region',
  },
  props: {
    currentCity: {
      type: String,
      default: 'SP',
    },
    model: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    focus: false,
    regionLocal: null,
    regionLocation: null,
    regionSearchInput: null,
    regionSuggestions: [],
    timeGap: null,
  }),
  computed: {
    region: {
      get () {
        return this.regionLocal;
      },
      set (value) {
        this.regionLocal = value;
        this.$emit('region', value);
      },
    },
    resettable () {
      return !!(this.regionSearchInput && this.regionSearchInput.length);
    },
    icon () {
      return this.regionSuggestions && this.regionSuggestions.length ? '$dropdown' : '';
    },
  },
  watch: {
    model (value) {
      if (!value) {
        this.clearSearch();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchRegionSuggestions (dispatch, payload) {
        return dispatch(`${this.$route.name}/fetchRegionSuggestions`, payload); 
      },
      getRegionById (dispatch, payload) {
        return dispatch(`${this.$route.name}/getRegionById`, payload); 
      },
    }),
    ...mapMutations({
      setRegionSelected: 'search/SET_REGION_SELECTED',
    }),
    onSelection (value) {
      if (!value) return;
      this.$emit('select', value);
      this.getRegionByLocation(value.id);
      this.setRegionSelected(value.subdistrict)
    },
    clearSearch () {
      clearTimeout(this.timeGap);
      this.regionSearchInput = null;
      this.region = null;
      this.regionSuggestions = [];
      this.$emit('clear');
      this.setRegionSelected(null)
    },
    async getRegionByLocation (regionId) {
      const region = await this.getRegionById({ regionId, domain: this.$route.name });
      const lat = region.center[0][1];
      const lng = region.center[0][0];

      region.center[0][0] = lat;
      region.center[0][1] = lng;

      region.coordinates.forEach(coords => {
        const lat = coords[1];
        const lng = coords[0];

        coords[0] = lat;
        coords[1] = lng;
      });

      this.region = region;
    },
    fetchRegions (query) {
      const city = this.currentCity === 'SP' ? 'São Paulo' : 'Rio de Janeiro';

      clearInterval(this.timeGap);

      if (query && query.length > 3) {
        this.setTimeGap(query, city);
      }
      else {
        this.regionSuggestions = [];
      }
    },
    setTimeGap (query, city) {
      this.timeGap = setTimeout(async () => {
        this.regionSuggestions = await this.fetchRegionSuggestions({
          query,
          city,
          domain: this.$route.name,
        }) || [];
        this.$emit('suggestions', this.regionSuggestions);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.region {
  @apply my-auto;
  ::v-deep .v-input__slot {
    @apply flex;
    &:after, &:before {
      display: none !important;
    }
  }
}
#region-autocomplete {
  ::v-deep .v-menu__content {
    @apply mt-2 w-full;
    max-width: none !important;
  }
  ::v-deep .v-input {
    &__append-inner {
      cursor: pointer;
    }
  }
}
</style>
