import { css } from '@/core/theme';

const root = css({

  variants: {

    size: {

      'default': {
        height: '20px',
      },

      'large': {
        height: '24px',
      },
    },

    hasLabel: {

      true: {
        width: 'fit-content',
        display: 'grid',
        alignItems: 'center',
        columnGap: '$xs',
      },

      false: {
        maxWidth: '40px',
      },
    },

    labelPosition: {

      'left': {
        gridTemplateColumns: 'auto 40px',
        justifyContent: 'flex-start',
      },

      'right': {
        gridTemplateColumns: '40px auto',
      },
    },
  },

  position: 'relative',
  display: 'inline-block',

  '&:hover': {
    cursor: 'pointer',
  },
});

const slider = css({

  variants: {

    size: {

      'default': {
        borderRadius: '20px',

        '&:before': {
          width: '20px',
          height:' 20px',
        },
      },

      'large': {
        borderRadius: '24px',

        '&:before': {
          width: '24px',
          height:' 24px',
        },
      },
    },

    hasLabel: {

      true: {
        width: '40px',
      },

      false: { },
    },

    labelPosition: {

      'left': {
        gridColumn: 2,
      },

      'right': {
        gridColumn: 1,
      },
    },
  },

  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: '$neutral-grey-2',
  boxShadow: 'inset 0px 1.27941px 8.52941px rgba(0, 0, 0, 0.15)',
  transition: 'all .25s',
  
  '&:before': {
    position: 'absolute',
    content: '',
    left: 0,
    bottom: 0,
    backgroundColor: '$neutral-grey-4',
    transition: 'all .25s',
    borderRadius: '50%',
  },
});

const checkbox = css({
  opacity: 0,
  width: 0,
  height: 0,
  display: 'none',

  [`&:checked + .${slider}`]: {
    backgroundColor: '$brand-lighter',
    boxShadow: 'inset 0px 1.27941px 8.52941px rgba(0, 0, 0, 0.15)',
  },

  [`&:checked + .${slider}:before`]: {
    backgroundColor: '$brand-default',
    transform: 'translateX(20px)',
  },
});

const label = css({
  
  variants: {

    labelPosition: {

      'left': {
        gridColumn: 1,
      },

      'right': {
        gridColumn: 2,
      },
    },
  },

  userSelect: 'none',
  display: 'flex',
});

export const styles = {
  root,
  slider,
  checkbox,
  label,
};
