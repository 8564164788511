var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{class:_setup.styles.root({ variant: _vm.variant }).className},[_c('div',{staticClass:"flex flex-col gap-2 pt-4"},[_c(_setup.Typography,{class:_setup.styles.logo({ variant: _vm.variant }).className,attrs:{"id":"logo-home","tag":"router-link","variant":"header-xl-secondary","to":"/home"}},[_vm._v(" Cidade Virtual ")]),_c(_setup.Typography,{attrs:{"tag":"p","variant":"body-xs","color":"brand-lighter"}},[_vm._v(" Atualizado em "+_vm._s(_setup.updatedStore.updatedAt)+" ")])],1),_c('nav',{ref:"navRef",class:_setup.styles.nav({ hidden: _setup.isHomeVariant }).className},[_vm._l((_setup.activeNavbarMenus),function(item){return _c('div',{key:_setup.getItemKey(item),class:_setup.styles.item({
        variant: _vm.variant,
        active: item && (_setup.route.path === item.href),
      }).className,attrs:{"data-key":_setup.getItemKey(item)},on:{"mouseenter":_setup.onMouseEnter,"mouseleave":_setup.onMouseLeave}},[_c('router-link',{attrs:{"to":item.href},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c(_setup.Typography,{attrs:{"variant":"body-table","tag":"a","href":href},on:{"click":function($event){return _setup.onNavigate(navigate, $event, item)}}},[_vm._v(" "+_vm._s(item && item.label)+" ")])]}}],null,true)}),_c('div',{class:_setup.styles.panel({
          active: _setup.active === _setup.getItemKey(item),
        }).className,style:({
          left: `${_setup.bounds.left}px`,
        })},[_c(_setup.NavbarPanel,{attrs:{"data":item,"bnsir-broker":_vm.bnsirBroker},on:{"navigate":_setup.onNavigate}})],1)],1)}),_c('div',{class:_setup.styles.item({
        variant: _vm.variant,
      }).className},[_c('router-link',{attrs:{"to":_setup.NAVBAR_MENU_ITEMS.LOGOUT.href},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c(_setup.Typography,{attrs:{"tag":"a","variant":"body-table","href":href},on:{"click":function($event){return _setup.onNavigate(navigate, $event, _setup.NAVBAR_MENU_ITEMS.LOGOUT)}}},[_vm._v(" "+_vm._s(_setup.NAVBAR_MENU_ITEMS.LOGOUT.label)+" ")])]}}])})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }