const gtmKeys = {
  buyer: 'Comprador',
  lead: 'Consultor',
  rent: 'Locador',
};

const gtmType = {
  radius: 'Localização',
  region: 'Região',
  area: 'Desenho',
};

const currentCityName = {
  RJ: 'Rio de Janeiro',
  SP: 'São Paulo',
};

const propertyTypeName = {
  1: 'Apartamento',
  2: 'Casa',
  3: 'Cobertura',
};

const createGTMData = data => {
  const {
    routeName,
    filterMode,
    currentCity,
    attributesFilter,
  } = data;

  return {
    event: 'select_content',
    content_type: gtmKeys[routeName],
    mode: gtmType[filterMode],
    city: currentCityName[currentCity],
    property_type: propertyTypeName[attributesFilter.propertyType],
    price_min: attributesFilter.priceMin || null,
    price_max: attributesFilter.priceMax || null,
    area_min: attributesFilter.areaRange ? attributesFilter.areaRange[0] : null,
    area_max: attributesFilter.areaRange ? attributesFilter.areaRange[1] : null,
    bedrooms: attributesFilter.bedrooms || null,
    parking_lots: attributesFilter.parkingLots || null,
    suits: attributesFilter.suites || null,
  };
};

export default data => {
  const { filterMode, searchTerm, address } = data;

  const gtmData = createGTMData(data);
  let district;

  if (address && address.address_components) {
    const filteredDistrict = address.address_components.filter(component => 
      component.types.includes('sublocality_level_1') || component.types.includes('administrative_area_level_4'),
    ).pop();

    district = filteredDistrict ? filteredDistrict.long_name : null;
  }

  if (filterMode !== 'area') {
    gtmData.district = filterMode === 'radius' ? district : searchTerm;
  }

  return gtmData;
};
