const ownersLoneOwner = [
{
  "unit": "AP 11",
  "taxPayerNumber": "0410290271-6",
  "referenceYear": 2020,
  "details": [
    {
      "name": "Dono 1",
      "document": "00001382827733",
      "isBlocked": false,
      "order": 1
    }
  ]
}]

const ownersTwoOwners = [
{
  "unit": "AP 11",
  "taxPayerNumber": "0410290271-6",
  "referenceYear": 2020,
  "details": [
    {
      "name": "Dono 1",
      "document": "00001382827733",
      "isBlocked": false,
      "order": 1
    },
    {
      "name": "Dono 2",
      "document": "00001382827734",
      "isBlocked": false,
      "order": 2
    },
  ]
}]

const noOwners = [
{
  "unit": "AP 11",
  "taxPayerNumber": "0410290271-6",
  "referenceYear": 2020,
  "details": [],
  "transaction": [{
    "year": 2022,
    "month": 11,
    "registryNumber": 6206,
    "registryOffice": "10º Cartório de registro de imóvel"
  }]
}]

const oneIsBlocked = [
  {
    "unit": "AP 11",
    "taxPayerNumber": "0410290271-6",
    "referenceYear": 2020,
    "details": [
      {
        "name": "Bloqueado pelo titular (LGPD)",
        "document": "**************",
        "isBlocked": true,
        "order": 1
      }
    ],
    "transaction": null
  }]

const twoIsBlocked = [
{
  "unit": "AP 11",
  "taxPayerNumber": "0410290271-6",
  "referenceYear": 2020,
  "details": [
    {
      "name": "Bloqueado pelo titular (LGPD)",
      "document": "**************",
      "isBlocked": true,
      "order": 1
    },
    {
      "name": "Bloqueado pelo titular (LGPD)",
      "document": "**************",
      "isBlocked": true,
      "order": 2
    },
  ],
  "transaction": null
}]

const onlyOneIsBlocked = [
  {
    "unit": "AP 11",
    "taxPayerNumber": "0410290271-6",
    "referenceYear": 2020,
    "details": [
      {
        "name": "Bloqueado pelo titular (LGPD)",
        "document": "**************",
        "isBlocked": true,
        "order": 1
      },
      {
        "name": "Dono 2",
        "document": "00001382827734",
        "isBlocked": false,
        "order": 2
      },
  
    ]
  }]

export {
  noOwners,
  oneIsBlocked,
  onlyOneIsBlocked,
  ownersLoneOwner,
  ownersTwoOwners,
  twoIsBlocked
}
