<script setup>

import { computed } from 'vue';

const props = defineProps({
  hovered: {
    type: Boolean,
    default: false,
  },
});

const colors = {
  default: '#303A56',
  hovered: '#6B8CEF',
};

const color = computed(() => props.hovered ? colors.hovered : colors.default);

</script>

<template>
<svg xmlns="http://www.w3.org/2000/svg" width="67" height="54" viewBox="0 0 67 54" fill="none">
<path d="M8.73117 49.1399H6.53251C6.3752 49.1399 6.24768 49.2675 6.24768 49.4248V52.7151C6.24768 52.8725 6.3752 53 6.53251 53H65.2049C65.3622 53 65.4898 52.8725 65.4898 52.7151V22.8492V8.23882V3.70062C65.4898 3.54331 65.3622 3.41579 65.2049 3.41579H56.8298C56.6725 3.41579 56.545 3.54331 56.545 3.70062V6.57598C56.545 6.73329 56.4175 6.86081 56.2602 6.86081H50.344H43.0497C42.8924 6.86081 42.7649 6.98833 42.7649 7.14564V21.0451C42.7649 21.2024 42.6374 21.3299 42.4801 21.3299H37.5377C37.3804 21.3299 37.2529 21.2024 37.2529 21.0451V2.32261C37.2529 2.1653 37.1254 2.03778 36.968 2.03778H22.3796C22.2223 2.03778 22.0948 2.1653 22.0948 2.32261V20.6409V25.4639V30.287V34.8243C22.0948 34.9816 21.9673 35.1091 21.81 35.1091H9.30083C9.14352 35.1091 9.016 35.2367 9.016 35.394V48.8551C9.016 49.0124 8.88848 49.1399 8.73117 49.1399Z" fill="#E3E8F5"/>
<path d="M20.378 29.2492V33.7865C20.378 33.9438 20.2505 34.0714 20.0932 34.0714H7.58403C7.42673 34.0714 7.29921 34.1989 7.29921 34.3562V47.8173C7.29921 47.9746 7.17168 48.1022 7.01438 48.1022H4.81571C4.65841 48.1022 4.53088 48.2297 4.53088 48.387V52.3897C4.53088 52.547 4.6584 52.6745 4.81571 52.6745H64.3211H64.0578C63.9005 52.6745 63.773 52.547 63.773 52.3897V21.8114V7.20104V2.66284C63.773 2.50553 63.6454 2.37801 63.4881 2.37801H55.113C54.9557 2.37801 54.8282 2.50553 54.8282 2.66284V5.5382C54.8282 5.69551 54.7007 5.82303 54.5434 5.82303H48.6272H41.3329C41.1756 5.82303 41.0481 5.95055 41.0481 6.10786V20.0073C41.0481 20.1646 40.9206 20.2921 40.7633 20.2921H35.8209C35.6636 20.2921 35.5361 20.1646 35.5361 20.0073V1.28483C35.5361 1.12752 35.4086 1 35.2513 1H20.6628C20.5055 1 20.378 1.12752 20.378 1.28483V19.6031V24.4261V29.2492Z" stroke="#A5B2D9" stroke-width="0.708785"/>
<path d="M65.7625 52.8912L1.41736 52.8912" stroke="#A5B2D9" stroke-width="1.06318" stroke-linecap="round"/>
<rect x="18.5332" y="13.1626" width="36.4266" height="39.7381" rx="1.20927" :fill="color"/>
<rect x="37.9672" y="16.6712" width="4.41534" height="14.3499" rx="0.291391" transform="rotate(90 37.9672 16.6712)" fill="#A5B2D9"/>
<rect x="46.7979" y="24.3981" width="4.41534" height="23.1806" rx="0.291391" transform="rotate(90 46.7979 24.3981)" fill="white"/>
<path d="M20.7679 44.452L28.6036 38.7534L40.0008 46.589L49.9734 31.6301" stroke="#768AC6" stroke-linecap="round"/>
<ellipse cx="40.0009" cy="45.8767" rx="2.13699" ry="2.13699" fill="#D9D9D9"/>
<ellipse cx="28.6035" cy="38.7534" rx="2.13699" ry="2.13699" fill="#D9D9D9"/>
<ellipse cx="49.9734" cy="31.6301" rx="2.13699" ry="2.13699" fill="#D9D9D9"/>
</svg>
</template>