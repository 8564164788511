const formatCurrency = (value) => {
  if (value) {
    return value.toLocaleString('pt-BR', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'BRL',
    });
  }
};

export default formatCurrency;
