<template>
   <base-card>
    <div class="flex flex-column gap-1">
        <Typography
            variant="header-large"
            color="neutral-grey-6"
          >
            Sociedades
          </Typography>
          <Typography
            variant="body-xs"
            color="neutral-grey-5"
          >
            Sociedades registradas
          </Typography>
      </div>
      <v-data-table
        v-if="Object.keys(normalizedData).length > 0"
        :headers="headers"
        :items="normalizedData"
        :items-per-page="itemsPerPage"
        sort-by="Ranking"
        :sort-desc="true"
        data-test-id="partnerships-table"
      >
        <template v-slot:[`item.Ranking`]="{ item }" data-test-id="partnerships-table-ranking">
          {{ formatYear(item.Ranking) }}
        </template>
      </v-data-table>
      <div v-else>
        <p>Não há dados para serem exibidos.</p>
      </div>
    </base-card>
</template>

<script>

import BaseCard from '@/components/cards/base-card.vue';
import InfoMarkerCard from '@/components/cards/info-marker-card.vue';
import { INFO_STATUS } from '@/common/constants';
import { getLabel, formatYear } from '@/utils';
import Typography from '../base/Typography';


export default {
  name: 'PartnerShips',
  components: {
    BaseCard,
    InfoMarkerCard,
    Typography
},
  props: ['rawData'],
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        {
          text: 'Nome',
          value: 'Name',
          align: 'left',
        },
        {
          text: 'Documento',
          value: 'DocumentFormatted',
          align: 'center',
          width: 200,
        },
        {
          text: 'Última atualização',
          value: 'Ranking',
          align: 'center',
          sortBy: 'Ranking',
          width: 200
        },
      ],
    };
  },
  computed: {
    normalizedData() {
      if (this.rawData.PartnerShips) {
        return this.rawData.PartnerShips.map(item => (
          {
            ...item,
            Status: getLabel(INFO_STATUS, item.Status),
          }
        ));
      }

      return [];
    },
  },
  methods: {
    formatYear,
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}

::v-deep th {
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #6879A6 !important;
}

::v-deep td {
  font-family: Lato !important;
  font-size: 14px !important;
}

::v-deep tbody tr {
  height: 68px!important;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child) {
  color: var(--colors-neutral-grey-5);
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:first-child)){
  color: #777;
  text-align: center;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.button-width {
  min-width: 156px!important;
  height: 32px;
  padding: 10px 16px;
  gap: 10px;
}

:deep(.v-data-table thead tr th:nth-child(3) span) {
  padding-left: 16px;
}

:deep(.v-data-table .v-data-footer__select .v-select) {
  margin: 13px 0 13px 16px;
}
:deep(.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon) {
  font-size: 32px;
}

:deep(.v-data-table .v-data-footer__icons-before .v-btn:last-child){
  margin-right: 0px;
}

:deep(.v-data-table .v-data-footer__icons-after .v-btn:first-child){
  margin-left: 0px;
}

:deep(.v-data-table .v-data-footer__pagination) {
  margin: 0px 16px;
}

:deep(.v-data-table .v-data-footer) {
  display: flex;
  align-items: center;
  border-top: none!important;
  height: 32px;
  margin-top: 16px;
}

:deep(.v-data-table .v-data-footer .v-data-footer__select) {
  height: 32px;
  margin-right: 0px!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th) {
  border-bottom: none!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th i){
  margin-bottom: 5px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child)){
  margin: 15px 0px;
}

:deep(.v-data-table .v-data-table__wrapper) {
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}
</style>
