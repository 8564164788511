<template>
  <button class="button"
          :class="{
            'button--active': active,
            'button--rounded': rounded,
          }"
          :disabled="isDisabled"
          @click="onButtonClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled === true;
    },
  },
  methods: {
    onButtonClick(...args) {
      this.$emit('click', ...args);
    },
  },
};
</script>

<style lang="scss" scoped>

  $button_color_active: #4662B9;
  $button_color_hover: #F0F4FB;
  $button_color_focus: #D1DCF3;

  .button {
    @apply border border-solid rounded-md font-bold p-0 m-0 px-6 py-1;
    border-color: $button_color_active;
    background-color: white;
    color: $button_color_active;
    min-height: 48px;
    font-family: 'Lato', sans-serif;

    &:active:hover {
      background-color: $button_color_focus;
    }

    &:hover {
      background-color: $button_color_hover;
    }


    &--rounded {
      @apply rounded-full;
    }

    &--active {
      @apply text-white;
      background-color: $button_color_active;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

</style>
