<template>
  <div>
    <div
      v-if="responseError || hasPreSignUpError"
      class="alert-error"
      data-test="login-error"
    >
      <error-icon class="alert-error-icon" />

      <span class="alert-error-message">
        {{ hasPreSignUpError ? 'Usuário ou senha inválido(s)' : responseError }}
      </span>
    </div>

    <form
      class="input-container-wrapper"
      @submit.prevent="signInWithEmailAndPassword"
    >
      <TextField
        id="loginEmail"
        v-model="email"
        :error="emailError"
        type="email"
        label="E-mail"
        placeholder="Digite seu e-mail"
        @change="validateEmail"
      />

      <TextField
        id="loginPassword"
        v-model="password"
        :error="passwordError"
        type="password"
        label="Senha"
        placeholder="Digite sua senha"
        @keyup="validatePassword"
      />

      <Button
        full-width
        :disabled="loading || !!emailError || !!passwordError"
        variant="primary"
        type="submit"
      >
        {{ loading ? 'Entrando...' : 'Entrar' }}
      </Button>
    </form>
  
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import ErrorIcon from '@/components/icons/ErrorIcon.vue';
import TextField from '@/components/base/TextField';
import Button from '@/components/base/Button';

import { VALIDATION } from '@/common/constants';
import { mapGetters } from 'vuex';

export default {

  name: 'LoginEmail',

  components: {
    ErrorIcon,
    GoogleTagManager,
    TextField,
    Button,
  },

  data () {
    return {
      email: null,
      password: null,
      responseError: null,
      emailError: null,
      passwordError: null,
      loading: false,
      gtmEvent: null,
    };
  },

  computed: {
    ...mapGetters('amplify', ['hasPreSignUpError']),
  },

  methods: {

    validateEmail (event) {

      const { target } = event;
      const validate = VALIDATION.email.test(target.value);

      if (!validate) {
        this.emailError = 'Digite um endereço de e-mail válido.';
      } else {
        this.emailError = null;
      }
    },

    validatePassword (event) {

      const { target } = event;

      if (target.value.length < 4) {
        this.passwordError = 'Digite uma senha válida.';
      } else {
        this.passwordError = null;
      }
    },

    async signInWithEmailAndPassword () {

      try {

        this.gtmEvent = {
          event: 'login',
          type: 'form',
        };

        this.loading = true;

        const user = await this.$auth.signIn(this.email, this.password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.$store.commit('amplify/SET_USER', user);
          await this.$router.push('/login/first-access');

          return;
        }

        this.$router.push('/home');
      } catch (err) {

        this.responseError = 'Usuário ou senha inválido(s).';
      } finally {
    
        this.loading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>

.input-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 142px;
  margin-top: 24px;
  row-gap: 24px;
}

.alert-error {
  @apply flex justify-start flex-wrap items-center;
  @apply rounded-md;
  @apply py-4 mt-4;

  background: #FFEDED;

  &-icon {
    @apply ml-4 mr-2;
  }

  &-message {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #707070;
  }
}

</style>
