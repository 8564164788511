import { css } from '@/core/theme';

const root = css({
  width: '580px',
  boxShadow: '$medium',
  backgroundColor: '$neutral-white',
  borderRadius: '0 0 5px 5px',
  overflow: 'hidden',
});

const head = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$large',
  borderBottom: '1px solid $neutral-grey-2',

  '& > h2': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '$medium',

    '& > svg': {
      display: 'inline-flex',
      width: '64px',
      height: 'auto',
    },
  },
});

const body = css({
  paddingBottom: '$large',
  
  '& > h3': {
    padding: '$medium $large 0',
  },
});

const list = css({
  padding: '0 !important',
  marginTop: '$small',
});

const item = css({

  variants:  {

    active: {

      true: {
        backgroundColor: '$additional-blue-1',

        '& > a': {
          color: '$highlight-default !important', // `!important` because Vuetify

          '& > svg path': {
            fill: '$highlight-default',
          },
        },
      },

      false: { },
    },
  },

  transition: 'all .25s',

  '& > a': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '$nano $large',
    color: '$brand-dark !important', // `!important` because Vuetify
    transition: 'all .25s',

    '& > svg': {
      width: '16px',

      '& > path': {
        fill: '$highlight-light',
      },
    },

    '&::after': {
      content: ' ',
      borderBottom: '1px solid $neutral-grey-2',
      position: 'absolute',
      bottom: 0,
      width: 'calc(100% - 64px)',
    },
  },

  '&:hover': {
    backgroundColor: '$highlight-lighter',

    '& > a': {
      color: '$highlight-light !important', // `!important` because Vuetify
    },
  },

  '&:last-child': {

    '& > a': {
      
      '&::after': {
        display: 'none',
      },
    },
  },
});

export const styles = {
  root,
  head,
  body,
  list,
  item,
};
