<template>
  <aside
    id="sidebar"
    class="relative h-full z-10"
  >
    <v-overlay
      :z-index="-1"
      :value="useExpandedLayout"
    />

    <div
      v-show="!collapsedSidebar"
      :class="['sidebar', 'flex', 'flex-col', 'h-full']"
    >
      <router-view />
    </div>

    <div
      v-show="useMapFilter"
      class="sidebar-toggle-anchor absolute"
      :class="{'sidebar-toogle-collapsed': collapsedSidebar}"
    >
      <span class="sidebar-toggle-hide relative sidebar-toggle">
        <v-btn
          class="sidebar-hide-toggle-button sidebar-toggle-button"
          tile
          width="32px"
          height="34px"
          @click="toggleCollapseSidebar"
        >
          <v-icon
            v-if="collapsedSidebar"
            class="font-extrabold"
            large
          >
            mdi-chevron-right
          </v-icon>

          <v-icon
            v-else
            class="font-extrabold"
            large
          >
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </span>

      <span
        v-show="result && Object.values(result).length"
        class="d-none sidebar-toggle-expand mt-2 relative sidebar-toggle"
      >
        <v-btn
          class="sidebar-expand-toggle-button sidebar-toggle-button p-2"
          tile
          width="44px"
          height="44px"
          @click="toggleExpandSidebar"
        >
          <v-icon
            v-if="expandedSidebar"
            class="font-extrabold"
            large
          >
            mdi-minus
          </v-icon>

          <v-icon
            v-else
            class="font-extrabold"
            large
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </span>
    </div>
  </aside>
</template>

<script>

import { mapState, mapActions } from 'vuex';

export default {

  name: 'Sidebar',

  computed: {

    ...mapState('core', [
      'collapsedSidebar',
      'expandedSidebar',
      'useMapFilter',
    ]),

    ...mapState({

      result (state, getters) {
        return getters[`${this.$route.name}/result`]; 
      },
    }),

    useExpandedLayout () {

      return (
        !this.collapsedSidebar
        && this.expandedSidebar
        && this.result
        && Object.values(this.result).length
      );
    },
  },

  methods: {

    ...mapActions({
      toggleExpandSidebar: 'core/toggleExpandSidebar',
      toggleCollapseSidebar: 'core/toggleCollapseSidebar',
    }),
  },
};

</script>
<style lang="scss" scoped>
#sidebar {
  min-width: 474px;
}

.sidebar-toogle-collapsed {
  left: 0;
}
</style>