import base from './base';

export const useOpenSearchService = () => {

  return {

    async listings (payload) {

      const response = await base.get('open-search/listings', {
        params: payload,
      });
  
      return response && response.data;
    },

    async attributes () {

      const response = await base.get('open-search/attributes');

      return response && response.data;
    },

    async districts () {

      const response = await base.get('open-search/districts');

      return response && response.data;
    },

    async sellers (city, sellerSubstring) {

      const response = await base.get('open-search/sellers', {
        params: {
          city,
          sellerSubstring,
        },
      });

      return response && response.data;
    },
  };
};
