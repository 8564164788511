import {
  applySellerSummary,
  fetchClientsSearchSummary,
  fetchSummary,
} from './utils';

import {
  constants as summaryResultConstants,
  dynamics as summaryResultDynamics,
} from '@/domain/Summary/SummaryResultBlock';

import { SummaryBlock } from '@/utils/SummaryBlock';

import entityHydrator from '@/utils/entityHydrator';

const initialState = {
  summary: null,
  region: null,
  loading: false,
};

const getters = {
  summary: state => state.summary,
  resultBlock: state => {
    return state.summary ? entityHydrator(new SummaryBlock(state.summary), summaryResultConstants, summaryResultDynamics) : null;
  },
  isLoading: state => state.loading,
};

const mutations = {
  FETCH_SEARCH_SUMMARY_SUCCESS () {},
  FETCH_SEARCH_SUMMARY_ERROR (state, error) {
    state.error = error;
  },
  RESET_SEARCH_SUMMARY (state) {
    state.summary = null;
  },
  SET_SEARCH_SUMMARY (state, summary) {
    state.summary = summary;
  },
  SET_REGION (state, region) {
    state.region = region;
  },
  SET_LOADING (state, status) {
    state.loading = status;
  },
};

const actions = {
  applySellerSummary,
  fetchClientsSearchSummary,
  fetchSummary,
  setRegion (context, payload) {
    context.commit('SET_REGION', payload);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
