<script setup>

import {
  ref,
  computed,
} from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';

/**
 * Icons
 */
import ExternalLinkIcon from '@/components/icons/ExternalLinkIcon';

/**
 * Constants
 */
import { OPENSEARCH_PORTALS } from '@/common/constants';

/**
 * Styles
 */
import { styles } from './OpenSearchCard.styles';

/**
 * Props
 */
const props = defineProps({

  data: {
    type: Object,
    default: () => ({ }),
  },
});

/**
 * Data
 */
const more = ref(false);

/**
 * Computed
 */
const heading = computed(() => [
  `${OPENSEARCH_PORTALS[props.data?.portal] || ''}`,
  `${props.data?.seller ? ` ${OPENSEARCH_PORTALS[props.data?.portal] ? '|' : ''} ${props.data?.seller}` : ''}`,
].join(''));

const address = computed(() => [
  props.data?.address?.route,
  props.data?.address?.district,
  props.data?.address?.city,
  props.data?.address?.state,
].filter(Boolean).join(' - '));

const attributes = computed(() => props.data?.attributes ? [...props.data.attributes].sort((a, b) => a.localeCompare(b)) : []);

/**
 * Misc
 */
const currency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 0,
});

const area = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 0,
});

/**
 * Methods
 */
const onClick = (event) => {

  if (typeof event.target.dataset.viewMore === 'undefined') {
    window.open(props.data?.externalUrl || '');
  }
};

const onImageError = (event) => event.target.src = require('@/assets/images/opensearch-listing-fallback.png');

const onViewMoreAttributes = () => more.value = !more.value;

</script>

<template>
  <div
    :class="styles.root().className"
    :title="heading"
    @click="onClick"
  >
    <div :class="styles.details().className">
      <Typography
        tag="h1"
        variant="label-medium"
        color="brand-default"
      >
        {{ heading }}
      </Typography>

      <Typography
        tag="h2"
        variant="title-medium"
        color="neutral-grey-5"
      >
        {{ address }}
      </Typography>

      <Typography
        :class="styles.features().className"
        tag="div"
        variant="body-medium"
        color="neutral-grey-5"
      >
        <span
          v-if="data?.typology?.utilArea"
          data-area
        >
          Área útil <strong>{{ area.format(data.typology.utilArea) }} m²</strong> 
        </span>

        <span
          v-if="data?.typology?.builtArea"
          data-area
        >
          Área construída <strong>{{ area.format(data.typology.builtArea) }} m²</strong> 
        </span>

        <span
          v-if="data?.typology?.utilArea || data?.typology?.builtArea"
          data-bullet
        >
          •
        </span> 

        <span
          v-if="data?.typology.bedrooms"
          data-feature
        >
          {{ data?.typology?.bedrooms }} dormitório{{ data?.typology?.bedrooms > 1 ? 's' : '' }}
        </span>

        <span
          v-if="data?.typology.suites"
          data-feature
        >
          {{ data?.typology?.suites }} suíte{{ data?.typology?.suites > 1 ? 's' : '' }}
        </span>

        <span
          v-if="data?.typology.parkingLots"
          data-feature
        >
          {{ data?.typology?.parkingLots }} vaga{{ data?.typology?.parkingLots > 1 ? 's' : '' }}
        </span>
      </Typography>

      <Typography
        :class="styles.pricing().className"
        tag="div"
        variant="body-medium"
        color="neutral-grey-5"
      >
        <span
          v-if="data?.price?.sell"
          data-price
        >
          Valor venda <strong>{{ currency.format(data.price.sell) }}</strong>
        </span>
        
        <span
          v-if="data?.price?.rent"
          data-price
        >
          Valor locação <strong>{{ currency.format(data.price.rent) }}</strong>
        </span>

        <span
          v-if="data?.price?.condominiumFee"
          data-price
        >
          Condomínio <strong>{{ currency.format(data.price.condominiumFee) }}</strong>
        </span>

        <span
          v-if="data?.price?.iptu"
          data-price
        >
          IPTU <strong>{{ currency.format(data.price.iptu) }}</strong>
        </span>
      </Typography>

      <div :class="styles.attributes().className">
        <Typography
          v-for="(attribute, attributeIndex) in attributes"
          v-show="(attributeIndex < 5 || more)"
          :key="`attributeIndex${attributeIndex}`"
          tag="span"
          variant="label-xs"
          color="brand-default"
          :class="styles.badge().className"
        >
          {{ attribute }}
        </Typography>

        <Typography
          v-if="(attributes.length > 5)"
          data-view-more
          tag="span"
          variant="label-xs"
          color="brand-default"
          :class="styles.badge({ style: 'inverted' }).className"
          @click="onViewMoreAttributes"
        >
          Ver {{ more ? 'menos' : 'mais' }}
        </Typography>
      </div>
    </div>

    <div :class="styles.image().className">
      <img
        :src="data?.image || ''"
        :alt="heading"
        @error="onImageError"
      >
      
      <div>
        <ExternalLinkIcon />

        <Typography
          tag="span"
          variant="label-small"
          color="neutral-white"
        >
          Ver anúncio
        </Typography>
      </div>
    </div>
  </div>
</template>
