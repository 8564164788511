<template>
  <div class="skeleton-results">
    <div class="w-full flex">
      <div class="mr-4 w-1/3">
        <v-skeleton-loader
            width="100%"
            type="image"
        ></v-skeleton-loader>
        <div class="flex">
          <v-skeleton-loader class="mt-3" width="50%" type="text" />
          <v-skeleton-loader class="mt-3 mr-2 ml-auto" width="25px" height="25px" type="button" />
        </div>
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <div class="flex items-center mt-2">
          <v-skeleton-loader class="mr-2" width="20%" height="20%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="" width="20%" height="10%" type="text" />
        </div>
        <v-skeleton-loader class="mt-3" width="40%" type="text" />
      </div>
      <div class="mr-4 w-1/3">
        <v-skeleton-loader
            width="100%"
            type="image"
        ></v-skeleton-loader>
        <div class="flex">
          <v-skeleton-loader class="mt-3" width="50%" type="text" />
          <v-skeleton-loader class="mt-3 mr-2 ml-auto" width="25px" height="25px" type="button" />
        </div>
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <div class="flex items-center mt-2">
          <v-skeleton-loader class="mr-2" width="20%" height="20%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="" width="20%" height="10%" type="text" />
        </div>
        <v-skeleton-loader class="mt-3" width="40%" type="text" />
      </div>
      <div class="w-1/3">
        <v-skeleton-loader
            width="100%"
            type="image"
        ></v-skeleton-loader>
        <div class="flex">
          <v-skeleton-loader class="mt-3" width="50%" type="text" />
          <v-skeleton-loader class="mt-3 mr-2 ml-auto" width="25px" height="25px" type="button" />
        </div>
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <v-skeleton-loader class="mt-1" width="70%" type="text" />
        <div class="flex items-center mt-2">
          <v-skeleton-loader class="mr-2" width="20%" height="20%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="mr-2" width="20%" height="10%" type="text" />
          <v-skeleton-loader class="" width="20%" height="10%" type="text" />
        </div>
        <v-skeleton-loader class="mt-3" width="40%" type="text" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonSimilares',
};

</script>

<style lang="scss" scoped>

.skeleton-results {
  @apply mt-4 flex flex-col justify-start items-center w-full;
}

</style>
