// import base from './base';
import { clients, backgroundCheck } from '@/assets/mocks/fixtures';

export default {
  async fetchByFilter (filter, attributes, options) {
    // @FIXME Add call to client search API endpoint
    // const response = await base.post('data/clients/search', {
    //   filter,
    //   attributes,
    //   options,
    // });
    // // @TODO: Use a custom error (ApiError)
    // return response && response.data;
    return { response: clients };
  },
  async getByDocument (documentNumber) {
    // const response = await base.post(`data/clients/get/${documentNumber}`);
    // // @TODO: Use a custom error (ApiError)
    // return response && response.data;
    return {
      response: [backgroundCheck],
    };
  },
  async fetchSearchSummary (filter, attributes, options) {
    // const response = await base.post('data/clients/search/summary', {
    //   filter,
    //   attributes,
    //   options,
    // });

    // @TODO: Use a custom error (ApiError)
    // return response && response.data;
    return {
      response: [],
    };
  },
};
