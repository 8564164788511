import { render, staticRenderFns } from "./FilterArea.vue?vue&type=template&id=3a2f565c&scoped=true"
import script from "./FilterArea.vue?vue&type=script&lang=js"
export * from "./FilterArea.vue?vue&type=script&lang=js"
import style0 from "./FilterArea.vue?vue&type=style&index=0&id=3a2f565c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2f565c",
  null
  
)

export default component.exports