<template>
  <div class="search-result-wrapper relative d-flex flex-col h-full justify-start">
    <div
      ref="scrollElement"
      class="bg-white search-result"
    >
      <div v-if="!loading">
        <SearchSummary
          v-if="totalItemCount"
          header-text="Resumo dos resultados"
          footer-text="Filtros utilizados"
        >
          <template #result>
            <SearchSummarySeller
              type="result"
              :data="summary"
            />
          </template>

          <template #filter>
            <SearchSummarySeller
              type="filter"
              :data="unitFilterSummary"
            />
          </template>
        </SearchSummary>
        
        <h2
          class="search-result-title font-extrabold text-2xl pt-4 pb-1 pl-3 text-center"
          @click="handleEaster({ summaryData, searchResults, filter }, $event)"
        >
          Condomínios
        </h2>
      </div>

      <div
        v-if="noResults"
        class="flex flex-wrap justify-center py-10 px-10"
      >
        <p>A sua busca não retornou nenhum resultado :(</p>
      </div>

      <div
        v-if="loading"
        class="grid grid-cols-2 gap-4 mt-4 px-2"
      >
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>

      <ul
        v-else
        v-infinite-scroll="false /*loadMore*/"
        class="search-result-container d-flex flex-wrap justify-around px-3 py-3 mb-8"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="10"
      >
        <PropertyItem
          v-for="(item, index) in searchResults &&
            searchResults.sort((first, second) => first.order - second.order)"
          :key="index"
          :class="[
            'max-w-lg',
            !expandedSidebar ? 'w-1/2' : 'w-1/4',
            expandedSidebar && index > 3 && 'mt-5',
            !expandedSidebar && index > 1 && 'mt-5',
          ]"
          :disable-flagging="item.addressId === condoId"
          :property="item"
          @click:highlight="cancelNextItemClick"
          @click="navigateToProperty(item)"
        />
      </ul>
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import debounce from 'lodash.debounce';

import { mapState, mapActions } from 'vuex';

import infiniteScroll from 'vue-infinite-scroll';

import PropertyItem from '@/components/property/PropertyItem.vue';
import SkeletonCard from '@/components/skeleton/SkeletonCard.vue';
import viewProductGTM from '../../utils/viewProductGTM';
import GoogleTagManager from '../../components/google/GoogleTagManager.vue';

import SearchSummary from '@/components/SearchSummary';
import SearchSummarySeller from '@/components/SearchSummarySeller';

import { OPENSEARCH_PROPERTY_TYPES } from '@/common/constants';

export default {
  directives: {
    infiniteScroll,
  },
  components: {
    PropertyItem,
    SkeletonCard,
    GoogleTagManager,
    SearchSummary,
    SearchSummarySeller,
  },
  props: {
    condoId: {
      type: Number,
      default: 0,
    },
    searchResults: {
      type: Array,
      default: () => null,
    },
    unitFilter: {
      type: Object,
      default: () => ({ }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      gtmEvent: null,
      preventNextClickOnItem: false,
    };
  },
  computed: {
    ...mapState('core', [
      'collapsedSidebar',
      'expandedSidebar',
      'showAdditionalFilters',
    ]),
    ...mapState('summary', ['summary']),
    ...mapState('property', ['property']),
    ...mapState({
      error (state, getters) {
        return getters[`${this.$route.name}/error`]; 
      },
      result (state, getters) {
        return getters[`${this.$route.name}/result`]; 
      },
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`]; 
      },
    }),
    summaryData () {
      return this.$store.state[this.$route.name].summary;
    },
    totalItemCount () {
      return (this.result && Object.keys(this.result).length) || 0;
    },
    itemCount () {
      return (this.searchResults && this.searchResults.length) || 0;
    },
    scrollElement () {
      return this.$refs.scrollElement;
    },
    unitFilterSummary () {

      let filterSummary = { };

      const {
        propertyType,
        areaRange,
        bedrooms,
        suites,
        parkingLots,
        propertyAgeVar,
      } = this.unitFilter;

      if (propertyType) {
        filterSummary.propertyType = { label: 'Tipologia', value: OPENSEARCH_PROPERTY_TYPES[propertyType] };
      }

      if (propertyType) {
        filterSummary.areaRange = { label: 'Metragem', value: `${areaRange[areaRange.length - 1] || 'N/D'} m²` };
      }

      if (bedrooms) {
        filterSummary.bedrooms = { label: 'Dormitórios', value: bedrooms };
      }

      if (suites) {
        filterSummary.suites = { label: 'Suítes', value: suites };
      }

      if (parkingLots) {
        filterSummary.parkingLots = { label: 'Vagas', value: parkingLots };
      }

      if (propertyAgeVar) {
        filterSummary.propertyAgeVar = { label: 'Ano de construção', value: (new Date().getFullYear() - propertyAgeVar) };
      }

      return filterSummary;
    },
    noResults () {
      return !(this.result && Object.keys(this.result).length);
    },
  },
  created () {
    this.navigateToProperty = debounce(this.navigateToProperty.bind(this), 200);
  },
  methods: {
    ...mapActions({
      toggleExpandSidebar: 'core/toggleExpandSidebar',
      fetchProperty: 'property/fetchProperty',
      fetchAddresses (dispatch, payload) {
        return dispatch(`${this.$route.name}/fetchAddresses`, payload); 
      },
    }),
    cancelNextItemClick () {
      this.preventNextClickOnItem = true;
    },
    async loadMore () {
      const pageSize = 16;
      const mapIds
          = (this.result
            && Object.entries(this.result)
              .filter(([
                _,
                value,
              ]) => !value.addressLine1) // eslint-disable-line no-unused-vars
              .map(([addressId]) => addressId))
          || [];
      const pageIds = mapIds.slice(0, pageSize);

      await this.fetchAddresses(this.$route.name, pageIds || []);
    },
    navigateToProperty (item) {
      if (this.preventNextClickOnItem) {
        this.preventNextClickOnItem = false;

        return;
      }

      if (this.expandedSidebar) {
        this.toggleExpandSidebar();
      }

      if (!this.property || item.addressId !== this.property.addressId) {
        this.gtmEvent = viewProductGTM(item);
      }

      this.fetchProperty({ addressId: item.addressId, domain: 'seller' });
    },
  },
};
</script>
<style scoped>
  .search-result-wrapper {
    overflow: auto;
    background-color: #fff;
    margin-bottom: 60px;
  }
  /deep/ .property .attributes ul span {
    font-size: 9px;
    font-weight: bolder;
  }

  /deep/ .property .divider {
    margin: 0 0.3em;
    height: 15px;
    border-right: 1px solid #777;
  }

  /deep/ .property:last-child {
    margin-right: auto;
  }
  .search-result-title {
    color: #384159;
  }

</style>
