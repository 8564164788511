import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import api from '@/api';

export const useUpdatedStore = defineStore('updated', () => {
  const updated = ref('-');

  const updatedAt = computed(() => updated.value);

  async function fetchUpdated () {
    const response = await api.offers.updated();
    const date = new Intl.DateTimeFormat('pt-BR').format(new Date(response));

    updated.value = date;
  }

  return {
    updatedAt,
    fetchUpdated,
  };
});