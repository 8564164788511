<template>
  <base-card>
    <div class="flex flex-column gap-1">
      <Typography
        variant="header-large"
        color="neutral-grey-6"
      >
        Endereços
      </Typography>
      <Typography
        variant="body-xs"
        color="neutral-grey-5"
      >
        Endereços registrados
      </Typography>
    </div>
    <div
      v-if="detail.Properties"
    >
      <v-data-table
        :headers="propertiesAddressesHeaders"
        :items="propertiesAddresses"
        :items-per-page="itemsPerPage"
        :footer-props="{
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
        }"
        sort-by="Ranking"
        :sort-desc="true"
        class="property-owner"
      >
        <template v-slot:[`item.Area`]="{ item }">
          <div class="py-4">
            <Button
              variant="primary"
              v-if="item"
              size="small"
              @click="linkToMaps(item)"
              class="button-width"
            >
            <div class="flex gap-2">
              <img
                width="12px"
                src="@/assets/images/map-button-pin.svg"
              >
                Mapa
              </div>
            </Button>
          </div>
        </template>
        <template v-slot:[`item.Year`]="{ item }">
            {{ item.Year || 'N/D' }}
        </template>
        <template v-slot:[`item.Ranking`]="{ item }">
          {{ formatYear(item.Ranking) }}
        </template>
      </v-data-table>
    </div>
    <div
      v-if="detail.Addresses"
      class="mt-4"
    >
      <v-data-table
        :headers="addressesHeader"
        :items="addresses"
        :items-per-page="itemsPerPage"
        :footer-props="{
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
        }"
        sort-by="Status"
        :sort-desc="true"
      >
        <template v-slot:[`item.ShortAlias`]="{ item }">
          <div class="py-4">
            <Button
              variant="primary"
              v-if="item"
              size="small"
              @click="linkToMaps(item)"
              class="button-width"
            >
            <div class="flex gap-2">
              <img
                width="12px"
                src="@/assets/images/map-button-pin.svg"
              >
                Mapa
              </div>
            </Button>
          </div>
        </template>
        <template v-slot:[`item.Ranking`]="{ item }">
          <div class="flex align-center justify-center">
            {{ formatYear(item.Ranking) }}
          </div>
        </template>
      </v-data-table>
    </div>
  </base-card>
</template>

<script>

import { mapState } from 'vuex';
import { formatYear } from '@/utils';
import BaseCard from '@/components/cards/base-card';
import Typography from '../base/Typography';
import Button from '../base/Button';

export default {
  name: 'AddressesTab',
  components: {
    BaseCard,
    Typography,
    Button
},
  data () {
    return {
      itemsPerPage: 5,
      addressesHeader: [
        {
          text: 'Correspondência',
          value: 'Alias',
          sortBy: 'Alias',
        },
        {
          text: '',
          value: 'ShortAlias',
          sortable: false,
          width: 65,
        },
        {
          text: 'Última atualização',
          value: 'Ranking',
          sortBy: 'Ranking',
          width: 200,
          align: 'center'
        },
      ],
      propertiesAddressesHeaders: [
        {
          text: 'Propriedade',
          value: 'Address',
          sortBy: 'Address',
        },
        {
          text: '',
          value: 'Area',
          sortable: false,
          width: 65,
        },
        {
          text: 'Ano',
          value: 'Year',
          sortBy: 'Year',
          align: 'center',
          width: 100,
        },
        {
          text: 'Última Atualização',
          value: 'Ranking',
          align: 'center',
          sortBy: 'Ranking',
          width: 190,
        },
      ],
    };
  },
  computed: {
    ...mapState('person', ['detail']),
    propertiesAddresses () {
      return this.detail.Properties;
    },
    addresses () {
      return this.detail.Addresses;
    },
  },
  methods: {
    linkToMaps (property) {
      window.open(`https://www.google.com/maps/place/${property?.Alias ? property?.Alias : property?.Address}`, '_blank')
    },
    formatYear,
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}

:deep(.v-data-table .v-data-table__wrapper) {
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}

.button-width {
  min-width: 88px!important;
  height: 32px;
  padding: 10px 16px;
  gap: 10px;
}

:deep(.v-data-table thead tr th:nth-child(3) span) {
  padding-left: 16px;
}

:deep(.v-data-table .v-data-footer__select .v-select) {
  margin: 13px 0 13px 16px;
}
:deep(.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon) {
  font-size: 32px;
}

:deep(.v-data-table .v-data-footer__icons-before .v-btn:last-child){
  margin-right: 0px;
}

:deep(.v-data-table .v-data-footer__icons-after .v-btn:first-child){
  margin-left: 0px;
}

:deep(.v-data-table .v-data-footer__pagination) {
  margin: 0px 16px;
}

:deep(.v-data-table .v-data-footer) {
  display: flex;
  align-items: center;
  border-top: none!important;
  height: 32px;
  margin-top: 16px;
}

:deep(.v-data-table .v-data-footer .v-data-footer__select) {
  height: 32px;
  margin-right: 0px!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th) {
  border-bottom: none!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th i){
  margin-bottom: 5px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child)){
  margin: 15px 0px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child) {
  font-size: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:first-child)){
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
</style>
