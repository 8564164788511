/**
 * Font weights
 */
export default {
  'font-weight-regular': 400,
  'font-weight-default': 500,
  'font-weight-medium': 600,
  'font-weight-bold': 700,
  'font-weight-black': 900,
};
