<template>
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.1 3.79167V5.83333H1.2C0.88174 5.83333 0.576516 5.95625 0.351472 6.17504C0.126428 6.39383 0 6.69058 0 7L0 12.8333C0 13.1428 0.126428 13.4395 0.351472 13.6583C0.576516 13.8771 0.88174 14 1.2 14H10.8C11.1183 14 11.4235 13.8771 11.6485 13.6583C11.8736 13.4395 12 13.1428 12 12.8333V7C12 6.69058 11.8736 6.39383 11.6485 6.17504C11.4235 5.95625 11.1183 5.83333 10.8 5.83333H9.9V3.79167C9.9 2.78605 9.48911 1.82163 8.75772 1.11055C8.02633 0.399478 7.03434 0 6 0C4.96566 0 3.97368 0.399478 3.24228 1.11055C2.51089 1.82163 2.1 2.78605 2.1 3.79167ZM3.6 5.83333V3.79167C3.6 3.17283 3.85286 2.57934 4.30294 2.14175C4.75303 1.70417 5.36348 1.45833 6 1.45833C6.63652 1.45833 7.24697 1.70417 7.69706 2.14175C8.14714 2.57934 8.4 3.17283 8.4 3.79167V5.83333H3.6ZM4.8 9.04167C4.80005 8.83955 4.8541 8.64092 4.95686 8.46522C5.05962 8.28953 5.20756 8.1428 5.3862 8.03942C5.56483 7.93604 5.76802 7.87955 5.97587 7.87549C6.18372 7.87142 6.38908 7.91992 6.57185 8.01624C6.75461 8.11255 6.90851 8.25338 7.01845 8.42491C7.12839 8.59645 7.19062 8.79281 7.19902 8.99476C7.20742 9.1967 7.16172 9.39731 7.06639 9.57692C6.97106 9.75653 6.82937 9.90898 6.6552 10.0193L6.6504 10.0222C6.6504 10.0222 6.7674 10.7106 6.8994 11.5214V11.522C6.8994 11.6379 6.85205 11.749 6.76777 11.8309C6.6835 11.9129 6.56919 11.9589 6.45 11.9589H5.5488C5.42961 11.9589 5.31531 11.9129 5.23103 11.8309C5.14675 11.749 5.0994 11.6379 5.0994 11.522V11.5214L5.3484 10.0222C5.17993 9.91649 5.04138 9.7714 4.94538 9.60022C4.84938 9.42904 4.79899 9.23722 4.7988 9.04225L4.8 9.04167Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "PaddockIcon"
}
</script>
