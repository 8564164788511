import store from '@/store';
import { LGPDRoutes } from './routes';

export class Redirector {
  to = {}
  from = {}
  foundPath = false
  path = ''
  isAuthenticated = false
  isMobile = false

  constructor (to, from, isAuthenticated, isMobile) {
    this.to = to;
    this.from = from;
    this.isAuthenticated = isAuthenticated;
    this.isMobile = isMobile;

    return this;
  }

  checkPublicRoutes () {
    if (this.foundPath) {
      return this;
    }

    if (this.to.fullPath.includes('/report')) {
      this.foundPath = true;
      this.path = this.to.fullPath;
    }

    if (this.to.fullPath.includes('/login/first-access')) {
      this.foundPath = true;
      this.path = this.to.fullPath;
    }

    if (this.to.fullPath.includes('/login/reset-password')) {
      this.foundPath = true;
      this.path = this.to.fullPath;
    }

    if (this.to.fullPath.includes('/login/confirm-new-password')) {
      this.foundPath = true;
      this.path = this.to.fullPath;
    }

    return this;
  }

  authRoutes () {
    if (this.foundPath) {
      return this;
    }

    if (!this.isAuthenticated) {
      store.dispatch('personData/setSeen', null)
      this.foundPath = true;
      this.path = '/login';
    }

    return this;
  }

  otherRules () {
    if (this.foundPath) {
      return this;
    }

    if (this.to.path !== '/person-data/detail') {
      store.commit('person/SET_DETAIL', null);
    }
    
    if (LGPDRoutes[this.to.path]) {
      store.commit('core/SET_VERTICAL', LGPDRoutes[this.to.path]);
      store.dispatch('user/getLGPDTermsAcceptance', LGPDRoutes[this.to.path]);
    }

    // Social sign-in
    if (this.to.query.success && this.to.query.code && this.to.query.state) {
      this.foundPath = true;
      this.path = '/home';
    }
    
    // Prevent user accessing login page when authenticated
    if (this.to.path === '/login') {
      this.foundPath = true;
      this.path = '/home';
    }

    if (this.to.path === '/estudos'
      && store.getters['user/idToken'].payload.real_estate_id !== process.env.VUE_APP_BOSSA_REAL_ESTATE_ID) {

      this.foundPath = true;
      this.path = '/home';
    }

    if(this.to.path === '/' && this.isAuthenticated){
      this.foundPath = true;
      this.path = '/home';
    }

    // Limpa resultado do consulta dados ao sair dessa vertical
    if(!(this.from.path === '/person-data' && this.to.path === '/person-data/detail') &&
      !(this.from.path === '/person-data/detail' && this.to.path === '/person-data')){
        store.dispatch('personData/setResultList', null)
    }

    // Limpa resultado da avm ao mudar de página
    if((this.from.path === '/avm')) {
      store.dispatch('avm/resetLastSearch')
    }

    return this;
  }

  redirect () {
    if (!this.foundPath) {
      this.path = this.to.fullPath;
    }

    if(localStorage.getItem('leadPath') && this.isAuthenticated) {
      this.path = localStorage.getItem('leadPath')
      localStorage.removeItem('leadPath')
    }

    if (this.path.includes('person-data?data') && !this.path.includes('mobile')) {
      if (this.isMobile) {
        const mobileRoute = '/mobile/person-data/search';
        const personData = this.path.split('?')[1];

        this.path = `${mobileRoute}?${personData}`;
      }
    }

    return this.path;
  }
}
