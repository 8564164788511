<template>
  <section class="imoveis-similares">
    <h2 class="font-extrabold text-lg">Imóveis similares</h2>
    <SkeletonSimilares v-if="items && items.isLoading" data-test="loader" />
    <div v-else-if="!items" data-test="error-message" class="py-4">Não há dados para serem exibidos</div>
    <ul v-if="items && items.length" class="grid grid-cols-3 pl-0">
      <PropertyItem
        class="max-w-lg mt-5"
        data-test="item"
        v-for="(item, index) in items"
        :key="index"
        :property="item"
        :show-typologies="false"
        :show-favorite-button="false"
        :allow-highlighting="false"
        @click="$emit('click:item', item)" />
    </ul>
  </section>
</template>

<script>
import SkeletonSimilares from '../skeleton/SkeletonSimilares';
import PropertyItem from './PropertyItem';

export default {
  name: 'SimilarProperties',
  components: { PropertyItem, SkeletonSimilares },
  props: {
    items: {
      type: [Object, Array],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  .imoveis-similares {
    @apply mb-8;
    ::v-deep .property {
      &:first-child a {
        padding-left: 0 !important;
      }
      &:last-child a {
        padding-right: 0 !important;
      }
    }
  }
</style>
