<script setup>

import { styles } from './HomeMenu.styles';
import Typography from '@/components/base/Typography';
import store from '../../store/index';
import router from '@/router';

const css = selector => styles[selector]().className;

async function logout () {
  await store.dispatch('user/logout', { router });
}

</script>

<template>
  <div :class="css('container')">
    <div :class="css('content')">
      <Typography
        tag="h2"
        variant="header-xl-secondary"
        color="neutral-white"
      >
        Cidade Virtual
      </Typography>
      <Typography
        tag="span"
        :class="css('logout')"
        variant="button-small"
        color="neutral-white"
        @click="logout()"
      >
        Sair
      </Typography>
    </div>
  </div>
</template>
