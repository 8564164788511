import { keyframes } from '@stitches/core';

import { css } from '@/core/theme';

const shimmer = keyframes({

  '100%': {
    transform: 'translateX(100%)',
  },
});

const root = css({
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'middle',
  overflow: 'hidden',
  backgroundColor: '$highlight-lighter',

  '&::after': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translateX(-100%)',
    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, $additional-blue-2 47%, rgba(255,255,255,0) 100%)',
    animation: `${shimmer} 2s infinite`,
    content: '',
  },
});

export const styles = {
  root,
};
