<script setup>

import {
  ref,
  computed,
  watch,
  useSlots,
} from 'vue';

/**
 * Styles
 */
import { styles } from './Switch.styles';

/**
 * Props
 */
const props = defineProps({

  value: {
    default: false,
    type: Boolean,
  },

  size: {
    default: 'default',
    type: String,
    validator: (value) => {
      return [
        'default',
        'large',
      ].indexOf(value) >= 0;
    },
  },

  labelPosition: {
    default: 'right',
    type: String,
    validator: (value) => {
      return [
        'left',
        'right',
      ].indexOf(value) >= 0;
    },
  },
});

/**
 * Slots
 */
const slots = useSlots();

/**
 * Emits
 */
const emit = defineEmits(['input']);

/**
 * Data
 */
const localValue = ref(false);

/**
 * Computed
 */
const active = computed({

  get () {
    return props.value || localValue.value;
  },

  set (value) {
    
    localValue.value = value;

    emit('input', value);
  },
});

const hasLabel = computed(() => !!slots.default);

/**
 * Watch
 */
watch(() => props.value, (value) => active.value = value);

</script>

<template>
  <label
    :class="styles.root({
      size,
      hasLabel,
      labelPosition,
    }).className"
  >
    <input
      v-model="active"
      :class="styles.checkbox().className"
      type="checkbox"
    >

    <span
      :class="styles.slider({
        size,
        hasLabel,
        labelPosition,
      }).className"
    />

    <div
      v-if="slots.default"
      :class="styles.label({ labelPosition }).className"
    >
      <slot />
    </div>
  </label>
</template>
