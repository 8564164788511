import base from './base';

export default {
  async decrypt(domain, dataToDecrypt) {
    const response = await base.post(`${domain}/decrypt/`, {
      data: dataToDecrypt
    });
    return response && response.data;
  }
};
