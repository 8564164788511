<template>
  <div class="avm-quota">
    <h3 class="avm-quota-title">Consultas</h3>
    <span v-if="avmQuota" class="avm-quota-description">
      Você usou
      <b>{{ avmQuota.total - avmQuota.available }}</b>
      de {{ avmQuota.total }} consultas disponíveis
    </span>
    <v-progress-linear
      class="avm-quota-bar"
      :value="avmQuotaPercentage"
      height="10"
      rounded
    ></v-progress-linear>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'avmQuota',
  created() {
    this.fetchAVMQuota();
  },
  computed: {
    ...mapState('user', ['avmQuota']),
    avmQuotaPercentage() {
      if (!this.avmQuota) return 0;
      return 100 - (this.avmQuota.available * 100) / this.avmQuota.total;
    },
  },
  methods: {
    ...mapActions({
      fetchAVMQuota: 'user/fetchAVMQuota',
    }),
  },
};
</script>

<style lang="scss" scoped>
.avm-quota {
  background-color: #eceff8;
  @apply w-auto mx-2;
  padding: 20px;
  &-title {
    @apply font-bold;
    font-size: 14px;
  }
  &-description {
    @apply text-sm;
    margin-top: 12px;
    font-size: 12px;
  }
  &-bar {
    margin-top: 12px;
  }
  ::v-deep.v-progress-linear {
    &__determinate {
      border-radius: 6px;
    }
    &__background {
      left: 0 !important;
      width: 100% !important;
    }
  }
}
</style>
