<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0C3.35742 0 0 3.35742 0 7.5C0 11.6426 3.35742 15 7.5 15C11.6426 15 15 11.6426 15 7.5C15 3.35742 11.6426 0 7.5 0ZM7.5 3.75C8.01768 3.75 8.4375 4.16982 8.4375 4.6875C8.4375 5.20518 8.01768 5.625 7.5 5.625C6.98232 5.625 6.5625 5.20605 6.5625 4.6875C6.5625 4.16895 6.98145 3.75 7.5 3.75ZM8.67188 11.25H6.32812C5.94141 11.25 5.625 10.9365 5.625 10.5469C5.625 10.1572 5.93994 9.84375 6.32812 9.84375H6.79688V7.96875H6.5625C6.17432 7.96875 5.85938 7.65381 5.85938 7.26562C5.85938 6.87744 6.17578 6.5625 6.5625 6.5625H7.5C7.88818 6.5625 8.20312 6.87744 8.20312 7.26562V9.84375H8.67188C9.06006 9.84375 9.375 10.1587 9.375 10.5469C9.375 10.9351 9.06152 11.25 8.67188 11.25Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon',
};
</script>
