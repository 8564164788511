<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    data-test-id="custom-data-table"
  >
  <slot></slot>
  </v-data-table>
</template>

<script>
export default {
  name: 'CustomDataTable',
  props: ['headers', 'items'],
};
</script>

<style lang="scss" scoped>

::v-deep th {
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #6879A6 !important;
}

::v-deep td {
  font-family: Lato !important;
  font-size: 14px !important;
  padding: 22px 16px !important;
}

::v-deep tr:hover td {
  background-color: #fff;
}

</style>
