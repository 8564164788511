<template>
  <div
    class="btn-social-login btn-social-login__google"
    @click="signInWithGoogle()"
  >
    <social-login-google/>
    <span class="pl-3">
      Entrar com Google
    </span>
    <google-tag-manager :trackEvent="gtmEvent" />
  </div>
</template>

<script>

import SocialLoginGoogle from '@/components/icons/SocialLoginGoogle.vue'
import GoogleTagManager from '@/components/google/GoogleTagManager.vue'

export default {
  name: 'LoginGoogle',
  components: {
    SocialLoginGoogle,
    GoogleTagManager,
  },
  data () {
    return {
      gtmEvent: null,
    }
  },
  methods: {
    async signInWithGoogle() {
      this.gtmEvent = {
        event: 'login',
        type: 'social_google',
      }
      await this.$auth.federatedSignIn({ provider: 'Google' })
    },
  },
}

</script>

<style lang="scss" scoped>

.btn-social-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border-radius: 9999px; // rounded corners

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    cursor: pointer;
  }

  &__google {
    padding: 16px 89px;
    margin-top: 24px;

    border: 1px solid #E8E8E8;
    
    background: rgba(232, 232, 232, 0.2);
    color: #707070;
  }

  &__google:hover {
    background: rgba(232, 232, 232, 0.4);
  }
}

</style>