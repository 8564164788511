<template>
  <div>
    <v-dialog
        v-model="showModal"
        persistent
        content-class="payment-required-modal"
    >
      <div class="wrapper" data-test="modal-wrapper">
        <div>
          <div class="title">
            <NotAllowedIcon/>
            <div class="mt-4" data-test="modal-title">Acesso restrito</div>
          </div>
        </div>
        <div class="description mx-auto" data-test="modal-description">
          Identificamos um problema, por favor entre em contato com o atendimento ao cliente para mais informações.
        </div>
        <div class="buttons">
          <v-btn
              data-test="modal-button"
              rounded
              depressed
              color="#3366CC"
              class="accept-btn"
              dark
              @click="startWhatsappChat"
          >
            Falar com o atendimento
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import NotAllowedIcon from '@/components/icons/NotAllowedIcon.vue';
import GoogleTagManager from '../google/GoogleTagManager';

export default {
  name: 'PaymentRequiredModal',
  components: {
    GoogleTagManager,
    NotAllowedIcon,
  },
  data: () => ({
    showModal: false,
    gtmEvent: null,
    tokenData: null,
  }),
  computed: {
    whatsappMessage() {
      const { name, email } = this.tokenData;
      return `Olá, me chamo ${name} e meu email de acesso é ${email}. Podem por favor me ajudar a restaurar o acesso ao Cidade Virtual? Código do erro: 402`;
    },
    whatsappLink() {
      return encodeURI(`https://api.whatsapp.com/send?phone=${process.env.VUE_APP_WHATSAPP_SUPPORT}&text=${this.whatsappMessage}`);
    },
  },
  async created() {
    this.$eventBus.$on('error:paymentRequired', () => {
      this.showModal = true;
      this.gtmEvent = {
        event: 'payment',
        type: 'payment',
        status: 'error',
      };
    });

    this.tokenData = this.$store.getters['user/idToken'].payload;
  },
  methods: {
    startWhatsappChat() {
      window.open(this.whatsappLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .payment-required-modal {
  @apply flex p-0;
  font-family: 'Lato', sans-serif;
  background-color: white;
  font-style: normal;
  font-weight: 400;
  width: 464px;
  border-radius: 10px;
  min-height: 284px;
  .wrapper {
    @apply flex flex-col py-4 px-6;
    .accept-checkbox {
      @apply m-0 p-0;
      height: 25px;
      .v-icon {
        font-size: 27px;
      }
    }
    .title {
      @apply text-center mb-2 mt-4;
      @apply flex flex-col items-center justify-center;
      font-family: 'Roboto', sans-serif;
      color: #3366CC;
      font-weight: 600;
      font-size: 22.5px !important;
      line-height: 27px;
    }
    .close {
      color: #6B6B6B;
      font-size: 28px;
    }
    .description {
      @apply my-4 pr-3 text-center;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      max-width: 80%;
      color: #707070;
      a {
        color: #3366CC;
        font-weight: bold;
      }
    }
    .buttons {
      @apply mt-auto text-center mb-2;
      .accept-btn {
        min-width: 100px;
        text-transform: none;
        &.v-btn--disabled {
          background-color: #DEDFDF !important;
          color: white !important;
        }
      }
    }
  }
}
</style>
