<template>
  <tbody>
    <caption v-if="firstResult" class="caption">
      {{ row.propertyType }}
    </caption>
    <tr class="table-row">
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        <span v-if="row.source.image">
          <img class="m-auto" width="65%" :src="row.source.image">
        </span>
        <span class="text-center block">
          {{ row.area }} m²
        </span>
      </td>
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        {{ row.bedrooms }}
      </td>
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        {{ row.suites }}
      </td>
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        {{ row.parkingLots }}
      </td>
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        <span>
          {{ (priceHistory && priceHistory.offerPrice || '-' ) | money }}
        </span>
        <v-icon
          v-if="priceHistory && priceHistory.value && offerGraph && offerGraph.data && offerGraph.data.length > minChartDataLength"
          class="chart-icon up"
          :class="{'chartNotPlain': (priceHistory && priceHistory.offerPrice !== 'plain')}"
          color="#707070"
          large
          @click="highlightedTypology = offerGraph"
        >
          icon-trending-{{ (offerGraph && offerGraph.value) || 'plain' }}
        </v-icon>
      </td>
      <td class="table-row__cell" :class="{'highlight': row.highlighted === 1}">
        <template v-if="row.market && row.market.price">
          <template v-if="row.market.updatedAt">
            <tooltip :message="lastUpdatedAtMessage(row.market.updatedAt)">
              <span class="tooltip__hook" :class="{'tooltip__hook--highlighted': row.highlighted === 1}">
                {{ row.market.price | money }}
              </span>
            </tooltip>
          </template>
          <template v-else>
            <span :class="{'highlight': row.highlighted === 1}">
              {{ row.market.price | money }}
            </span>
          </template>
        </template>
        <template v-else>
          <span>-</span>
        </template>
        <v-icon
          v-if="row.market && row.market.chart && row.market.chart.text && row.market.chart.data && row.market.chart.data.length > minChartDataLength"
          class="chart-icon up"
          :class="{'chartNotPlain': (row.market && row.market.chart && row.market.chart.value !== 'plain')}"
          color="#707070"
          large
          @click="highlightedTypology = row.market.chart"
        >
          icon-trending-{{ (row.market && row.market.chart && row.market.chart.value) || 'plain' }}
        </v-icon>
        <OfferPriceTrendPopup
          v-if="highlightedTypology"
          :highlighted-typology.sync="highlightedTypology"
          @update:visibility="highlightedTypology = null"
        />
      </td>
    </tr>
    <tr v-if="!lastResult">
      <td colspan="7">
        <hr class="row-separator">
      </td>
    </tr>
  </tbody>
</template>

<script>

import Tooltip from '@/components/tooltip/Tooltip.vue'
import OfferPriceTrendPopup from '@/views/property/OfferPriceTrendPopup.vue';
import { lastUpdatedAtMessage } from '@/utils/lastUpdatedAtMessage'

export default {
  name: 'TableRowMoreTypologies',
  components: {
    OfferPriceTrendPopup,
    Tooltip
  },
  props: {
    row: {
      default: null,
    },
    firstResult: {
      default: null,
    },
    lastResult: {
      default: null,
    },
    priceHistory: {
      default: null,
    },
  },
  data() {
    return {
      highlightedTypology: null,
      minChartDataLength: 2,
    };
  },
  computed: {
    offerGraph() {
      if (this.priceHistory) {
        return {
          title: 'Histórico valor médio das ofertas',
          type: 'chart',
          value: this.priceHistory.value,
          text: this.priceHistory.offerPrice,
          data: this.priceHistory.graphData,
        };
      }

      return null;
    }
  },
  methods: {
    lastUpdatedAtMessage
  }
};

</script>

<style lang="scss" scoped>

  .table-row{
    @apply
      border-b
      rounded-none
      text-center;

    color: #707070;
    font-family: Lato;
    font-size: 15px;

    &:last-child{
      @apply border-b-0;
    }

    &__cell{
      @apply
        px-1
        py-1
        border-l
        border-r;

      border-left-color: #707070;
      border-right-color: #707070;
      border-bottom: solid 1px rgba(255, 255, 255, 0);

      text-align: center;
      button .v-icon{
        margin-top: -5px;
      }
      &:first-child{
        @apply pl-4 border-l-0;
      }

      &:last-child{
        @apply border-r-0;
      }
    }
  }

  .caption{
    @apply font-bold py-2 pl-4 my-5;

    font-family: Lato !important;
    font-size: 16px !important;
    color: #707070;
  }

  hr.row-separator {
    background-color: #afafaf;
    width: 100%;
    height: 1px;
    margin: 1.5rem 0;
  }

  .up {
    margin-top: -20px;
  }

  .highlight {
    color: #4663b9;
    font-weight: bold;
  }

  .chartNotPlain {
    font-size: 30px !important;
    margin-top: -10px !important;
  }
  .tooltip {
    &__hook {
      display: inline-block;
      border-bottom: 1px dashed $colors-neutral-grey-5;
    
      &:hover {
        cursor: pointer;
      }

      &--highlighted {
        border-bottom: 1px dashed $colors-highlight-default;
      }
    }
  }

  ::v-deep .theme--light.v-icon:focus::after{
    opacity: 0;
  }

</style>
