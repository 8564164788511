<template>
  <div class="row no-gutters h-100">
    <div class="w-full text-sm">
      <div class="w-full p-6">
        <v-btn
          x-small
          icon
          outlined
          class="close-btn absolute ml-n5 mt-1"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="width:90%;">
          <h3
            class="typology font-weight-black"
            style="font-size:1.5em"
          >
            {{ property.propertyType + ' com ' + property.utilArea + 'm²' }}
          </h3>
          <div
            v-if="property.addressLine3"
            class="text-gray-600 text-md font-weight-medium"
          >
            {{ property.addressLine1 ? property.addressLine1 : '' }}
            {{ property.addressLine2 ? property.addressLine2 : '' }}
            {{ property.addressLine3 ? property.addressLine3 : '' }}
          </div>
        </div>
        <div class="table-wrapper">
          <ads-data-table
            :headers="tableHeaders"
            :items="adsList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { formatYear } from '@/utils';
import AdsDataTable from '@/components/tables/AdsDataTable.vue';
import { OFFER_GROUP, AD_PORTALS } from '@/common/constants';

export default {
  name: 'PropertyModalTable',
  components: {
    AdsDataTable,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'Portal',
          value: 'portal',
        },
        {
          text: 'Imobiliária',
          value: 'sellerName',
        },
        {
          text: 'Data do anúncio',
          value: 'updatedAt',
        },
        {
          text: 'Grupo da publicação',
          value: 'class',
        },
        {
          text: 'Área útil',
          value: 'utilArea',
        },
        {
          text: 'Dorms.',
          value: 'bedrooms',
        },
        {
          text: 'Suítes',
          value: 'suites',
        },
        {
          text: 'Vagas',
          value: 'parkingLots',
        },
        {
          text: 'Valor anunciado',
          value: 'value',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('property', ['ads']),
    adsList () {
      return [...this.ads]
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map(ads => ({
          ...ads,
          portal: AD_PORTALS[ads.portal] || '-',
          sellerName: {
            name: ads.sellerName,
            url: ads.external_url,
          },
          class: OFFER_GROUP[ads.class],
          utilArea: `${ads.utilArea}m²`,
          updatedAt: this.formatYear(ads.updatedAt),
          value: ads.value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol',
            maximumFractionDigits: 0,
          }),
        }));
    },
  },
  methods: {
    formatYear,
    closeDialog () {
      this.$emit('close-evt');
    },
  },
};

</script>

<style lang="scss">

.table-wrapper {
  @apply w-full mt-3;
  overflow: scroll;
  max-height: 80vh;
}

.close-btn {
  right: 10%;
  border-color: $light-gray;
}

</style>

