<script setup>

import { computed, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';

import Typography from '@/components/base/Typography';

import SmallButton from '@/components/mobile/SmallButton';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import { formatCurrency } from '@/utils';

const router = useRouter();
const route = useRoute();
const vuexStore = inject('vuex-store');

const ads = computed(() => {
  return vuexStore.getters['property/ads'];
});

const lastOfferTypology = computed(() => {
  return vuexStore.getters['property/lastOfferTypology'];
});

const adsPageHeight = computed(() => {
  return route.path.includes('ads') ? 'auto' : '0';
});

</script>

<template>
  <div
    class="ads"
    :style="{ height: adsPageHeight, minHeight: adsPageHeight }"
  >
    <div class="header">
      <div class="flex flex-row justify-between">
        <div class="px-4">
          <SmallButton
            label="Voltar"
            :icon="ArrowBackIcon"
            class="my-4"
            @small-button-click="() => router.back()"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-grow justify-center w-full">
      <template v-if="ads && lastOfferTypology">
        <div class="px-4">
          <Typography
            class="my-4 text-center"
            color="brand-lighter"
            variant="header-xl"
            tag="h1"
          >
            {{ lastOfferTypology }}
          </Typography>
        </div>
        <div class="p-8 bg-white rounded-t-lg flex-grow flex flex-col">
          <table>
            <thead>
              <tr>
                <Typography
                  class="text-left"
                  color="brand-default"
                  variant="title-large-secondary"
                  tag="th"
                >
                  Portal
                </Typography>
                <Typography
                  class="text-center"
                  color="brand-default"
                  variant="title-large-secondary"
                  tag="th"
                >
                  Área
                </Typography>
                <Typography
                  class="text-right"
                  color="brand-default"
                  variant="title-large-secondary"
                  tag="th"
                >
                  Valor
                </Typography>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="detail in ads"
                :key="detail.id"
              >
                <td class="text-left">
                  <div class="py-4">
                    <a
                      :href="detail.external_url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        class="text-center underline"
                        color="neutral-grey-7"
                        variant="placeholder"
                        tag="span"
                      >
                        {{ detail.portal }}
                      </Typography>
                    </a>
                  </div>
                </td>
                <Typography
                  class="text-center"
                  color="neutral-grey-7"
                  variant="placeholder"
                  tag="td"
                >
                  {{ detail.utilArea }} m²
                </Typography>
                <Typography
                  class="text-right"
                  color="neutral-grey-7"
                  variant="placeholder"
                  tag="td"
                >
                  {{ formatCurrency(detail.value) }}
                </Typography>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ads {
  position: absolute;
  top: 0;
  left: 0;

  width: clamp(100%, 100%, 100vw);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1B2644;

  z-index: 999;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
}
</style>
