<template>
  <BaseCard
    bg-color="#ECEFF8"
    class="default-typology-card"
  >
    <div
      v-if="typology"
      class="default-typology-card__headline headline-container"
    >
      <div class="headline-container__title">
        {{ typology.propertyType }}
      </div>
      <div class="headline-container__chips">
        <div
          v-if="offerCount"
          class="chips chips--offer-count"
        >
          {{ offerCount }}
        </div>
        <div
          v-if="typologyRate"
          class="chips chips--typology-rate"
        >
          {{ typologyRate }}
        </div>
      </div>
    </div>
    <div
      v-if="typology"
      class="default-typology-card__info-container"
    >
      <div class="default-typology-card__typology-info typology-info">
        <div class="typology-info--area">
          {{ typologyArea }}
        </div>
        <div class="typology-info--bedrooms">
          {{ typologyBedrooms }}
        </div>
        <div class="typology-info--suites">
          {{ typologySuites }}
        </div>
        <div class="typology-info--parking-lots">
          {{ typologyParkingLots }}
        </div>
      </div>

      <div class="default-typology-card__prices-info">
        <div class="flex prices">
          <div class="px-2 fs-14">
            <b>Valor ofertado</b>
            <div
              class="text-primary flex"
              :class="{ 'text-center': !priceHistory }"
            >
              <span v-if="priceHistory">{{ priceHistory.offerPrice | money }}</span>
              <span v-else>N/I</span>
              <span
                v-if="priceHistory && priceHistory.graphData && priceHistory.graphData.length > minChartDataLength"
                class="icon-wrapper"
              >
                <v-icon
                  v-if="priceHistory.value"
                  class="chart-icon"
                  :class="{'chart-not-plain': (priceHistory && priceHistory.value !== 'plain')}"
                  color="#7387C4"
                  dense
                  @click="highlightedTypology = offerGraph"
                >
                  icon-trending-{{ (priceHistory && priceHistory.value) || 'plain' }}
                </v-icon>
              </span>
            </div>
          </div>
          <div class="px-2 fs-14 w-28">
            <b>Valor AVM</b>
            <div
              class="flex text-primary"
              :class="{ 'text-center': !typology.market.price }"
            >
              <div v-if="typology.market && typology.market.price">
                <template v-if="typology.market.updatedAt">
                  <tooltip :message="lastUpdatedAtMessage(typology.market.updatedAt)">
                    <span class="tooltip__hook">
                      {{ typology.market.price | money }}
                    </span>
                  </tooltip>
                </template>
                <template v-else>
                  <span>
                    {{ typology.market.price | money }}
                  </span>
                </template>
              </div>
              <div v-else>
                <span>N/I</span>
              </div>
              <span
                v-if="typology.market && typology.market.chart && typology.market.chart.data && typology.market.chart.data.length > minChartDataLength"
                class="icon-wrapper"
              >
                <v-icon
                  v-if="typology.market.chart.text"
                  class="chart-icon"
                  :class="{'chart-not-plain': (typology.market && typology.market.chart && typology.market.chart.value !== 'plain')}"
                  color="#7387C4"
                  dense
                  @click="highlightedTypology = typology.market.chart"
                >
                  icon-trending-{{ (typology.market && typology.market.chart && typology.market.chart.value) || 'plain' }}
                </v-icon>
              </span>
            </div>
          </div>
          <OfferPriceTrendPopup
            v-if="highlightedTypology"
            :highlightedTypology="highlightedTypology"
            @update:visibility="highlightedTypology = null"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>

import Tooltip from '@/components/tooltip/Tooltip.vue';
import InfoIcon from '@/components/icons/InfoIcon';
import BaseCard from './base-card';
import OfferPriceTrendPopup from '../../views/property/OfferPriceTrendPopup.vue';
import { lastUpdatedAtMessage } from '@/utils/lastUpdatedAtMessage';

export default {
  name: 'DefaultTypologyCard',
  components: {
    InfoIcon,
    Tooltip,
    BaseCard,
    OfferPriceTrendPopup,
  },
  props: {
    typology: {
      type: Object,
      default: null,
    },
    priceHistory: {
      default: null,
    },
  },
  data () {
    return {
      minChartDataLength: 2,
      highlightedTypology: null,
    };
  },
  computed: {
    offerCount () {
      const pluralizedString = this.pluralizeString(this.typology.offerCount);

      return `${this.typology.offerCount} anúncio${pluralizedString}`;
    },
    typologyRate () {
      return `${parseInt(this.typology.rate)}% do total de anúncios`;
    },
    typologyArea () {
      const value = this.typology.area ? this.typology.area : 0;

      return `Área útil ${value} m² | `;
    },
    typologyBedrooms () {
      const value = this.typology.bedrooms ? this.typology.bedrooms : 0;
      const pluralizedString = this.pluralizeString(value);

      return `${value} dorm${pluralizedString}. | `;
    },
    typologySuites () {
      const value = this.typology.suites ? this.typology.suites : 0;
      const pluralizedString = this.pluralizeString(value);

      return `${value} suite${pluralizedString} | `;
    },
    typologyParkingLots () {
      const value = this.typology.parkingLots ? this.typology.parkingLots : 0;
      const pluralizedString = this.pluralizeString(value);

      return `${value} vaga${pluralizedString}`;
    },
    offerGraph () {
      return {
        title: 'Histórico valor médio das ofertas',
        type: 'chart',
        value: this.priceHistory.value,
        text: this.priceHistory.offerPrice,
        data: this.priceHistory.graphData,
      };
    },
  },
  methods: {
    lastUpdatedAtMessage,
    pluralizeString (value) {
      return value == 1 ? '' : 's';
    },
  },
};
</script>

<style lang="scss" scoped>
.default-typology-card {
  @apply py-4;

  font-family: Lato;
  color: #384159;
  font-size: 14px;

  &__headline {
    @apply flex gap-3;
  }

  &__info-container {
    @apply grid grid-cols-12;
  }

  &__typology-info {
    @apply col-span-5 flex;
  }

  &__prices-info {
    @apply col-span-7 ml-auto;
  }

  .text-primary {
    color: #3566c9;
    font-weight: 700;
    margin-top: 14px;
  }

  .v-btn {
    font-size: 12px;
    font-weight: 700;
    text-transform: none;
  }

  .chart-icon {
    font-size: 32px !important;
    height: 21px !important;
  }

  .chart-not-plain {
    font-size: 28px !important;
    height: 21px !important;
  }

  .prices {
    font-size: 12px;
    b {
      @apply font-bold;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 12px;
      color: #384159;
    }
  }

  &-details {
    line-height: 15px;
  }
}

.headline-container {
  &__title {
    @apply font-black;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #384159;
  }

  &__chips {
    @apply flex gap-2;
    font-size: 12px;
  }
}

.chips {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 5px 15px;
  border-radius: 9999px;
  font-weight: 600;

  &--offer-count {
    background-color: #7387C4;
    color: #fff;
  }

  &--typology-rate {
    @apply ml-1;
    background-color: #d3d6de;
    color: #384159;
  }
}

.typology-info {
  @apply flex;
  @apply mt-auto pb-1 gap-1;
  @apply text-center;

  font-weight: 400 !important;
}

.fs-14{
  font-size: 14px !important;
  &:not(:first-child) {
    @apply pl-3;
    border-left: 1px solid rgba(56, 65, 89, .15);
  }
}
.w-28{
  width: 7rem;
}

.icon-wrapper{
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  height: 21px;
}

.info-icon {
  @apply m-auto;
  color: #A2A5AB;
}

.tooltip {
    &__hook {
    color: $colors-highlight-default;
    display: inline-block;
    border-bottom: 1px dashed $colors-highlight-default;
  
    &:hover {
      cursor: pointer;
    }
  }
}

</style>
