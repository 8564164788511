import { css } from '@/core/theme';

const root = css({

  variants: {

    state: {

      'loading': {
        transition: 'filter .25s',
        filter: 'grayscale(100%) brightness(0.925)',
      },

      'idle': { },
    },
  },

  maxWidth: '300px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  columnGap: '$small',
});

const by = css({
  minWidth: '292px',
});

export const styles = {
  root,
  by,
};
