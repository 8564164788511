<template>
  <base-card>
    <custom-data-table :headers="labels" :items="items" />
  </base-card>
</template>

<script>

import BaseCard from '../../components/cards/base-card.vue';
import CustomDataTable from '../../components/tables/CustomDataTable.vue';

export default {
  components: {
    BaseCard,
    CustomDataTable,
  },
  props: {
    searchResults: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      labels: [
        {
          text: 'Nome do condomínio',
          value: 'condo',
          align: 'start',
        },
        {
          text: 'Endereço',
          value: 'address',
          align: 'start',
        },
        {
          text: 'Bairro',
          value: 'district',
          align: 'center',
        },
        {
          text: 'Cidade',
          value: 'city',
          align: 'center',
        },
        {
          text: 'Estado',
          value: 'state',
          align: 'center',
        },
        {
          text: 'Área útil',
          value: 'usefulArea',
          align: 'center',
        },
        {
          text: 'Dorms.',
          value: 'bedrooms',
          align: 'center',
        },
        {
          text: 'Suítes',
          value: 'suites',
          align: 'center',
        },
        {
          text: 'Vagas',
          value: 'parkingLots',
          align: 'center',
        },
        {
          text: 'Ofertas',
          value: 'offers',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    items() {
      return this.searchResults.map(row => {
        const { condo, addressLine1, addressLine2, typology, offersCount } = row;
        return {
          condo,
          address: addressLine1,
          district: addressLine2.split('-')[0],
          city: addressLine2.split('-')[1],
          state: addressLine2.split('-')[2],
          usefulArea: typology.utilArea ? `${this.minMaxTransformer(typology.utilArea)} m²` : '-',
          bedrooms: typology.bedRooms ? this.minMaxTransformer(typology.bedRooms) : '-',
          suites: typology.suites ? this.minMaxTransformer(typology.suites) : '-',
          parkingLots: typology.parkingLots ? this.minMaxTransformer(typology.parkingLots) : '-',
          offers: offersCount,
        };
      });
    },
  },
  methods: {
    minMaxTransformer(arr) {
      const uniqueValues = new Set();
      arr.forEach(value => uniqueValues.add(value));
      const newArray = Array.from(uniqueValues);
      return (newArray.length >= 2) ? `${newArray[0]} a ${newArray[newArray.length - 1]}` : newArray[0];
    },
  },
};
</script>
