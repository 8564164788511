<template>
  <div class="lgpd-blocked-info-message">
    <div class="content mx-auto">
      <span class="icon">
      <PaddockIcon />
    </span>
    <Typography
      color="neutral-grey-5"
      variant="caption"
      tag="span"
    >
      {{ message }}
    </Typography>
      <span></span>
    </div>
  </div>
</template>

<script>
import PaddockIcon from "@/components/icons/PaddockIcon";
import Typography from "../base/Typography";
export default {
  name: 'BlockedInfoMessage',
  components: { PaddockIcon, Typography },
  props: {
    message: {
      type: String,
      default: '',
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  display: contents;
}
.lgpd-blocked-info-message {
  @apply px-4 py-2 flex items-center mx-auto;
  min-height: 37px;
  font-size: 12px;
  color: #707070;
  background-color: #E8E8E8;
  .icon {
    @apply inline-block mr-2;
    color: #E35050;
  }
}
</style>
