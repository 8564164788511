import { css } from '@/core/theme';

const root = css({
  position: 'relative',
  width: '100%',
});

const field = css({

  variants: {

    variant: {

      default: { },

      sort: {
        maxHeight: '40px',

        '& > input': {
          height: '40px',
          borderRadius: '5px',
          fontSize: '$select-medium',

          '&:hover:not([disabled])': {
            borderColor: '$brand-dark',
            boxShadow: 'inset 0 0 0 1px $colors$brand-dark',
          },

          '&:focus': {
            backgroundColor: '$brand-dark',
            color: '$neutral-white',
            borderColor: '$brand-dark',
            boxShadow: 'inset 0 0 0 1px $colors$brand-dark',
          },
        },
      },
    },

    listShowing: { },
  },

  '& > input': {
    cursor: 'pointer',
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '$large',
  },

  '& > label': {
    cursor: 'pointer !important',
  },
});

const icon = css({

  variants: {

    variant: {

      default: { },

      sort: {
        top: '$xs',
      },
    },

    listShowing: { },

    disabled: {

      true: {
        cursor: 'not-allowed',
      },

      false: { },
    },
  },

  compoundVariants: [
    {
      variant: 'default',
      listShowing: true,
      css: {
        transform: 'rotate(180deg)',
      },
    },

    {
      variant: 'sort',
      listShowing: true,
      css: {
        
        '& > svg': {

          '& path': {
            fill: '$neutral-white',
          },
        },
      },
    },
  ],

  display: 'inline-block',
  position: 'absolute',
  top: '$medium',
  right: '$small',
  cursor: 'pointer',
  transition: 'all .25s',
});

const list = css({

  variants: {

    variant: {

      default: { },

      sort: {
        top: 'calc($small * 3 - $quarck)',
      },
    },

    listShowing: { },
  },

  position: 'absolute',
  top: 'calc($small * 4 - $quarck)',
  left: 0,
  zIndex: 12,
  backgroundColor: '$neutral-white',
  borderRadius: '5px',
  border: '1px solid $neutral-grey-2',
  boxShadow: '$small',
  width: '100%',
  transition: 'all .25s',
  overflow: 'auto',
  maxHeight: '196px',
  padding: '$nano 0',

  '& > ul': {
    padding: 0,
  },
});

const item = css({
  padding: '$quarck $small',
  transition: 'all .25s',
  fontSize: '$select-medium',
  color: '$brand-dark',
  
  '&:hover': {
    backgroundColor: '$neutral-grey-1',
    cursor: 'pointer',
  },
});

export const styles = {
  root,
  field,
  icon,
  list,
  item,
};
