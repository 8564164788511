import { Auth } from 'aws-amplify';

import gtag from '@/plugins/vendors/GTag';
import { isMobile } from '@/utils/user-agent';
import store from '@/store';

import { Redirector } from './redirector';

const before = async (to, from) => {

  const isAuthenticated = async () => {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

      await store.dispatch('user/setAccessToken', currentAuthenticatedUser);

      if (currentAuthenticatedUser 
      && currentAuthenticatedUser.attributes) {

        await store.commit('amplify/SET_USER', currentAuthenticatedUser);
        await store.commit('amplify/SET_EMAIL', currentAuthenticatedUser.attributes.email);

        return true;
      }
    } catch (e) {
      if (to.query?.error_description?.includes('PreSignUp')) {
        await store.commit('amplify/SET_HAS_PRESIGNUP_ERROR', true);
      }

      if(to.path === '/person-data') {
        localStorage.setItem('leadPath', to.fullPath)
      }

      return false;
    }
  };

  gtag('config', 'UA-62841054-9', {
    page_path: to.path,
  });

  const redirector = new Redirector(to, from, await isAuthenticated(), isMobile());

  const nextPath = async () => {

    return redirector
      .checkPublicRoutes()
      .authRoutes()
      .otherRules()
      .redirect();
  }; 

  return await nextPath();
};

export default before;
