<template>
  <div class="gtm-component" />
</template>

<script>
export default {
  name: 'GoogleTagManager',
  props: {
    trackEvent: {
      type: [Object, Array],
      default: null,
    },
  },
  watch: {
    trackEvent(data) {
      if (!data) return;
      if (Array.isArray(data)) {
        data.forEach(event => {
          if (!event || typeof event !== 'object' || Array.isArray(event)) return;
          this.$gtm.trackEvent(event);
        });
      } else {
        this.$gtm.trackEvent(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .gtm-component {
    @apply hidden;
  }
</style>
