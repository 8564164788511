<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <mask
      id="mask0_4_2230"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="36"
      height="36"
    >
      <rect
        width="36"
        height="36"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_4_2230)">
      <path
        d="M18 22.275L23.475 16.7625H12.4875L18 22.275ZM18 33C15.95 33 14.0125 32.6062 12.1875 31.8187C10.3625 31.0312 8.76875 29.9562 7.40625 28.5938C6.04375 27.2313 4.96875 25.6375 4.18125 23.8125C3.39375 21.9875 3 20.05 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.04375 8.7375 7.40625 7.3875C8.76875 6.0375 10.3625 4.96875 12.1875 4.18125C14.0125 3.39375 15.95 3 18 3C20.075 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0312 10.325 31.8187 12.15C32.6062 13.975 33 15.925 33 18C33 20.05 32.6062 21.9875 31.8187 23.8125C31.0312 25.6375 29.9625 27.2313 28.6125 28.5938C27.2625 29.9562 25.675 31.0312 23.85 31.8187C22.025 32.6062 20.075 33 18 33ZM18 30.75C21.55 30.75 24.5625 29.5063 27.0375 27.0188C29.5125 24.5313 30.75 21.525 30.75 18C30.75 14.45 29.5125 11.4375 27.0375 8.9625C24.5625 6.4875 21.55 5.25 18 5.25C14.475 5.25 11.4688 6.4875 8.98125 8.9625C6.49375 11.4375 5.25 14.45 5.25 18C5.25 21.525 6.49375 24.5313 8.98125 27.0188C11.4688 29.5063 14.475 30.75 18 30.75Z"
        :fill="offerTypeColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  computed: {
    offerTypeColor() {
      switch (this.offerType) {
        case 'bossaOffers':
          return '#044DA1'
        case 'lastMonthOffers':
          return '#000000'
        case 'newOffers':
          return '#006141'
        case 'previousOffers':
          return '#6B6B6B'
        case 'potentialOffers': {
          return '#555555'
        }
        default:
          return '#3366cc'
      }
    }
  },
  props: {
    offerType: {
      type: String,
      required: false,
    },
  }
}
</script>