<template>
  <div :class="styles.root().className">
    <div 
      id="app-wrapper" 
      :class="styles.wrapper().className"
    >
      <slot />
    </div>

    <div
      id="app-overlay"
      :class="styles.overlay().className"
    />
  </div>
</template>

<script>

import { styles } from './Layout.styles';

export default {

  name: 'Layout',

  created () {
    this.styles = styles;
  },
};

</script>
