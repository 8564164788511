<template>
  <Autocomplete
    id="g-map-autocomplete"
    :resettable="!!query.length"
    :focus="searchInputFocused"
    @clear="clearInputSearchValue"
  >
    <gmap-autocomplete
      ref="gMapAutocomplete"
      placeholder="Digite o nome do condomínio ou endereço"
      :options="autocompleteOptions"
      @keyup="onAutocompleteInputChange"
      @focusin="searchInputFocused = true"
      @focusout="searchInputFocused = false"
      @place_changed="onAutoCompleteSelection"
    />
  </Autocomplete>
</template>

<script>

import Autocomplete from '@/components/autocomplete/Autocomplete.vue';

import { mapGetters } from 'vuex';
import { CITY_BOUNDARIES } from '@/common/constants';

export default {

  name: 'GoogleMapAutocomplete',

  components: {
    Autocomplete,
  },

  model: {
    prop: 'model',
    event: 'select',
  },

  props: {
    model: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    query: '',
    localResult: null,
    searchInputFocused: false,
    pacContainerMoved: false,
    autocompleteOptions: {
      componentRestrictions: {
        country: 'br',
      },
      strictBounds: true,
      fields: [
        'geometry',
        'address_components',
      ],
    },
  }),

  computed: {
    ...mapGetters('map', [
      'geocoderResults',
      'currentCity',
    ]),
    selectedCityBoundary () {
  
      const cityNameEnum = {
        SP: 'SAO_PAULO',
        RJ: 'RIO_DE_JANEIRO',
      };

      return CITY_BOUNDARIES[cityNameEnum[this.currentCity]];
    },
    result: {

      get () {
        return this.localResult;
      },
  
      set (value) {
        this.localResult = value;
        this.$emit('select', value);
      },
    },
  },

  watch: {
    model (value) {
    
      if (!value) {
        this.clearInputSearchValue();
      }
    },
    geocoderResults (data) {
    
      const results = data && data.results;

      if (results && results.length 
        && this.$refs.gMapAutocomplete) {

        this.result = results[0];

        const address = this.result.formatted_address;

        this.$refs.gMapAutocomplete.$refs.input.value = address;
        this.query = address;
        this.$emit('change', this.query);
      }
    },
    searchInputFocused (value) {
    
      if (value 
        && !this.pacContainerMoved) {
      
        const autocompleteResults = document.querySelector('.pac-container');
        const addressCondoSearch = document.getElementById('g-map-autocomplete');

        if (autocompleteResults 
          && addressCondoSearch) {

          this.pacContainerMoved = true;
      
          addressCondoSearch.appendChild(autocompleteResults);
        }
      }
    },
    currentCity () {

      const { north, south, east, west } = this.selectedCityBoundary;
      const elementBounds = this.$refs.gMapAutocomplete.$autocomplete.bounds;
      const boundKeys = Object.keys(elementBounds);

      function setPoint (axis, point, value) {
        elementBounds[axis][point] = value;
      }

      boundKeys.forEach((boundKey, boundKeyIndex) => {

        const isNorthSouth = boundKeyIndex % 2 === 0;

        setPoint.apply(this, [
          boundKey,
          'hi',
          isNorthSouth ? north : east,
        ]);

        setPoint.apply(this, [
          boundKey,
          'lo',
          isNorthSouth ? south: west,
        ]);
      });
    },
  },
  created () {
    this.autocompleteOptions.bounds = this.selectedCityBoundary;
  },

  methods: {

    onAutocompleteInputChange (event) {
      this.query = event.target.value;
      this.$emit('change', this.query);
    },

    onAutoCompleteSelection (value) {
      this.result = value;
    },

    clearInputSearchValue () {
      this.searchInputFocused = false;
      this.query = '';
      this.$refs.gMapAutocomplete.$refs.input.value = '';
      this.$emit('change', this.query);
      this.$emit('clear');
    },
  },
};

</script>

<style lang="scss" scoped>

#g-map-autocomplete {
  &:deep(.pac-container) {
    @apply mt-12;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    padding: 16px;

    &::after{
      display: none;
    }

    .pac-item {
      color: var(--colors-neutral-grey-5);
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      .pac-item-query {
        color: var(--colors-brand-dark);
      }
    }
  }

  &:deep(.pac-icon) {
    display: none;
  }
}
</style>
