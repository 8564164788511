var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:_setup.styles.root({
    size: _vm.size,
    hasLabel: _setup.hasLabel,
    labelPosition: _vm.labelPosition,
  }).className},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.active),expression:"active"}],class:_setup.styles.checkbox().className,attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.active)?_vm._i(_setup.active,null)>-1:(_setup.active)},on:{"change":function($event){var $$a=_setup.active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.active=$$a.concat([$$v]))}else{$$i>-1&&(_setup.active=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.active=$$c}}}}),_c('span',{class:_setup.styles.slider({
      size: _vm.size,
      hasLabel: _setup.hasLabel,
      labelPosition: _vm.labelPosition,
    }).className}),(_setup.slots.default)?_c('div',{class:_setup.styles.label({ labelPosition: _vm.labelPosition }).className},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }