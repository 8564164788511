import { NAVBAR_HEIGHT } from '@/common/constants';
import { css } from '@/core/theme';

const root = css({

  variants: {

    variant: {

      'default': {
        position: 'fixed',
        backgroundPosition: '0 0',
      },

      'home': {
        position: 'absolute',
        backgroundPosition: '0 100%',
      },
    },
  },

  top: 0,
  zIndex: 16,
  width: '100%',
  height: NAVBAR_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 $large',
  transition: 'all .25s',
  backgroundSize: '100% 200%',
  backgroundImage: 'linear-gradient(to bottom, $brand-darker 50%, transparent 50%)',
});

const logo = css({

  variants: {

    variant: {

      'default': {
        color: '$neutral-white !important', // `!important` because Vuetify
      },

      'home': {
        color: '$brand-dark !important', // `!important` because Vuetify
      },
    },
  },
});

const nav = css({

  variants: {
    
    hidden: {

      true: {
        opacity: 0,
        visibility: 'hidden',
      },

      false: {
        opacity: 1,
      },
    },
  },

  display: 'flex',
  columnGap: '$xl',
  height: '100%',
  transition: 'all .25s',

  '& > div': {
    position: 'relative',
    height: '100%',
  },
});

const panel = css({

  variants: {

    active: {

      true: {
        opacity: 1,
      },

      false: {
        visibility: 'hidden',
      },
    },
  },

  position: 'fixed',
  top: NAVBAR_HEIGHT,
  left: 0,
  transition: 'all .001s',
  opacity: 0,
});

const item = css({

  variants: {

    variant: {

      'default': {
        
        '& > a': {
          color: '$neutral-white !important', // `!important` because Vuetify
        },
      },

      'home': {

        '& > a': {
          color: '$brand-dark !important', // `!important` because Vuetify
        },
      },
    },

    active: {

      true: {

        '& > a': {
          color: '$highlight-light !important', // `!important` because Vuetif
        },
      },

      false: { },
    },
  },

  position: 'relative',
  transition: 'all .25s',

  '& > a': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: '$neutral-white !important', // `!important` because Vuetify

    '&:hover': {
      color: '$highlight-light !important', // `!important` because Vuetify
    },
  },
});

export const styles = {
  root,
  logo,
  nav,
  panel,
  item,
};
