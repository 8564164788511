<template>
  <section id="login">
    <div class="wrapper-outer">
      <div class="bg bg-start">
        <img
          height="648px"
          src="@/assets/images/privacy-background.svg"
        >
      </div>
      <div class="wrapper">
        <div class="wrapper-inner">
          <slot />
          <footer class="copyright">
            &copy; Cidade Virtual {{ new Date().getFullYear() }} | Todos os direitos reservados.
          </footer>
        </div>
      </div>
      <div class="bg bg-end">
        <img
          width="648px"
          src="@/assets/images/privacy-background-alt.svg"
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LoginLayout',
  data: () => ({
    page: 'objetivo',
  }),
};
</script>

<style lang="scss" scoped>
  #login {
    @apply h-full w-full absolute left-0 top-0 overflow-hidden;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #707070;
    z-index: 4;

    .wrapper {
      @apply relative z-10 flex flex-col items-center justify-center flex-grow;
      &-inner {
        @apply mt-5;
      }
      &-outer {
        @apply h-screen overflow-auto;
        background-color: #f2f4fa;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }


    .copyright {
      @apply text-center my-5;
      font-size: 12px;
      color: #384059;
      bottom: 0;
    }

    .bg {
      img {
        @apply absolute;
      }
    }

    .bg {
      &-start {
        img {
          @apply top-0 left-0;
        }
      }
      &-end {
        img {
          @apply bottom-0 right-0;
          margin-top: -100%;
        }
      }
    }

  }
</style>
