<template>
  <div
    v-show="!hidden"
    class="filter-area"
  >
    <div class="filter-area-container">
      <slot name="header" />
      <slot name="location-selector" />
      <div class="filter-container">
        <slot name="filters" />
        <slot name="additional-filters" />
      </div>
    </div>
    <slot name="button-area" />
  </div>
</template>

<script>

export default {

  name: 'SearchFilter',

  props: {
    
    hidden: {
      type: [
        Boolean,
        Error,
      ],
      default: false,
    },
  },

  computed: {
    isMobile () {
      return this.$route.path.includes('mobile')
    }
  },
  
};

</script>

<style lang="scss" scoped>
.filter-area {
  overflow: auto;
  height: 100%;
  display: flex;
  padding: 26px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  width: 100%;
  background-color: $colors-neutral-white;
}
.filter-area-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.filter-container {
  display: flex;
  width: 100%;
  padding: 16px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}
</style>