import { css } from '@/core/theme';

const secondary = {
  display: 'inline-flex',
  height: '20px',
  padding: '5px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  border: '0.5px solid var(--colors-brand-dark)',
  cursor: 'default',
  color: '$brand-dark'
}

const altColorIcon = {
  facebook: {
    display: 'inline-flex',
    height: '20px',
    padding:'5px 10px',
    columnGap: '4px',
    borderRadius: '560px',
    border: '0.5px solid $neutral-grey-3',
    color: '$brand-default',
    cursor: 'default',
    width: '84px',
    backgroundColor: '$neutral-white'
  },

  procon: {
    display: 'inline-flex',
    height: '20px',
    padding:'5px 10px',
    gap: '4px',
    borderRadius: '560px',
    border: 'none',
    backgroundColor: '$feedback-error',
    color: '$neutral-white',
    cursor: 'default',
    width: '70px',
  },
}

const root = css({
  compoundVariants: [
    {
      variant: 'secondary',
      state: 'default',
      css: secondary,
    },

    {
      variant: 'secondary',
      state: 'hover',
      css: secondary,
    },

    {
      variant: 'secondary',
      state: 'active',
      css: secondary,
    },

    {
      variant: 'altColorIconFacebook',
      state: 'default',
      css: altColorIcon.facebook,
    },

    {
      variant: 'altColorIconFacebook',
      state: 'hover',
      css: altColorIcon.facebook,
    },

    {
      variant: 'altColorIconFacebook',
      state: 'active',
      css: altColorIcon.facebook,
    },

    {
      variant: 'altColorIconProcon',
      state: 'default',
      css: altColorIcon.procon,
    },

    {
      variant: 'altColorIconProcon',
      state: 'hover',
      css: altColorIcon.procon,
    },

    {
      variant: 'altColorIconProcon',
      state: 'active',
      css: altColorIcon.procon,
    },
  ],

  variants: {

    state: { },

    variant: {
      secondary: {
        ...secondary,
        '&:hover': secondary,
        '&:active': secondary,
      },

      altColorIconFacebook: {
        ...altColorIcon.facebook,
        '&:hover': altColorIcon.facebook,
        '&:active': altColorIcon.facebook,
      },

      altColorIconProcon: {
        ...altColorIcon.procon,
        '&:hover': altColorIcon.procon,
        '&:active': altColorIcon.procon,
      }
    }
  },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$quarck',
  padding: '$nano $xs',
  border: '1px solid $neutral-grey-3',
  borderRadius: '50px',
  transition: 'border-color .25s',

  '&:active': {
    borderColor: '$highlight-light',
    color: '$highlight-light',
  },

  '&:hover': {
    borderColor: '$highlight-light',
    cursor: 'pointer',
  },

  '& > svg': {
    width: '12px',
    height: '10px',
  },
});

export const styles = {
  root,
};
