<script setup>

import { computed } from 'vue';

const props = defineProps({
  hovered: {
    type: Boolean,
    default: false,
  },
});

const colors = {
  default: '#303A56',
  hovered: '#6B8CEF',
};

const color = computed(() => props.hovered ? colors.hovered : colors.default);

</script>

<template>
  <svg
    width="65"
    height="53"
    viewBox="0 0 65 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00013 48.0198H5.86588C5.70858 48.0198 5.58105 48.1473 5.58105 48.3046V51.5052C5.58105 51.6625 5.70857 51.79 5.86588 51.79H63.1598C63.3171 51.79 63.4446 51.6625 63.4446 51.5052V22.3408V8.07042V3.64445C63.4446 3.48714 63.3171 3.35962 63.1598 3.35962H54.9928C54.8355 3.35962 54.708 3.48714 54.708 3.64444V6.43964C54.708 6.59695 54.5805 6.72447 54.4232 6.72447H48.6512H41.5334C41.3761 6.72447 41.2486 6.852 41.2486 7.0093V20.5721C41.2486 20.7294 41.121 20.8569 40.9637 20.8569H36.1496C35.9923 20.8569 35.8648 20.7294 35.8648 20.5721V2.2985C35.8648 2.14119 35.7373 2.01367 35.58 2.01367H21.3442C21.1869 2.01367 21.0594 2.1412 21.0594 2.2985V20.1839V24.8947V29.6055V34.0307C21.0594 34.188 20.9319 34.3155 20.7746 34.3155H8.56979C8.41248 34.3155 8.28496 34.443 8.28496 34.6003V47.735C8.28496 47.8923 8.15744 48.0198 8.00013 48.0198Z"
      fill="#E3E8F5"
    />
    <path
      d="M19.3826 28.5918V33.017C19.3826 33.1743 19.2551 33.3018 19.0978 33.3018H6.89303C6.73572 33.3018 6.6082 33.4293 6.6082 33.5867V46.7213C6.6082 46.8786 6.48068 47.0061 6.32337 47.0061H4.18913C4.03182 47.0061 3.9043 47.1337 3.9043 47.291V51.1872C3.9043 51.3445 4.03182 51.4721 4.18912 51.4721H62.3032H62.0527C61.8954 51.4721 61.7679 51.3445 61.7679 51.1872V21.3271V7.05675V2.63077C61.7679 2.47347 61.6403 2.34594 61.483 2.34594H53.3161C53.1588 2.34594 53.0312 2.47347 53.0312 2.63077V5.42597C53.0312 5.58328 52.9037 5.7108 52.7464 5.7108H46.9745H39.8566C39.6993 5.7108 39.5718 5.83833 39.5718 5.99563V19.5584C39.5718 19.7157 39.4443 19.8432 39.287 19.8432H34.4729C34.3156 19.8432 34.188 19.7157 34.188 19.5584V1.28483C34.188 1.12752 34.0605 1 33.9032 1H19.6675C19.5102 1 19.3826 1.12752 19.3826 1.28483V19.1702V23.881V28.5918Z"
      stroke="#A5B2D9"
      stroke-width="0.708785"
    />
    <path
      d="M63.7111 51.6836L0.863281 51.6836"
      stroke="#A5B2D9"
      stroke-width="1.06318"
      stroke-linecap="round"
    />
    <circle
      cx="33.4897"
      cy="29.4199"
      r="17.0189"
      fill="white"
      :stroke="color"
      stroke-width="0.725806"
    />
    <line
      x1="45.8661"
      y1="41.3791"
      x2="47.9519"
      y2="43.4649"
      :stroke="color"
      stroke-width="0.587903"
    />
    <path
      d="M45.6592 45.7585L50.995 51.1871L55.6464 46.5357L50.1785 41.2393L45.6592 45.7585Z"
      :fill="color"
    />
    <path
      d="M51.0878 51.1871L46.0677 46.167C45.8421 45.9414 45.8421 45.5756 46.0677 45.3501L49.77 41.6477C49.9956 41.4221 50.3613 41.4221 50.5869 41.6477L52.4381 43.4989L54.6977 45.7585L55.6464 46.7072"
      :stroke="color"
      stroke-width="0.725806"
      stroke-linecap="round"
    />
    <path
      d="M44.3842 19.2075L31.7813 22.9781C31.619 23.0267 31.5273 23.1981 31.5769 23.36L33.3177 29.0468C33.3667 29.2069 33.5356 29.2977 33.6962 29.2503L44.3832 26.0951C44.513 26.0567 44.6021 25.9343 44.6021 25.799V19.3573C44.6021 19.2223 44.5135 19.1688 44.3842 19.2075Z"
      :fill="color"
    />
    <path
      d="M25.7726 42.2291L23.3379 33.6572C23.2921 33.4961 23.3846 33.3282 23.5452 33.2809L32.4318 30.6589C32.5932 30.6113 32.7627 30.7033 32.8108 30.8645L35.1774 38.7996C35.2244 38.957 35.1384 39.1234 34.9828 39.1761L31.7374 40.2763C31.5969 40.3239 31.5182 40.473 31.5582 40.6158C31.6008 40.768 31.7612 40.8545 31.9117 40.8065L35.2167 39.7533C35.3811 39.7009 35.5563 39.7951 35.6032 39.9612L36.1968 42.0627C36.2518 42.2576 36.1054 42.4511 35.9029 42.4511H26.0664C25.9299 42.4511 25.8099 42.3604 25.7726 42.2291Z"
      :fill="color"
    />
    <path
      d="M20.1631 25.412C20.5774 25.3623 26.8477 23.3633 30.1967 22.2846C30.3565 22.2332 30.444 22.0627 30.3936 21.9026L28.5653 16.0928M29.6316 16.0928L31.396 21.5279C31.4467 21.6842 31.6121 21.7723 31.7701 21.7273L41.0645 19.0775C41.2276 19.0309 41.3216 18.8604 41.2737 18.6976L40.5076 16.0928M40.9554 16.0928C41.0467 16.3515 41.473 17.6931 41.7405 18.5398C41.7903 18.6974 41.9563 18.7864 42.1153 18.7412L44.6021 18.0334M20.1631 26.457L30.4623 23.39C30.6266 23.3411 30.799 23.4369 30.8441 23.6023L32.4102 29.3447C32.454 29.5055 32.3609 29.6718 32.2008 29.7183L20.1631 33.2171M20.1631 34.1554C20.4429 34.1407 21.4848 33.8388 22.1714 33.6285C22.334 33.5787 22.5063 33.6701 22.5554 33.8329L25.1532 42.4511M44.6021 27.0114L34.0346 30.1671C33.8751 30.2147 33.7832 30.3813 33.8278 30.5416L35.1777 35.3898C35.2237 35.5549 35.3965 35.6499 35.5606 35.6001L44.6021 32.8546M44.6021 33.7503L35.7999 36.519C35.6425 36.5685 35.5529 36.7341 35.5975 36.8929L37.1595 42.4511M31.5582 40.6158V40.6158C31.5182 40.473 31.5969 40.3239 31.7374 40.2763L34.9828 39.1761C35.1384 39.1234 35.2244 38.957 35.1774 38.7996L32.8108 30.8645C32.7627 30.7033 32.5932 30.6113 32.4318 30.6589L23.5452 33.2809C23.3846 33.3282 23.2921 33.4961 23.3379 33.6572L25.7726 42.2291C25.8099 42.3604 25.9299 42.4511 26.0664 42.4511H35.9029C36.1054 42.4511 36.2518 42.2576 36.1968 42.0627L35.6032 39.9612C35.5563 39.7951 35.3811 39.7009 35.2167 39.7533L31.9117 40.8065C31.7612 40.8545 31.6008 40.768 31.5582 40.6158ZM44.3832 26.0951L33.6962 29.2503C33.5356 29.2977 33.3667 29.2069 33.3177 29.0468L31.5769 23.36C31.5273 23.1981 31.619 23.0267 31.7813 22.9781L44.3842 19.2075C44.5135 19.1688 44.6021 19.2223 44.6021 19.3573C44.6021 19.9121 44.6021 21.6286 44.6021 25.799C44.6021 25.9343 44.513 26.0567 44.3832 26.0951Z"
      :stroke="color"
      stroke-width="0.150968"
    />
  </svg>
</template>