import api from '../../api';

const initialState = {
  error: null,
  isLoading: false,
  detail: null,
  activeTab: 0,
  personQuery: '',
  hasFac: null,
  score: null,
};

const getters = {
  error: state => state.error,
  isLoading: state => state.isLoading,
  detail: state => state.detail,
  activeTab: state => state.activeTab,
  personQuery: state => state.personQuery,
  hasFac: state => state.hasFac,
  score: state => state.score,
};

const mutations = {
  SET_DETAIL(state, item) {
    state.detail = item;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_DETAIL_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ACTIVE_TAB(state, activeTab) {
    state.activeTab = activeTab;
  },
  SET_PERSON_QUERY(state, query) {
    state.personQuery = query;
  },
  SET_HAS_FAC(state, hasFac) {
    state.hasFac = hasFac;
  },
  SET_SCORE(state, score) {
    state.score = score;
  },
  SET_LOADING(state, status) {
    state.isLoading = status;
  }
};

const actions = {
  setActiveTab(context, tabName) {
    context.commit('SET_ACTIVE_TAB', tabName);
  },
  async fetchHasFac({ commit }, { phones, emails }) {
    const data = await api.search.resultHasFac(phones, emails);
    commit('SET_HAS_FAC', !!(data && data.hasFac));
  },
  async fetchScore({ commit }, { document, sequentialId }) {
    const score = await api.search.getPersonScore(document, sequentialId);
    commit('SET_SCORE', score);
  },
  setPersonQuery({ commit }, query) {
    commit('SET_PERSON_QUERY', query);
  },
  setLoading({ commit }, status) {
    commit('SET_LOADING', status);
  }
};

export default {
  namespaced: true,
  actions,
  state: initialState,
  getters,
  mutations,
};
