<script setup>

import {
  ref,
  computed,
} from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';
import PencilIcon from '@/components/icons/PencilIcon.vue';
import LegacyReportButton from '@/components/buttons/LegacyReportButton.vue';

/**
 * Styles
 */
import { styles } from './MapDrawPanel.styles';

/**
 * Props
 */
const props = defineProps({

  isDrawFinished: {
    type: Boolean,
    default: false,
  },

  isDrawable: {
    type: Boolean,
    default: false,
  },

  isClearable: {
    type: Boolean,
    default: false,
  },

  isResultPage: {
    type: Boolean,
    default: false,
  },
});

/**
 * Emits
 */
const emit = defineEmits([
  'draw',
  'clear',
]);

/**
 * Data
 */
const isDrawing = ref(false);

/**
 * Computed
 */
const isDrawableOrClearable = computed(() => props.isDrawable || props.isClearable);

/**
 * Refs
 */
const drawButton = ref(null);

/**
 * Methods
 */
const onDraw = () => {

  if (isDrawing.value) {

    onClear();
  }
  else {

    isDrawing.value = true;

    emit('draw');
  }
};

const onClear = () => {

  const { $el } = drawButton.value;

  if ($el) {

    $el.blur();
  }

  isDrawing.value = false;

  emit('clear', true);
};

</script>

<template>
  <div :class="styles.root().className">
    <div
      v-if="isDrawableOrClearable"
      v-show="!isDrawFinished"
      :class="styles.instructions({ isDrawing }).className"
    >
      <div>
        <Typography
          tag="span"
          variant="body-medium-secondary"
          color="brand-dark"
        >
          1.
        </Typography>

        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          Navegue pelo mapa até a área desejada
        </Typography>
      </div>

      <div>
        <Typography
          tag="span"
          variant="body-medium-secondary"
          color="brand-dark"
        >
          2.
        </Typography>

        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          Clique no botão <strong>"Desenhar"</strong> para marcar essa área
        </Typography>
      </div>
    </div>

    <div
      :class="styles.actions({
        isDrawing,
        isDrawableOrClearable,
      }).className"
    >
      <template v-if="isDrawableOrClearable">
        <Button
          v-show="!isDrawFinished"
          ref="drawButton"
          show-left-icon-on-hover
          variant="primary"
          :state="isDrawing ? 'hover' : undefined"
          @click="onDraw"
        >
          <template #left-icon>
            <PencilIcon />
          </template>
        
          <strong>
            {{ isDrawing ? 'Desenhando' : 'Desenhar' }}
          </strong>
        </Button>
      
        <Button
          v-if="isDrawFinished && !isResultPage"
          variant="secondary"
          @click="onClear"
        >
          <strong>
            Limpar desenho
          </strong>
        </Button>
      </template>
  
      <LegacyReportButton v-if="isResultPage" />
    </div>
  </div>
</template>
