<template>
  <section>
    <div v-if="score" class="flex flex-col" data-test-id="analysis-properties">
      <div class="mt-3">
        <h3 class="title--primary">Propriedades</h3>
        <div class="my-4">
          <div data-test="has-return-value">
            <div v-if="!score.properties" data-test="data-error-message">
              Não há dados disponíveis.
            </div>
            <div v-else class="flex w-full" data-test="success">
              <div class="w-6/12">
                <div class="flex">
                  <p class="title w-5/6">Tipo de imóvel</p>
                  <p class="title w-4/6 ml-12">Valor médio</p>
                </div>
                <div v-for="key in Object.keys(score.properties)" :key="key" class="flex justify-space-between mb-1">
                  <span class="active w-5/6" data-test="property-name">{{ getPropertyLabel(key) }}</span>
                  <span class="w-4/6 ml-12" data-test="property-value">
                    {{ score.properties[key] | money }}
                  </span>
                </div>
              </div>
              <div class="flex">
                <v-btn
                    data-test="details-button"
                    class="btn ml-auto"
                    rounded
                    width="117"
                    height="34"
                    color="rgba(70, 99, 185, .7)"
                    @click="setActiveTab('Addresses')"
                >Ver detalhes</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PROPERTY_TYPE_OPTIONS, PROPERTY_TYPE } from '@/common/constants';

export default {
  name: 'AnalysisProperties',
  data: () => ({
    PROPERTY_TYPE_OPTIONS,
    PROPERTY_TYPE,
  }),
  computed: {
    ...mapState('person', ['score']),
  },
  methods: {
    ...mapActions('person', ['setActiveTab']),
    getPropertyLabel(key) {
      const name = `${key}`.toUpperCase();
      const value = this.PROPERTY_TYPE[name];
      if (!value) return '';
      const res = this.PROPERTY_TYPE_OPTIONS.filter(item => item.value === value).pop();
      return res && res.label;
    },
  },
};
</script>

<style lang="scss">
  .active {
    color: #5F698C !important;
  }
</style>
