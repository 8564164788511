<script setup>

import {
  ref,
  computed, 
  onMounted,
  nextTick,
} from 'vue';

import { useRoute } from 'vue-router/composables';

import { useUpdatedStore } from '@/pinia/updatedStore';

const updatedStore = useUpdatedStore();

/**
 * Constants
 */
import {
  NAVBAR_MENU,
  NAVBAR_MENU_ITEMS,
} from '@/common/constants';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import NavbarPanel from '@/components/NavbarPanel';

/**
 * Styles
 */
import { styles } from './Navbar.styles';

/**
 * Props
 */
const props = defineProps({

  variant: {
    type: String,
    default: 'default',
    validator: (value) => {
      return [
        'default',
        'home',
      ].indexOf(value) >= 0;
    },
  },

  bnsirBroker: {
    type: Boolean,
    default: false,
  },
});

/**
 * Emits
 */
const emit = defineEmits([
  'navigate',
  'logout',
]);

/**
 * Router
 */
const route = useRoute();

/**
 * Refs
 */
const navRef = ref(null);

/**
 * Data
 */
const active = ref(false);
const bounds = ref({ });

/**
 * Computed
 */
const isHomeVariant = computed(() => props.variant === 'home');

const activeNavbarMenus = computed(() => NAVBAR_MENU.filter(menu => 
  props.bnsirBroker ? !menu.hidden : (!menu.hidden && !menu.bnsir),
));

/**
 * Mounted
 */
onMounted(async () => {
  
  await nextTick();

  const firstNavbarItem = navRef.value.querySelector('div[data-key]:first-child');

  if (firstNavbarItem) {

    onMouseEnter({
      target: firstNavbarItem,
      currentTarget: null,
    });
  }
});

/**
 * Methods
 */
const onMouseEnter = event => {

  const {
    target,
    currentTarget,
  } = event;

  if (currentTarget) {
    active.value = target.dataset.key;
  }

  bounds.value = target.getBoundingClientRect();

  const panel = 580; // <NavbarPanel> width
  const left = Math.round(bounds.value.left);
  const current = left + panel;
  const limit = window.innerWidth;
  const padding = currentTarget ? 20 : 0;

  if (current >= limit) {
    bounds.value.x = (left - panel) + (bounds.value.width + padding);
  }
};

const onMouseLeave = () => {
  active.value = false;
  bounds.value = { };
};

const onNavigate = (navigate, event, item) => {

  navigate(event);

  emit('navigate', item);

  setTimeout(onMouseLeave, 250);
};

const getItemKey = item => `navbarMenuItem_${item.label}`;

updatedStore.fetchUpdated();

</script>

<template>
  <header
    :class="styles.root({ variant }).className"
  >
    <div class="flex flex-col gap-2 pt-4">
      <Typography
        id="logo-home"
        tag="router-link"
        variant="header-xl-secondary"
        to="/home"
        :class="styles.logo({ variant }).className"
      >
        Cidade Virtual
      </Typography>

      <Typography
        tag="p"
        variant="body-xs"
        color="brand-lighter"
      >
        Atualizado em {{ updatedStore.updatedAt }}
      </Typography>
    </div>

    <nav
      ref="navRef"
      :class="styles.nav({ hidden: isHomeVariant }).className"
    >
      <div
        v-for="item in activeNavbarMenus"
        :key="getItemKey(item)"
        :data-key="getItemKey(item)"
        :class="styles.item({
          variant,
          active: item && (route.path === item.href),
        }).className"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <router-link
          v-slot="{ href, navigate }"
          :to="item.href"
        >
          <Typography
            variant="body-table"
            tag="a"
            :href="href"
            @click="onNavigate(navigate, $event, item)"
          >
            {{ item && item.label }}
          </Typography>
        </router-link>
      
        <div
          :class="styles.panel({
            active: active === getItemKey(item),
          }).className"
          :style="{
            left: `${bounds.left}px`,
          }"
        >
          <NavbarPanel
            :data="item"
            :bnsir-broker="bnsirBroker"
            @navigate="onNavigate"
          />
        </div>
      </div>

      <div
        :class="styles.item({
          variant,
        }).className"
      >
        <router-link
          v-slot="{ href, navigate }"
          :to="NAVBAR_MENU_ITEMS.LOGOUT.href"
        >
          <Typography
            tag="a"
            variant="body-table"
            :href="href"
            @click="onNavigate(navigate, $event, NAVBAR_MENU_ITEMS.LOGOUT)"
          >
            {{ NAVBAR_MENU_ITEMS.LOGOUT.label }}
          </Typography>
        </router-link>
      </div>
    </nav>
  </header>
</template>
