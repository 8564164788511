import { render, staticRenderFns } from "./NavbarPanelInvestorIcon.vue?vue&type=template&id=01381f6e"
import script from "./NavbarPanelInvestorIcon.vue?vue&type=script&setup=true&lang=js"
export * from "./NavbarPanelInvestorIcon.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports