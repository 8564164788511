<template>
  <div class="unit-filters">
    <div
      class="flex flex-wrap"
      @keyup.enter="searchByEnterKey()"
    >
      <div
        v-if="condo"
        class="w-full mb-4"
      >
        <CondominiumCard :condominium="condo" />
      </div>

      <div class="flex gap-4 mb-4 container-height">
        <!-- propertyType -->
        <div class="w-1/2 select-height">
          <v-select
            compact
            eager
            outlined
            v-model="form.propertyType"
            name="tipologia"
            label="Tipologia"
            placeholder="Tipologia"
            :items="propertyTypes.map(value => value.description)"
            :menu-props="{ bottom: true, offsetY: true }"
          >
          </v-select>
        </div>
        
        <!-- areaRange -->
        <div class="w-1/2 select-height">
          <v-select
            compact
            eager
            outlined
            v-model="form.areaRange"
            name="metragem"
            label="Metragem"
            placeholder="Metragem"
            :items="metragens.map(value => `${value}`)"
            :menu-props="{ bottom: true, offsetY: true }"
          >
          </v-select>
        </div>
      </div>
      <div class="flex gap-4 mb-4">
        <!-- bedrooms -->
        <div class="w-1/2 text-field-container">
          <v-text-field
            v-model="form.bedrooms"
            v-mask="'#########'"
            name="bedrooms"
            label="Dormitórios"
            placeholder="Dormitórios"
            outlined
          />
        </div>

        <!-- suites -->
        <div class="w-1/2">
          <v-text-field
            v-model="form.suites"
            v-mask="'#########'"
            name="suites"
            label="Suítes"
            placeholder="Suítes"
            outlined
          />
        </div>
      </div>
      <div class="flex gap-4 mb-4">
        <!-- parkingLots -->
        <div class="w-1/2">
          <v-text-field
            v-model="form.parkingLots"
            v-mask="'#########'"
            name="parkingLots"
            label="Vagas"
            placeholder="Vagas"
            outlined
          />
        </div>

        <!-- yearOfConstruction -->
        <div class="w-1/2">
          <v-text-field
            v-model="form.yearOfConstruction"
            v-mask="'####'"
            name="age"
            label="Ano de construção"
            placeholder="Ano de construção"
            outlined
          />
        </div>
      </div>

      <div class="flex flex-column gap-4">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
        Selecione as características
        </Typography>
        <div class="flex flex-wrap align-center gap-2">
          <Button
            variant="secondaryXs"
            v-for="(attribute, attributeIndex) in chars"
            :key="`attributeIndex${attributeIndex}`"
            @click="() => setPropertyAttributes(attribute.id)"
            :class="[containsAttribute(attribute.id) && 'button-active', !containsAttribute(attribute.id) && 'button-default']"
          >
            {{ attribute.description }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mask } from 'vue-the-mask';
import { mapActions, mapState, mapMutations } from 'vuex';

import CondominiumCard from './CondominiumCard.vue';
import Select from '@/components/base/Select';
import TextField from '@/components/base/TextField';
import Badge from '@/components/base/Badge';
import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';

export default {

  directives: {
    mask,
  },
  components: {
    Button,
    CondominiumCard,
    Select,
    TextField,
    Badge,
    Typography,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },

    condo: {
      type: Object,
      default: () => null,
    },
  },
  data () {

    return {
      ticksLabels: [
        '0',
        '5',
        '10',
        '15',
        '20',
        '25',
        '30+',
      ],
      metragens: [],
      chars: [],
      checkbox_options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
      ],
      form: {
        propertyType: '',
        areaRange: '',
        bedrooms: null,
        suites: null,
        parkingLots: null,
        yearOfConstruction: null,
      },
    };
  },
  computed: {

    ...mapState({

      sellerAreaRangePercent (state, getters) {
        return getters[`${this.$route.name}/sellerAreaRangePercent`]; 
      },
    }),

    measures () {

      if (!(this.condo || this.condo.tipologies)) return [];

      return [...new Set(this.condo.tipologies)];
    },

    propertyTypes () {

      if (!this.condo || !this.condo.tipologies) {
        return [];
      }

      const tipologia = this.condo.tipologies.sort((a, b) => {
        if (a.label < b.label) return -1;

        if (a.label > b.label) return 1;
      });

      return tipologia;
    },

    typologyName () {
    
      return this.filter.propertyType ? this.propertyTypes.find(el => el.id === this.filter.propertyType).description : '';
    },
  },
  watch: {

    condo: {
      deep: true,
      handler (condo) {
        if (!condo) return;

        this.filter.propertyType = null;
        this.metragens = [];
        this.filter.propertyAttributes = condo.amenitiesIdCondominium || [];
      },
    },

    filter: {
      deep: true,
      handler (filter) {
        if (!filter) return;

        this.filter.typologyName = this.typologyName;
      },
    },

    form: {
      deep: true,
      handler (form, oldForm) {
        const propertyType = this.propertyTypes.find(value => value.description === form.propertyType);

        if (propertyType) {
          this.filter.propertyType = propertyType.id;
        }

        this.metragemPorTipo();

        if(form.areaRange !== ''){
          this.setAreaRange(form.areaRange);
        }

        this.filter.suites = form.suites;
        this.filter.bedrooms = form.bedrooms;
        this.filter.parkingLots = form.parkingLots;

        const age = Math.abs(new Date().getFullYear() - form.yearOfConstruction);
        
        this.filter.propertyAgeVar = age > 300 || !form.yearOfConstruction ? 30 : age;
      },
    },
  },
  async created () {
  
    this.$watch('filter', filter => {

      if (filter.cleared === true) {
        delete filter.cleared;
        this.clearHelpers();
      }
    });

    this.chars = await this.getCharacteristics({ domain: this.$route.name });
  },
  methods: {
    ...mapActions({
  
      getCharacteristics (dispatch, payload) {
        return dispatch(`${this.$route.name}/getCharacteristics`, payload); 
      },
    }),
    ...mapMutations({

      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },

      searchByEnterKey (commit, payload) {
        return commit(`${this.$route.name}/SEARCH_BY_ENTER_KEY`, payload); 
      },
    }),
    onAttributeClick (attributeId) {

      const attributes = this.filter.propertyAttributes;

      if (attributes.includes(attributeId)) {

        const index = attributes.indexOf(attributeId);

        if (index > -1) {
          attributes.splice(index, 1);
        }
      }
      else {
    
        attributes.push(attributeId);
      }
    },
    setPropertyAttributes (attribute) {
      if(this.containsAttribute(attribute)){
        return this.filter.propertyAttributes = this.filter.propertyAttributes.filter(propAttribute => propAttribute !== attribute)
      }
      this.filter.propertyAttributes = [attribute, ...this.filter.propertyAttributes]
    },
    setAreaRange (measure) {
      const value = measure * this.sellerAreaRangePercent;
      const min = parseInt(measure - value, 10);
      const max = parseInt(measure + value, 10);
      const val = parseInt(measure, 10);
      
      this.filter.areaRange = [
        min,
        max,
        val,
      ];
    },
    metragemPorTipo () {

      this.filter.areaRange = null;

      this.metragens = [];

      let indexTipoligia = 0;

      for (; indexTipoligia < this.propertyTypes.length; indexTipoligia++) {
        const element = this.propertyTypes[indexTipoligia].id;

        if (this.propertyTypes[indexTipoligia].id === this.filter.propertyType) {
          this.metragens = this.propertyTypes[indexTipoligia].units.utilArea;
        }
      }
    },
    clearHelpers () {
    
      this.form = {
        propertyType: '',
        areaRange: '',
        bedrooms: 0,
        suites: 0,
        parkingLots: 0,
        yearOfConstruction: null,
      };
    },
    containsAttribute (attribute) {
      return this.filter.propertyAttributes.find(propAttribute => propAttribute === attribute)
    },
  },
};

</script>

<style lang="scss" scoped>
.unit-filters {
  display: flex;
  gap: 12px;
  flex-grow: 0;
  font-family: "Lato", sans-serif;
}
.button-active {
  color: var(--colors-neutral-white)!important;
  border-color: 1px solid var(--colors-highlight-default)!important;
  background-color: var(--colors-highlight-default)!important;
}
.button-default {
  color: var(--colors-highlight-light)!important;
  background-color: var(--colors-neutral-white)!important;
}
:deep(.v-input__slot) {
  min-height: 36px!important;
  height: 36px!important;
  margin-bottom: 0px!important;
}
:deep(.v-text-field--outlined){
  border-radius: 8px;
  height: 36px;
  cursor: pointer;
}
:deep(.v-text-field--outlined fieldset){
  min-height: 40px!important;
  height: 40px!important;
  background-color: $colors-highlight-lighter;
  border-color: #E8E8E8;
}
:deep(.v-text-field--outlined .v-label--active) {
  top: 16px!important;
  color: $colors-highlight-default;
}
:deep(.v-text-field--outlined .v-label) {
  top: 7px;
}
:deep(.v-input__append-inner){
  margin-top: 6px!important;
}
:deep(.v-icon.v-icon){
  font-size: 32px;
}

:deep(.v-input input){
  cursor: pointer;
}
:deep(.v-menu__content){
  cursor: pointer;
}
:deep(.v-select__selection){
  margin: 4px 4px 7px 0;
}
:deep(.v-input__control) {
  height: 36px;
}
:deep(.v-text-field--outlined.v-input--is-focused fieldset){
  border-color: $colors-highlight-default!important;
  top: -4px;
}
.text-field-container > div > input {
  height: 40px!important;
}
.select-height {
  height: 40px;
}
.container-height {
  height: 36px;
}
</style>
