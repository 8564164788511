import { css } from '@/core/theme';
import { NAVBAR_HEIGHT } from '@/common/constants';

const wrapper = css({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '412px 1fr',
  columnGap: '$small',
  backgroundColor: '$highlight-lighter',
});

const sidebar = css({
  position: 'sticky',
  top: NAVBAR_HEIGHT,
  height: `calc(100vmin - ${NAVBAR_HEIGHT})`,
  backgroundColor: '$neutral-white',
});

const content = css({
  position: 'relative',
  backgroundColor: '$neutral-white',
  marginTop: '$small',
  marginRight: '$large',
  padding: '$medium $xxl $small $medium',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
});

const sorter = css({
  margin: '$nano 0 $small $small',
});

const listings = css({

  '& > [data-skeleton]': {
    margin: '0 0 $medium $small',
  },
});

const card = css({ });

const divider = css({

  variants: {

    owner: {

      'sorter': {
        borderTop: '1px solid $neutral-grey-2',
        margin: '$medium 0 $large',
      },
    },
  },

  borderTop: '1px solid $neutral-grey-1',
  margin: '$small 0 $small $small',
});

const pagination = css({
  display: 'flex',
  justifyContent: 'center',
  margin: '$large 0 $small',
});

const skeleton = css({

  '&:last-child': {
    marginBottom: '$medium',
  },
  
  '& > [data-skeleton]': {
    marginLeft: '$small',
  },
});

export const styles = {
  wrapper,
  sidebar,
  content,
  sorter,
  listings,
  card,
  divider,
  pagination,
  skeleton,
};
