<template>
  <div :class="styles.root().className">
    <Button
      :class="styles.button().className"
      variant="icon"
      @click="onHelpButtonClick"
    >
      <QuestionMarkIcon />
    </Button>

    <HelpBuyerModal
      v-if="isBuyer"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpBuyerModal>

    <HelpSellerModal
      v-else-if="isSeller"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpSellerModal>

    <HelpRentModal
      v-else-if="isRent"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpRentModal>

    <HelpLeadModal
      v-else-if="isProspect"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpLeadModal>

    <HelpPersonDataModal
      v-else-if="isPersonData"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          class="person-data-footer"
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpPersonDataModal>

    <HelpAvmModal
      v-else-if="isAvm"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpAvmModal>

    <HelpOpenSearchModal
      v-else-if="isOpenSearch"
      @close="onHelpModalClose"
    >
      <template #footer>
        <HelpModalFooter
          @understand="onUnderstandButtonClick"
        />
      </template>
    </HelpOpenSearchModal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import Button from '@/components/base/Button';

import HelpBuyerModal from '@/components/HelpBuyerModal';
import HelpSellerModal from '@/components/HelpSellerModal';
import HelpRentModal from '@/components/HelpRentModal';
import HelpLeadModal from '@/components/HelpLeadModal';
import HelpPersonDataModal from '@/components/HelpPersonDataModal';
import HelpAvmModal from '@/components/HelpAvmModal';
import HelpOpenSearchModal from '@/components/HelpOpenSearchModal';
import HelpModalFooter from '@/components/HelpModalFooter';

import QuestionMarkIcon from '@/components/icons/QuestionMarkIcon';

import { styles } from './HelpButton.styles';

export default {

  name: 'HelpButton',

  components: {
    Button,

    HelpBuyerModal,
    HelpSellerModal,
    HelpRentModal,
    HelpLeadModal,
    HelpPersonDataModal,
    HelpAvmModal,
    HelpOpenSearchModal,
    HelpModalFooter,

    QuestionMarkIcon,
  },

  data () {

    return {
      modalOpen: false,
    };
  },

  computed: {

    ...mapGetters('amplify', ['user']),

    helpModalKey () {
      return `CV_helpModal_${this.$route.name}_${this.user ? this.user.username : 'unknown'}`;
    },

    isBuyer () {
      return this.modalOpen && this.$route.name === 'buyer';
    },

    isSeller () {
      return this.modalOpen && this.$route.name === 'seller';
    },

    isRent () {
      return this.modalOpen && this.$route.name === 'rent';
    },

    isProspect () {
      return this.modalOpen && this.$route.name === 'lead';
    },

    isPersonData () {
      return this.modalOpen && this.$route.name === 'personData';
    },

    isAvm () {
      return this.modalOpen && this.$route.name === 'avm';
    },

    isOpenSearch () {
      return this.modalOpen && this.$route.name === 'openSearch';
    },
  },

  created () {
    this.styles = styles;
  },

  mounted () {

    const alreadyUnderstood = window.localStorage.getItem(this.helpModalKey);

    if (!alreadyUnderstood) {
      this.modalOpen = true;
    }
  },

  methods: {

    sendActivity (creativeName) {

      this.$activity.send({
        event: 'select_content',
        content_type: 'botao',
        creative_slot: 'widget-ajuda',
        creative_name: creativeName,
        level_name: this.$route.name,
      });
    },
    
    onHelpButtonClick () {

      this.sendActivity('interrogacao');
      
      this.modalOpen = true;
    },

    onHelpModalClose () {

      this.sendActivity('fechar');

      this.modalOpen = false;
    },

    onUnderstandButtonClick () {

      window.localStorage.setItem(this.helpModalKey, 'understood');

      this.sendActivity('ok');

      this.modalOpen = false;
    },
  },
};

</script>
