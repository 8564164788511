<script setup>

import { ref } from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';

/**
 * Icons
 */
import ChevronDownIcon from '@/components/icons/ChevronDownIcon';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon';

/**
 * Styles
 */
import { styles } from './SearchSummary.styles';

/**
 * Props
 */
const props = defineProps({

  headerText: {
    type: String,
    default: 'Resumo',
  },

  footerText: {
    type: String,
    default: 'Filtros',
  },
});

/**
 * Data
 */
const showingFilters = ref(false);

/**
 * Methods
 */
const toggleFilters = () => showingFilters.value = !showingFilters.value;

</script>

<template>
  <div :class="styles.root().className">
    <div :class="styles.container().className">
      <div :class="styles.header().className">
        <Typography
          tag="h1"
          variant="button-small"
          color="neutral-white"
        >
          <strong>{{ headerText }}</strong>
        </Typography>
      </div>

      <div :class="styles.result().className">
        <slot name="result" />
      </div>

      <div :class="styles.footer().className">
        <div
          :class="styles.toggle({ state: showingFilters ? 'collapsed' : 'uncollapsed' }).className"
          @click="toggleFilters"
        >
          <Typography
            tag="span"
            variant="select-small"
            color="brand-dark"
          >
            <strong>{{ footerText }}</strong>

            <ChevronDownIcon v-if="!showingFilters" />
            <ChevronUpIcon v-else />
          </Typography>
        </div>

        <div
          :class="styles.filter({
            state: showingFilters ? 'collapsed' : 'uncollapsed'
          }).className"
        >
          <slot name="filter" />
        </div>
      </div>
    </div>
  </div>
</template>
