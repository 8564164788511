<template>
  <div class="row no-gutters h-100">
    <div class="col-5">
      <v-carousel
        class="h-full"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(media, index) in property.images"
          :key="index"
        >
          <template>
            <img
              :src="media.url"
              @error="$event.target.src = default404"
            >
          </template>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="col-7 relative text-sm">
      <div class="p-6">
        <v-chip
          color="primary"
          class="px-5 absolute show-table-btn mr-16 cursor-pointer"
          @click="showTable"
        >
          <template v-if="ads && ads.isLoading">
            <v-progress-circular
              indeterminate
              color="white"
              size="16"
              width="2"
            />
          </template>
          <template v-else-if="property.adsCount > 1">
            Ver todos os {{ property.adsCount }} anúncios
          </template>
          <template v-else>
            Ver o anúncio
          </template>
        </v-chip>
        <v-btn
          x-small
          icon
          outlined
          class="close-btn absolute ml-n5 mt-1"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="width:90%;">
          <h3
            class="typology font-weight-black"
            style="font-size:1.5em"
          >
            {{ property.propertyType + ' com ' + property.utilArea + 'm²' }}
          </h3>
          <div>
            <span
              v-if="property.addressLine1"
              class="text-gray-600 text-md font-weight-medium"
            >
              {{ property.addressLine1 }}
            </span>
            <br>
            <span
              v-if="property.addressLine2"
              class="text-gray-600 text-md font-weight-medium"
            >
              {{ property.addressLine2 }}
            </span>
            <br>
            <span
              v-if="property.addressLine3"
              class="text-gray-600 text-md font-weight-medium"
            >
              {{ property.addressLine3 }}
            </span>
          </div>
          <div class="text-xs my-1">
            <span class="text-main">
              Anunciado: {{ getFormattedDate(property.createdAt) }}
            </span>
            <span class="mx-1">|</span>
            <span>
              Atualizado: {{ getFormattedDate(property.updatedAt || property.createdAt) }}
            </span>
          </div>
        </div>
        <div class="location w-full mt-3">
          <div
            v-if="property.title || property.description"
            class="mb-3"
            style="max-height: 150px;overflow: auto"
          >
            <div
              v-if="property.title"
              :class="{ 'font-weight-bold': property.description }"
            >
              {{ property.title }}
            </div>
          </div>
          <div
            v-if="property.source && property.attributes && property.attributes.length"
            class="mb-3"
          >
            <b>Características:</b>
            {{ property.attributes.map(attr => attr.label).join(', ') }}
          </div>

          <div class="row my-0 propertyFeatures">
            <div class="col">
              <div class="row text-xs featureRow">
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{ property.utilArea ? property.utilArea + 'm²' : 'N/I' }}
                  </div>
                  <span v-if="property.propertyType === 'Casa'">Terreno</span>
                  <span v-else>Área util</span>
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{ property.bedrooms || 'N/I' }}
                  </div>
                  Dorm{{ property.bedrooms && property.bedrooms > 1 ? 's' : '' }}
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{ property.suites || 'N/I' }}
                  </div>
                  Suíte{{ property.suites && property.suites > 1 ? 's' : '' }}
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{ property.parking_lots || 'N/I' }}
                  </div>
                  Vaga{{ property.parking_lots && property.parking_lots > 1 ? 's' : '' }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row text-xs featureRow no-border">
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{ property.price ? $options.filters.money(property.price) : 'N/I' }}
                  </div>
                  <span>Valor</span>
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{
                      property.price && property.utilArea
                        ? $options.filters.money(parseInt(property.price / property.utilArea, 10))
                        : 'N/I'
                    }}
                  </div>
                  Valor m²
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{
                      property.condoValue
                        ? $options.filters.money(property.condoValue)
                        : 'N/I'
                    }}
                  </div>
                  Condomínio
                </div>
                <div class="col">
                  <div class="text-main value font-weight-bold">
                    {{
                      property.iptuValue ? $options.filters.money(property.iptuValue) : 'N/I'
                    }}
                  </div>
                  IPTU
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mx-auto mb-2">
          <div
            v-if="graphData && graphData.length > minChartDataLenght"
            class="chart w-full mx-auto px-2 mb-6"
          >
            <div class="my-4 font-weight-bold">
              Histórico de preços:
            </div>
            <GChart
              :height="30"
              :width="100"
              :settings="{ packages: ['corechart'], language: 'pt-BR' }"
              type="LineChart"
              :data="graphDataFormatted"
              :options="chartOptions"
              :resize-debounce="500"
              @ready="chartIsReady = true"
            />
            <div
              v-show="!chartIsReady"
              class="w-full text-center"
            >
              <v-progress-circular
                class="mx-auto"
                color="rgba(70, 99, 185, 0.95)"
                indeterminate
              />
            </div>
          </div>
          <div
            v-else
            class="my-4"
          >
            <strong>Histórico de preços:</strong> Sem histórico.
          </div>
        </div>

        <div class="row no-gutters">
          <div
            class="col"
            style="text-align: right"
          >
            <a
              class="report-btn underline"
              @click="flagProperty = property"
            >
              Encontrou informação incorreta? Denuncie.
            </a>
            <PropertyFlaggingDialog
              v-if="!!flagProperty"
              :property="flagProperty"
              :report-reasons="['Preço da oferta', 'Dados do imóvel', 'Descrição']"
              is-offer
              :reason="3"
              @update:visibility="flagProperty = null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts';
import PropertyFlaggingDialog from './PropertyFlaggingDialog.vue';
import default404 from '@/assets/images/404.jpeg';

export default {
  name: 'PropertyModalContent',
  components: {
    GChart,
    PropertyFlaggingDialog,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    graphData: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      minChartDataLenght: 2,
      flagProperty: null,
      chartIsReady: false,
      chartOptions: {
        height: 180,
        curveType: 'function',
        pointSize: 5,
        vAxis: {
          format: 'R$ #,###.###',
        },
        legend: {
          position: 'right',
        },
        tooltip: {
          isHtml: true,
        },
        chartArea: {
          width: '60%',
          height: '50%',
        },
        colors: [
          '#768AC6',
          '#4663B9',
          '#39415A',
        ],
      },
    };
  },
  computed: {
    ...mapGetters('property', ['ads']),
    default404 () {
      return default404;
    },
    graphDataFormatted () {
      if (!this.graphData || !this.graphData.length) return this.graphData;

      const titles = this.graphData[0];
      const tooltipColumn = { type: 'string', role: 'tooltip', p: { html: true } };

      return this.graphData.map(([
        data,
        min,
        avg,
        max,
      ], index) => {
        const prettyDate = this.getFormattedDate(data);

        if (!index) {
          return [
            data,
            min,
            tooltipColumn,
            avg,
            tooltipColumn,
            max,
            tooltipColumn,
          ];
        }

        return [
          prettyDate,
          min,
          this.getTooltipHTML(prettyDate, titles[1], this.$options.filters.money(min)),
          avg,
          this.getTooltipHTML(prettyDate, titles[2], this.$options.filters.money(avg)),
          max,
          this.getTooltipHTML(prettyDate, titles[3], this.$options.filters.money(max)),
        ];
      });
    },
  },
  methods: {
    getTooltipHTML (date, title, value) {
      return `<div class="p-2" style="min-width: 150px">
            <strong>${date}</strong>
            <div>${title}: <b>${value}</b></div>
          </div>`;
    },
    getFormattedDate (value) {
      if (value) {
        const date = value.split('-').reverse();

        if (date.length === 2) date.unshift('01');

        if (date.length === 3) {
          const dateObject = new Date(date[2], date[1] - 1, date[0]);

          const day = Intl.DateTimeFormat('pt-BR', { day: 'numeric' }).format(dateObject);
          const month = Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(dateObject);
          const year = Intl.DateTimeFormat('pt-BR', { year: 'numeric' }).format(dateObject);

          return `${day} ${month.substring(0, 3).toUpperCase()} ${year}`;
        }

        return value;
      }

      return false;
    },
    closeDialog () {
      this.$emit('close-evt');
    },
    showTable () {
      this.$emit('show-table');
    },
  },
};

</script>

<style lang="scss" scoped>

:deep(.v-window__next) {
  right: 0;
}


.highlighted-property-dialog {
  border-radius: 15px !important;
  max-height: 100% !important;
  height: auto;
  width: 95% !important;
  overflow: hidden;
}

.highlighted-property-dialog .v-carousel .v-window-item {
  position: absolute;
  width: 100%;
}

.highlighted-property-dialog .h-full {
  height: 100% !important;
}

.highlighted-property-dialog .v-carousel__item {
  height: 100% !important;
}

.google-visualization-tooltip {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

i {
  color: $primary-blue;
}

.report-btn {
  background-color: white;
}

img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

#property-descrition {
  display: -webkit-box;
  overflow: hidden;
  font-size: 1em;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.condo {
  color: black;
}

.pricing {
  height: 3em;
}

.close-btn {
  left: 95%;
  border-color: $light-gray;
}

.attributes {
  li {
    span {
      font-size: 0.9em;
    }

    span:first-child {
      color: $medium-gray;
      font-size: 1em;
    }
  }
}

.text-main {
  color: #3366cc;
}

.propertyFeatures {
  .featureRow {
    font-size: 0.85em;

    .value {
      font-size: 1em;
    }

    .col {
      padding: 0.5em;
      text-align: center;

      &:not(:last-child) {
        border-right: 1px solid #707070;
      }
    }

    &.no-border {
      color: #707070;

      .col {
        background-color: #e8e8e8;
        border: 0;
        padding: 0.5em 0.4em;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}

.show-table-btn,
.dismiss-btn {
  min-width: 36px !important;
  right: 0.6%;
}

.valueHistory{
  min-height: 200px;
}

.show-table-btn {
  background-color: $colors-highlight-light !important;
  transition: all .2s ease-out;

  &:hover {
    background-color: $colors-highlight-default !important;
  }
}
</style>
