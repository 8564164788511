<template>
  <div>
    <v-dialog
        v-model="showModal"
        content-class="forbidden-modal"
    >
      <div class="wrapper" data-test="modal-wrapper">
        <div>
          <div class="text-right">
            <v-icon class="close" data-test="modal-close-button" @click="showModal = false">mdi-close</v-icon>
          </div>
          <div class="title">
            <NotAllowedIcon/>
            <div class="mt-4" data-test="modal-title">Acesso Negado</div>
          </div>
        </div>
        <div class="description mx-auto" data-test="modal-description">
          Desculpe, no momento seu perfil não tem permissão para acessar este conteúdo.
        </div>
        <div class="buttons">
          <v-btn
              data-test="modal-ok-button"
              rounded
              depressed
              color="#3366CC"
              class="accept-btn"
              dark
              @click="showModal = false"
          >
            Retornar
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import NotAllowedIcon from '@/components/icons/NotAllowedIcon';

export default {
  name: 'ForbiddenModal',
  components: {
    NotAllowedIcon,
  },
  data: () => ({
    showModal: false,
  }),
  created() {
    this.$eventBus.$on('error:forbidden', () => {
      this.showModal = true;
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .forbidden-modal {
  @apply flex p-0;
  font-family: 'Lato', sans-serif;
  background-color: white;
  font-style: normal;
  font-weight: 400;
  width: 464px;
  border-radius: 10px;
  min-height: 284px;
  .wrapper {
    @apply flex flex-col py-4 px-6;
    .accept-checkbox {
      @apply m-0 p-0;
      height: 25px;
      .v-icon {
        font-size: 27px;
      }
    }
    .title {
      @apply text-center mb-2 mt-4;
      @apply flex flex-col items-center justify-center;
      font-family: 'Roboto', sans-serif;
      color: #3366CC;
      font-weight: 600;
      font-size: 22.5px !important;
      line-height: 27px;
    }
    .close {
      color: #6B6B6B;
      font-size: 28px;
    }
    .description {
      @apply my-4 pr-3 text-center;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      max-width: 253px;
      color: #707070;
      a {
        color: #3366CC;
        font-weight: bold;
      }
    }
    .buttons {
      @apply mt-auto text-center mb-2;
      .accept-btn {
        min-width: 100px;
        &.v-btn--disabled {
          background-color: #DEDFDF !important;
          color: white !important;
        }
      }
    }
  }
}
</style>
