import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';

import '@/styles/custom.vuetify.scss';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdi',
  },
  rtl: false,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#4663B9',
        accent: '#4663B9',
        secondary: '#FFFFFF',
        success: '#43A047',
        info: '#90CAF9',
        warning: '#FFCA28',
        error: '#E57373',
      },
    },
  },
});
