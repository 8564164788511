import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import core from './modules/core';
import map from './modules/map';
import user from './modules/user';
import search from './modules/search/search';
import seller from './modules/seller';
import report from './modules/report';
import person from './modules/person';
import personData from './modules/personData';
import property from './modules/property';
import summary from './modules/summary';
import flag from './modules/flag';
import lead from './modules/lead';
import buyer from './modules/buyer';
import rent from './modules/rent';
import privacy from './modules/privacy';
import login from './modules/login';
import amplify from './modules/amplify';
import avm from './modules/avm';
import MobileCondominium from './modules/mobileCondominium';

Vue.use(Vuex);

Vue.config.devtools = true;

const plugins = [
  createPersistedState({
    paths: [
      'person',
      'user',
      'personData'
    ],
  }),
];

export default new Vuex.Store({
  modules: {
    core,
    map,
    user,
    search,
    seller,
    report,
    buyer,
    lead,
    rent,
    avm,
    studies: search,
    person,
    personData,
    property,
    summary,
    flag,
    privacy,
    login,
    amplify,
    MobileCondominium,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins,
});
