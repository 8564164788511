<template>
  <div class="search-filters">
    <div class="grid grid-cols-2 gap-5 align-center">
      <div>
        <CustomSelect
          v-model="orderBy.value"
          name="orderBy"
          :prefix="prefix"
          :items="orderBy.options"
          variant="sort"
          @input="onSelectChange"
        />
      </div>
      <div>
        <CustomSwitch
          v-model="switchIsActive"
          label-position="left"
          @input="onSwitchChange"
        >
          <Typography
            tag="span"
            variant="label-medium"
            color="neutral-grey-6"
          >
            com ofertas
          </Typography>
        </CustomSwitch>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/base/Select';
import Switch from '@/components/base/Switch';
import Typography from '@/components/base/Typography';

export default {
  name: 'SearchFilters',
  components: { CustomSelect: Select, CustomSwitch: Switch, Typography },
  data: () => ({
    switchIsActive: false,
    orderBy: {
      value: 'minDistance',
      dualArrow: true,
      isFilter: true,
      options: {
        minDistance: 'Menor distância',
        maxDistance: 'Maior distância',
        minOffers: 'Menor número de ofertas',
        maxOffers: 'Maior número de ofertas',
        minValue: 'Menor valor',
        maxValue: 'Maior valor',
      },
    },
  }),
  computed: {
    isMobileRoute () {
      return this.$route.path.includes('mobile');
    },
    prefix () {
      return this.isMobileRoute ? '' : 'Ordenar por: ';
    },
  },
  methods: {
    onSwitchChange (value) {
      this.$emit('withOffers', value);
    },
    onSelectChange (value) {
      this.$emit('orderBy', value);
    },
  },
};
</script>

<style lang="scss" scoped>
$search_filters_border_color: #dddddd;

.search-filters {
  @apply border-b;
  border-bottom-color: $search_filters_border_color;
}
</style>
