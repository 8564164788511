/**
 * Activity core module
 */
export default {

  send (payload) {

    window.dataLayer.push(payload);

    if (window.location.host.startsWith('localhost')
      || window.location.host.startsWith('qa')) {

      console.log('[Activity] send:', payload);
    }
  },
};
