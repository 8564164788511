<template>
  <LoginLayout>
    <div class="login-form">
      <div class="title">
        Cidade Virtual
      </div>
      <div class="description-label">
        Esqueceu a senha?
      </div>

      <div class="description">
        Preencha seu e-mail abaixo para receber instruções de como alterar sua senha.
      </div>

      <login-reset-step-one />
    </div>
  </LoginLayout>
</template>

<script>

import LoginLayout from '@/layouts/Login.vue';
import LoginResetStepOne from '@/components/login/LoginResetEmail.vue';

export default {
  name: 'LoginResetPassword',
  components: {
    LoginLayout,
    LoginResetStepOne,
  },
};

</script>

<style lang="scss" scoped>
.login-form {
  @apply mb-3;
  min-width: 412px;
  background: #FFFFFF;
  border: 1px solid #8496cbbb !important;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 24px;

  .title {
    @apply text-center;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 25.5px !important;
    line-height: 100%;
    color: #384059;
  }

  .description-label {
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22.5px;
    line-height: 27px;
    
    color: #38415A;
  }

  .description {
    margin-top: 24px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #707070;
    max-width: 364px;
  }
}

</style>
