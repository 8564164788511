import { css } from '@/core/theme';

const container = css({
  backgroundColor: '$brand-darker',
  minHeight: '60px',
  borderBottom: '1px solid rgba(225, 232, 252, 0.3)',
  display: 'flex',
});

const content = css({
  width: '100%',
  maxWidth: '1070px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const logout = css({
  cursor: 'pointer',
});

export const styles = {
  container,
  content,
  logout,
};
