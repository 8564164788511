import axios from 'axios';

const baseURL = process.env.VUE_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  timeout: 300000,
});

export default axiosInstance;
