<template>
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.17227 14.2796L0.422266 8.57007C0.140381 8.25112 0 7.88276 0 7.47397C0 7.06519 0.140381 6.73815 0.421143 6.45739L6.17114 0.747822C6.58245 0.336517 7.2003 0.213251 7.73802 0.435929C8.27573 0.658607 8.625 1.18491 8.625 1.7644V13.224C8.625 13.8053 8.27546 14.33 7.73779 14.5528C7.20012 14.7756 6.58105 14.6929 6.17227 14.2796Z" fill="#4663B9"/>
  </svg>
</template>

<script>

export default {
  name: 'LeftArrowBlue',
}

</script>
