import { render, staticRenderFns } from "./HomeCardInfo.vue?vue&type=template&id=2a384796&scoped=true"
import script from "./HomeCardInfo.vue?vue&type=script&setup=true&lang=js"
export * from "./HomeCardInfo.vue?vue&type=script&setup=true&lang=js"
import style0 from "./HomeCardInfo.vue?vue&type=style&index=0&id=2a384796&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a384796",
  null
  
)

export default component.exports