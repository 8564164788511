import api from '@/api';

export default async function fetchAddresses(
  { state, commit, dispatch },
  data
) {
  try {
    const { routeName: domain, firstPage: addressIdList } = data;
    commit('SET_LOADING', true);
    const ids = addressIdList.filter(
      id => !(state.result[id] && state.result[id].shortAddress)
    );

    if (!(ids && ids.length > 0)) return;

    const filterForm = state.lastSearch[1];
    const filter = state.lastSearch[0];
    const { response: results } = await api.address.getAddresses(
      filterForm,
      filter,
      domain
    );

    dispatch('setListSearchResult', results);
    commit('FETCH_ADDRESSESS_LIST_SUCCESS');
  } catch (error) {
    commit('FETCH_ADDRESSESS_LIST_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }
}
