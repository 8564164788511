import api from '@/api';

const initialState = {
  isLoading: false,
  hasResult: false,
  lastSearch: {},
  avmResult: {},
  result: {},
  askingPrice: {},
};

const getters = {
  isLoading: state => state.isLoading,
  hasResult: state => state.hasResult,
  lastSearch: state => state.lastSearch,
  avmResult: state => state.avmResult,
  askingPrice: state => state.askingPrice,
  hasAskingPrice: state => state.askingPrice && Object.keys(state.askingPrice).length,
};

const mutations = {
  LAST_SEARCH(state, data) {
    state.lastSearch = data;
  },
  HAS_RESULT(state, data) {
    state.hasResult = data;
  },
  IS_LOADING(state, data) {
    state.isLoading = data;
  },
  RESULT(state, data) {
    state.avmResult = data;
  },
  ASKING_PRICE(state, data) {
    state.askingPrice = data;
  },
};

const actions = {
  clearFilters({ dispatch }) {
    dispatch('resetLastSearch');
  },
  resetLastSearch({ commit }) {
    commit('HAS_RESULT', false);
    commit('LAST_SEARCH', {});
    commit('RESULT', {});
    commit('IS_LOADING', false);
  },
  async fetchAVM({ commit, dispatch, state }, { avmData, gtmData }) {
    try {
      commit('LAST_SEARCH', avmData);
      commit('HAS_RESULT', false);
      commit('IS_LOADING', true);

      const { response } = await api.avm.evaluation(avmData);
      commit('RESULT', response.content.avm);

      const askingPrice = await api.avm.askingPrice(avmData);
      commit('ASKING_PRICE', askingPrice);

      if (state.avmResult.code) {
        commit('HAS_RESULT', state.avmResult.code === 200);
      }

      window.scrollTo(0, 0);

      this._vm.$gtm.trackEvent(gtmData);
    } catch (e) {
      console.error(e);
    } finally {
      commit('IS_LOADING', false);
      dispatch('user/fetchAVMQuota', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
