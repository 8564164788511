import { Amplify, Auth } from 'aws-amplify';
import Vue from 'vue';

const amplifyConfigureParams = {
  identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID, // Amazon Cognito Identity Pool ID
  region: process.env.VUE_APP_AWS_COGNITO_REGION, // Region where Amazon Cognito project was created
  userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID, // Amazon Cognito User Pool ID
  userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID, // Amazon Cognito App Client ID (App client secret needs to be disabled)
  mandatorySignIn: true, // Users are not allowed to get the aws credentials unless they are signed in
  oauth: {
    domain: process.env.VUE_APP_AWS_COGNITO_OAUTH_DOMAIN, // Amazon Cognito OAuth domain
    redirectSignIn: `${process.env.VUE_APP_BASE_URL}/login?success=true`, // Redirect URL after successful sign in
    redirectSignOut: `${process.env.VUE_APP_BASE_URL}/login`, // Redirect URL after successful sign out
    responseType: 'code',
  },
};

Amplify.configure(amplifyConfigureParams);

Vue.prototype.$auth = Auth;
