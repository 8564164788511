<template>
  <div
    data-test="component"
    class="login-password"
  >
    <div
      v-if="passwordError"
      class="alert-error"
      data-test="password-error"
    >
      <error-icon class="alert-error-icon mt-1 align-self-start" />
      <div class="alert-error-message">
        <ul class="list-disc">
          <li>No mínimo 1 número</li>
          <li>
            No mínimo 1 caracter especial:
            <br>
            <small>^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , &gt; &lt; ' : ; | _ ~ ` + =</small>
          </li>
          <li>No mínimo 1 letra maiúscula</li>
          <li>No mínimo 1 letra minúscula</li>
        </ul>
      </div>
    </div>
    <div
      v-if="formError"
      class="alert-error"
      data-test="default-error"
    >
      <error-icon class="alert-error-icon mt-1 align-self-start" />
      <div class="alert-error-message">
        Desculpe, ocorreu um erro. Tente novamente
      </div>
    </div>
    <div class="input-container-wrapper">
      <div class="input-container">
        <label
          for="password"
          class="label label-password"
          :class="{'label--active': activeInput['password'], 'label--active--error': errorInput['password']}"
        >
          Senha
        </label>

        <input
          id="password"
          v-model="password"
          class="input-field input-password"
          :class="{'input-field--error': errorInput['password']}"
          type="password"
          placeholder="Digite sua senha"
          autocomplete="off"
          @focus="toggleActiveInput('password', true)"
          @blur="toggleActiveInput('password', false)"
        >
      </div>
      <div
        v-if="errorInput['password']"
        class="front-error-message"
      >
        Digite uma senha válida.
      </div>
      <div class="input-container mt-3">
        <label
          for="password2"
          class="label label-password"
          :class="{'label--active label--active-password': activeInput['password2'], 'label--active--error': errorInput['password2']}"
        >
          Confirmação da senha
        </label>
        <input
          id="password2"
          v-model="password2"
          class="input-field input-password"
          :class="{'input-field--error': errorInput['password2']}"
          type="password"
          placeholder="Confirmar senha"
          @focus="toggleActiveInput('password2', true)"
          @blur="toggleActiveInput('password2', false)"
        >
      </div>
      <div
        v-if="errorInput['password2']"
        class="front-error-message"
      >
        As senhas devem ser iguais
      </div>
    </div>
    <button
      class="btn-reset-password"
      data-test="continue-button"
      :disabled="loading"
      @click="resetPassword"
    >
      Continuar
    </button>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import ErrorIcon from '@/components/icons/ErrorIcon.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import { VALIDATION } from '@/common/constants';

export default {
  name: 'LoginPassword',
  components: {
    GoogleTagManager,
    ErrorIcon,
  },
  data () {
    return {
      gtmEvent: null,
      activeInputs: {
        password: false,
        password2: false,
      },
      errorInputs: {
        password: false,
        password2: false,
      },
      formError: false,
      passwordError: false,
      passwordRegexp: VALIDATION.password,
      password: '',
      password2: '',
      loading: false,
    };
  },
  computed: {
    activeInput () {
      return this.activeInputs;
    },
    errorInput () {
      return this.errorInputs;
    },
  },
  methods: {
    toggleActiveInput (input, state) {
      this.errorInputs[input] = false;
      this.activeInputs[input] = state;
    },
    validatePassword () {
      let error;

      if (!(this.password && this.password2)) error = true;

      if (this.password !== this.password2) error = true;

      if (error) {
        this.errorInputs.password = true;
        this.errorInputs.password2 = true;

        return false;
      }

      return this.password.match(this.passwordRegexp);
    },
    async resetPassword () {
      try {
        this.formError = false;
        this.passwordError = false;
        this.loading = true;

        if (this.validatePassword()) {
          const user = this.$store.getters['amplify/user'];
          const requiredAttributes = {};

          if (user && user.challengeParam) {
            const { userAttributes } = user.challengeParam;

            user.challengeParam.requiredAttributes.forEach(attr => {
              if (!userAttributes[attr]) {
                requiredAttributes[attr] = attr === 'name' ? userAttributes.email.split('@')[0] : '-';
              }
            });
          }

          await this.$auth.completeNewPassword(user, this.password, requiredAttributes);
          this.$store.commit('amplify/SET_USER', null);
          this.gtmEvent = {
            event: 'password',
            type: 'first_access',
          };
          await this.$router.push('/');
        }
        else {
          this.passwordError = true;
        }
      }
      catch (error) {
        this.formError = true;
      }
      finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>

.btn-reset-password {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 9999px;
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 20px 86px;
  margin-top: 24px;
  background: #4662B9;
  color: #FFFFFF;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

}

input.input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18.5px 14px;
  background: rgba(126, 146, 206, 0.1);
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  transition: box-shadow .2s ease-out;

  &::placeholder {
    color: #707070;
  }

  &:focus {
    outline: none;
    background: rgba(126, 146, 206, 0.1);
    box-shadow: inset 0 0 0 2px #4663B9;
    box-sizing: border-box;
    transition: box-shadow .2s ease-out;
  }

  &--error {
    box-shadow: inset 0 0 0 2px #B75757;
    transition: box-shadow .2s ease-out;
    background-color: #FFFFFF;

    &:focus {
      box-shadow: inset 0 0 0 2px #B75757;
      transition: box-shadow .2s ease-out;
      background-color: #FFFFFF;
    }
  }
}

.input-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 142px;
  margin-top: 24px;
}
.input-container {
  position: relative;
}

.label {
  position: absolute;
  top: 0;
  left: 14px;
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #707070;
  background-color: #FFFFFF;
  border-radius: 3px;
  z-index: 10;
  padding: 0 3px;
  opacity: 0;
  transform: translateY(22px);
  transition: all .2s ease-out;

  &--active {
    color: #4663B9;
    transform: translateY(-6px);
    transition: all .2s ease-out;
    font-size: 12px;
    line-height: 12px;
    opacity: 1;

    &--error {
      color: #B75757;
    }

  }
}

.front-error-message {
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #B75757;
  margin-top: 8px;
  margin-left: 14px;
}

.alert-error {
  @apply flex justify-start flex-wrap items-center;
  @apply rounded-md;
  @apply py-4 mt-4;

  background: #FFEDED;

  &-icon {
    @apply ml-4 mr-2;
  }

  &-message {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #707070;
  }
}

</style>
