<template>
  <div 
    class="w-full flex flex-column px-4 py-5 border-data score-data"
    v-if="data.CreditScore && Object.keys(data.CreditScore).length > 0"
  >
    <div class="flex flex-row">
      <div class="flex flex-column gap-10">
        <Typography
          color="brand-dark"
          variant="title-large"
          tag="span"
        >
          Score
        </Typography>
        <div class="flex flex-row gap-4">
          <div class="flex flex-col items-start gap-2 score-width">
            <Typography
              color="brand-dark"
              variant="body-small"
              tag="span"
            >
              Últimos 60 dias
            </Typography>
            <Typography
              color="brand-dark"
              variant="body-table"
              tag="span"
            >
              {{ data?.CreditScore?.D60 > -1 ? data?.CreditScore?.D60 : '-'}}
            </Typography>
          </div>
          <div class="flex flex-col items-start gap-2 score-width">
            <Typography
              color="brand-dark"
              variant="body-small"
              tag="span"
            >
              Últimos 30 dias
            </Typography>
            <Typography
              color="brand-dark"
              variant="body-table"
              tag="span"
            >
              {{ data?.CreditScore?.D30 > -1 ? data?.CreditScore?.D30 : '-' }}
            </Typography>
          </div>
        </div>
      </div>
      <div class="flex align-center justify-center actual-score-data">
        <Typography
          variant="header-xxl"
          tag="span"
          :class="scoreColor(data?.CreditScore?.D00)"
        >
          {{ data?.CreditScore?.D00 > -1 ? data?.CreditScore?.D00 : '-'}}
        </Typography>
      </div>
    </div>
    <div class="w-full flex flex-column">
      <span
        class="relative arrow-score"
        :style="arrowPosition"
      >
        <img src="@/assets/images/icon-arrow-solid.svg">
      </span>
      <div class="score-meter"/>
      <div class="flex flex-row align-start justify-between">
        <Typography
          color="brand-dark"
          variant="body-xs"
          tag="span"
        >
          0
        </Typography>
        <Typography
          color="brand-dark"
          variant="body-xs"
          tag="span"
        >
          100
        </Typography>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Não há dados para serem exibidos.</p>
  </div>
</template>

<script>
import Typography from '../base/Typography';

export default {
  name: 'Score',
  components: { Typography },
  props: ['data'],
  computed: {
    arrowPosition () {
      if (this.data && Object.keys(this.data).length > 0) {
        if (this.data.CreditScore.D00 > 0) {
          const percent = (this.data.CreditScore.D00 / (this.data.maxValue || 1000)) * 100;
          const style = `left: calc(${percent}% - 10px)`;

          return style;
        }
      }

      return 'left: -5px';
    },
  },
  methods: {
    scoreColor (score) {
      if(score <= 200) {
        return 'score-color-awful'
      } else if(score > 200 && score <= 400) {
        return 'score-color-low'
      } else if(score > 400 && score <= 600) {
        return 'score-color-average'
      } else if(score > 600 && score <= 800) {
        return 'score-color-good'
      } else if(score > 800) {
        return 'score-color-great'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.border-data {
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}
.score-data {
  background: var(--colors-neutral-grey-2);
  width: 332px;
  gap: 45px;
}
.actual-score-data {
  border-radius: 10px;
  border: 1px solid var(--colors-brand-default);
  width: auto;
  max-width: 84px;
  height: 54px;
}
.score-color-awful {
  color: #E35050!important;
}
.score-color-low {
  color: #E3A350!important;
}
.score-color-average {
  color: #E3D401!important;
}
.score-color-good {
  color: #50E38B!important;
}
.score-color-great {
  color: #50E355!important;
}
.score-meter {
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background-image: linear-gradient(
    90deg,
    hsl(0deg 100% 36%) 0%,
    hsl(29deg 100% 46%) 33%,
    hsl(49deg 100% 41%) 67%,
    hsl(104deg 100% 45%) 100%
  );
}

.arrow-score {
  width: 15px;
}

.score-width {
  max-width: 85px;
}
</style>
