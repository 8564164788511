const initialState = {
  user: null,
  email: null,
  code: null,
  hasPreSignUpError: false,
};

const getters = {
  user: state => state.user,
  code: state => state.code,
  email: state => state.email,
  hasPreSignUpError: state => state.hasPreSignUpError,
};

const mutations = {
  SET_USER (state, user) {
    state.user = user;
  },
  SET_EMAIL (state, email) {
    state.email = email;
  },
  SET_CODE (state, code) {
    state.code = code;
  },
  SET_HAS_PRESIGNUP_ERROR (state, status) {
    state.hasPreSignUpError = status;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
};
