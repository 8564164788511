<script setup>

import { styles } from './HomeCardTools.styles';
import Typography from '@/components/base/Typography';
import HomeCardTool from './HomeCardTool.vue';

const props = defineProps({
  tools: {
    type: Array,
    default: () => {},
  },
});

const css = selector => styles[selector]().className;

</script>

<template>
  <div :class="css('container')">
    <div :class="css('title')">
      <Typography
        tag="h2"
        variant="header-xl-secondary"
        color="brand-dark"
      >
        Ferramentas
      </Typography>
    </div>
    <div :class="css('tools')">
      <HomeCardTool
        v-for="(tool, index) in tools"
        :key="index"
        :values="tool"
      />
    </div>
  </div>
</template>
