export default function clearFilters ({ commit, state }) {
  const conditions = [
    state.filterMode,
    state.filterMode === 'area',
    state.filter,
  ];

  const constructFilterObject = () => {
    const filterObject = {};

    filterObject['coordinates'] = state.filter.coordinates;
    filterObject['type'] = state.filter.type;

    return filterObject;
  };

  const filter = conditions.every(el => el) ? constructFilterObject() : null;

  commit('SET_FILTER', filter);
}
