<script setup>

import { computed } from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';

/**
 * Styles
 */
import { styles } from './SearchSummarySeller.styles';

/**
 * Props
 */
const props = defineProps({

  type: {
    type: String,
    default: 'result',
    validator (value) {
      return [
        'result',
        'filter',
      ].includes(value);
    },
  },

  data: {
    type: Object,
    default: () => ({ }),
  },
});

/**
 * Computed
 */
const isResult = computed(() => props.type === 'result');

const isFilter = computed(() => props.type === 'filter');

/**
 * Misc
 */
const numberFormatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 0,
});

const moneyFormatter = new Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 0,
  style: 'currency',
});

</script>

<template>
  <div :class="styles.root({ type }).className">
    <template v-if="data && isResult">
      <div data-column />

      <div data-column>
        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          Condomínios encontrados
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          Valor médio ofertado
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data?.street?.limit }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ numberFormatter.format(data?.street?.condominiumCount) }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ moneyFormatter.format(data?.street?.avgOfferValue) }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data?.radius?.limit }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ numberFormatter.format(data?.radius?.condominiumCount) }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ moneyFormatter.format(data?.radius?.avgOfferValue) }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data?.district?.limit }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ numberFormatter.format(data?.district?.condominiumCount) }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ moneyFormatter.format(data?.district?.avgOfferValue) }}
        </Typography>
      </div>
    </template>

    <template v-if="data && isFilter">
      <div
        v-for="([filter], index) in Object.entries(data)"
        :key="`filterIndex${index}`"
        data-column
      >
        <Typography
          tag="div"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data[filter]?.label }}
        </Typography>

        <Typography
          tag="span"
          variant="body-medium-secondary"
          color="brand-dark"
        >
          {{ data[filter]?.value }}
        </Typography>
      </div>
    </template>
  </div>
</template>
