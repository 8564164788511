<template>
  <thead>
    <tr class="table-header">
      <th class="table-header__title" v-for="col, id in headerCols" :key="id">
        {{ col }}
      </th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'TableHeaderMoreTypologies',
  props: {
    headerCols: {
      default: null
    },
  }
}

</script>

<style lang="scss" scoped>

  .table-header{
    @apply
      font-bold
      border-b-2
      rounded-none
      text-center;
      
    color: #707070;
    border-bottom-color: #707070;
    font-family: Lato;
    font-size: 16px;

    &__title{
      @apply px-2 py-2 text-center;

      &:nth-child(-n+4){
        width: 90px;
      }

      &:first-child{
        @apply pl-0;
      }

      &:last-child{
        @apply pr-0;
      }
    }
  }

</style>