<template>
  <div class="skeleton-property">
    <v-skeleton-loader
      class="ml-0 mr-auto px-2"
      width="100%"
      type="article"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="px-2"
      width="100%"
      type="image"
    ></v-skeleton-loader>
    <div class="w-full flex justify-center mt-6 px-2">
      <v-skeleton-loader
        class="mr-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="ml-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonProperty',
}

</script>

<style lang="scss" scoped>

.skeleton-property {
  @apply flex flex-col min-h-full w-full justify-start items-center;
}

</style>