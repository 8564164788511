const toggleStringSequence = (id, string, delimiter = ',') => {

  const exists = string
    .indexOf(`${id}`) !== -1;

  if (!exists) {

    string += `${delimiter}${id}`;
  }
  else {

    string = string
      .replace(id, '');
  }

  string = string
    .split(delimiter)
    .filter(attribute => Boolean(attribute))
    .join(delimiter);

  return string;
};

export default toggleStringSequence;
