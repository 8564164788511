<script setup>

import { computed, inject } from 'vue';

import ProgressBar from '@/components/base/ProgressBar';
import Typography from '@/components/base/Typography';

const vuexStore = inject('vuex-store');

const isLoading = computed(() => vuexStore.getters['user/consultaDadosLoading']);
const quota = computed(() => vuexStore.getters['user/consultaDadosQuota']);

vuexStore.dispatch('user/fetchConsultaDadosQuota');

</script>

<template>
  <div class="flex flex-col w-full px-5 mb-4">
    <Typography
      color="brand-light"
      variant="title-large"
      tag="div"
      class="mb-2"
    >
      Seus créditos
    </Typography>

    <div
      class="border border-solid p-4 flex flex-col justify-center"
      style="border-color: #A5B2D9; border-radius: 10px;"
    >
      <ProgressBar
        class="text-white"
        :disabled="isLoading"
        title="Consulta Dados"
        :available="quota.available"
        :total="quota.total"
        :current="quota.available"
        :used="quota.total - quota.available"
        :is-mobile="true"
      />
      <Typography
        color="neutral-white"
        variant="body-small"
        tag="div"
        class="mt-4"
      >
        Os créditos são disponibilizados no início de cada mês.
      </Typography>
    </div>
  </div>
</template>
