<script>

export default {
  inheritAttrs: false,
};

</script>

<script setup>

import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from 'vue';

/**
 * Components
 */
import TextField from '../TextField';

/**
 * Icons
 */
import CaretDownIcon from '@/components/icons/CaretDownIcon';
import CaretSortIcon from '@/components/icons/CaretSortIcon';

/**
 * Styles
 */
import { styles } from './Select.styles';

/**
 * Props
 */
const props = defineProps({

  label: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  value: {
    type: [
      String,
      Number,
    ],
    default: null,
  },

  error: {
    type: [
      String,
      Boolean,
    ],
    default: null,
  },

  prefix: {
    type: String,
    default: null,
  },

  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  placeholder: {
    type: String,
    default: null,
  },

  items: {
    type: [
      Object,
      Array,
    ],
    default: () => [],
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  variant: {
    type: String,
    default: 'default',
    validator: (value) => {
      return [
        'default',
        'sort',
      ].indexOf(value) >= 0;
    },
  },
});

/**
 * Emits
 */
const emit = defineEmits(['input']);

/**
 * Refs
 */
const inputRootRef = ref(null);
const inputDropdownRef = ref(null);
const inputRef = ref(null);

/**
 * Data
 */
const listShowing = ref(false);
const selectedValue = ref(props.value);
const blurWhitelist = ref([]);

/**
 * Computed
 */
const isItemsArray = computed(() => Array.isArray(props.items));

const showNoOptions = computed(() => isItemsArray.value ? !props.items.length : !Object.keys(props.items).length);

const isDefaultVariant = computed(() => props.variant === 'default');

const isSortVariant = computed(() => props.variant === 'sort');

const computedValue = computed(() => !isItemsArray.value ? props.items[selectedValue.value] : selectedValue.value);

const prefixValue = computed(() => props.prefix ? `${props.prefix}${computedValue.value}` : computedValue.value);

/**
 * Watch
 */
watch(() => props.value, value => selectedValue.value = value);

/**
 * Mounted
 */
onMounted(() => {

  document.addEventListener('click', onClickOutside);

  nextTick(() => blurWhitelist.value = generateBlurWhitelist());
});

/**
 * Before unmount
 */
onBeforeUnmount(() => {

  document.removeEventListener('click', onClickOutside);
});

/**
 * Methods
 */
const generateBlurWhitelist = () => {

  const whitelist = [];

  whitelist.push(inputRef.value);
  whitelist.push(inputDropdownRef.value);

  const children = inputRootRef.value.querySelectorAll('*');

  for (const child of children) {
    whitelist.push(child);
  }

  return whitelist;
};

const onClickOutside = event => {

  if (blurWhitelist.value.indexOf(event.target) < 0) {

    listShowing.value = false;
  }
};

const onFocus = event => {

  if (props.disabled) {
    return;
  }

  const input = inputRef.value.$el.querySelector('input');

  if (input 
    && !input.activeElement) {

    listShowing.value = true;

    input.focus();
  }
};

const onSelect = item => {

  listShowing.value = false;
  selectedValue.value = item;

  emit('input', item);
};

</script>

<template>
  <div
    ref="inputRootRef"
    :class="styles.root().className"
  >
    <TextField
      :id="id"
      ref="inputRef"
      read-only
      :class="styles.field({ variant }).className"
      :label="label"
      :error="error"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="prefixValue"
      @click="onFocus"
    />

    <div
      v-if="isDefaultVariant || isSortVariant"
      :class="styles.icon({
        variant,
        listShowing,
        disabled,
      }).className"
      @click="onFocus"
    >
      <CaretDownIcon
        v-if="isDefaultVariant"
        data-icon
      />

      <CaretSortIcon
        v-else-if="isSortVariant"
        data-icon
      />
    </div>

    <div
      v-if="listShowing"
      :class="styles.list({
        variant,
        listShowing,
      }).className"
    >
      <ul>
        <li
          v-for="(item, itemIndex) in items"
          :key="`selectFieldIndex${itemIndex}`"
          :class="styles.item().className"
          @click="onSelect(isItemsArray ? item : itemIndex)"
        >
          <slot
            v-if="$slots.item"
            name="item"
            :item="item"
          />

          <template v-else>
            {{ item }}
          </template>
        </li>

        <li
          v-if="showNoOptions"
          :class="styles.item().className"
        >
          Sem opções disponíveis.
        </li>
      </ul>
    </div>
  </div>
</template>
