<template>
  <div :class="isMobile ? 'shadow-none': 'box-shadow-desktop'" class="button-area">
    <div class="grid single">
      <CustomButton
        class="voltarLimpar"
        rounded
        @click="onReturn"
      >
        Voltar
      </CustomButton>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/forms/Button.vue';

export default {
  name: 'ButtonBar',
  components: { CustomButton },
  props: {
    onReturn: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    isMobile () {
      return this.$route.path.includes('mobile')
    }
  },
  
};
</script>

<style lang="scss" scoped>
.box-shadow-desktop {
  box-shadow: 0 -2px 5px 0 rgb(0 0 0 / 10%);
}
.button-area {
  width: 100%;
  background-color: $colors-neutral-white;
  display: flex;
  z-index: 50;
  box-shadow: 0 -2px 5px 0 rgb(0 0 0 / 10%);
  .grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    &.single {
      .button {
        max-width: 165px;
      }
    }
  }
}
</style>