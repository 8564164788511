<template>
  <base-card>
    <div class="flex flex-column gap-1">
      <Typography
        variant="header-large"
        color="neutral-grey-6"
      >
        Links úteis
      </Typography>
      <Typography
        variant="body-xs"
        color="neutral-grey-5"
      >
        Links de apoio ao corretor
      </Typography>
    </div>
    <hr>
    <div class="flex flex-col gap-4">
      <a
        v-for="{ baseUrl, label }, id in links"
        :key="id"
        :href="baseUrl"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Typography
          variant="body-medium"
          color="highlight-light"
          tag="span"
        >
          {{ label }}
        </Typography>
      </a>
    </div>
  </base-card>
</template>

<script>

import BaseCard from '@/components/cards/base-card.vue';
import InfoMarkerCard from '@/components/cards/info-marker-card.vue';
import Typography from '../base/Typography';

export default {
  name: 'UsefulLinks',
  components: {
    BaseCard,
    InfoMarkerCard,
    Typography
},
  data () {
    return {
      links: [
        {
          baseUrl: 'https://linkedin.com/',
          label: 'Linkedin',
        },
        {
          baseUrl: 'https://www.jusbrasil.com.br/consulta-processual/',
          label: 'JusBrasil',
        },
        {
          baseUrl: 'https://www.consultasocio.com/buscar/',
          label: 'Consulta Sócio',
        },
        {
          baseUrl: 'https://www.escavador.com/',
          label: 'Escavador',
        },
        {
          baseUrl: 'https://123i.uol.com.br/',
          label: '123i',
        },
        {
          baseUrl: 'https://www.vivareal.com.br/',
          label: 'VivaReal',
        },
        {
          baseUrl: 'http://www3.prefeitura.sp.gov.br/cit/Forms/frmPesquisaGeral.aspx',
          label: 'Cadastro de Imóveis Tombados',
        },
        {
          baseUrl: 'https://www3.prefeitura.sp.gov.br/deolhonaobra/Forms/frmConsultaSlc.aspx',
          label: 'Consulta de Obras e Edificações',
        },
        {
          baseUrl: 'https://www.google.com/',
          label: 'Google',
        },
        {
          baseUrl: 'https://www.bing.com/',
          label: 'Bing',
        },
        {
          baseUrl: 'https://www.facebook.com/',
          label: 'Facebook',
        },
        {
          baseUrl: 'https://www.instagram.com/',
          label: 'Instagram',
        },
      ],
    }
  },
}

</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}

hr {
  height: 0px;
  align-self: stretch;
  stroke-width: 1px;
  stroke: #E8E8E8;
  margin: 0px 16px 0px 0px;
}

</style>