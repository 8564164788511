<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 9.5C19 4.25273 14.7473 0 9.5 0C4.25273 0 0 4.25273 0 9.5C0 14.7473 4.25273 19 9.5 19C14.7473 19 19 14.7473 19 9.5ZM12.5059 7.72246L10.758 9.4666L12.5059 11.2441C12.851 11.593 12.851 12.157 12.5059 12.4725C12.157 12.851 11.593 12.851 11.2775 12.4725L9.5334 10.758L7.75586 12.4725C7.40703 12.851 6.84297 12.851 6.52754 12.4725C6.14902 12.157 6.14902 11.593 6.52754 11.2441L8.24199 9.4666L6.52754 7.72246C6.14902 7.40703 6.14902 6.84297 6.52754 6.49414C6.84297 6.14902 7.40703 6.14902 7.75586 6.49414L9.5334 8.24199L11.2775 6.49414C11.593 6.14902 12.157 6.14902 12.5059 6.49414C12.851 6.84297 12.851 7.40703 12.5059 7.72246Z" fill="#B75757"/>
  </svg>
</template>

<script>

export default {
  name: 'ErrorIcon',
}

</script>