<template>
  <!-- card Ofertas -->
  <div :class="noPadding ? 'p-0' : 'p-2'" class="offerContainer">
    <v-card
      class="w-full cardOferta"
      :class="property.seller && property.seller.name === 'BNSIR' ? 'ofertaBossa' : ''"
      :style="property.class === 30 ? 'background-color: #f3f3f3;' : ''"
      @click="onClick"
    >
      <div
        v-if="property.class !== 30"
        class="h-40"
      >
        <v-img
          v-if="property"
          :id="property.id"
          :src="fotoCard"
          aspect-ratio="5px 0 0 5px"
          :lazy-src="default404"
          class="w-full h-40 min-h-full"
          @error="replaceByDefault"
        />
      </div>
      <div class="containerDatosOferta">
        <div class="contetValorTipologia">
          <v-card-title
            v-if="property.propertyType"
            class="titulo"
          >
            <span>{{ property.propertyType }}</span>
            <span
              v-if="property.class===30"
              class="ml-1"
            >com {{ property.utilArea||'N/I' }}m²</span>
          </v-card-title>
          <div class="contentValor">
            <span
              v-if="property.price"
              class="valor"
            >
              {{ property.price | money }}
            </span>
            <span
              v-if="!disableChart"
              class="priceTrendValor"
            >
              <v-icon v-if="property.priceTrend === 'up'">icon-trending-up</v-icon>
              <v-icon v-else-if="property.priceTrend === 'down'">icon-trending-down</v-icon>
              <v-icon v-else>icon-trending-plain</v-icon>
            </span>
          </div>
        </div>
        <v-card-text class="anuncio mt-2">
          Anunciado: {{ getFormattedDate(property.createdAt) }}
          <span class="mx-1">|</span>
          Atualizado: {{ getFormattedDate(property.updatedAt || property.createdAt) }}
        </v-card-text>
        <ul
          class="attributesListCondo"
          :class="property.class === 30 ? 'mb-0' : ''"
          v-html="attributeLine"
        />
        <!--        <v-card-text class="anuncio" v-if="property.createdAt && property.yearMonth && property.class !== 30">-->
        <!--          Anunciado: {{ getFormattedDate(property.createdAt) }}-->
        <!--          <span class="mx-1">|</span>-->
        <!--          Atualizado: {{ getFormattedDate(property.updatedAt || property.createdAt) }}-->
        <!--        </v-card-text>-->
        <v-card-text
          v-if="pageCapitacao && property.class === 30"
          class="containerimobilhariaCodigo mt-2"
        >
          <span class="font-weight-bold text-capitalize text-small grey--text text--darken-3">{{
            property.sellerName.toLowerCase()
          }}</span> <span v-if="false">| Cód. : {{ property.sellerId }}</span>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>

import default404 from '@/assets/images/404.jpeg';

export default {
  name: 'PropertyOffer',
  props: {
    property: { type: Object, required: true },
    disableChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      propertyType: {
        previousOffers: 30, 
        potentialOffers: 40,
      }
    }
  },
  computed: {
    noPadding() {
      if(this.property.class === this.propertyType.previousOffers || this.property.class === this.propertyType.potentialOffers){
        return true
      }
      return false
    },
    default404 () {
      return default404;
    },
    fotoCard () {
      if (!this.property.image) return default404;

      return this.property.image;
    },
    pageCapitacao () {
      if (this.$route.fullPath === '/search/lead') return true;

      return false;
    },
    attributeLine () {
      var attrComponents = [];

      if (this.property) {
        let areaString = '';

        if (this.property.utilArea) {
          if (this.property.utilArea) {
            areaString = this.property.utilArea + 'm&sup2;';
          }
        }

        attrComponents.push(`<span style="font-weight: bold;margin-bottom: 2px;">${areaString || 'N/I'}</span> <span style="font-weight: 300;color: #707070;">Área útil</span>`);
        attrComponents.push(`<span style="font-weight: bold;margin-bottom: 2px;">${this.property.bedrooms || 'N/I'}</span> <span style="font-weight: 300;color: #707070;"> Dorm` + (this.property.bedrooms > 1 ? 's' : '') + '</span>');
        attrComponents.push(`<span style="font-weight: bold;margin-bottom: 2px;">${this.property.suites || 'N/I'}</span> <span style="font-weight: 300;color: #707070;">Suíte` + (this.property.suites > 1 ? 's' : '') + '</span>');
        attrComponents.push(`<span style="font-weight: bold;margin-bottom: 2px;">${this.property.parking_lots || 'N/I'}</span> <span style="font-weight: 300;color: #707070;"> Vaga` + (this.property.parking_lots > 1 ? 's' : '') + '</span>');
      }

      return attrComponents.map(a => `<li><span class="flex flex-col items-center justify-center">${a}</span></li>`).join('<div class="divider" style="margin: 0 15px;height: 35px;border-right: 1px solid #777;"></div>');
    },
  },
  methods: {
    onClick () {
      this.$emit('click');
    },
    getFormattedDate (value) {
      const date = value.split('-').reverse();

      if (date.length === 2) date.unshift('01');

      return date.join('/');
    },
    replaceByDefault () {
    },
  },
};
</script>

<style scoped lang="scss">
.offerContainer:nth-of-type(even){
  padding-left: 0.5rem;
}

.offerContainer:nth-of-type(odd){
  padding-right: 0.5rem;
}

.cardOferta {
  border-radius: 13px;
  overflow: hidden;
  border: solid 1px #cccccc;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .containerDatosOferta {
    padding: 12px 18px 15px;

    .contetValorTipologia {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .titulo {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.21;
        color: #000000;
        padding: 0px;
      }

      .contentValor {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .valor {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.21;
          color: #3366cc;
        }

        .priceTrendValor {

          i {
            color: #3366cc;
            margin-bottom: 2px;
          }

        }
      }
    }

    .attributesListCondo {
      padding-left: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 12px 0px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.25;
      color: #3366cc !important;
    }

    .anuncio {
      padding: 0px;
      font-size: 11px;
      line-height: 1.18;
      color: #000000;
      margin-bottom: 4px;
    }

    .containerimobilhariaCodigo {
      padding: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      color: #000000;

      .imobilharia {
        font-size: 13px;
        line-height: 1.23;
        font-weight: bold;
      }

    }
  }
}
</style>
