<template>
  <base-card bg-color="#E3E8F5" class="baseCard">
    <Typography
      color="highlight-darker"
      variant="title-large"
      tag="span"
    >
      Perfil de consumo
    </Typography>
    <div class="indicatorsContainer">
      <div class="w-4/5 flex align-start">
        <div class="w-1/2 flex flex-column align-start">
          <div v-for="indicator in indicatorNameFirstCol" :key="indicator.id" class="flex flex-column gap-1 w-11/12">
            <div class="flex flex-row align-baseline justify-between">
              <Typography
                color="highlight-dark"
                variant="body-medium"
                tag="span"
              >
                {{ indicator.name }}
              </Typography>
              <Typography
                color="highlight-dark"
                variant="body-xs"
                tag="span"
              >
                Score {{findScore(indicator.id, true)}}<Typography color="neutral-grey-5" variant="body-xs" tag="span">/1000</Typography>
              </Typography>
            </div>
            <div class="flex flex-column align-start">
              <span
                  class="indicator"
                  :style="arrowPosition(indicator.id)"
                >
                  <img
                    src="@/assets/images/consumption-indicator.svg"
                  >
              </span>
              <div class="score-meter">
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 flex flex-column align-start">
          <div v-for="indicator in indicatorNameSecondCol" :key="indicator.id" class="flex flex-column gap-1 w-11/12">
            <div class="flex flex-row align-baseline justify-between">
              <Typography
                color="highlight-dark"
                variant="body-medium"
                tag="span"
              >
                {{ indicator.name }}
              </Typography>
              <Typography
                color="highlight-dark"
                variant="body-xs"
                tag="span"
              >
                Score {{findScore(indicator.id, false)}}<Typography color="neutral-grey-5" variant="body-xs" tag="span">/1000</Typography>
              </Typography>
            </div>
            <div class="flex flex-column align-start">
              <span
                  class="indicator"
                  :style="arrowPosition(indicator.id)"
                >
                  <img
                    src="@/assets/images/consumption-indicator.svg"
                  >
              </span>
              <div class="score-meter">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/5 flex justify-center">
          <div class="box-last-updated">
            <div class="last-updated-title">
              <Typography
                color="neutral-grey-5"
                variant="body-medium"
                tag="span"
              >
                Última atualização
              </Typography>
            </div>
            <div class="self-end">
              <Typography
                color="neutral-grey-5"
                variant="body-medium"
                tag="span"
                class="last-updated-date"
              >
                {{ lastUpdated }}
              </Typography>
            </div>
        </div>
      </div>
      <Typography
        color="neutral-grey-4"
        variant="label-small"
        tag="span"
      >
        Consideramos pontuação acima de 800 como o ideal para os perfis de consumo.
      </Typography>
    </div>
    
  </base-card>
</template>

<script>
import Typography from "../base/Typography";
import BaseCard from "../cards/base-card.vue";

export default {
  components: { BaseCard, Typography },
  props: ["data"],
  data() {
    return {
      indicatorIdsFirstCol: [3,8,2], 
      indicatorIdsSecondCol: [14, 6 ,13],
      indicatorNameFirstCol: [{
          name: 'Conta bancária premium',
          id: 3
        }, {
          name: 'Posse de carro de luxo',
          id: 8
        }, {
          name: 'Conta de investimento',
          id: 2
        }
      ],
      indicatorNameSecondCol: [{
          name: 'Itens de luxo',
          id: 14
        }, {
          name: 'Posse de imóvel de alto padrão',
          id: 6
        }, {
          name: 'Tomador de empréstimo',
          id: 13
        }
      ]
    }
  },
  methods: {
    findScore(id, first) {
      if(first) {
        if(Object.keys(this.firstFilteredData).length > 0) {
          return this.firstFilteredData.find(indicator => indicator.Id === id).Score
        }
      }

      if(Object.keys(this.secondFilteredData).length > 0) {
        return this.secondFilteredData.find(indicator => indicator.Id === id).Score
      }
      
      return '-'
    },
    arrowPosition (id) {
      if (this.data && Object.keys(this.data).length > 0) {
        const score = this.data.find(indicator => indicator.Id === id).Score
        if (score > 0) {
          const percent = (score / 1000) * 100;
          const style = `left: calc(${percent}% - 10px)`;

          return style;
        }
      }

      return 'left: -5px';
    },
  },
  computed: {
    firstFilteredData() {
      const dataFiltered = this.data.filter((indicator) => {
        if(this.indicatorIdsFirstCol.includes(indicator.Id)) {
          return indicator
        }
      })
      return dataFiltered
    },
    secondFilteredData() {
      const dataFiltered = this.data.filter((indicator) => {
        if(this.indicatorIdsSecondCol.includes(indicator.Id)) {
          return indicator
        }
      })
      return dataFiltered
    },
    lastUpdated () {
      if(this.data && Object.keys(this.data).length > 0){
        const result = this.data.reduce((a, b) => a.ModelUpdate > b.ModelUpdate ? a : b).ModelUpdate
        const resultConverted = new Intl.DateTimeFormat('pt-BR', { year: "numeric", month: "numeric",}).format(new Date(result))
        return resultConverted
      }
      return 'N/D'
    }
  }
};
</script>

<style lang="scss" scoped>
.baseCard {
  display: grid;
  border-radius: 20px;
  padding: 26px 16px;
  align-items: flex-start;
  align-content: space-between;
  column-gap: 26px;
  flex-wrap: wrap;
  flex-direction: column;
}

.indicatorsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex: 1 0 0;
  flex-wrap: wrap;
  row-gap: 8px;
}

.score-meter {
  position: relative;
  top: -14px;
  height: 10px;
  border-radius: 20px;
  border: 2px solid var(--colors-neutral-grey-4);
  background-image: linear-gradient(
    90deg,
    hsl(0deg 100% 36%) 0%,
    hsl(29deg 100% 46%) 33%,
    hsl(49deg 100% 41%) 67%,
    hsl(104deg 100% 45%) 100%
  );
}

.indicator {
  position: relative;
  z-index: 1000;
}

.box-last-updated {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
  background: #FFF;
  min-height: 148px;
  min-width: 157px;
  background-image: url("../../assets/images/line.svg");
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

.last-updated-date {
  position: relative;
  left: -12px;
  bottom: 13px;
}

.last-updated-title {
  margin-left: 13px;
  margin-top: 9px;
}
</style>