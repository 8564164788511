export default (summaryBlockInstance, constants, dynamics) => {
  for (const key of Object.keys(constants)) {
    summaryBlockInstance.addStaticData(key, constants[key]);
  }

  for (const key of Object.keys(dynamics)) {
    dynamics[key].forEach(el => {
      summaryBlockInstance.addDynamicData(key, el.key, el.fn, el.rawKey);
    });
  }

  return summaryBlockInstance.items;
};
