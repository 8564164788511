<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { LCircle, findRealParent, propsBinder } from 'vue2-leaflet';
import { DomEvent } from 'leaflet';
import Editable from '../../mixins/editable';

const props = {
  radius: Number,
};

export default {
  name: 'EditableCircle',
  extends: LCircle,
  mixins: [Editable],
  props,
  created() {
  },
  mounted() {
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$listeners);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this);
  },
  beforeDestroy() {
    this.mapObject.disableEdit(this.parentContainer);
    this.parentContainer.removeLayer(this);
  },
};
</script>
