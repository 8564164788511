import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useHomeStore = defineStore('home', () => {
  const selected = ref('lead');

  const getSelected = computed(() => selected.value);

  function setSelected (id) {
    selected.value = id;
  }

  return {
    getSelected,
    setSelected,
  };
});