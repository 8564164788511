import {
  clearFilters,
} from './utils';

const initialState = {
  filter: null,
};

const getters = {
  filter: state => state.filter,
};

const mutations = {
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
};

const actions = {
  clearFilters,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
