var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-input"},[_c('div',{staticClass:"custom-input__content-wrapper"},[_c('gmap-autocomplete',{ref:"gMapAutocomplete",staticClass:"input-field",class:{
        'input-field--filled': _vm.isFilled,
        'input-field--error': _vm.isError,
      },attrs:{"id":_vm.name,"placeholder":_vm.isActive ? _vm.placeholder : '',"options":_vm.autocompleteOptions},on:{"focusin":function($event){return _vm.toggleActiveInput(true)},"focusout":function($event){return _vm.toggleActiveInput(false)},"place_changed":_vm.onAutoCompleteSelection}}),_c('label',{staticClass:"label",class:{
        'label--filled': _vm.isFilled,
        'label--active': _vm.isActive,
        'label--error': _vm.isError,
      },attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1),(_vm.isError)?_c('div',{staticClass:"custom-input__error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }