<template>
  <v-card
    :class="columns ? `base-card col-${columns}` : 'base-card'"
    :elevation="elevation"
    :style="backgroundColorStyle"
  >
    <slot/>
  </v-card>
</template>

<script>

export default {
  name: 'BaseCard',
  props: {
    bgColor: {
      default: '#fff',
    },
    elevation: {
      default: 0,
    },
    columns:{
      default: ''
    }
  },
  computed: {
    backgroundColorStyle () {
      return `background-color: ${this.bgColor}; border-color: ${this.bgColor}`
    },
  },
}

</script>

<style lang="scss" scoped>

  .base-card{
    @apply w-full px-4 py-2;
    border-radius: 18px;
  }

</style>