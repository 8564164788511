<template>
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4853 0.840278C13.7592 0.56374 14.1315 0.406899 14.5208 0.404032C14.9101 0.401164 15.2847 0.552504 15.5627 0.824976C15.8407 1.09745 15.9996 1.4689 16.0045 1.85814C16.0095 2.24739 15.8602 2.62278 15.5893 2.90228L7.74926 12.7033C7.61457 12.8483 7.45205 12.9647 7.27138 13.0455C7.09072 13.1263 6.89563 13.1699 6.69775 13.1736C6.49988 13.1773 6.30328 13.1411 6.1197 13.0672C5.93612 12.9933 5.76932 12.8831 5.62926 12.7433L0.431257 7.54728C0.155034 7.27092 -9.37257e-05 6.89616 4.24844e-08 6.50542C9.38107e-05 6.11469 0.155401 5.74 0.431757 5.46378C0.708113 5.18756 1.08288 5.03243 1.47361 5.03252C1.86434 5.03262 2.23903 5.18792 2.51526 5.46428L6.62626 9.57628L13.4463 0.886278C13.4586 0.870466 13.472 0.855437 13.4863 0.841278L13.4853 0.840278Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
};
</script>
