<template>
  <v-btn
    rounded
    small
    outlined
    class="google-btn"
    @click="visitGoogle()"
  >
    <google-logo class="google-btn__logo" />
    Pesquisar
  </v-btn>
</template>

<script>

import GoogleLogo from '@/components/icons/SocialLoginGoogle.vue';

export default {
  name: 'OpenGoogleMaps',
  components: {
    GoogleLogo,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    visitGoogle () {
      const url = 'https://www.google.com/search';
      const name = this.property.condoName.replace(/\s/g, '+');
      const address = `${this.property.addressLine1.replace(/\s/g, '+')}+${this.property.addressLine2.replace(/\s/g, '+')}`;
      const query = `?q=${name}+-+${address}`;

      window.open(`${url}${encodeURI(query)}`);
    },
  },
};

</script>

<style lang="scss" scoped>
.google-btn {
  @apply ml-1;

  &__logo {
    @apply ml-0;
    height: 11px;
  }
}

</style>
