<template>
  <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1105 26.0035C8.28619 24.4474 6.59522 22.7414 5.05525 20.9035C2.74428 18.1433 1.26944e-06 14.0327 1.26944e-06 10.1156C-0.00100097 8.11508 0.591478 6.15926 1.70246 4.49562C2.81343 2.83198 4.39297 1.53531 6.24117 0.769709C8.08937 0.00411171 10.1231 -0.195999 12.0851 0.194702C14.0471 0.585403 15.849 1.54935 17.263 2.96456C18.2043 3.90174 18.9505 5.01632 19.4582 6.24379C19.966 7.47126 20.2252 8.78724 20.221 10.1156C20.221 14.0327 17.4767 18.1433 15.1658 20.9035C13.6258 22.7414 11.9348 24.4474 10.1105 26.0035ZM10.1105 5.7825C8.9613 5.7825 7.85917 6.23902 7.04656 7.05163C6.23395 7.86424 5.77743 8.96637 5.77743 10.1156C5.77743 11.2648 6.23395 12.3669 7.04656 13.1795C7.85917 13.9921 8.9613 14.4486 10.1105 14.4486C11.2597 14.4486 12.3618 13.9921 13.1745 13.1795C13.9871 12.3669 14.4436 11.2648 14.4436 10.1156C14.4436 8.96637 13.9871 7.86424 13.1745 7.05163C12.3618 6.23902 11.2597 5.7825 10.1105 5.7825Z" fill="currentColor"/>
    <circle cx="10" cy="10" r="5" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PinIcon',
};
</script>
