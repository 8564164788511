<template>
  <div class="d-flex flex-column gap-4 w-full">
    <Typography
      tag="h1"
      variant="header-black"
      color="brand-dark"
      align="center"
    >
      {{ sidebarTitle }}
    </Typography>
    <div class="w-full d-flex justify-center align-center">
      <Tab
        class="w-4/6"
        :labels="['São Paulo', 'Rio de Janeiro']"
        :onTabClick="[() => onTabClick('SP'), () => onTabClick('RJ')]"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { CITY_CENTER, FILTER_MODE, NAVBAR_MENU_ITEMS} from '@/common/constants';
import Tab from '@/components/Tab/Tab.vue';
import Typography from '@/components/base/Typography';

export default {
  name: 'LocationSelector',
  components: {
    Tab,
    Typography
  },
  data() {
    return {
      FILTER_MODE,
      CITY_CENTER
    };
  },
  created () {
    this.setCurrentCity('SP');
    this.changeCurrentCity('SP');
  },
  computed: {
    ...mapGetters('map', ['currentCity']),
    sidebarTitle () {
      switch (this.$route.path) {

      case NAVBAR_MENU_ITEMS.RENTER.href:
        return NAVBAR_MENU_ITEMS.RENTER.label;

      case NAVBAR_MENU_ITEMS.CONSULTANT.href:
        return NAVBAR_MENU_ITEMS.CONSULTANT.label;

      default:
        return NAVBAR_MENU_ITEMS.BUYER.label;
      }
    },
    isMobile () {
      return this.$route.path.includes('mobile')
    }
  },
  

  methods: {
    ...mapActions('map', ['setCurrentCity']),
    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),
    onTabClick (city) {
      this.changeCurrentCity(city)
      this.setFilterMode(FILTER_MODE.RADIUS)
    },
    changeCurrentCity (key) {
      this.setCurrentCity(key);
      this.setMapCenter(CITY_CENTER[key]);
    },
  },
};
</script>

<style lang="scss" scoped>
#city-filter {
  margin-bottom: 16px;
  
  .v-btn {
    border: 0;
    font-weight: 400;
    text-transform: none;
    color: $secondary-dark-blue;
    border-radius: 29px;
    min-height: 20px!important;
    height: 32px;
    min-width: 158px;
  }
  .btn--active {
    background-color: $secondary-dark-blue;
    font-weight: 600;
    color: white;
  }
}
</style>