import { Auth } from 'aws-amplify';

import api from '../../api';

const initialState = {
  accessToken: null,
  idToken: null,
  favorites: [],
  consultaDadosQuota: null,
  consultaDadosQuotaLoading: false,
  avmQuota: null,
  avmQuotaLoading: false,
  LGPDAcceptance: false,
};

const getters = {
  accessToken: state => state.accessToken,
  idToken: state => state.idToken,
  favorites: state => state.favorites,
  consultaDadosQuota: state => state.consultaDadosQuota,
  consultaDadosQuotaLoading: state => state.consultaDadosQuotaLoading,
  avmQuota: state => state.avmQuota,
  avmQuotaLoading: state => state.avmQuotaLoading,
  LGPDAcceptance: state => state.LGPDAcceptance,
  leadPermit (state) {
    return Boolean(Number(state.idToken?.payload?.lead_permit));
  },
};

const mutations = {

  SET_ACCESS_TOKEN: (state, accessToken) => {
    state.accessToken = accessToken;
  },

  SET_ID_TOKEN: (state, idToken) => {
    state.idToken = idToken;
  },

  SIGN_IN_ERROR: (state, error) => {
    state.error = error;
  },

  SET_CONSULTA_DADOS_QUOTA: (state, quota) => {
    state.consultaDadosQuota = quota;
  },

  SET_CONSULTA_DADOS_QUOTA_LOADING: (state, loading) => {
    state.consultaDadosQuotaLoading = loading;
  },

  SET_AVM_QUOTA: (state, quota) => {
    state.avmQuota = quota;
  },

  SET_AVM_QUOTA_LOADING: (state, loading) => {
    state.avmQuotaLoading = loading;
  },

  SET_LGPD_ACCEPTANCE: (state, acceptance) => {
    state.LGPDAcceptance = acceptance;
  },
};

const actions = {

  async setAccessToken ({ commit }, { signInUserSession }) {

    api.base.defaults.headers.AccessToken = signInUserSession.accessToken.jwtToken;
    api.base.defaults.headers.Authorization = signInUserSession.idToken.jwtToken;

    commit('SET_ACCESS_TOKEN', signInUserSession.accessToken);
    commit('SET_ID_TOKEN', signInUserSession.idToken);
  },

  async logout ({ commit }, { router }) {
    commit('SET_ACCESS_TOKEN', null);
    commit('SET_ID_TOKEN', null);

    delete api.base.defaults.headers.Authorization;
    delete api.base.defaults.headers.AccessToken;

    // Prevents amplify redirect
    localStorage.setItem('amplify-signin-with-hostedUI', 'false');

    await Auth.signOut({ global: true })
      .catch(e => {
        if (e.message === 'Access Token has been revoked') {
          localStorage.clear();
        }
      })
      .finally(async () => {
        localStorage.removeItem('vuex');
        router.push('/login');
      });
  },

  async fetchConsultaDadosQuota ({ commit }) {

    commit('SET_CONSULTA_DADOS_QUOTA_LOADING', true);

    await api.user.getConsultaDadosQuota()
      .then(personDataQuota => commit('SET_CONSULTA_DADOS_QUOTA', personDataQuota))
      .catch(console.log)
      .finally(() => commit('SET_CONSULTA_DADOS_QUOTA_LOADING', false));
  },

  async fetchAVMQuota ({ commit }) {

    commit('SET_AVM_QUOTA_LOADING', true);

    await api.avm.quota()
      .then(avmQuota => commit('SET_AVM_QUOTA', avmQuota))
      .catch(console.log)
      .finally(() => commit('SET_AVM_QUOTA_LOADING', false));
  },

  async getLGPDTermsAcceptance ({ commit }, domain) {

    await api.user.getLGPDTermsAcceptance(domain)
      .then(response => commit('SET_LGPD_ACCEPTANCE', !!(response && response.accepted)))
      .catch(() => console.log);
  },

  async setLGPDTermsAcceptance ({ commit }, params = { }) {

    await api.user.setLGPDTermsAcceptance(params.vertical, params.accepted)
      .then(() => commit('SET_LGPD_ACCEPTANCE', params.accepted))
      .catch(() => commit('SET_LGPD_ACCEPTANCE', false));
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters,
  mutations,
  actions,
};
