import { LAST_UPDATED_AT_MESSAGE, DATE_TIME_INTL_STANDARD } from './../common/constants'

const lastUpdatedAtMessage = date => {
  const dateFromString = new Date(date)
  const intlFormat = new Intl.DateTimeFormat(DATE_TIME_INTL_STANDARD).format(dateFromString)

  return `${LAST_UPDATED_AT_MESSAGE} ${intlFormat}`
}

export {
  lastUpdatedAtMessage
}
