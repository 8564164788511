<template>
  <div
    v-if="hasResult"
    class="avm-report"
  >
    <div class="avm-report__title">
      Quanto vale esse imóvel?
    </div>
    <div class="avm-report__search-params">
      <div class="avm-report__search-grid">
        <div class="avm-report__search-grid-title">
          Localização
        </div>
        <div class="avm-report__content-block">
          <div>
            <div class="avm-report__content-item-title">
              Endereço
            </div>
            <div class="avm-report__content-item-description">
              {{ lastSearch.endereco }}, {{ lastSearch.numero }}
            </div>
          </div>
          <div class="flex flex-row flex-grow">
            <div class="avm-report__content-item w-1/2">
              <div class="avm-report__content-item-title">
                Bairro
              </div>
              <div class="avm-report__content-item-description">
                {{ lastSearch.bairro }}
              </div>
            </div>
            <div class="avm-report__content-item">
              <div class="avm-report__content-item-title">
                Cidade
              </div>
              <div class="avm-report__content-item-description">
                {{ lastSearch.cidade }} - {{ lastSearch.estado }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="avm-report__search-grid">
        <div class="avm-report__search-grid-title">
          Características
        </div>
        <div class="avm-report__content-block">
          <div class="flex flex-row flex-grow mr-2">
            <div class="avm-report__content-item w-1/2">
              <div class="avm-report__content-item-title">
                Tipologia
              </div>
              <div class="avm-report__content-item-description">
                {{ lastSearch.tipologia }}
              </div>
            </div>
            <div class="avm-report__content-item">
              <div class="avm-report__content-item-title">
                Ano de construção
              </div>
              <div class="avm-report__content-item-description">
                {{ lastSearch.ano_construcao }}
              </div>
            </div>
          </div>
          <div>
            <div class="avm-report__content-item-title">
              Descrição
            </div>
            <div class="avm-report__content-item-description">
              {{ lastSearch.area_util }} m<sup>2</sup>
              | {{ lastSearch.dormitorios }} {{ lastSearch.dormitorios === 1 ? 'Dormitório' : 'Dormitórios' }}
              | {{ lastSearch.suites }} {{ lastSearch.suites === 1 ? 'Suíte' : 'Suítes' }}
              | {{ lastSearch.vagas }} {{ lastSearch.vagas === 1 ? 'Vaga' : 'Vagas' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="avm-report__response">
      <div class="avm-report__response-header">
        <div>
          <div class="avm-report__response-header-title">
            Valores estimados
          </div>
          <div class="avm-report__response-header-description">
            Comparativo com imóveis semelhantes na mesma região
          </div>
        </div>
        <div class="avm-report__response-header-image">
          <img
            src="@/assets/images/datazap.svg"
            alt="Logotipo DataZap"
          >
        </div>
      </div>
      <div class="avm-report__response-content">
        <div class="flex flex-row justify-between">
          <div class="avm-report__response-descriptions">
            <div class="avm-report__response-descriptions--avm">
              <div class="avm-report__response-name">
                AVM <span class="narrow">| DataZap</span>
              </div>
              <div class="avm-report__response-description">
                Avaliação mercadológica
              </div>
            </div>
            <div
              v-if="hasAskingPrice"
              class="avm-report__response-descriptions--asking-price"
            >
              <div class="avm-report__response-name">
                Asking price
              </div>
              <div class="avm-report__response-description">
                Valores pedidos pelos proprietários
              </div>
            </div>
          </div>
          <div class="avm-report__response-values">
            <div class="avm-report__response-values--min">
              <div class="avm-report__response-title--min">
                Mínimo
              </div>
              <div class="avm-report__response-values--main">
                {{ avmResult.response.MIN | money }}
              </div>
              <div class="avm-report__response-values--meter">
                {{ parseInt(avmResult.response.MIN_M2, 10) | money }} m<sup>2</sup>
              </div>
              <template v-if="hasAskingPrice">
                <div class="avm-report__response-values--main mt-6">
                  {{ askingPrice && askingPrice.asking_price.min | money }}
                </div>
                <div class="avm-report__response-values--meter">
                  {{ askingPrice && askingPrice.asking_price.min_m2 | money }} m<sup>2</sup>
                </div>
              </template>
            </div>
            <div class="avm-report__response-values--avg">
              <div class="avm-report__response-title--avg">
                Médio
              </div>
              <div class="avm-report__response-values--main">
                {{ avmResult.response.CENTRAL | money }}
              </div>
              <div class="avm-report__response-values--meter">
                {{ parseInt(avmResult.response.CENTRAL_M2, 10) | money }} m<sup>2</sup>
              </div>
              <template v-if="hasAskingPrice">
                <div class="avm-report__response-values--main mt-6">
                  {{ askingPrice && askingPrice.asking_price.avg | money }}
                </div>
                <div class="avm-report__response-values--meter">
                  {{ askingPrice && askingPrice.asking_price.avg_m2 | money }} m<sup>2</sup>
                </div>
              </template>
            </div>
            <div class="avm-report__response-values--max">
              <div class="avm-report__response-title--max">
                Máximo
              </div>
              <div class="avm-report__response-values--main">
                {{ avmResult.response.MAX | money }}
              </div>
              <div class="avm-report__response-values--meter">
                {{ parseInt(avmResult.response.MAX_M2, 10) | money }} m<sup>2</sup>
              </div>
              <template v-if="hasAskingPrice">
                <div class="avm-report__response-values--main mt-6">
                  {{ askingPrice && askingPrice.asking_price.max | money }}
                </div>
                <div class="avm-report__response-values--meter">
                  {{ askingPrice && askingPrice.asking_price.max_m2 | money }} m<sup>2</sup>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'AvmReport',
  computed: {
    ...mapGetters('avm', [
      'hasResult',
      'isLoading',
      'lastSearch',
      'avmResult',
      'askingPrice',
      'hasAskingPrice',
    ]),
  },
};

</script>

<style lang="scss" scoped>

.avm-report {
  @apply flex flex-col items-center;
  z-index: 3;
  background-color: #fff;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 100%;
  position: relative;

  &__title {
    @apply my-10;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #384059;
    text-align: center;
  }

  &__search-params {
    @apply px-8 pb-48;
    @apply flex flex-col;
    width: clamp(765px, 765px, 765px);
    background: #F3F5FB;
    border-radius: 5px;
  }

  &__search-grid {
    @apply flex flex-col;
    @apply mt-8;

    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      color: #303A56;
    }
  }

  &__content-block {
    @apply grid grid-cols-2;
    @apply mt-4;
  }

  &__content-item {
    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #303A56;
    }

    &-description {
      @apply mt-2;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #707070;
    }
  }

  &__response {
    width: 701px;
    background: #FFFFFF;
    border: 1px solid #C7D0EA;
    border-radius: 5px;
    transform: translateY(-142px);

    &-header {
      @apply w-full flex flex-row justify-between items-center;
      padding: 22px 24px;
      background: #303A56;
      color: #FFFFFF;
      border-radius: 4px 4px 0px 0px;

      &-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
      }

      &-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #F3F5FB;
      }
    }

    &-content {
      padding: 0 24px 26px 24px;
    }

    &-descriptions {
      @apply w-1/2;
      @apply flex flex-col justify-end;

      &--avm {
        @apply mt-16;
      }

      &--asking-price {
        @apply mt-6;
      }
    }

    &-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 18px;
      color: #303A56;

      .narrow {
        font-weight: 400;
      }
    }

    &-description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #A2A5AB;
      margin-top: 8px;
    }

    &-values {
      @apply flex flex-row flex-grow justify-between;
      font-family: 'Lato';
      font-style: normal;
      color: #303A56;

      &--min, &--max, &--avg {
        @apply flex flex-col justify-between;
      }

      &--avg {
        color: rgba(90, 127, 237, 1);
      }

      &--main {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 8px;
      }

      &--meter {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
      }
    }

    &-title {
      &--min,
      &--avg,
      &--max
       {
        margin: 25px 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #A2A5AB;
      }

      &--avg {
        color: rgba(90, 127, 237, 1);
      }
    }
  }
}

</style>
