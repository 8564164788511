<template>
  <base-card>
    <div class="flex flex-column gap-1">
      <Typography
          variant="header-large"
          color="neutral-grey-6"
        >
          Pessoas de Contato
        </Typography>
        <Typography
          variant="body-xs"
          color="neutral-grey-5"
        >
          Contatos importantes
        </Typography>
    </div>
    <v-data-table
      v-if="relationships.length > 0"
      :headers="headers"
      :items="sanitizedRelationships"
      sort-by="updated_at"
      :sort-desc="true"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <span>{{ item?.name }}</span>
          </td>
          <td v-if="!item.isBlocked">
            <div class="flex align-center justify-center">
              <span>{{ item?.type }}</span>
            </div>
          </td>
          <td v-else colspan="4">
            <div class="isBlocked-container" data-test-id="lgpd-blocked">
              <img src="@/assets/images/block-red.svg">
              <span class="isBlocked-text">
                Dados bloqueados a pedido do titular de acordo com a Lei Geral de Proteção de Dados (LGPD)
              </span>
            </div>
          </td>
          <td v-if="!item.isBlocked">
            <div class="flex align-center justify-center">
              <span>{{ item?.death }}</span>
            </div>
          </td>
          <td v-if="!item.isBlocked">
            <a
              href="/person-data"
              target="_blank"
            >
              <div class="py-4">
                <Button
                  variant="primary"
                  v-if="item"
                  size="small"
                  @click="openNewPersonSearch(item?.button?.value)"
                  data-test-id="person-search"
                  class="button-width"
                >
                  <div class="flex gap-2">
                    <img
                      width="12px"
                      src="@/assets/images/consultaDados.svg"
                    >
                      Consultar dados
                  </div>
                </Button>
              </div>
            </a>
          </td>
          <td v-if="!item.isBlocked">
            <div  class="flex align-center justify-center">
              <span>{{ item?.updated_at }}</span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <p v-else class="text-center">
      Não há dados para serem exibidos.
    </p>
  </base-card>
</template>

<script>

import { mapMutations } from 'vuex';
import { formatYear } from '@/utils';
import BaseCard from '../cards/base-card.vue';
import InfoMarkerCard from '../cards/info-marker-card.vue';
import SimpleTitle from '../search/SimpleTitle.vue';
import Typography from '../base/Typography';
import Button from '../base/Button';

export default {
  name: 'ContactPersons',
  components: {
    BaseCard,
    InfoMarkerCard,
    SimpleTitle,
    Typography,
    Button
},
  props: {
    relationships: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Vínculo',
          value: 'type',
          align: 'right',
          width: 118,
        },
        {
          text: 'Óbito',
          value: 'death',
          width: 115,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 156,
        },
        {
          text: 'Última atualização',
          value: 'updated_at',
          align: 'center',
          width: 200,
        },
      ],
    };
  },
  computed: {
    sanitizedRelationships() {
      return this.relationships.map(relationship => ({
        ...relationship,
        death: +relationship.death ? 'Sim' : '-',
        button: this.button(relationship),
        updated_at: formatYear(relationship.updated_at),
        isBlocked: relationship.is_blocked || false,
      }));
    },
  },
  methods: {
    ...mapMutations({
      setPersonQuery: 'person/SET_PERSON_QUERY',
    }),
    button(relationship) {
      return {
        type: relationship.document ? 'document' : 'name',
        value: relationship.document || relationship.name,
      };
    },
    openNewPersonSearch(value) {
      this.setPersonQuery(value);
    },
  },
};

</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 20px;
  gap: 16px;
}

.isBlocked-container {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--colors-feedback-error);
  background: #FFF;
  margin: 16px 0px;
}

.isBlocked-text {
  color: #707070;
  font-family: 'Lato';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

::v-deep th {
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #6879A6 !important;
}

::v-deep td {
  font-family: Lato !important;
  font-size: 14px !important;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child) {
  color: var(--colors-neutral-grey-5);
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:first-child)){
  color: #777;
  text-align: center;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.button-width {
  min-width: 156px!important;
  height: 32px;
  padding: 10px 16px;
  gap: 10px;
}

:deep(.v-data-table thead tr th:nth-child(3) span) {
  padding-left: 16px;
}

:deep(.v-data-table .v-data-footer__select .v-select) {
  margin: 13px 0 13px 16px;
}
:deep(.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon) {
  font-size: 32px;
}

:deep(.v-data-table .v-data-footer__icons-before .v-btn:last-child){
  margin-right: 0px;
}

:deep(.v-data-table .v-data-footer__icons-after .v-btn:first-child){
  margin-left: 0px;
}

:deep(.v-data-table .v-data-footer__pagination) {
  margin: 0px 16px;
}

:deep(.v-data-table .v-data-footer) {
  display: flex;
  align-items: center;
  border-top: none!important;
  height: 32px;
  margin-top: 16px;
}

:deep(.v-data-table .v-data-footer .v-data-footer__select) {
  height: 32px;
  margin-right: 0px!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th) {
  border-bottom: none!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th i){
  margin-bottom: 5px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child)){
  margin: 15px 0px;
}

:deep(.v-data-table .v-data-table__wrapper) {
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}
</style>