<template>
  <div class="skeleton-results">
    <div class="w-full flex justify-center">
      <v-skeleton-loader
        class="mx-auto my-4 flex"
        :class="titleClass"
        width="100%"
        type="heading"
      ></v-skeleton-loader>

    </div>
    <div class="w-full flex">
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
    </div>
    <div class="w-full flex">
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
    </div>
    <div class="w-full flex">
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        width="48%"
        type="card"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonResults',
  props: {
    titleClass: {
      type: [String, Object],
      default: 'justify-center',
    },
  },
};

</script>

<style lang="scss" scoped>

.skeleton-results {
  @apply mt-4 flex flex-col justify-start items-center min-h-screen w-full;
}

</style>
