<template>
  <Autocomplete
    v-model="address"
    custom-item-slot
    remote
    :custom-item-height="36"
    :options="suggestions"
    :loading="suggestionsLoading"
    :single="{
      label: 'name',
      value: 'id',
    }"
    name="district"
    label="Endereço ou condomínio"
    placeholder="Endereço ou condomínio"
    @search-input="onSearchInputUpdate"
    @select="getCondo"
  >
    <template #default="{ item }">
      <div
        :title="`${item.name} - ${item.address}, ${item.number} - ${item.district}, ${item.city} - ${item.state}`"
        class="item"
      >
        <Typography
          tag="div"
          variant="body-medium"
          color="brand-dark"
          class="name-line"
        >
          {{ item.name }}
        </Typography>

        <Typography
          tag="span"
          variant="body-small"
          color="brand-default"
          class="address-line"
        >
          {{ item.address }}, {{ item.number }} - {{ item.district }}, {{ item.city }} - {{ item.state }}
        </Typography>
      </div>
    </template>
  </Autocomplete>
</template>

<script>

import debounce from 'lodash.debounce';

import { mapState, mapActions, mapMutations } from 'vuex';
import { FILTER_MODE, CITY_CENTER, PROPERTY_TYPE } from '../../common/constants';

import Autocomplete from '@/components/forms/Autocomplete.vue';
import Typography from '@/components/base/Typography';

export default {

  components: {
    Autocomplete,
    Typography,
  },

  props: {

    filter: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data () {

    return {
      FILTER_MODE,
      CITY_CENTER,
      PROPERTY_TYPE,
      suggestions: [],
      suggestionsLoading: false,
      address: '',
    };
  },

  computed: {

    ...mapState({

      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`]; 
      },

      isLoading (state, getters) {
        return getters[`${this.$route.name}/isLoading`]; 
      },

      error (state, getters) {
        return getters[`${this.$route.name}/error`]; 
      },
    }),
  },

  watch: {

    filter (filter) {

      if (!filter.location) {
        this.address = '';
        this.suggestions = [];
      }
    },
  },

  created () {
  
    this.fetchCondos = debounce(this.fetchCondos.bind(this), 400);
  },

  methods: {

    ...mapActions({

      fetchCondoSuggestions (dispatch, payload) {
        return dispatch(`${this.$route.name}/fetchCondoSuggestions`, payload); 
      },
  
      getCondoById (dispatch, payload) {
        return dispatch(`${this.$route.name}/getCondoById`, payload); 
      },
    }),

    ...mapMutations({
  
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },
  
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),

    onSearchInputUpdate (query) {
  
      if (query) {
        this.fetchCondos(query);
      }
    },

    async getCondo (value) {

      if (!value) return;

      const info = await this.getCondoById({ condoId: value.id, domain: 'seller' });
      const { lat, lng } = value || {};

      const condo = { info, location: value };

      this.filter.address = condo;

      this.$emit('condoUpdate', condo);

      this.setFilter({
        geolocation: [
          parseFloat(lat),
          parseFloat(lng),
        ],
      });
    },

    async fetchCondos (query) {
    
      try {

        this.suggestionsLoading = true;

        this.suggestions = await this.fetchCondoSuggestions({ query, domain: this.$route.name });
      }
      catch (err) {
    
        console.error(err);
      }
      finally {
    
        this.suggestionsLoading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.input {
  padding: 14px!important;
}
.item {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -1px;

  .address-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
</style>