import api from '@/api';

export default async function generateReport(
  { state, commit, rootGetters },
  data
) {
  let hash = null;
  const mapPins = rootGetters['map/pins'];

  try {
    commit('SET_LOADING', true);
    const { lastSearch = {}, summary = {}, route } = data;
    const { relatorio } = state;
    const resultFiltered = mapPins.map(pin => pin.addressId);

    const filter = () => {
      return route === 'seller' ? lastSearch.requestParams : lastSearch[0];
    };

    const attributes = () => {
      return route === 'seller' ? lastSearch.attributesFilter : lastSearch[1];
    };

    const params = () => {
      return route === 'seller' ? {} : lastSearch[2];
    };

    const { searchId } = await api.address.generateReport(
      {
        filter: filter(),
        attributes: attributes(),
        params: params(),
        summary,
        relatorio
      },
      resultFiltered,
      route
    );
    hash = searchId;
  } catch (error) {
    console.error(error);
  } finally {
    commit('SET_LOADING', false);
  }

  return hash;
}
