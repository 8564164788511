import { flagProperty, unsetPropertyFlag } from './utils';

const initialState = {
  flagged: [],
};

const getters = {
  flagged(state) {
    return state.flagged;
  },
};

const mutations = {
  FLAG_PROPERTY(state, propertyId) {
    state.flagged.push(propertyId);
  },
  UNSET_PROPERTY_FLAG(state, propertyId) {
    state.flagged = state.flagged.filter(id => id !== propertyId);
  },
  FLAG_PROPERTY_SUCCESS() {},
  FLAG_PROPERTY_ERROR(state, error) {
    state.error = error;
  },
  UNSET_PROPERTY_FLAG_SUCCESS() {},
  UNSET_PROPERTY_FLAG_ERROR(state, error) {
    state.error = error;
  },
  RESET_FLAG_STATE(state) {
    state.flagged = [];
  },
};

const actions = {
  flagProperty,
  unsetPropertyFlag,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
