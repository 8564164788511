import { css } from '@/core/theme';

const size = {
  default: {
    height: '32px',
    padding: '$small'
  },

  xs: {
    height: '24px',
    padding: '10px 16px'
  },

  small: {
    padding: '$xs',
  },

  medium: {
    padding: '$small',
  },

  large: {
    height: '40px',
    padding: '$small'
  },
};

const primary = {

  default: {
    color: '$neutral-white !important',
    backgroundColor: '$highlight-light',
  },

  hover: {
    backgroundColor: '$highlight-default',
  },

  active: {
    backgroundColor: '$highlight-dark',
  },

  focus: {
    backgroundColor: '$highlight-dark',
  },
};

const secondary = {
  
  default: {
    color: '$highlight-light',
    border: '1px solid $highlight-light',
    backgroundColor: '$neutral-white',
  },

  hover: {
    color: '$neutral-white',
    backgroundColor: '$highlight-light',
  },

  active: {
    color: '$neutral-white',
    borderColor: '1px solid $highlight-default',
    backgroundColor: '$highlight-dark',
  },

  focus: {
    color: '$neutral-white',
    borderColor: '$highlight-default',
    backgroundColor: '$highlight-default',
  },
};

const alternative = {
  
  default: {
    color: '$neutral-white',
    backgroundColor: '$brand-default',
  },

  hover: {
    backgroundColor: '$brand-dark',
  },

  active: {
    backgroundColor: '$brand-darker',
  },
};

const alternativeFade = {
  
  default: {
    color: '$highlight-light',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },

  hover: {
    color: '$neutral-white',
    backgroundColor: 'rgba(107, 140, 239, 0.32)',
  },

  active: {
    color: '$neutral-white',
    backgroundColor: '$highlight-default',
    border: '2px solid var(--colors-highlight-default)',
  },
};

const alternativeGreen = {
  
  default: {
    color: '$neutral-white',
    backgroundColor: '$feedback-success',
    display: 'inline-flex',
    padding: '16px 16px !important',
    gap: '$nano',
    minWidth: '114px!important',
    height: '32px',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '14px',
  },

  hover: {
    backgroundColor: '$feedback-success',
    boxShadow: '$medium',
  },

  active: {
    backgroundColor: '#1E9977',
    boxShadow: '$medium',
  },
  
  disabled: {
    color: '$neutral-white !important',
    backgroundColor: '$neutral-grey-3',
    boxShadow: 'none',
  },
};

const alternativeGoogle = {
  
  default: {
    display: 'flex',
    height: '24px',
    minWidth: '99px!important',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flexShrink: '0',
    border: '1px solid var(--colors-highlight-light)',
    color: '$highlight-light'
  },

  hover: {
    boxShadow: '$medium',
  },

  active: {
    backgroundColor: '$brand-lighter',
    boxShadow: '$xs',
  },
  
  disabled: {
    color: '$neutral-white !important',
    backgroundColor: '$neutral-grey-3',
    border: 'none',
    boxShadow: 'none',
  },
};

const alternative123i = {
  
  default: {
    display: 'flex',
    height: '24px',
    minWidth: '94px!important',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flexShrink: '0',
    border: '1px solid var(--colors-highlight-light)',
    color: '$highlight-light'
  },

  hover: {
    boxShadow: '$medium',
  },

  active: {
    backgroundColor: '$brand-lighter',
    boxShadow: '$xs',
  },
  
  disabled: {
    color: '$neutral-white !important',
    backgroundColor: '$neutral-grey-3',
    border: 'none',
    boxShadow: 'none',
  },
};

const seeMore = {
  default: {
    backgroundColor: '$neutral-white',
    display: 'flex',
    width: '73px!important',
    height: '20px',
    padding: '5px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: '0',
    border: '1px solid var(--colors-additional-blue-3)',
    color: '$brand-default'
  },

  hover: {
    backgroundColor: '$additional-blue-3',
  },

  active: {
    background: '$brand-default',
    color: '$neutral-white'
  },

  disabled: {
    border: 'none'
  }
}

const mobilePrimary = {
  
  default: {
    color: '$brand-dark',
    backgroundColor: '$brand-lighter',
  },

  hover: {
    backgroundColor: '$brand-lighter',
  },

  active: {
    backgroundColor: '$brand-lighter',
  },
};

const icon = {

  default: {
    backgroundColor: '$brand-light',
  },

  hover: {
    backgroundColor: '$brand-default',
  },

  active: {
    backgroundColor: '$brand-dark',
  },
};

const iconXs = {

  default: {
    backgroundColor: '$neutral-white',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
  },

  hover: {
    backgroundColor: '$highlight-lighter',
  },

  active: {
    backgroundColor: '$brand-light',
  },
};

const iconWhatsApp = {

  default: {
    backgroundColor: '$feedback-success',
  },

  active: {
    backgroundColor: '#1E9977',
  },

  disabled: {
    backgroundColor: '$neutral-grey-3',
  },
};

const showIconOnHover = {
  
  true: {

    '& > span[data-icon]': {
      maxWidth: 0,
      opacity: 0,
      overflow: 'hidden',
      transition: 'max-width 1s ease, opacity 1s ease',
    },

    '&:hover': {

      '& > span[data-icon]': {
        maxWidth: '64px',
        opacity: 1,
      },
    },
  },

  false: { },
};

/**
 * Component
 */
const root = css({

  compoundVariants: [

    /**
     * primary: States
     */
    {
      variant: 'primary',
      state: 'default',
      css: primary.default,
    },

    {
      variant: 'primary',
      state: 'hover',
      css: primary.hover,
    },
    
    {
      variant: 'primary',
      state: 'hover',
      showLeftIconOnHover: true,
      css: {
        ...primary.hover,
        ...showIconOnHover['true']['&:hover'],
      },
    },

    {
      variant: 'primary',
      state: 'hover',
      showRightIconOnHover: true,
      css: {
        ...primary.hover,
        ...showIconOnHover['true']['&:hover'],
      },
    },

    {
      variant: 'primary',
      state: 'active',
      css: primary.active,
    },

    {
      variant: 'primary',
      state: 'focus',
      css: primary.focus,
    },

    {
      variant: 'primaryLarge',
      state: 'hover',
      css: primary.hover,
    },

    {
      variant: 'primaryLarge',
      state: 'hover',
      showLeftIconOnHover: true,
      css: {
        ...primary.hover,
        ...showIconOnHover['true']['&:hover'],
      },
    },

    {
      variant: 'primaryLarge',
      state: 'hover',
      showRightIconOnHover: true,
      css: {
        ...primary.hover,
        ...showIconOnHover['true']['&:hover'],
      },
    },

    /**
     * secondary: States
     */
    {
      variant: 'secondary',
      state: 'default',
      css: secondary.default,
    },

    {
      variant: 'secondary',
      state: 'hover',
      css: secondary.hover,
    },

    {
      variant: 'secondary',
      state: 'active',
      css: secondary.active,
    },

    {
      variant: 'secondary',
      state: 'focus',
      css: secondary.focus,
    },

    {
      variant: 'secondary',
      state: 'disabled',
      css: secondary.disabled,
    },

    /**
     * alternative: States
     */
    {
      variant: 'alternative',
      state: 'default',
      css: alternative.default,
    },

    {
      variant: 'alternative',
      state: 'hover',
      css: alternative.hover,
    },

    {
      variant: 'alternative',
      state: 'active',
      css: alternative.active,
    },

    /**
     * mobile-primary: States
     */
    {
      variant: 'mobile-primary',
      state: 'default',
      css: mobilePrimary.default,
    },

    {
      variant: 'mobile-primary',
      state: 'hover',
      css: mobilePrimary.hover,
    },
    
    {
      variant: 'mobile-primary',
      state: 'hover',
      showLeftIconOnHover: true,
      css: {
        ...mobilePrimary.hover,
        ...showIconOnHover['true']['&:hover'],
      },
    },

    {
      variant: 'mobile-primary',
      state: 'active',
      css: mobilePrimary.active,
    },

  ],
  
  variants: {
    state: { },
    showLeftIconOnHover: {
      ...showIconOnHover,
    },
    showRightIconOnHover: {
      ...showIconOnHover,
    },

    variant: {
      primary: {
        ...primary.default,
        '&:hover': primary.hover,
        '&:active': primary.active,
        '&:focus': primary.focus,
      },
      primaryXs: {
        ...primary.default,
        ...size.xs,
        '&:hover': primary.hover,
        '&:active': primary.active,
        '&:focus': primary.focus,
      },
      primaryDefault: {
        ...primary.default,
        ...size.default,
        '&:hover': primary.hover,
        '&:active': primary.active,
        '&:focus': primary.focus,
      },
      primaryLarge: {
        ...primary.default,
        ...size.large,
        '&:hover': primary.hover,
        '&:active': primary.active,
        '&:focus': primary.focus,
      },
      secondary: {
        ...secondary.default,
        '&:hover': secondary.hover,
        '&:focus': secondary.focus,
        '&:active': secondary.active,
      },
      secondaryXs: {
        ...secondary.default,
        ...size.xs,
        '&:hover': secondary.hover,
        '&:focus': secondary.focus,
        '&:active': secondary.active,
      },
      secondaryDefault: {
        ...secondary.default,
        ...size.default,
        '&:hover': secondary.hover,
        '&:focus': secondary.focus,
        '&:active': secondary.active,
      },
      alternative: {
        ...alternative.default,
        ...size.large,
        '&:hover': alternative.hover,
        '&:active': alternative.active,
      },
      alternativeFade: {
        ...alternativeFade.default,
        ...size.large,
        '&:hover': alternativeFade.hover,
        '&:active': alternativeFade.active,
      },
      alternativeGreen: {
        ...alternativeGreen.default,
        '&:hover': alternativeGreen.hover,
        '&:active': alternativeGreen.active,
        '&:disabled': alternativeGreen.disabled,
      },
      alternativeGoogle: {
        ...alternativeGoogle.default,
        '&:hover': alternativeGoogle.hover,
        '&:active': alternativeGoogle.active,
        '&:disabled': alternativeGoogle.disabled,
      },
      alternative123i: {
        ...alternative123i.default,
        '&:hover': alternative123i.hover,
        '&:active': alternative123i.active,
        '&:disabled': alternative123i.disabled,
      },
      seeMore: {
        ...seeMore.default,
        '&:hover': seeMore.hover,
        '&:active': seeMore.active,
        '&:disabled': seeMore.disabled,
      },
      'mobile-primary': {
        ...mobilePrimary.default,
        '&:hover': mobilePrimary.hover,
        '&:active': mobilePrimary.active,
      },
      icon: {
        minWidth: '50px!important',
        minHeight: '50px!important',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        color: '$neutral-white',
        
        '& > span': {
          textAlign: 'center',

          '& > svg': {
            display: 'inline-block',
          },
        },

        ...icon.default,
        '&:hover': icon.hover,
        '&:active': icon.active,
      },
      iconXs: {
        minWidth: '30px!important',
        height: '30px!important',
        padding: '0 !important',
        color: '$neutral-white',
        
        '& > span': {
          textAlign: 'center',

          '& > svg': {
            display: 'inline-block',
          },
        },

        ...iconXs.default,
        '&:hover': iconXs.hover,
        '&:active': iconXs.active,
      },
      iconWhatsApp: {
        minWidth: '30px!important',
        minHeight: '30px!important',
        padding: '0 !important',

        '& > span': {
          display: 'none',
        },

        '& > svg': {
            display: 'inline-block',
        },

        ...iconWhatsApp.default,
        '&:active': iconWhatsApp.active,
        '&:disabled': iconWhatsApp.disabled,
      },
    },

    size: {
      default: {
        height: '32px',
        padding: '$small'
      },

      xs: {
        height: '24px',
        padding: '10px 16px'
      },

      small: {
        padding: '$xs',
      },
  
      medium: {
        padding: '$small',
      },

      large: {
        height: '40px',
        padding: '$small'
      },
    },

    fullWidth: {

      true: {
        width: '100%',
      },

      false: { },
    },
  },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  textAlign: 'center',
  boxShadow: '$xs',
  borderRadius: '50px',
  transition: 'color .25s, background-color .25s, border-color .25s, border-width .25s',
  border: 'none',
  outline: 'none !important',
  flexShrink: 0,

  '&:hover': {
    cursor: 'pointer',
  },

  '&:disabled': {
    color: '$neutral-grey-4 !important',
    backgroundColor: '$neutral-grey-2',
    cursor: 'default',

    '&:hover': {
      backgroundColor: '$neutral-grey-2',
    },

    '&:focus': {
      backgroundColor: '$neutral-grey-2',
    },

    '&:active': {
      backgroundColor: '$neutral-grey-2',
    },
  },
});

export const styles = {
  root,
};
