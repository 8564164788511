<template>
  <v-app>
    <Navbar
      v-if="!isLoginRoute && !isMobileRoute"
      :variant="navbarVariant"
      :bnsir-broker="isBNSIRBroker"
      @navigate="onNavigate"
    />

    <main
      :class="styles.wrapper({
        layout,
      }).className"
      style="height:100dvh!important;"
    >
      <div
        v-if="isSidebar && !isHomeRoute"
        :class="styles.sidebar().className"
      >
        <router-view name="sidebar" />
      </div>

      <div
        id="main-content"
        :class="styles.content({ type }).className"
       
      >
        <AppMap v-if="!isLoginRoute && !isOpenSearchRoute && !isHomeRoute && !isMobileRoute" />

        <router-view/>
    
        <Property v-if="!!property && !isOpenSearchRoute && !isHomeRoute" />
      </div>
    </main>

    <v-dialog
      v-model="debugActive"
      dark
      fullscreen
    >
      <v-card>
        <v-toolbar dark>
          <span>Info</span>

          <v-spacer />

          <v-btn
            icon
            dark
            @click="debugActive = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      
        <v-card-text>
          <pre> {{ debugValue }} </pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import {
  mapState,
  mapActions,
} from 'vuex';

import AppMap from '@/views/Map.vue';
import Property from '@/views/property/Property.vue';

/**
 * Constants
 */
import { NAVBAR_MENU_ITEMS } from '@/common/constants';

/**
 * Activity
 */
import Activity from '@/core/activity';

/**
 * Components
 */
import Navbar from '@/components/Navbar';

/**
 * Styles
 */
import { styles } from './Main.styles';

/**
 * Component
 */
export default {

  name: 'Home',

  components: {
    AppMap,
    Property,
    Navbar,
  },

  data () {

    return {
      debugValue: '',
      debugActive: false,
      hideMenu: ['privacy'],
      hasSidebar: [
        'seller',
        'buyer',
        'lead',
        'rent',
      ],
    };
  },

  computed: {

    ...mapState('user', [
      'token',
      'idToken',
    ]),

    ...mapState('property', ['property']),

    ...mapState({

      summary (state, getters) {
        return getters[`${this.$route.name}/summary`]; 
      },

      result (state, getters) {
        return getters[`${this.$route.name}/result`]; 
      },
    }),

    ...mapState('core', [
      'collapsedSidebar',
      'expandedSidebar',
    ]),

    isSidebar () {
      return this.hasSidebar.indexOf(this.$route.name) >= 0;
    },

    isMenu () {
      return this.hideMenu.indexOf(this.$route.name) < 0;
    },

    isLoginRoute () {
      return this.$route.path.match(/^\/login\/?/ig);
    },

    isMobileRoute () {
      return this.$route.path.match(/^\/mobile\/?/ig);
    },

    isHomeRoute () {
      return this.$route.path.match(/^\/home\/?/ig);
    },

    isOpenSearchRoute () {
      return this.$route.path.match(/^\/search\/open\/?/ig);
    },

    isResults () {
      return !!(this.result && Object.values(this.result).length) && !this.collapsedSidebar;
    },

    layout () {
      if (this.isHomeRoute) {
        return 'home';
      } else if (!this.isSidebar) {
        return 'no-sidebar';
      } else if (this.collapsedSidebar) {
        return 'full';
      } else if (this.isResults) {
        return 'results';
      }

      return 'sidebar';
    },

    type () {
      return !this.isLoginRoute && !this.isOpenSearchRoute ? 'with-map' : 'without-map';
    },

    navbarVariant () {
      return this.isHomeRoute ? 'home' : 'default';
    },

    isBNSIRBroker () {
      const token = this.idToken;
      const userToken = token && token.payload;

      return userToken && userToken.real_estate_id === process.env.VUE_APP_BOSSA_REAL_ESTATE_ID;
    },
  },

  created () {

    this.styles = styles;
  
    this.$eventBus.$on('window:debug', message => {
      this.debugValue = message;
      this.debugActive = true;
    });
  },

  methods: {

    ...mapActions({

      logout (dispatch) {

        return dispatch('user/logout', {
          router: this.$router,
        }); 
      },
    }),

    onNavigate (item) {

      const {
        gtm,
        label,
      } = item;

      Activity.send({
        event: 'view_menu',
        item_id: gtm,
      });

      if (label === NAVBAR_MENU_ITEMS.LOGOUT.label) {

        this.logout();
      }
    },
  },
};

</script>
