<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="styles.root().className">
      <div class="flex flex-col items-center justify-center gap-2">
        <Typography
          color="brand-dark"
          variant="header-large"
          tag="span"
        >
          Nós entregamos todos os anúncios em um só lugar
        </Typography>

        <Typography
          color="brand-dark"
          variant="body-large"
          tag="span"
        >
          Conheça todas as funcionalidades da vertical Busca de Anúncios
        </Typography>
      </div>
      <div :class="styles.content().className">
        <div>
          <video
            ref="firstColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/open-search-1.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Utilize os filtros na barra lateral para uma uma busca objetiva.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Cidade e bairro
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Tipo de negócio e de anunciante
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Múltiplas imobiliárias
            </Typography>
          
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Portal do anúncio
            </Typography>
          </ul>
        </div>

        <div>
          <video
            ref="secondColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/open-search-2.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Ordene os resultados da maneira que preferir.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Relevância
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valores
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Área
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Dormitórios, suítes e vagas
            </Typography>
          </ul>
        </div>

        <div>
          <video
            ref="thirdColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/open-search-3.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Confira as principais informações de cada anúncio.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Imobiliária e portal
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Endereço parcial
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Detalhes do imóvel
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Link para o anúncio original
            </Typography>
          </ul>
        </div>
      </div>

      <div :class="styles.footer().className">
        <slot name="footer" />
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '@/components/base/Modal';
import Typography from '@/components/base/Typography';

import playVideoSequence from '@/utils/playVideoSequence';

import { styles } from './HelpOpenSearchModal.styles';

export default {

  name: 'HelpOpenSearchModal',

  components: {
    Modal,
    Typography,
  },

  data () {

    return {
      videoSequence: null,
    };
  },

  created () {
    this.styles = styles;
  },

  mounted () {

    this.$nextTick(() => {

      this.videoSequence = playVideoSequence([
        this.$refs.firstColumnVideo,
        this.$refs.secondColumnVideo,
        this.$refs.thirdColumnVideo,
      ]);
    });
  },

  beforeUnmount () {
    this.videoSequence.destroy();
  },
};

</script>
