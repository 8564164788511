export default function setListSearchResult(
  { commit, state, dispatch },
  listResult = []
) {
  const composeResult =
    listResult &&
    listResult.reduce(
      (acc, prev) => ({
        ...acc,
        [prev.addressId]: {
          ...(state.result && state.result[prev.addressId]),
          ...prev,
        },
      }),
      state.result || {}
    );
  commit('SET_LIST_SEARCH_RESULT', composeResult);
  dispatch('map/setMapPins', composeResult, { root: true });
}
