<template>
  <LoginLayout>
    <div class="login-form">
      <div class="title">
        Cidade Virtual
      </div>
      <div class="enter-label">
        Senha temporária
      </div>

      <div class="description-label">
        Você precisa informar uma nova senha ou seu acesso será bloqueado em 24hrs.
      </div>

      <ResetPassword />
    </div>
  </LoginLayout>
</template>

<script>

import LoginLayout from '@/layouts/Login.vue';
import ResetPassword from '@/components/login/FirstAccess.vue';

export default {
  name: 'LoginFirstAccess',
  components: {
    ResetPassword,
    LoginLayout,
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 412px;
  background: #FFFFFF;
  border: 1px solid #8496cbbb !important;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 24px;

  .title {
    @apply text-center;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 25.5px !important;
    line-height: 100%;
    color: #384059;}

  .enter-label {
    margin-top: 40px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22.5px !important;
    line-height: 19px;
    color: #38415A;
  }

  .description-label {
    margin-top: 20px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #707070;
  }

}

</style>
