<template>
  <div>
    <div
      id="tab-control"
      class="flex justify-between w-full -mt-8"
    >
      <div>
        <v-btn
          rounded
          small
          outlined
          :class="['ml-0', activeTab === PROPERTY_TABS.MAP && 'active']"
          @click="activeTab = PROPERTY_TABS.MAP"
        >
          Mapa
        </v-btn>
        <v-btn
          rounded
          small
          outlined
          :class="['ml-1', activeTab === PROPERTY_TABS.LOCATION && 'active']"
          @click="activeTab = PROPERTY_TABS.LOCATION"
        >
          VISTA DA RUA
        </v-btn>
        <v-btn
          v-if="property.originLink != null"
          rounded
          small
          outlined
          class="ml-1"
          @click="link123i()"
        >
          <svg
            id="XMLID_2_"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="41.342"
            height="19.825"
            viewBox="0 0 41.342 19.825"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.461"
                y1="1.153"
                x2="0.461"
                y2="-1.055"
                gradientUnits="objectBoundingBox"
              >
                <stop
                  offset="0"
                  stop-color="#fff"
                  stop-opacity="0.667"
                />
                <stop
                  offset="1"
                  stop-color="#e8e9e8"
                  stop-opacity="0"
                />
              </linearGradient>
              <linearGradient
                id="linear-gradient-2"
                x1="0.462"
                y1="1.099"
                x2="0.462"
                y2="-1.011"
                gradientUnits="objectBoundingBox"
              >
                <stop
                  offset="0"
                  stop-color="#fff"
                  stop-opacity="0.667"
                />
                <stop
                  offset="1"
                  stop-color="#e5e5e5"
                  stop-opacity="0"
                />
              </linearGradient>
              <linearGradient
                id="linear-gradient-3"
                x1="0.468"
                y1="1.139"
                x2="0.468"
                y2="-1.026"
                gradientUnits="objectBoundingBox"
              >
                <stop
                  offset="0"
                  stop-color="#fff"
                  stop-opacity="0.667"
                />
                <stop
                  offset="1"
                  stop-color="#e3e4e3"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
            <path
              id="polygon3"
              d="M0,9.394H1.347v5.043H6.735v1.692H19.169v1.347H34.953v2.349h6.39V0h-6.39V2.694H19.169V4.352H6.735V6.044H0Z"
              fill="#343433"
            />
            <path
              id="polygon5"
              d="M2.9,21.747H4.247V26.79H6.6V20.4H2.9Z"
              transform="translate(-1.898 -13.354)"
              fill="#fff"
            />
            <path
              id="polygon7"
              d="M22.4,17.6h7.391V19.3H22.4V25.34h9.394V23.3H26.1V21.644h5.7V15.6H22.4Z"
              transform="translate(-14.663 -10.212)"
              fill="#fff"
            />
            <path
              id="polygon9"
              d="M71.179,23.479H58.4V20.785h7.737V18.437H58.4V15.743h7.737V13.394H58.4V10.7H71.179Z"
              transform="translate(-38.23 -7.004)"
              fill="#fff"
            />
            <rect
              id="rect11"
              width="4.352"
              height="13.09"
              transform="translate(35.954 5.699)"
              fill="#f48224"
            />
            <rect
              id="rect13"
              width="4.352"
              height="3.35"
              transform="translate(35.954 1.002)"
              fill="#f48224"
            />
            <rect
              id="rect26"
              width="1.692"
              height="3.35"
              transform="translate(5.388 10.085)"
              fill="url(#linear-gradient)"
            />
            <rect
              id="rect39"
              width="1.692"
              height="5.043"
              transform="translate(17.822 10.085)"
              fill="url(#linear-gradient-2)"
            />
            <rect
              id="rect52"
              width="1.692"
              height="6.39"
              transform="translate(33.606 10.085)"
              fill="url(#linear-gradient-3)"
            />
          </svg>
        </v-btn>
        <OpenGoogleMaps
          v-if="property.condoName !== PROPERTY_TYPE_NAME.HOUSE"
          :property="property"
        />
      </div>
      <div v-if="property.condoName === PROPERTY_TYPE_NAME.HOUSE">
        <v-btn
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.PHOTOS && 'active']"
          @click="activeTab = PROPERTY_TABS.PHOTOS"
        >
          Fotos
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.MEDIA && 'active']"
          @click="activeTab = PROPERTY_TABS.MEDIA"
        >
          Fachada
        </v-btn>
        <v-btn
          v-if="commonPlaces && commonPlaces.length"
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.COMMON_PLACES && 'active']"
          @click="activeTab = PROPERTY_TABS.COMMON_PLACES"
        >
          Área comum
        </v-btn>
        <v-btn
          v-if="areaINterna && areaINterna.length"
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.COMMON_INTERNA && 'active']"
          @click="activeTab = PROPERTY_TABS.COMMON_INTERNA"
        >
          Área Interna
        </v-btn>
        <v-btn
          v-if="livem && livem.length"
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.COMMON_LIVING && 'active']"
          @click="activeTab = PROPERTY_TABS.COMMON_LIVING"
        >
          Living
        </v-btn>
        <v-btn
          v-if="blueprints && blueprints.length"
          rounded
          small
          outlined
          :class="['image-group', activeTab === PROPERTY_TABS.BLUEPRINT && 'active']"
          @click="activeTab = PROPERTY_TABS.BLUEPRINT"
        >
          Plantas
        </v-btn>
      </div>
    </div>
    <div
      id="tab"
      class="w-full mt-3"
    >
      <div
        v-if="media && activeTab === PROPERTY_TABS.MEDIA"
        id="media"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-1"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in media"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!media">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
      <div
        v-if="activeTab === PROPERTY_TABS.MAP"
        id="map"
        class="w-full h-full"
      >
        <l-map
          ref="propertyAreaMap"
          :center="mapCenter"
          :zoom="mapOptions.zoom"
          :max-zoom="mapOptions.maxZoom"
          :min-zoom="mapOptions.minZoom"
          :options="{ scrollWheelZoom: 'center' }"
          class="w-full h-full"
        >
          <l-tile-layer :url="mapOptions.url" />
          <l-marker
            :auto-pan="true"
            :lat-lng="property.geolocation"
          >
            <l-icon icon-url="@/assets/images/marker-icon-1.png" />
          </l-marker>
        </l-map>
      </div>
      <div
        v-if="activeTab === PROPERTY_TABS.LOCATION"
        id="location"
        class="w-full h-full"
      >
        <street-view-panorama
          class="h-full w-full"
          :options="{ scrollwheel: false }"
          :position="{
            lat: property.geolocation[0],
            lng: property.geolocation[1],
          }"
          :pov="mapOptions.streetView.pov"
        />
      </div>
      <div
        v-if="blueprints && activeTab === PROPERTY_TABS.BLUEPRINT"
        id="blueprint"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-2"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in blueprints"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!blueprints">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
      <div
        v-if="commonPlaces && activeTab === PROPERTY_TABS.COMMON_PLACES"
        id="commonPlace"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-3"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in commonPlaces"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!commonPlaces">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
      <div
        v-if="areaINterna && activeTab === PROPERTY_TABS.COMMON_INTERNA"
        id="areaINterna"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-4"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in areaINterna"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!areaINterna">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
      <div
        v-if="livem && activeTab === PROPERTY_TABS.COMMON_LIVING"
        id="livem"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-5"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in livem"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!livem">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
      <div
        v-if="photos && activeTab === PROPERTY_TABS.PHOTOS"
        id="photos"
        class="w-full h-full"
      >
        <carousel
          ref="carousel-6"
          :per-page="1"
          :autoplay="false"
          :loop="true"
          :pagination-enabled="true"
          :navigation-enabled="true"
          :navigation-next-label="''"
          :navigation-prev-label="''"
        >
          <slide
            v-for="images in photos"
            :key="images.url"
          >
            <img
              :src="images.url"
              class="media"
              @error="$event.target.src = default404"
            >
          </slide>
          <slide v-if="!photos">
            <img
              class="media"
              :src="default404"
            >
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { LMarker, LMap, LTileLayer, LIcon } from 'vue2-leaflet';
import { StreetViewPanorama } from 'vue2-google-maps';
import { PROPERTY_TABS, PROPERTY_TYPE_NAME } from '@/common/constants';
import OpenGoogleMaps from '@/components/buttons/OpenGoogleMaps.vue';
import default404 from '@/assets/images/404.jpeg';

export default {
  components: {
    Carousel,
    Slide,
    LMarker,
    LMap,
    LIcon,
    LTileLayer,
    StreetViewPanorama,
    OpenGoogleMaps,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PROPERTY_TABS,
      PROPERTY_TYPE_NAME,
      activeTab: PROPERTY_TABS.MEDIA,
      mapOptions: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 18,
        maxZoom: 18,
        minZoom: 14,
        streetView: {
          pov: null,
        },
      },
    };
  },
  computed: {
    mapCenter () {
      return this.property && this.property.geolocation;
    },
    hasImages () {
      return !!(this.property && this.property.images && this.property.images.length);
    },
    blueprints () {
      return this.hasImages && this.property.images.filter(images => images.label.includes('Planta'));
    },
    media () {
      return this.hasImages && this.property.images.filter(images => images.label.includes('Fachada'));
    },
    commonPlaces () {
      return this.hasImages && this.property.images.filter(images => images.label.includes('Área Comum'));
    },
    areaINterna () {
      return this.hasImages && this.property.images.filter(images => images.label.includes('Área Interna'));
    },
    livem () {
      return this.hasImages && this.property.images.filter(images => images.label.includes('Living'));
    },
    photos () {
      return this.hasImages && this.property.images;
    },
    default404 () {
      return default404;
    },
  },
  created () {
    if (this.property.condoName === PROPERTY_TYPE_NAME.HOUSE) {
      this.activeTab = PROPERTY_TABS.PHOTOS;
    } else {
      this.activeTab = PROPERTY_TABS.MEDIA;
    }
  },
  mounted () {
    let count = 5;

    while (count--) {
      const current = this.$refs[`carousel-${count}`];

      if (current) {
        this.$refs[`carousel-${count}`].goToPage(0);
        this.$forceUpdate();
      }
    }
  },
  methods: {
    link123i () {
      if (this.property.originLink != null) {
        window.open(this.property.originLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  outline: 0 !important;
}
#tab-control {
  height: 35px;
  max-height: 60px;
  .image-group {
    @apply ml-1;
    background: #3366CC;
    color: white;
  }
  button {
    text-transform: uppercase;
    &.active {
      border: 1px solid white!important;
      background-color: $primary-blue!important;
      color: white!important;
    }
  }
}
#tab {
  height: 460px;
}
.media {
  height: 440px;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}
#blueprint {
  .media {
    height: 440px;
    width: 100%;
    max-width: 100%;
    object-fit: scale-down;
  }
}
::v-deep .VueCarousel-navigation-prev{
  transform: translateY(-50%) translateX(0%);
  &::before{
    content: "\F141";
    font-size: 36px;
    font-family: "Material Design Icons", sans-serif;
  }
}
::v-deep .VueCarousel-navigation-next{
  transform: translateY(-50%) translateX(0%);
  &::before{
    content: "\F142";
    font-size: 36px;
    font-family: "Material Design Icons", sans-serif;
  }
}
</style>
