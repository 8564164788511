import { MAP_OPTIONS } from '../../common/constants';

const initialState = {
  center: null,
  zoomLevel: MAP_OPTIONS.DEFAULT_ZOOM_LEVEL,
  bounds: [],
  highlightedItem: null,
  pins: [],
  geocoderResults: null,
  currentCity: null
};

const getters = {
  center: state => state.center,
  zoomLevel: state => state.zoomLevel,
  bounds: state => state.bounds,
  highlightedItem: state => state.highlightedItem,
  pins: ({ pins }, _getters, _rootState, rootGetters) => {
    const flaggedPins = rootGetters['flag/flagged'] || [];
    const showWithoutOffers = rootGetters['search/showWithoutOffers'];
    const offersToExclude = rootGetters['search/offersToExclude'];

    let filteredPins = pins.filter(pin => !flaggedPins.includes(pin.addressId));

    if (!showWithoutOffers) {
      filteredPins = filteredPins.filter(pin => pin.offersCount);
    }

    if(offersToExclude?.length) {
      filteredPins = filteredPins.filter(pin => !offersToExclude.includes(pin.addressId))
    }

    return filteredPins;
  },
  geocoderResults: state => state.geocoderResults,
  currentCity: state => state.currentCity,
};

const mutations = {
  SET_GEOCODER_RESULTS(state, results) {
    state.geocoderResults = results;
  },
  SET_MAP_CENTER: (state, center) => {
    state.center = center;
  },
  SET_MAP_ZOOM_LEVEL: (state, zoomLevel) => {
    state.zoomLevel = zoomLevel;
  },
  SET_MAP_BOUNDARIES: (state, bounds) => {
    state.bounds = bounds;
  },
  SET_HIGHLIGHTED_ITEM: (state, item) => {
    state.highlightedItem = item;
  },
  SET_MAP_PINS: (state, pins) => {
    state.pins = pins;
  },
  CLEAR_MAP_PINS: state => {
    state.pins = [];
  },
  SET_CURRENT_CITY(state, city) {
    state.currentCity = city;
  },
};

const actions = {
  resetMapZoomLevel({ commit }) {
    commit('SET_MAP_ZOOM_LEVEL', MAP_OPTIONS.DEFAULT_ZOOM_LEVEL);
  },
  setMapPins({ commit }, payload) {
    const parsedResults = Object.values(payload);
    commit('SET_MAP_PINS', parsedResults);
  },
  clearPins({ commit }) {
    commit('CLEAR_MAP_PINS');
  },
  setGeocoderResults({ commit }, results) {
    commit('SET_GEOCODER_RESULTS', results);
  },
  setCurrentCity({ commit }, city) {
    commit('SET_CURRENT_CITY', city);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
