<script setup>

import { computed } from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';

/**
 * Styles
 */
import { styles } from './SearchSummaryBuyer.styles';

/**
 * Props
 */
const props = defineProps({

  type: {
    type: String,
    default: 'result',
    validator (value) {
      return [
        'result',
        'filter',
      ].includes(value);
    },
  },

  data: {
    type: Object,
    default: () => ({ }),
  },
});

/**
 * Computed
 */
const isResult = computed(() => props.type === 'result');

const isFilter = computed(() => props.type === 'filter');

</script>

<template>
  <div :class="styles.root({ type }).className">
    <template v-if="data && isResult">
      <div data-column>
        <Typography
          tag="span"
          variant="title-large-secondary"
          color="brand-dark"
        >
          {{ data?.condominiumCount?.value }}
        </Typography>

        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data?.condominiumCount?.label }}
        </Typography>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="title-large-secondary"
          color="brand-dark"
        >
          {{ data?.offers?.value }}
        </Typography>

        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          <span :class="styles.tooltip().className">
            {{ data?.offers?.label }}

            <div data-tooltip-text>
              <Typography
                tag="span"
                variant="body-small"
                color="brand-dark"
              >
                Imóveis com anúncios ativos
              </Typography>
            </div>
          </span>
          
          encontradas
        </Typography>
      </div>

      <div data-column-double>
        <div>
          <Typography
            tag="span"
            variant="body-table"
            color="brand-dark"
          >
            {{ data?.hasOffers?.value }}
          </Typography>

          <Typography
            tag="span"
            variant="title-small-secondary"
            color="brand-dark"
          >
            {{ data?.hasOffers?.label }}
          </Typography>
        </div>

        <div>
          <Typography
            tag="span"
            variant="body-table"
            color="brand-dark"
          >
            {{ data?.hasNoOffers?.value }}
          </Typography>

          <Typography
            tag="span"
            variant="title-small-secondary"
            color="brand-dark"
          >
            {{ data?.hasNoOffers?.label }}
          </Typography>
        </div>
      </div>

      <div data-column>
        <Typography
          tag="span"
          variant="body-table"
          color="brand-dark"
        >
          {{ data?.realEstate?.value }}
        </Typography>

        <Typography
          tag="span"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data?.realEstate?.label }}
        </Typography>
      </div>
    </template>

    <template v-if="data && isFilter">
      <div
        v-for="([filter], index) in Object.entries(data)"
        :key="`filterIndex${index}`"
        data-column
      >
        <Typography
          tag="div"
          variant="title-small-secondary"
          color="brand-dark"
        >
          {{ data[filter]?.label }}
        </Typography>

        <Typography
          tag="span"
          variant="body-medium-secondary"
          color="brand-dark"
        >
          {{ data[filter]?.value }}
        </Typography>
      </div>
    </template>
  </div>
</template>
