<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3432 8.31804L14.6075 4.07831C15.1308 3.55803 15.1308 2.71449 14.6075 2.19379L13.6598 1.25153C13.1365 0.731259 12.2881 0.731259 11.7643 1.25153L7.5 5.49127L3.23565 1.25153C2.71236 0.731259 1.86392 0.731259 1.3402 1.25153L0.392472 2.19379C-0.130824 2.71407 -0.130824 3.55761 0.392472 4.07831L4.65682 8.31804L0.392472 12.5578C-0.130824 13.078 -0.130824 13.9216 0.392472 14.4423L1.3402 15.3845C1.86349 15.9048 2.71236 15.9048 3.23565 15.3845L7.5 11.1448L11.7643 15.3845C12.2876 15.9048 13.1365 15.9048 13.6598 15.3845L14.6075 14.4423C15.1308 13.922 15.1308 13.0785 14.6075 12.5578L10.3432 8.31804Z"
      fill="#A2A5AB"
    />
  </svg>
</template>

<script>

export default {
  name: 'CloseIcon',
};

</script>
