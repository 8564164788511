<template>
  <section
    id="consulta-dados"
    class="consulta-dados"
  >
    <LGPDModal />
    <div
      v-if="detail"
      class="container"
    >
      <div class="flex mb-6">
        <h2 class="page-title my-auto">
          Consulta de Dados
        </h2>
        <v-btn
          class="font-weight-medium ml-auto my-auto"
          rounded
          outlined
          color="#044DA1"
          @click="goToConsultaDados"
          @mouseup="newSearchButtonMouseup"
        >
          NOVA CONSULTA
        </v-btn>
      </div>
      <div class="flex">
        <aside>
          <div class="sidebar">
            <h3 class="title--primary text-2xl">
              Dados
            </h3>
            <ol class="sidebar-links">
              <li
                class="link"
                :class="{ active: !activeTab }"
                @click="setActiveTab(null)"
                @mouseup="sendViewListEvent('principais_informacoes')"
              >
                <div class="flex">
                  <div class="flex flex-column">
                    <Typography
                      variant="title-medium"
                      :color="!activeTab ? 'neutral-white' : 'brand-dark'"
                    >
                      Principais Informações
                    </Typography>
                    <Typography
                      variant="body-small"
                      :color="!activeTab ? 'neutral-white' : 'neutral-grey-5'"
                    >
                      Atualização em {{ formatYear(detail.Ranking) }}
                    </Typography>
                  </div>
                </div>
                <div class="flex align-center">
                  <img
                    v-if="!activeTab"
                    class="ml-auto"
                    src="@/assets/images/Chevron-right-white.svg"
                  >
                  <img
                    v-else
                    class="ml-auto"
                    src="@/assets/images/Chevron-right.svg"
                  >
                </div>
              </li>
              <hr>
              <li
                v-for="(item, index) in sidebar"
                :key="index"
              >
              <div class="accordion-item">
                <sidebar-accordion
                :item="item"
                :disabled="item.disabled"
                />
              </div>
              <hr>
              </li>
              <div
                class="sidebar-item cursor-pointer"
                :class="{ 'active-sidebar-item': activeTab === 'ClientAnalysis' }"
                @mouseup="sendViewListEvent('analise_cliente')"
                @click="setActiveTab('ClientAnalysis')"
              >
                <Typography
                  variant="title-large"
                  :color="activeTab === 'ClientAnalysis' ? 'neutral-white' : 'highlight-dark'"
                >
                  Análise do cliente
                </Typography>
                <div class="flex align-center">
                  <img
                    v-if="activeTab !== 'ClientAnalysis'"
                    class="ml-auto"
                    src="@/assets/images/Chevron-right.svg"
                  >
                  <img
                    v-else
                    class="ml-auto"
                    src="@/assets/images/Chevron-right-white.svg"
                  >
                </div>
              </div>
              <hr>
              <div
                class="sidebar-item cursor-pointer"
                :class="{ 'active-sidebar-item': activeTab === 'UsefulLinks' }"
                @mouseup="sendViewListEvent('links_uteis')"
                @click="setActiveTab('UsefulLinks')"
              >
                <Typography
                  variant="title-large"
                  :color="activeTab === 'UsefulLinks' ? 'neutral-white' : 'highlight-dark'"
                >
                  Links úteis
                </Typography>
                <div class="flex align-center">
                  <img
                    v-if="activeTab !== 'UsefulLinks'"
                    class="ml-auto"
                    src="@/assets/images/Chevron-right.svg"
                  >
                  <img
                    v-else
                    class="ml-auto"
                    src="@/assets/images/Chevron-right-white.svg"
                  >
                </div>
              </div>
            </ol>
          </div>
          
        </aside>
        <div class="detail">
          <!-- INIT -->
          <div v-if="!activeTab">
            <!-- DADOS BASICOS -->
            <basic-info :data="detail" />

            <!-- RENDA + CLASSE SOCIAL -->
            <div class="mt-4 flex w-full gap-4">
              <income :data="detail" />
              <score :data="detail" />
            </div>
            <!-- ./RENDA + CLASSE SOCIAL -->

            <div class="mt-4 flex justify-center">
              <consumption-profile :data="detail.StatisticModels"/>
            </div>

            <!-- CONTATO E ENDEREÇOS -->
            <div class="contact-address-blocks mt-4 flex flex-row">
              <basic-phone-email-block />
              <Properties :properties="detail.Properties" :addresses="detail.Addresses" />
            </div>
            <!-- ./CONTATO E ENDEREÇOS -->
          </div>
          <div v-else>
            <!-- SOCIEDADES -->
            <section v-if="activeTab === 'PartnerShips'">
              <partner-ships :raw-data="detail" />
            </section>
            <!-- ./SOCIEDADES-->

            <!-- ENDERECOS -->
            <section
              v-if="activeTab === 'Addresses'"
              id="addressTable"
            >
              <addresses-tab />
            </section>
            <!-- ./ENDERECOS -->

            <!-- ENDERECOS COMERCIAIS -->
            <section v-if="activeTab === 'BusinessAddresses'">
              <business-address-table />
            </section>
            <!-- ./ENDERECOS COMERCIAIS-->

            <!-- TELEFONES -->
            <section v-if="activeTab === 'Phones'">
              <PhonesTable :phones="detail.Phones" />
            </section>
            <!-- ./TELEFONES-->

            <!-- EMAILS -->
            <section v-if="activeTab === 'Emails'">
              <EmailsTable :emails="detail.Emails" />
            </section>
            <!-- ./EMAILS-->

            <!-- PESSOAS DE CONTATO-->
            <section v-if="activeTab === 'Relationships'">
              <ContactPersons :relationships="detail.Relationships" />
            </section>
            <!-- ./PESSOAS DE CONTATO-->

            <!-- ANALISE DO CLIENTE -->
            <section v-if="activeTab === 'ClientAnalysis'">
              <ClientAnalysis />
            </section>
            <!-- ./ANALISE DO CLIENTE-->

            <!-- LINKS ÚTEIS -->
            <section v-if="activeTab === 'UsefulLinks'">
              <UsefulLinks />
            </section>
            <!-- ./LINKS ÚTEIS-->
          </div>
          <!-- END -->
        </div>
      </div>
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatYear } from '@/utils';
import BasicInfo from '@/components/search/BasicInfo.vue';
import Counter from '@/components/search/Counter.vue';
import Income from '@/components/search/Income.vue';
import Score from '@/components/search/Score.vue';
import SidebarAccordion from '@/components/search/SidebarAccordion.vue';
import PartnerShips from '@/components/search/PartnerShips.vue';
import PhonesTable from '@/components/tables/Phones.vue';
import BasicPhoneEmailBlock from '@/components/search/BasicPhoneEmailBlock.vue';
import EmailsTable from '@/components/tables/Emails.vue';
import Properties from '@/components/tables/Properties.vue';
import AddressesTab from '@/components/search/AddressesTab.vue';
import LGPDModal from '@/components/LGPD/LGPDModal.vue';
import ClientAnalysis from '@/components/search/ClientAnalysis.vue';
import UsefulLinks from '@/components/search/UsefulLinks.vue';
import BusinessAddressTable from '@/components/tables/BusinessAddressTable.vue';
import ContactPersons from '@/components/tables/ContactPersons.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import ConsumptionProfile from '../components/search/ConsumptionProfile.vue';
import Typography from '../components/base/Typography';

export default {
  name: 'ConsultaDadosDetail',
  components: {
    GoogleTagManager,
    LGPDModal,
    Properties,
    EmailsTable,
    PhonesTable,
    SidebarAccordion,
    BasicInfo,
    Counter,
    Income,
    Score,
    BusinessAddressTable,
    PartnerShips,
    BasicPhoneEmailBlock,
    AddressesTab,
    UsefulLinks,
    ClientAnalysis,
    ContactPersons,
    ConsumptionProfile,
    Typography
},
  data: () => ({
    gtmEvent: null,
    sidebarContent: [
      {
        title: 'Contato',
        content: [
          {
            title: 'Endereços',
            tabName: 'Addresses',
            clickEvent: 'contato_enderecos',
          },
          {
            title: 'Telefones',
            tabName: 'Phones',
            clickEvent: 'contato_telefones',
          },
          {
            title: 'Emails',
            tabName: 'Emails',
            clickEvent: 'contato_emails',
          },
          {
            title: 'Pessoas de Contato',
            tabName: 'Relationships',
            clickEvent: 'contato_pessoas_contato',
          },
        ],
      },
      {
        title: 'Profissional',
        content: [
          {
            title: 'Endereços comerciais',
            tabName: 'BusinessAddresses',
            clickEvent: 'profissional_enderecos_comerciais',
          },
          {
            title: 'Sociedades',
            tabName: 'PartnerShips',
            clickEvent: 'profissional_sociedades',
          },
        ],
      },
    ],
    itemsPerPage: 5,
    headersAddress: [
      { text: 'Empresas', value: 'CompanyName' },
      { text: 'Endereços', value: 'ShortAlias' },
      { text: 'Estado', value: 'State' },
      { text: 'CNPJ', value: 'CompanyDocument' },
    ],
  }),
  computed: {
    ...mapState('person', [
      'detail',
      'activeTab',
    ]),
    sidebar () {
      const { detail, sidebarContent, lastUpdateCallback } = this;

      if (!detail) return [];

      return sidebarContent.map(item => {
        const disabled = !item.content.some(
          item => detail.hasOwnProperty(item.tabName) && detail[item.tabName].length,
        );

        return {
          ...item,
          disabled,
          content: item.content.map(data => ({ ...data,
            lastUpdate: `Atualização em ${detail[data.tabName]
              ? this.formatYear(
                detail[data.tabName].map(obj => obj.Ranking || obj.updated_at).sort(lastUpdateCallback)[0],
              ) : '-'}` })),
        };
      });
    },
  },
  watch: {
    async activeTab () {
      const page = document.getElementById('consulta-dados');

      await this.$nextTick();
      page.scroll(0, 0);
    },
  },
  mounted () {
    this.setActiveTab();

    if (!this.detail) {
      this.goToConsultaDados();
    }
  },
  methods: {
    ...mapActions('person', ['setActiveTab']),
    formatYear,
    lastUpdateCallback (a, b) {
      return b - a;
    },
    sendViewListEvent (type) {
      this.gtmEvent = {
        event: 'view_list',
        type,
      };
    },
    newSearchButtonMouseup () {
      this.gtmEvent = {
        event: 'new_search_person',
        btn: true,
      };
    },
    goToConsultaDados () {
      this.$router.push('/person-data');
    },
  },
};
</script>

<style lang="scss" scoped>

.consulta-dados {
  scroll-behavior: smooth;
  .container {
    @apply p-0;
    max-width: 1330px;

    @screen xl {
      max-width: 1300px;
    }
  }

  padding: 2rem;
  overflow: auto;
  position: absolute;
  top: 75px;
  width: 100%;
  height: calc(100% - 75px);
  background-color: #f5f7fb;
  z-index: 3;

  .page-title {
    @apply font-extrabold text-3xl;
  }

  .detail {
    @apply ml-auto;
    width: 915px;
  }
}

.accordion-item {
  width: 325px;
}

.sidebar-item {
  display: flex;
  width: 325px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-radius: 10px;
    background: var(--colors-neutral-grey-1);
  }
}

.sidebar {
  display: flex;
  padding: 26px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 20px;
  background: var(--colors-neutral-white);

  &-links {
    width: 100%;
    padding-left: 0;

    > .link {
      display: flex;
      width: 325px;
      height: 60px;
      padding: 10px 20px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 10px;
      cursor: pointer;

      &.active, &.active:hover {
        border-radius: 10px;
        background: var(--colors-brand-default);
      }

      &:hover {
        background: var(--colors-neutral-grey-1);
        border-radius: 10px;
      }
    }
  }

  width: 350px;
}

hr {
  display: block;
  width: 100%;
  height: 0px;
  background-color: #D9D9D9;
  margin: 8px 0;
}

.active-sidebar-item, .active-sidebar-item:hover {
  border-radius: 10px;
  background: var(--colors-brand-default);
}

.table {
  width: 100%;
}

.on {
  color: #70ca63;
}

.off {
  color: #cccccc;
}

.red {
  color: brown;
}

.center {
  text-align: center;
}

.v-icon.text-current {
  @apply text-current;
}

.contact-address-blocks {
  div {
    margin-right: 18px;
    max-width: 50%;
    min-width: 50%;
    height: 100%;

    &:last-of-type {
      margin-right: 0 !important;
      min-width: calc(50% - 18px) !important;
      max-width: calc(50% - 18px) !important;
    }
  }
}

:deep(.v-data-table) {
  @apply w-full;
}

:deep(.simple-table .table) {
  width: 100%;
}

:deep(.v-tabs-items) {
  background: #eceff8;
}

:deep(th) {
  height: 20px;
  font-size: 12px;
  color: #6879a6;
  border-bottom: 0;
  padding-left: 0;
  vertical-align: middle;
}

:deep(td) {
  border-color: #e8e8e8;
  color: #707070;
  font-size: 14px;
  padding-left: 0;
  vertical-align: middle;
}

:deep(tbody tr) {
  height: 20px;
  vertical-align: middle;
}

</style>
