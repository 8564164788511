import base from './base';

const doAuth = async (providerUrl, payload) => {
  const response = await base.post(providerUrl, payload);
  return response && response.data;
};

export default {
  signInWithCognito({ idToken }) {
    return doAuth('auth/login', { idToken });
  },
  logout() {},
};
