import api from '@/api'

export default async function getReport({ state, commit }, hash) {
  let result = [];
  let searchSummary = null;
  try {
    commit('SET_LOADING', true);
    const { filter: sharedFilter } = state;
    const { response: results, report } = await api.address.getReport(hash, sharedFilter);
    result = results;
    searchSummary = report;
    commit('FETCH_ADDRESSESS_LIST_SUCCESS');
  } catch (error) {
    console.error(error);
    commit('FETCH_ADDRESSESS_LIST_ERROR', error);
  } finally {
    commit('SET_LOADING', false);
  }

  return {
    result,
    searchSummary,
  };
}
