<script setup>

import { computed } from 'vue';

const props = defineProps({
  hovered: {
    type: Boolean,
    default: false,
  },
});

const colors = {
  default: '#303A56',
  hovered: '#6B8CEF',
};

const color = computed(() => props.hovered ? colors.hovered : colors.default);

</script>

<template>
  <svg
    width="65"
    height="53"
    viewBox="0 0 65 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00013 48.0198H5.86588C5.70858 48.0198 5.58105 48.1473 5.58105 48.3046V51.5052C5.58105 51.6625 5.70857 51.79 5.86588 51.79H63.1598C63.3171 51.79 63.4446 51.6625 63.4446 51.5052V22.3408V8.07042V3.64445C63.4446 3.48714 63.3171 3.35962 63.1598 3.35962H54.9928C54.8355 3.35962 54.708 3.48714 54.708 3.64444V6.43964C54.708 6.59695 54.5805 6.72447 54.4232 6.72447H48.6512H41.5334C41.3761 6.72447 41.2486 6.852 41.2486 7.0093V20.5721C41.2486 20.7294 41.121 20.8569 40.9637 20.8569H36.1496C35.9923 20.8569 35.8648 20.7294 35.8648 20.5721V2.2985C35.8648 2.14119 35.7373 2.01367 35.58 2.01367H21.3442C21.1869 2.01367 21.0594 2.1412 21.0594 2.2985V20.1839V24.8947V29.6055V34.0307C21.0594 34.188 20.9319 34.3155 20.7746 34.3155H8.56979C8.41248 34.3155 8.28496 34.443 8.28496 34.6003V47.735C8.28496 47.8923 8.15744 48.0198 8.00013 48.0198Z"
      fill="#E3E8F5"
    />
    <path
      d="M19.3826 28.5918V33.017C19.3826 33.1743 19.2551 33.3018 19.0978 33.3018H6.89303C6.73572 33.3018 6.6082 33.4293 6.6082 33.5867V46.7213C6.6082 46.8786 6.48068 47.0061 6.32337 47.0061H4.18913C4.03182 47.0061 3.9043 47.1337 3.9043 47.291V51.1872C3.9043 51.3445 4.03182 51.4721 4.18912 51.4721H62.3032H62.0527C61.8954 51.4721 61.7679 51.3445 61.7679 51.1872V21.3271V7.05675V2.63077C61.7679 2.47347 61.6403 2.34594 61.483 2.34594H53.3161C53.1588 2.34594 53.0312 2.47347 53.0312 2.63077V5.42597C53.0312 5.58328 52.9037 5.7108 52.7464 5.7108H46.9745H39.8566C39.6993 5.7108 39.5718 5.83833 39.5718 5.99563V19.5584C39.5718 19.7157 39.4443 19.8432 39.287 19.8432H34.4729C34.3156 19.8432 34.188 19.7157 34.188 19.5584V1.28483C34.188 1.12752 34.0605 1 33.9032 1H19.6675C19.5102 1 19.3826 1.12752 19.3826 1.28483V19.1702V23.881V28.5918Z"
      stroke="#A5B2D9"
      stroke-width="0.708785"
    />
    <path
      d="M63.7111 51.6836L0.863281 51.6836"
      stroke="#A5B2D9"
      stroke-width="1.06318"
      stroke-linecap="round"
    />
    <rect
      x="17.5811"
      y="12.8799"
      width="35.579"
      height="38.8134"
      rx="1.20927"
      :fill="color"
    />
    <rect
      x="29.4404"
      y="41.9893"
      width="4.3126"
      height="8.62521"
      rx="0.291391"
      fill="white"
    />
    <rect
      x="21.8936"
      y="47.3799"
      width="4.3126"
      height="3.23445"
      rx="0.291391"
      fill="#A5B2D9"
    />
    <rect
      x="36.9878"
      y="36.5986"
      width="4.3126"
      height="14.016"
      rx="0.291391"
      fill="#A5B2D9"
    />
    <rect
      x="44.5347"
      y="27.9736"
      width="4.3126"
      height="22.6412"
      rx="0.291391"
      fill="white"
    />
    <circle
      cx="27.978"
      cy="20.958"
      r="2.91402"
      fill="white"
    />
    <path
      d="M22.4312 27.3294C22.4312 25.9944 23.5134 24.9121 24.8484 24.9121H30.8897C32.2247 24.9121 33.307 25.9944 33.307 27.3294V31.2087H22.4312V27.3294Z"
      fill="white"
    />
  </svg>
</template>