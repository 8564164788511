/* property */
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_ADS = 'SET_ADS';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_ERROR = 'GET_PROPERTY_ERROR';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_ERROR = 'GET_OFFER_ERROR';
export const GET_TYPOLOGY_SUCCESS = 'GET_TYPOLOGY_SUCCESS';
export const GET_TYPOLOGY_ERROR = 'GET_TYPOLOGY_ERROR';
export const GET_OWERS_SUCCESS = 'GET_OWERS_SUCCESS';
export const GET_OWERS_ERROR = 'GET_OWERS_ERROR';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR';

/* core */
export const SET_CONFIG = 'SET_CONFIG';
export const SET_USE_MAP_FILTER = 'SET_USE_MAP_FILTER';
export const TOGGLE_EXPANDED_SIDEBAR_LAYOUT = 'TOGGLE_EXPANDED_SIDEBAR_LAYOUT';
export const TOGGLE_COLLAPSED_SIDEBAR_LAYOUT = 'TOGGLE_COLLAPSED_SIDEBAR_LAYOUT';
export const TOGGLE_ADDITIONAL_FILTERS_VISIBILIY = 'TOGGLE_ADDITIONAL_FILTERS_VISIBILIY';
export const SET_VERTICAL = 'SET_VERTICAL';
