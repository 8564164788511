<template>
  <div class="tab-container">
    <v-btn
      outlined
      :class="{
        'btn--active': current === 'left',
        'mobile-button': mobile,
      }"
      @click="onClickHandler('left')"
      :disabled="disabled"
    >
      {{ labels[0] }}
    </v-btn>
    <v-btn
      outlined
      :class="{
        'btn--active': current === 'right',
        'mobile-button': mobile,
      }"
      @click="onClickHandler('right')"
      :disabled="disabled"
    >
      {{ labels[1] }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      required: true,
    },
    onTabClick: {
      type: [Array, Function],
      default: () => {}
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 'left',
    };
  },
  created() {
    this.current = 'left'
  },
  methods: {
    setCurrent (value) {
      this.current = value
    },
    onClickHandler (value) {
      this.setCurrent(value)

      if(this.onTabClick.length){
        if(value === 'left'){
          return this.onTabClick[0]()
        }
        return this.onTabClick[1]()
      }

      this.onTabClick()
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-container {
  display: flex;
  border-radius: 50px;
  background: var(--colors-highlight-lighter);
  padding: 6px;
  justify-content: space-between;
}

:deep(.v-btn) {
  display: flex;
  padding: 13px 41px!important;
  justify-content: center;
  align-items: center;
  height: 28px!important;
  gap: 10px;
  width: calc(50% - 4px);
  border-radius: 50px;
  background-color: var(--colors-highlight-lighter);
  border: none;
  text-transform: capitalize;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px!important;
  color: var(--colors-brand-dark)!important;
  text-align: center;
}

.v-btn.mobile-button {
  padding: 11px 0px!important;
}

:deep(.btn--active, .btn--active:hover, .btn--active:active) {
  background-color: var(--colors-brand-lighter);
}

:deep(.v-btn.v-btn--disabled){
  background-color: var(--colors-neutral-grey-2)!important;
  color: var(--colors-neutral-grey-4)!important;
}
</style>