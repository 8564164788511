<template>
  <v-expansion-panels
    class="accordion-wrapper"
    :class="{'disabled' : disabled}"
    :readonly="disabled"
    flat
  >
    <v-expansion-panel class="px-0">
      <v-expansion-panel-header class="px-0" :hide-actions="hideActions">
        <slot name="header"></slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'Accordion',
  props: {
    disabled: {
      default: false,
    },
    hideActions: {
      default: false
    },
  }
}

</script>

<style lang="scss" scoped>
  .accordion-wrapper {
    ::v-deep .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0 !important;
    }
    ::v-deep .v-expansion-panel-header__icon {
      background-color: #c7d0ea;
      border-radius: 50%;
    }
    ::v-deep .v-expansion-panel-header__icon .v-icon::before {
      color: #a7b3d5;
    }
  }

  .disabled {
    ::v-deep h3 {
      color: #777 !important;
    }

    ::v-deep .v-expansion-panel-header__icon {
      background-color: #c7c7c7;
      border-radius: 50%;
    }
    ::v-deep .v-expansion-panel-header__icon .v-icon::before {
      color: #777;
    }
  }
</style>