import base from './base';

const quota = async data => {
  const response = await base.get('/avm/quota', data);
  return response && response.data;
};

const evaluation = async data => {
  const response = await base.post('/avm/evaluation', data);
  return response && response.data;
};

const askingPrice = async data => {
  const response = await base.post('/offers/asking_price', data);
  return response && response.data;
};

export default {
  quota,
  evaluation,
  askingPrice,
};
