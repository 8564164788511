<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="styles.root().className">
      <div class="flex flex-col items-center justify-center gap-2">
        <Typography
          color="brand-dark"
          variant="header-large"
          tag="span"
        >
          Nós entregamos todas as oportunidades
        </Typography>

        <Typography
          color="brand-dark"
          variant="body-large"
          tag="span"
        >
          Conheça todas as funcionalidades da vertical Cliente Vendedor
        </Typography>
      </div>

      <div :class="styles.content().className">
        <div>
          <video
            ref="firstColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/default-map.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Ajude seu cliente a entender qual o volume de oferta e os valores praticados.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Mercado de terceiros
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Mercado de lançamentos
            </Typography>
          </ul>
        </div>

        <div>
          <video
            ref="secondColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/default-details.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Conte com a ferramenta de Avaliação Mercadológica do DataZap.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor de venda
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor de mercado
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor transacionado
            </Typography>
          </ul>
        </div>

        <div>
          <video
            ref="thirdColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/default-report.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Extraia relatórios com todos os dados de sua pesquisa e valores encontrados.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Resumo da pesquisa
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Base de ofertas - rua, raio 500m e bairro
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Resumo de valores
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Tabela com todos os condomínios
            </Typography>
          </ul>
        </div>
      </div>

      <div :class="styles.footer().className">
        <slot name="footer" />
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '@/components/base/Modal';
import Typography from '@/components/base/Typography';

import playVideoSequence from '@/utils/playVideoSequence';

import { styles } from './HelpSellerModal.styles';

export default {

  name: 'HelpSellerModal',

  components: {
    Modal,
    Typography,
  },

  data () {

    return {
      videoSequence: null,
    };
  },

  created () {
    this.styles = styles;
  },

  mounted () {

    this.$nextTick(() => {

      this.videoSequence = playVideoSequence([
        this.$refs.firstColumnVideo,
        this.$refs.secondColumnVideo,
        this.$refs.thirdColumnVideo,
      ]);
    });
  },

  beforeDestroy () {
    this.videoSequence.destroy();
  },
};

</script>
