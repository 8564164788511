import base from './base';

export default {
  async getAddress (addressId, domain) {
    const response = await base.post(`${domain}/search/detailed`, { addressId });

    return response && response.data;
  },
  async getOffer (offerId, adsIds, context) {
    const response = await base.post(`offers/${offerId}`, {
      context,
      adsIds,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async getTypologies (addressId, domain, attributes) {
    const response = await base.post(`${domain}/search/v2/typologies`, {
      addressId,
      attributes,
    });

    return response && response.data;
  },
  async getPriceHistoryByAddress ({ addressId, context }) {
    const response = await base.get(`offers/priceHistoryByAddress/${addressId}`, {
      params: {
        context,
      },
    });

    return response && response.data;
  },
  async getOwners (addressId, domain) {
    const response = await base.post(`${domain}/search/owners`, {
      addressId,
    });

    return response && response.data;
  },
  async getTransactions (condominium, domain) {
    const response = await base.get(`${domain}/condominiums/${condominium}/transactions`);

    return response && response.data;
  },
  async getOffers (addressId, context, attributes) {
    const response = await base.post('/offers/byAddress', {
      addressId,
      context,
      attributes,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async getAds (offerId, adsIds, context) {
    const response = await base.post('/offers/ads', {
      offerId,
      context,
      adsIds,
    });

    return response && response.data;
  },
  async getAddresses (filterForm, filter, domain) {
    const response = await base.post(`${domain}/search/cards`, {
      filter,
      attributes: filterForm,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async getReport (hash, filter) {
    const response = await base.post('report/get', {
      hash,
      filter,
    });

    // @TODO: Use a custom error (ApiError)
    return response && response.data;
  },
  async generateReport (payload, results, domain) {
    const response = await base.post(`${domain}/report/store`, {
      payload,
      results,
    });

    return response && response.data;
  },
  flagOffer ({ comment, reasons, offerId, addressId, filter, domain }) {
    return base.post(`${domain}/flag`, {
      comment,
      reasons,
      offerId,
      addressId,
      filter,
    });
  },
};
