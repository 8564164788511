<template>
  <div id="card-info-marker" :class="[!description && 'collapse']">
    <div class="container">
      <span :style="{backgroundColor: leftBarColor}"></span>
      <div class="content">
        <h3 :style="{fontSize: h3FontSize}">{{ title }}</h3>
        <p>{{ description }}</p>
      </div>
    </div>
    <hr v-if="bottomLine" />
  </div>
</template>
<script>
export default {
  name: 'InfoMarkerCard',
  props: {
    size: { type: String, default: 'medium' },
    title: { type: String, required: true },
    description: { type: String, default: '' },
    bottomLine: { type: Boolean, default: true },
    leftBarColor: { type: String, default: '#5a7fed' },
  },
  computed: {
    h3FontSize() {
      return this.size === 'medium' ? '24px' : '16px';
    },
  },
};
</script>
<style lang="scss" scoped>
#card-info-marker {
  width: 100%;

  &.collapse {
    .container {
      span {
        height: 32px;
      }
    }
  }
  .container {
    display: flex;
    padding: 0;
    span {
      display: block;
      width: 5px;
      height: 52px;
      border-radius: 10px;
      margin-right: 8px;
    }

    p,
    h3 {
      line-height: 1.5;
      margin: 0;
      font-family:'Lato';
    }
    h3 {
      font-size: 24px;
      color: #5e5e5e;
      font-weight: bold;
      line-height: 28px;
    }
    p {
      font-size: 12px;
      color: #777777;
      margin-top: 5px;
    }
  }
  hr {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #dedfdf;
    margin: 13px 0;
  }
}
</style>
