import { createStitches } from '@stitches/core';

import colors from './tokens/colors';
import fonts from './tokens/fonts';
import lineHeights from './tokens/lineHeights';
import fontWeights from './tokens/fontWeights';
import fontSizes from './tokens/fontSizes';
import shadows from './tokens/shadows';
import space from './tokens/space';

import media from './misc/media';
import reset from './misc/reset';

/**
 * Theme
 */
export const {
  css,
  theme,
  globalCss,
} = createStitches({
  media,
  theme: {
    colors,
    fonts,
    lineHeights,
    fontWeights,
    fontSizes,
    shadows,
    space,
  },
});

/**
 * Global styles
 */
const resetStyles = globalCss(reset);

/**
 * Apply CSS reset
 */
resetStyles();
