<template>
  <section id="privacy-policy">
    <div class="wrapper-outer">
      <div class="wrapper">
        <div
          class="wrapper-inner"
          data-privacy-scrollable
        >
          <div class="bg bg-start">
            <img
              height="648px"
              src="@/assets/images/privacy-background.svg"
            >
          </div>
          <div class="wrapper-size">
            <aside class="sidebar">
              <ul :class="{ 'mt-0': scrollValue }">
                <li :class="{ active: hash === '#/objetivo' }">
                  <a
                    class="hash-link"
                    href="#objetivo"
                  >Objetivo</a>
                </li>
                <li :class="{ active: hash === '#/ambito' }">
                  <a
                    class="hash-link"
                    href="#ambito"
                  >Âmbito</a>
                </li>
                <li :class="{ active: hash === '#/processo' }">
                  <a
                    class="hash-link"
                    href="#processo"
                  >Processo</a>
                </li>
                <li :class="{ active: hash === '#/porque' }">
                  <a
                    class="hash-link"
                    href="#porque"
                  >Porquê?</a>
                </li>
                <li :class="{ active: hash === '#/dados-pessoais' }">
                  <a
                    class="hash-link"
                    href="#dados-pessoais"
                  >Dados<br>Pessoais</a>
                </li>
                <li :class="{ active: hash === '#/meios' }">
                  <a
                    class="hash-link"
                    href="#meios"
                  >Meios</a>
                </li>
                <li :class="{ active: hash === '#/periodos' }">
                  <a
                    class="hash-link"
                    href="#periodos"
                  >Períodos</a>
                </li>
                <li :class="{ active: hash === '#/direitos' }">
                  <a
                    class="hash-link"
                    href="#direitos"
                  >Direitos</a>
                </li>
                <li :class="{ active: hash === '#/seguranca' }">
                  <a
                    class="hash-link"
                    href="#seguranca"
                  >Segurança</a>
                </li>
                <li :class="{ active: hash === '#/contato' }">
                  <a
                    class="hash-link"
                    href="#contato"
                  >Contato</a>
                </li>
              </ul>
            </aside>
            <div class="content">
              <div class="content-inner">
                <div class="headings">
                  <h1 class="titles">
                    Política de Privacidade para o Cidade Virtual
                  </h1>
                  <h2 class="titles mt-4">
                    A política de privacidade e dados pessoais resumida do Cidade Virtual.
                  </h2>
                </div>

                <div
                  id="objetivo"
                  class="block"
                >
                  <h3>Objetivo</h3>
                  <p>Informar todos os clientes e usuários dos serviços do CIDADE VIRTUAL no que refere à aplicação do Lei Geral de Proteção de Dados - LGPD.</p>
                </div>

                <div
                  id="ambito"
                  class="block"
                >
                  <h3>Âmbito</h3>
                  <p>Aplica-se a todos os os clientes e usuários dos serviços do CIDADE VIRTUAL.</p>
                </div>

                <div
                  id="processo"
                  class="block"
                >
                  <h3>Processo</h3>
                  <p>O CIDADE VIRTUAL necessita colher e tratar dados pessoais dos seus clientes. Neste sentido, a presente Política de Privacidade e Dados Pessoais do CIDADE VIRTUAL (doravante “Política da Privacidade”), visa ajudar os nossos clientes a compreender quais dados pessoais recolhemos, como e por que motivo os usamos, a quem divulgamos e como protegemos a sua privacidade quando utilizam os nossos serviços.</p>
                </div>

                <div
                  id="porque"
                  class="block"
                >
                  <h3>Porquê?</h3>
                  <p>O CIDADE VIRTUAL está empenhada em proteger a segurança e a privacidade dos seus clientes. Neste contexto, elaborou a presente Política de Privacidade, com a finalidade de afirmar o seu compromisso e respeito para com as regras de privacidade e de proteção de dados pessoais</p>
                  <p>Pretendemos que os nossos clientes conheçam as regras gerais de privacidade e os termos de tratamento dos dados que recolhemos, no estreito cumprimento da legislação aplicável neste âmbito, nomeadamente Lei nº 13.709, de 14 de Agosto de 2018 (“Lei Geral de Proteção de Dados - LGPD” ou simplesmente “LGPD”).</p>
                </div>

                <div
                  id="dados-pessoais"
                  class="block"
                >
                  <h3>O que são dados pessoais?</h3>
                  <p>Entende-se por dados pessoais qualquer informação relacionada a pessoa natural identificada ou identificável (titular dos dados), de qualquer natureza e independentemente do respectivo suporte. É considerada identificável a pessoa que possa ser identificada direta ou indiretamente, designadamente por referência a um número de identificação ou a mais elementos específicos da sua identidade física, fisiológica, psíquica, econômica, cultural ou social.</p>

                  <h3>Quem é o responsável pelo tratamento dos seus dados pessoais?</h3>
                  <p>A presente Política de Privacidade visa dar conhecimento aos clientes a respeito dos termos de tratamento de dados pessoais do CIDADE VIRTUAL, determinando as finalidades e meios de tratamento dos seus dados no contexto da prestação de serviços, pelo que este deve ser considerado como Controlador, nos termos do LGPD.</p>
                  <p>Assim, quando for atendido por um terceiro independente, por conta e ordem do CIDADE VIRTUAL, este terceiro será considerado um Operador, nos termos da LGPD. Assim, se houver qualquer tipo de questionamento a respeito da privacidade dos dados do cliente, solicitamos que também seja indicado o terceiro competente, quando for o caso, para fins de apuração de eventual infração, dolo, negligência, imprudência ou imperícia. Os corretores estão incluídos nesta classificação. Assim sendo, solicitamos que seja indicado o corretor que fez o atendimento, quando for o caso, para fins de apuração da conduta do profissional.</p>
                </div>

                <div
                  id="meios"
                  class="block"
                >
                  <h3>Que dados pessoais recolhemos e através de que meios?</h3>
                  <p>O CIDADE VIRTUAL coleta informações pessoais fornecidas pelo cliente, informações estas capazes de identificá-lo. Estas informações coletadas podem variar de acordo com o seu uso do CIDADE VIRTUAL, bem como com o tipo de informação que você opta a nos fornecer.</p>
                  <p>Para bom andamento da prestação do serviço, o cliente do CIDADE VIRTUAL fornece diversas informações tanto dos seus clientes pessoas físicas como de clientes pessoas jurídicas. Para saber quais são essas informações e como são tratadas, pedimos que acesse a íntegra da Política de Privacidade do CIDADE VIRTUAL.</p>

                  <h3>Quais as finalidades da coleta dos seus dados pessoais?</h3>
                  <p>Os dados pessoais recebidos só poderão ser utilizados para a finalidade contratada. Caso o CIDADE VIRTUAL realize tratamento diverso do consentido, por força de relação contratual do cliente, deverá obter novo consentimento do titular do dado.</p>
                  <p>A base legal utilizada para o tratamento dos dados pessoais é a execução do contrato e o legítimo interesse, portanto, não há necessidade do CIDADE VIRTUAL obter o consentimento do titular do dado. A finalidade para o tratamento do dado deve ser específica, salvo legítimo interesse do controlador.</p>
                  <p>Podemos usar várias categorias de informações pessoais mencionadas acima para diversos fins comerciais. Para ter acesso a essa informação, por favor acesse a Política de Privacidade na íntegra do CIDADE VIRTUAL.</p>

                  <h3>Com que fundamento tratamos os seus dados pessoais?</h3>
                  <p>O CIDADE VIRTUAL é uma corretora de imóveis e, para tanto, é preciso tratar alguns dados pessoais para a realização desse serviço. A LGPD exige, para que o tratamento de dados pessoais seja lícito, que exista um fundamento de licitude adequado para cada tratamento específico.</p>
                  <p>Relativamente aos tratamentos dos seus dados efetuados pelo CIDADE VIRTUAL para melhorar os nossos serviços e cumprir os nossos objetivos administrativos e de qualidade, o fundamento de licitude adequado será a prossecução de interesses legítimos bem como Cumprimento Contratual, quando aplicável, além do próprio consentimento do cliente. Tal implica que os titulares dos dados possam opor-se ao tratamento dos seus dados para os efeitos acima referidos, ao abrigo do LGPD, caso apresentem motivos válidos relacionados com a sua situação particular. Em tal eventualidade, o Controlador poderá apresentar razões legítimas que justifiquem a continuação desse tratamento, caso em que se reserva o direito de continuar a tratar os seus dados para esses efeitos, tal como nos casos em que tal tratamento seja necessário para efeitos de declaração, exercício ou defesa de um direito em processo judicial.</p>
                </div>

                <div
                  id="periodos"
                  class="block"
                >
                  <h3>Qual o período de conservação dos seus dados pessoais?</h3>
                  <p>O controlador (cliente) envia sua base de dados com os dados pessoais que serão tratados pelo CIDADE VIRTUAL (Operador). Tais dados são conservados em um formato que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados.</p>
                  <p>Após o fim dos serviços, os dados são devolvidos ao cliente, entretanto, em razão se segurança jurídica, alguns dados ficam armazenados em nuvem (Serviços Cloud) contratada pelo CIDADE VIRTUAL.</p>
                </div>

                <div
                  id="direitos"
                  class="block"
                >
                  <h3>Quais os direitos dos titulares dos dados?</h3>
                  <p>
                    Nos termos da legislação aplicável, o titular dos dados poderá solicitar, a todo o tempo, o acesso aos dados pessoais que lhe digam respeito, bem como à sua retificação, à portabilidade dos seus dados, diretamente através do e-mail <a
                      href="mailto:dpo@cidadevirtual.net"
                      class="titles"
                    >dpo@cidadevirtual.net</a>, ou mediante contato presencial com o CIDADE VIRTUAL.
                  </p>
                  <p>O titular dos dados tem direito a:</p>
                  <ul class="list">
                    <li>Solicitar detalhes sobre as categorias de informações pessoais coletadas sobre você e, se permitido e prático, uma cópia das informações pessoais (portabilidade de dados).</li>
                    <li>Solicitar a exclusão de qualquer informação pessoal que coletamos de você, sujeito a certas exceções. Assim que sua solicitação for verificada, daremos prosseguimento a ela, a menos que uma exceção se aplique. Existe a possibilidade de negarmos sua solicitação de exclusão se a retenção das informações for necessária para nós ou nossos provedores de serviço podermos:</li>
                    <li>Concluir a transação para a qual coletamos as informações pessoais, fornecer um bem ou serviço que você solicitou, realizar ações razoavelmente previstas no contexto de nosso relacionamento comercial contínuo com você ou, de outra forma, executar nosso contrato com você.</li>
                    <li>Detectar incidentes de segurança, proteger contra atividades maliciosas, enganosas, fraudulentas ou ilegais ou processar os responsáveis por tais atividades.</li>
                    <li>Depurar produtos para identificar e reparar erros que prejudiquem a funcionalidade pretendida existente.</li>
                    <li>Exercer a liberdade de expressão, garantir o direito de outro consumidor de exercer seus direitos de liberdade de expressão, ou exercer outro direito previsto em lei.</li>
                    <li>Cumprir com a LGPD e legislação competente.</li>
                    <li>Envolver-se em pesquisas públicas ou científicas, históricas ou estatísticas revisadas por pares de interesse público que cumpram todas as outras leis de ética e privacidade aplicáveis, caso a exclusão das informações provavelmente impossibilite ou prejudique seriamente a realização da pesquisa, se você tiver previamente informado seu consentimento.</li>
                    <li>Permitir apenas usos internos que estejam razoavelmente alinhados com as expectativas do consumidor com base em seu relacionamento conosco.</li>
                    <li>Cumprir uma obrigação legal.</li>
                    <li>Fazer outros usos internos e legais dessas informações que sejam compatíveis com o contexto em que você as forneceu.</li>
                  </ul>
                  <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, o titular dos dados tem direito a apresentar uma reclamação à ANPD ou a outra autoridade de controle competente nos termos da lei, caso considere que os seus dados não estão sendo objeto de tratamento legítimo por parte do CIDADE VIRTUAL, nos termos da legislação aplicável e da presente Política.</p>
                </div>

                <div
                  id="seguranca"
                  class="block"
                >
                  <h3>Quais as medidas de segurança adotadas pelo cidade virtual?</h3>
                  <p>O CIDADE VIRTUAL está empenhada em assegurar a confidencialidade, proteção e segurança dos dados pessoais dos seus clientes, através da implementação das medidas técnicas e organizativas adequadas para proteger os seus dados contra qualquer forma de tratamento indevido ou ilegítimo e contra qualquer perda acidental ou destruição destes dados. Para o efeito, dispomos de sistemas e equipes destinados a garantir a segurança dos dados pessoais tratados, criando e atualizando procedimentos que previnam acessos não autorizados, perdas acidentais e/ou destruição dos dados pessoais, comprometendo-se a respeitar a legislação relativa à proteção de dados pessoais dos clientes e a tratar estes dados apenas para os fins para que foram recolhidos, assim como a garantir que estes dados são tratados com adequados níveis de segurança e confidencialidade.</p>
                </div>

                <div
                  id="contato"
                  class="block"
                >
                  <h3>Contate-nos</h3>
                  <p>
                    Poderá contatar o Encarregado de Proteção de Dados (“DPO”) do CIDADE VIRTUAL para mais informações sobre o tratamento dos seus dados pessoais, bem como quaisquer questões relacionadas com o exercício dos direitos que lhe são atribuídos pela legislação aplicável e, em especial, os referidos na presente Política de Privacidade, através dos seguintes contatos: e-mail: <a
                      href="mailto:dpo@cidadevirtual.net"
                      class="titles"
                    >dpo@cidadevirtual.net</a>.
                  </p>
                </div>

                <footer class="copyright">
                  &copy; Cidade Virtual {{ new Date().getFullYear() }} | Todos os direitos reservados.
                </footer>
              </div>
            </div>
          </div>
          <div class="bg bg-end">
            <img
              width="648px"
              src="@/assets/images/privacy-background-alt.svg"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data: () => ({
    initialHashLink: '#/objetivo',
    hashLink: null,
    hashLinkClickHandler: null,
    scrollableHandler: null,
    isSmoothScrolling: false,
    hashLinkSelector: 'a.hash-link[href^="#"]',
    scrollableSelector: '[data-privacy-scrollable]',
    scrollValue: 0,
  }),
  computed: {
    hash () {
      const hash = this.hashLink || this.$route.hash;

      if (hash && !hash.startsWith('#/')) return `#/${hash.substring(1)}`;

      return hash;
    },
  },
  mounted () {
    this.handleHash();

    this.hashLinkClickHandler = event => {
      this.hashLink = event.target.getAttribute('href');
      event.preventDefault();
      this.handleHash(true, true);
    };

    this.scrollableHandler = () => {
      const scrollable = document.querySelector(this.scrollableSelector);

      if (!scrollable) return;

      this.scrollValue = scrollable.scrollTop;

      const endOfPage = scrollable.scrollTop + scrollable.clientHeight === scrollable.scrollHeight;

      if (this.hash && this.isSmoothScrolling) {
        const block = document.querySelector(`#${this.hash.substring(2)}`);

        if (!block) return;

        const diff = (block.offsetTop - scrollable.scrollTop);

        if (!diff || endOfPage) {
          window.location.hash = this.hash;
          this.isSmoothScrolling = false;
        }

        return;
      }

      const hashLinks = [...document.querySelectorAll(this.hashLinkSelector)]
        .filter((link, index, arr) => {
          const hash = link.getAttribute('href');
          const block = document.querySelector(hash);
          const diff = block.offsetTop - scrollable.scrollTop;

          if (endOfPage) return index === arr.length - 1;

          return diff + block.offsetHeight * 0.9 >= 0;
        });

      if (hashLinks.length) {
        const nearestBlockHash = `#/${hashLinks[0].getAttribute('href').substring(1)}`;

        window.location.hash = nearestBlockHash;
        this.hashLink = nearestBlockHash;
      }
    };

    document.querySelector(this.scrollableSelector).addEventListener('scroll', this.scrollableHandler);
    document.querySelectorAll(this.hashLinkSelector).forEach(link => {
      link.addEventListener('click', this.hashLinkClickHandler);
    });
  },
  unmounted () {
    document.querySelector(this.scrollableSelector).removeEventListener('scroll', this.scrollableHandler);
    document.querySelectorAll(this.hashLinkSelector).forEach(link => {
      link.removeEventListener('click', this.hashLinkClickHandler);
    });
    this.hashLinkClickHandler = null;
    this.scrollableHandler = null;
  },
  methods: {
    clearFilters (dispatch, payload) {
      return dispatch(`${this.$route.name}/clearFilters`, payload); 
    },
    handleHash (smooth, fromClick) {
      if (!this.hash) {
        this.hashLink = this.initialHashLink;
      }
      else if (this.hash !== this.initialHashLink || fromClick) {
        const hash = `#${this.hash.substring(2)}`;
        const block = document.querySelector(hash);

        if (!block) return;

        if (smooth) {
          this.isSmoothScrolling = true;
          block.scrollIntoView({
            behavior: 'smooth',
          });
        }
        else {
          window.location.hash = hash;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #privacy-policy {
    @apply h-full w-full pt-16 absolute left-0 top-0 overflow-hidden;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #707070;
    z-index: 4;

    .wrapper {
      @apply relative z-10 flex h-full w-full;
      &-outer {
        @apply h-full overflow-hidden bg-white flex;
      }
      &-size {
        @apply flex mx-auto;
        width: 792px;
      }
      &-inner {
        @apply flex overflow-auto w-full flex-col pt-6;
        .headings {
          @apply mb-12;
        }
        h3 {
          @apply mb-4;
          color: #38415A;
        }
        .list {
          @apply my-4 list-disc;
          li {
            @apply mb-4;
          }
        }
        .block {
          p:last-child {
            @apply mb-0;
          }
          &:not(:first-child), h3:not(:first-child) {
            @apply pt-8;
          }
        }
      }
    }

    .sidebar {
      @apply absolute px-3 top-0 h-full flex pt-6;
      width: 160px;
      ul {
        @apply py-0 px-3 my-6 overflow-auto w-full mt-6;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(0, 0, 0, .2);
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, .3);
        }
        li {
          &:not(:last-child) {
            @apply mb-5;
          }
          a {
            color: #38415A;
          }
          font-weight: 300;
          font-size: 24px;
          line-height: 28px;
          &.active {
            @apply relative;
            font-weight: 700;
            &::before {
              content: '';
              width: 4px;
              position: absolute;
              left: 0;
              top: 0;
              margin-left: -10px;
              background-color: #4663B9;
              height: 100%;
            }
          }
        }
      }
    }

    .content {
      @apply w-full;
      margin-left: 160px;
      &-inner {
        @apply border-l h-full;
        padding: 25px;
        border-color: #E8E8E8;
      }
    }

    .titles {
      color: #38415A
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }

    h2 {
      font-size: 16px;
      line-height: 16px;
      opacity: .8;
    }

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
    }

    .copyright {
      @apply mt-20;
      font-size: 12px;
      color: #384059;
    }

    .bg {
      @apply relative;
      img {
        @apply absolute;
      }
    }

    .bg {
      &-start {
        img {
          @apply top-0 left-0 -mt-6;
        }
      }
      &-end {
        img {
          @apply bottom-0 right-0;
          margin-top: -100%;
        }
      }
    }

  }
</style>
