import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm';

const config = {
  id: process.env.VUE_APP_GTM_ID,
  queryParams: {},
  defer: false,
  compatibility: false,
  nonce: Date.now(),
  enabled: true,
  debug: process.env.NODE_ENV === 'development',
  loadScript: true,
  ignoredViews: ['homepage'],
  trackOnNextTick: false,
}

Vue.use(VueGtm, config);
