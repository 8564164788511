import { css } from '@/core/theme';

const container = css({
  display: 'flex',
  flexDirection: 'column',

  '& > span': {
    marginBottom: '$medium',
  },

  '& > p': {
    marginTop: '$medium',
  },
});

const headline = css({
  display: 'flex',
  alignItems: 'end',
  gap: '8px',
  marginBottom: '16px',

  '& > span:first-of-type': {
    flexGrow: '1',
  },
});

const row = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const progress = css({
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  appearance: 'none',
  width: 'clamp(100%, 100%, 100%)',
  height: 'clamp(4px, 4px, 4px)',
  borderRadius: '9999px',
  backgroundColor: '$highlight-lighter',
  marginBottom: '$nano',

  '&::-webkit-progress-bar': {
    backgroundColor: '$highlight-lighter',
    borderRadius: '9999px',
  },

  '&::-moz-progress-bar': {
    backgroundColor: '$highlight-light',
    borderRadius: '9999px',
  },

  '&::-webkit-progress-value': {
    backgroundColor: '$highlight-light',
    borderRadius: '9999px',
  },

  '&:not([value])': {
    backgroundColor: '$neutral-grey-1',
  },
});

export const styles = {
  container,
  headline,
  row,
  progress,
};
