<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M10.0595 20C15.6152 20 20.119 15.5228 20.119 10C20.119 4.47715 15.6152 0 10.0595 0C4.5038 0 0 4.47715 0 10C0 15.5228 4.5038 20 10.0595 20Z" fill="#4663B9"/>
    <g opacity="0.6">
      <g opacity="0.6">
        <path opacity="0.6" d="M10.0579 11.4665L14.3673 7.1827C14.4856 7.06568 14.6457 7 14.8126 7C14.9795 7 15.1396 7.06568 15.2579 7.1827L15.6355 7.55808C15.7535 7.67573 15.8197 7.83506 15.8197 8.00116C15.8197 8.16726 15.7535 8.32658 15.6355 8.44423L10.5044 13.545C10.3853 13.6621 10.2246 13.7277 10.0572 13.7277C9.88968 13.7277 9.72897 13.6621 9.60989 13.545L4.48495 8.44885C4.36701 8.3312 4.30078 8.17187 4.30078 8.00577C4.30078 7.83967 4.36701 7.68035 4.48495 7.5627L4.86257 7.18885C4.98082 7.07142 5.14113 7.00545 5.30828 7.00545C5.47543 7.00545 5.63575 7.07142 5.754 7.18885L10.0579 11.4665Z" fill="#334887"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CircleArrow',
};
</script>
