/**
 * createColorVariants
 */
export const createColorVariants = (colors, properties = ['color']) => {

  let colorVariants = { };

  Object.entries(colors).forEach(([
    key,
    value,
  ]) => {

    let colorProperties = { };

    properties.forEach(property => {
      colorProperties[property] = value;
    });

    colorVariants[key] = colorProperties;
  });

  return colorVariants;
};
