<template>
  <base-card>
    <div class="mb-4">
      <Typography
        variant="title-large"
        color="highlight-darker"
      >
        Contato
      </Typography>
    </div>
    <div class="contact-card mb-4">
      <div class="w-full">
        <Typography
          variant="title-small"
          color="highlight-default"
        >
          Telefones
        </Typography>
      </div>
      <v-simple-table>
        <tbody>
          <tr
            v-for="phone, id in phones"
            :key="id"
          >
            <td :class="id === 0 ? 'pb-4' : 'py-4'">
              <div class="flex align-center">
                <div class="flex gap-2">
                  <div class="self-center">
                    <img
                      v-if="phone.IsMobile"
                      class="inline"
                      height="18px"
                      src="@/assets/images/icon-cellphone-light.svg"
                    >
                    <img
                      v-else
                      height="18px"
                      class="inline"
                      src="@/assets/images/icon-phone-light.svg"
                    >
                  </div>
                  <div class="flex flex-column justify-center align-start">
                    <Typography
                      variant="body-large"
                      color="neutral-grey-5"
                    >
                      {{ phone.FormattedNumber }}
                    </Typography>
                    <Typography
                      variant="label-xxs"
                      color="neutral-grey-4"
                    >
                    {{ `Atualizado ${formatYear(phone?.CreateDate)}` }}
                    </Typography>
                  </div>
                </div>
                <div class="flex align-center justify-center">
                  <v-tooltip v-if="phone.IsProcon" top >
                    <template #activator="{ on }">
                      <Badge
                        variant="altColorIconProcon"
                        v-on="on"
                      >
                        Procon
                      </Badge>
                    </template>
                    <span>Bloqueado Procon</span>
                  </v-tooltip>
                  <v-btn class="button-invisible" v-else />
                </div>
                <div class="flex align-center justify-end">
                  <v-tooltip v-if="phone.IsWhatsapp" top >
                    <template #activator="{ on }">
                      <Button
                        variant="iconWhatsApp"
                        v-on="on"
                        @click="startWhatsappChat(phone)"
                      >
                        WhatsApp
                      </Button>
                    </template>
                    <span>Abrir no WhatsApp</span>
                  </v-tooltip>
                  <v-btn class="button-invisible" v-else />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <Button
        class="self-center"
        variant="alternativeFade"
        size="medium"
        @click="showMore('telefone', 'Contato', 'Phones')"
      >
        Ver Mais
      </Button>
    </div>
    
    <div class="contact-card">
      <div class="w-full">
        <Typography
          variant="title-small"
          color="highlight-default"
        >
          Emails
        </Typography>
      </div>
      <v-simple-table>
        <tbody>
          <tr
            v-for="email, id in emails"
            :key="id"
          >
            <td :class="id === 0 ? 'pb-4' : 'py-4'">
              <div class="flex justify-between">
                <div class="flex align-center">
                  <div class="flex flex-column justify-center align-start">
                    <Typography
                      variant="body-table"
                      color="neutral-grey-5"
                    >
                    {{ email.Email }}
                    </Typography>
                    <Typography
                      variant="label-xxs"
                      color="neutral-grey-4"
                    >
                    {{ `Atualizado ${formatYear(email?.CreateDate)}` }}
                    </Typography>
                  </div>
                </div>
                <div class="flex align-center justify-end">
                  <Badge
                    v-if="email.IsValidated"
                    variant="secondary"
                    >
                    Validado
                  </Badge>
                </div>
              </div>
            </td>
            <!-- <td class="text-right">
              <v-tooltip
                v-if="email.IsLinkedIn"
                top
              >
                <template #activator="{ on }">
                  <v-chip
                    class="ml-5 shadow-lg cursor-default py-3 btn-email"
                    small
                    label
                    color="white"
                    v-on="on"
                  >
                    <img
                      src="@/assets/images/icon-linkedin.svg"
                      class="inline mr-1"
                    >
                    LinkedIn
                  </v-chip>
                </template>
                <span>Registrado no LinkedIn</span>
              </v-tooltip>
            </td> -->
          </tr>
        </tbody>
      </v-simple-table>
      <Button
        class="self-center"
        variant="alternativeFade"
        size="medium"
        @click="showMore('email', 'Contato', 'Emails')"
      >
        Ver Mais
      </Button>
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </base-card>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import BaseCard from '@/components/cards/base-card.vue';
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import ShowMoreButton from './ShowMoreButton.vue';
import Typography from '../base/Typography';
import { formatYear } from '@/utils';
import Badge from '../base/Badge';
import Button from '../base/Button';

export default {
  name: 'BasicPhoneEmailBlock',
  components: {
    GoogleTagManager,
    BaseCard,
    ShowMoreButton,
    Typography,
    Badge,
    Button
},
  data: () => ({
    gtmEvent: null,
  }),
  computed: {
    ...mapState('person', [
      'detail',
      'activeTab',
    ]),
    phones () {
      const phoneList = Object.assign([], this.detail.Phones);
      const phoneListDescSorted = phoneList.sort(this.sortDesc);
      const phoneListSliced = this.slicer(phoneListDescSorted, 4);

      return phoneListSliced;
    },
    emails () {
      const emailList = Object.assign([], this.detail.Emails);
      const emailListDescSorted = emailList.sort(this.sortDesc);
      const emailListSliced = this.slicer(emailListDescSorted, 4);

      return emailListSliced;
    },
  },
  methods: {
    ...mapActions('person', ['setActiveTab']),
    openAccordion(accordion) {
      const element = document.querySelector(`#${accordion} button`);
      const isOpen = element.classList.value.includes('v-expansion-panel-header--active');
      if (!isOpen) element.click();
    },
    showInformation(accordion, activeTab) {
      this.setActiveTab(activeTab);
      this.openAccordion(accordion);
    },
    sendViewListEvent (type) {
      this.gtmEvent = {
        event: 'view_list',
        type,
      };
    },
    showMore (type, accordionID, activeTabID) {
      this.sendViewListEvent(type)
      this.showInformation(accordionID, activeTabID)
    },
    sortDesc (a, b) {
      return b.Status - a.Status;
    },
    slicer (arr, amount) {
      return arr.slice(0, amount);
    },
    whatsappLink(phone) {
      return encodeURI(`https://api.whatsapp.com/send?phone=55${phone.AreaCode}${phone.Number}`);
    },
    startWhatsappChat(phone) {
      window.open(this.whatsappLink(phone), '_blank');
    },
    formatYear
  },
};
</script>

<style lang="scss" scoped>
.base-card {
  padding: 26px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
}

.contact-card {
  display: flex;
  padding: 16px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--colors-brand-light);
}

::v-deep td {
  font-family: Lato !important;
  font-size: 14px !important;
}

::v-deep tr:hover td {
  background-color: #fff;
}

.badge {
  padding: 0 5px !important;
  margin: 0 !important;

  &-linkedin {
    margin-left: 5px !important;
  }
}

.btn-email {
  color: #707070 !important;
  border: 1px solid #e8e8e8;
  text-transform: none !important;
  &.text-white {
    color: white !important;
  }
}

.button-invisible {
  background-color: var(--colors-neutral-white)!important;
  box-shadow: none;
  cursor: none;
  pointer-events: none;
  padding: 0!important;
}
</style>
