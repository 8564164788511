import { styles as helpModalStyles } from '@/components/HelpBuyerModal/HelpBuyerModal.styles';

const {
  root,
  content,
  footer,
  list,
} = helpModalStyles;

export const styles = {
  root,
  content,
  footer,
  list,
};
