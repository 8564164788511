<template>
  <table class="container more-typologies-table">
    <table-header :header-cols="headerCols" />
    <table-row
      v-for="(row, id) in typologiesRows"
      :key="id"
      :row="row"
      :first-result="id === 0"
      :last-result="id + 1 === typologiesRows.length"
      :price-history="priceHistory[row.typology_id]"
    />
  </table>
</template>

<script>
import TableHeader from './header';
import TableRow from './row';

export default {
  name: 'MoreTypologiesTable',
  components: {
    TableHeader,
    TableRow,
  },
  props: {
    typologiesRows: {
      type: Array,
      default: null,
    },
    priceHistory: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headerCols: [
        'Metragem',
        'Dormitórios',
        'Suítes',
        'Vagas',
        'Valor Ofertado',
        'AVM',
      ],
      chunksBySource: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.more-typologies-table {
  @apply table-auto;
  max-width: 956px;
}
</style>
