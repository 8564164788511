<template>
  <div class="developer">
    <iframe
      class="iframe"
      :src="url"
      frameborder="0"
    />
  </div>
</template>

<script>

export default {
  name: 'Developer',
  data () {
    return {
      url: process.env.VUE_APP_WORKSPACE_POWER_BI_DEVELOPER,
    };
  },
};
</script>

<style scoped lang="scss">
.developer {
  width: 100%;
  height: calc(100% - 75px);
  background-color: #FFF;
  position: absolute;
  top: 75px;
  z-index: 4;

  .iframe {
    padding-top: 16px;
    width: 100%;
    height: 100%;
  }
}
</style>
