export default function setMapSearchResult(state, mapResult = []) {
  state.result = Object.freeze(
    mapResult &&
      mapResult.reduce(
        (
          acc,
          [addressId, lat, lng, offerType, valueSqrtMeter, tipologia],
          index
        ) => ({
          ...acc,
          [addressId]: {
            addressId,
            order: index,
            geolocation: [lat, lng],
            offerType,
            valueSqrtMeter,
            tipologia
          }
        }),
        state.result || {}
      )
  );
}
