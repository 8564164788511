<template>
  <LoginLayout>
    <div class="login-form">
      <div class="title">
        Cidade Virtual
      </div>
      <div class="enter-label">
        Entrar
      </div>

      <login-google />

      <div class="enter-with-email-label">
        ou use seu e-mail para entrar na conta:
      </div>

      <login-email />

      <div class="remember-me">
        <router-link to="/login/reset-password">
          Esqueceu a senha?
        </router-link>
      </div>
    </div>
  </LoginLayout>
</template>

<script>

import LoginLayout from '@/layouts/Login.vue';
import LoginGoogle from '@/components/login/LoginGoogle.vue';
import LoginEmail from '@/components/login/LoginEmail.vue';

export default {
  name: 'Login',
  components: {
    LoginLayout,
    LoginGoogle,
    LoginEmail,
  },
};

</script>

<style lang="scss" scoped>
.login-form {
  @apply mb-3;
  min-width: 412px;
  background: #FFFFFF;
  border: 1px solid #8496cbbb !important;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 24px;

  .title {
    @apply text-center;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 25.5px !important;
    line-height: 100%;
    color: #384059;
  }

  .enter-label {
    margin-top: 24px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 19px;
    color: #38415A;
  }

  .enter-with-email-label {
    margin-top: 24px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #707070;
  }

  .remember-me {
    margin-top: 24px;
    font-family: 'Lato',sans-serif;
    font-style: bold;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;

    a, a:active, a:visited, a:link, a:hover {
      color: #384059 !important;
    }
  }

}

</style>
