var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.results)?_c('div',{staticClass:"report__map w-full"},[_c('l-map',{ref:"reportMap",attrs:{"options":_vm.MAP_OPTIONS.reportMapOptions,"zoom":_vm.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL,"max-zoom":_vm.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL,"min-zoom":_vm.MAP_OPTIONS.DEFAULT_ZOOM_LEVEL}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),(_vm.circleCenter)?_c('l-circle',{attrs:{"color":"#6B8CEF","fill-color":"#6B8CEF","fill-opacity":0.2,"options":_vm.MAP_OPTIONS.circleOptions,"lat-lng":_vm.circleCenter,"radius":_vm.circleRadius}}):_vm._e(),(_vm.regionGeoJSON)?_c('l-geo-json',{attrs:{"geojson":_vm.regionGeoJSON}}):_vm._e(),(_vm.results)?_c('l-marker-cluster',{ref:"cluster",attrs:{"options":_vm.MAP_OPTIONS.clusterOptions},on:{"clusterclick":_vm.onClusterClick}},_vm._l((_vm.results.filter(item => !!item)),function({ addressId, geolocation, valueSqrtMeter }){return _c('l-marker',{key:addressId,attrs:{"lat-lng":geolocation}},[_c('l-icon',{attrs:{"class-name":"icon","icon-size":[
            _vm.iconSize.width * 3,
            _vm.iconSize.height,
          ]}},[_c('span',[_c('img',{staticClass:"mx-auto",attrs:{"src":require('@/assets/images/map-pin-blue.svg'),"width":_vm.iconSize.width,"height":_vm.iconSize.height}})]),(valueSqrtMeter && valueSqrtMeter !== '-')?_c('l-tooltip',{attrs:{"class-name":"tooltip","options":_vm.tooltipOptions}},[_c('div',{class:{
                'pin-tooltip': true,
                'is-blue': true,
              }},[_vm._v(" "+_vm._s(valueSqrtMeter)+"/m² ")])]):_vm._e()],1),_c('l-popup',[_c('PropertyItem',{staticClass:"property__popup",attrs:{"disable-flagging":"","disable-chart":"","property":_vm.results.find(result => addressId === result.addressId)}})],1)],1)}),1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }