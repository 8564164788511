<template>
  <div
    v-if="results"
    class="report__map w-full"
  >
    <l-map
      ref="reportMap"
      :options="MAP_OPTIONS.reportMapOptions"
      :zoom="MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL"
      :max-zoom="MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL"
      :min-zoom="MAP_OPTIONS.DEFAULT_ZOOM_LEVEL"
    >
      <l-tile-layer :url="url" />

      <l-circle
        v-if="circleCenter"
        color="#6B8CEF"
        fill-color="#6B8CEF"
        :fill-opacity="0.2"
        :options="MAP_OPTIONS.circleOptions"
        :lat-lng="circleCenter"
        :radius="circleRadius"
      />
    
      <l-geo-json
        v-if="regionGeoJSON"
        :geojson="regionGeoJSON"
      />

      <l-marker-cluster
        v-if="results"
        ref="cluster"
        :options="MAP_OPTIONS.clusterOptions"
        @clusterclick="onClusterClick"
      >
        <l-marker
          v-for="{ addressId, geolocation, valueSqrtMeter } in results.filter(item => !!item)"
          :key="addressId"
          :lat-lng="geolocation"
        >
          <l-icon
            class-name="icon"
            :icon-size="[
              iconSize.width * 3,
              iconSize.height,
            ]"
          >
            <span>
              <img
                :src="require('@/assets/images/map-pin-blue.svg')"
                :width="iconSize.width"
                :height="iconSize.height"
                class="mx-auto"
              >
            </span>

            <l-tooltip
              v-if="valueSqrtMeter && valueSqrtMeter !== '-'"
              class-name="tooltip"
              :options="tooltipOptions"
            >
              <div
                :class="{
                  'pin-tooltip': true,
                  'is-blue': true,
                }"
              >
                {{ valueSqrtMeter }}/m²
              </div>
            </l-tooltip>
          </l-icon>
          
          <l-popup>
            <PropertyItem
              class="property__popup"
              disable-flagging
              disable-chart
              :property="results.find(result => addressId === result.addressId)"
            />
          </l-popup>
        </l-marker>
      </l-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon, LCircle, LTooltip, LGeoJson } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import PropertyItem from '@/components/property/PropertyItem.vue';
import { mapState } from 'vuex';
import { FILTER_MODE, MAP_OPTIONS } from '@/common/constants';

export default {
  name: 'ReportMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LCircle,
    LTooltip,
    PropertyItem,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    'l-geo-json': LGeoJson,
  },
  props: {
    results: {
      type: Array,
      default: () => null,
    },
    searchSummary: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      currentPropertyId: 0,
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      FILTER_MODE,
      MAP_OPTIONS,
      circleRadius: MAP_OPTIONS.DEFAULT_RADIUS,
      circleCenter: null,
      iconSize: {
        width: 37,
        height: 46,
      },
      tooltipOptions: {
        permanent: true,
        direction: 'top',
      },
    };
  },
  computed: {
    ...mapState('map', ['zoomLevel']),
    regionGeoJSON () {
      if (!this.searchSummary || !this.searchSummary.filter) return null;

      const { filterMode, coordinates } = this.searchSummary.filter;

      if (filterMode !== this.FILTER_MODE.REGION && filterMode !== this.FILTER_MODE.AREA) return null;

      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates,
            },
          },
        ],
      };
    },
  },
  created () {
    setImmediate(() => {
      const {
        filter: { filterMode, coordinates, radius },
        attributes,
      } = this.searchSummary;

      if (filterMode === this.FILTER_MODE.CONDO && attributes && attributes.detailed) {
        const first = attributes.detailed;

        this.currentPropertyId = first.addressId;
        this.circleCenter = first.geolocation;
        this.centerMapToOffset(first.geolocation, this.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL - 1);
      }
      else if (filterMode === this.FILTER_MODE.REGION || filterMode === this.FILTER_MODE.AREA) {
        if (coordinates && coordinates.length === 1) {
          const points = coordinates[0];
          let lat = 0;
          let lng = 0;

          points.forEach(point => {
            lng += point[0];
            lat += point[1];
          });
          lat /= points.length;
          lng /= points.length;
          this.centerMapToOffset([
            lat,
            lng,
          ], this.MAP_OPTIONS.DEFAULT_ZOOM_LEVEL);
        }
      }
      else {
        this.circleCenter = coordinates;
        this.centerMapToOffset(coordinates, this.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL - 2);
        this.circleRadius = radius || this.MAP_OPTIONS.DEFAULT_RADIUS;
      }
    });
  },
  methods: {
    centerMapToOffset (latlng, zoom) {
      const zoomLevel = zoom || this.zoomLevel;

      if (this.$refs.reportMap && this.$refs.reportMap.mapObject) {
        const { x, y } = this.$refs.reportMap.mapObject.latLngToContainerPoint(latlng);
        const point = this.$refs.reportMap.mapObject.containerPointToLatLng([
          x,
          y,
        ]);

        this.$refs.reportMap.mapObject.setView(point, zoomLevel);
      }
    },
    onClusterClick (event) {
      this.centerMapToOffset(event.latlng, this.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL);
    },
  },
};
</script>

<style>
.leaflet-tooltip {
  opacity: 1 !important;
  background: initial !important;
  color: transparent !important;
  padding: initial !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent !important;
  background: transparent !important;
  content: '';
}
.leaflet-tooltip-bottom {
  margin-bottom: 10px !important;
}
</style>

<style scoped lang="scss">
.pin-tooltip {
  opacity: 1 !important;
  color: #FFF !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  font-family: 'Lato', sans-serif !important;
  padding: 1px 5px 0 !important;
  box-shadow: none !important;
  border: 1px solid #FFF !important;
  border-radius: 2px !important;
  width: fit-content !important;
  margin: 0 auto !important;
  position: relative !important;
  top: 18px !important;

  &.is-blue {
    background-color: #6B8CEF !important;
  }
}
.report__map {
  width: 100%;
  margin: 10px auto;
  height: 600px;
  z-index: 3;
}
</style>
