<script>

export default {
  inheritAttrs: false,
};

</script>

<script setup>

import { computed, useSlots } from 'vue';

/**
 * Components
 */
import Typography from '@/components/base/Typography';
import WhatsAppIcon from '@/assets/images/whatsapp.svg'
import GoogleIcon from '@/assets/images/google.svg'
import OneTwoThreeiIcon from '@/assets/images/logo-123i.svg'

/**
 * Styles
 */
import { styles } from './Button.styles';


/**
 * Props
 */
const props = defineProps({

  variant: {
    type: String,
    default: 'primary',
    validator (value) {
      return [
        'primary',
        'primaryXs',
        'primaryDefault',
        'primaryLarge',
        'secondary',
        'secondaryXs',
        'alternative',
        'alternativeFade',
        'alternativeGreen',
        'alternativeGoogle',
        'alternative123i',
        'seeMore',
        'icon',
        'iconXs',
        'iconWhatsApp',
        'mobile-primary',
      ].includes(value);
    },
  },

  size: {
    type: String,
    default: 'default',
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  type: {
    type: String,
    default: 'button',
    validator: (value) => {
      return [
        'button',
        'submit',
        'reset',
      ].indexOf(value) >= 0;
    },
  },

  tag: {
    type: String,
    default: 'button',
  },

  showLeftIconOnHover: {
    type: Boolean,
    default: false,
  },
  
  showRightIconOnHover: {
    type: Boolean,
    default: false,
  },

  state: {
    type: String,
    default: null,
    validator: (value) => {
      return [
        'default',
        'hover',
        'active',
        'focus',
        'disabled',
      ].indexOf(value) >= 0;
    },
  },
});

const slots = useSlots()

/**
 * Computed
 */
const computedTag = computed(() => props.disabled !== undefined && props.disabled !== false ? 'button' : props.tag);

const computedType = computed(() => props.tag === 'button' || props.tag === 'input' ? props.type : null);

const computedTypography = computed(() => {
  switch (props.variant) {
    case 'seeMore':
      return 'label-xs'

    case 'primaryXs':
    case 'secondaryXs':
    case 'alternativeGoogle':
    case 'alternative123i':
      return 'label-small'
    
    case 'primary':
    case 'primaryDefault':
    case 'secondary':
    case 'secondaryDefault':
    case 'alternativeFade':
      return 'label-status'

    case 'alternativeGreen':
      return 'label-medium'
    
    case 'alternative':
      return 'title-large'

    case 'primaryLarge':
      if(Boolean(slots['left-icon']) || Boolean(slots['right-icon'])){
        return 'label-status'
      }
      return 'title-large'

    default:
      return `button-${props.size}`
  }
});

const showLeftIcon = computed(() => {
  Boolean(props.variant === 'primaryXs' ||
          props.variant === 'primaryDefault' ||
          props.variant === 'primaryLarge' ||
          props.variant === 'secondaryXs')
})

const hasLogo = computed(() => Boolean(
  props.variant === 'alternativeGreen' ||
  props.variant ==='iconWhatsApp' ||
  props.variant === 'alternativeGoogle' ||
  props.variant === 'alternative123i'))

const computedLogo = computed(() => {
  switch (props.variant) {
    case 'alternativeGreen':
    case 'iconWhatsApp':
      return WhatsAppIcon

    case 'alternativeGoogle':
      return GoogleIcon

    case 'alternative123i':
      return OneTwoThreeiIcon
  }
})

const computedLogoWidth = computed(() => {
  switch (props.variant) {
    case 'alternativeGreen':
    case 'iconWhatsApp':
      return '14px'

    case 'alternative123i':
      return '26px'

    default:
      return '12px'
  }
})

</script>

<template>
  <component 
    :is="computedTag"
    :class="styles.root({
      variant,
      size,
      fullWidth,
      showLeftIconOnHover,
      showRightIconOnHover,
      state,
    }).className"
    :disabled="disabled"
    :type="computedType"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- TODO: adicionar regra para apenas primaries e secondaryXs => && showLeftIcon Computed -->
    <span
      v-if="!!$slots['left-icon']"
      data-icon
    >
      <slot name="left-icon" />
    </span>

    <img 
      v-if="hasLogo"
      :width="computedLogoWidth"
      :src="computedLogo"
      :class="{
        'disabledLogoGoogle': (disabled && variant === 'alternativeGoogle'),
        'disabledLogo123i': (disabled && variant === 'alternative123i'),
      }">

    <slot v-if="variant === 'icon' || variant === 'iconXs'" />

    <Typography
      v-if="variant !== 'icon' && variant !== 'iconWhatsApp' && variant !== 'iconXs'"
      :variant="computedTypography"
      color="inherit"
      tag="span"
      :class="{
        'ml-1': showRightIconOnHover,
        'mr-1': showLeftIconOnHover
      }"
    >
      <slot />
    </Typography>

    <!-- TODO: adicionar regra para apenas primaryLarge => && variant === 'primaryLarge' -->
    <span
      v-if="!!$slots['right-icon']"
      data-icon
    >
      <slot name="right-icon" />
    </span>
  </component>
</template>

<style lang="scss" scoped>
.disabledLogoGoogle {
  mix-blend-mode: color-dodge;
}

.disabledLogo123i {
  mix-blend-mode: screen;
}
</style>