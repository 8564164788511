<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="styles.root().className">
      <div class="flex flex-col items-center justify-center gap-2">
        <Typography
          color="brand-dark"
          variant="header-large"
          tag="span"
        >
          Nós temos todas as informações para você entender o perfil do cliente
        </Typography>

        <Typography
          color="brand-dark"
          variant="body-large"
          tag="span"
        >
          Conheça todas as funcionalidades da vertical Consulta Dados
        </Typography>
      </div>

      <div :class="styles.content().className" class="margin-content">
        <div>
          <video
            class="video-margin"
            ref="firstColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/consulta-search.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Encontre pessoa física ou jurídica através de uma busca flexível.
          </Typography>

          <ul :class="styles.list().className" class="margin-text">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Endereço
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              E-mail
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Nome completo
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              CPF
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              CNPJ
            </Typography>
          </ul>
        </div>

        <div>
          <video
            class="video-margin"
            ref="secondColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/consulta-home.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Acesse o painel com todas as informações do cliente, organizadas por categoria.
          </Typography>

          <ul :class="styles.list().className" class="margin-text">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Renda e classe social
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Score de crédito
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Informações de contato
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Imóveis
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Sociedades
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Links
            </Typography>
          </ul>
        </div>

        <div>
          <video
            class="video-margin"
            ref="thirdColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/consulta-info.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Confira uma análise do cliente com os principais dados financeiros.
          </Typography>

          <ul :class="styles.list().className" class="margin-text">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Propriedades
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Renda individual
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Renda empresarial
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Renda presumida
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Status das suas empresas
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor médio das suas empresas
            </Typography>
          </ul>
        </div>
      </div>

      <div :class="styles.footer().className">
        <slot name="footer" />
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '@/components/base/Modal';
import Typography from '@/components/base/Typography';

import playVideoSequence from '@/utils/playVideoSequence';

import { styles } from './HelpPersonDataModal.styles';

export default {

  name: 'HelpPersonDataModal',

  components: {
    Modal,
    Typography,
  },

  data () {

    return {
      videoSequence: null,
    };
  },

  created () {
    this.styles = styles;
  },

  mounted () {

    this.$nextTick(() => {

      this.videoSequence = playVideoSequence([
        this.$refs.firstColumnVideo,
        this.$refs.secondColumnVideo,
        this.$refs.thirdColumnVideo,
      ]);
    });
  },

  beforeUnmount () {
    this.videoSequence.destroy();
  },
};

</script>

<style lang="scss" scoped>
.video-margin {
  margin-bottom: 4px!important;
}

.margin-content {
  margin-top: 12px;
}

.margin-text {
  margin-top: 8px;
}

</style>