<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="styles.root().className">
      <Typography
        color="brand-dark"
        variant="header-large"
        tag="h1"
      >
        Nós sabemos o valor justo de mercado para o seu imóvel
      </Typography>

      <Typography
        color="brand-dark"
        variant="body-large"
        tag="h2"
      >
        Conheça todas as funcionalidades da vertical Avaliação Mercadológica
      </Typography>

      <div :class="styles.content().className">
        <div class="width-video">
          <video
            ref="firstColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/avm-home.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Realize uma busca específica utilizando os filtros disponíveis.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Endereço
            </Typography>
        
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Tipologia
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Ano de construção
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Área útil
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Dormitórios, suítes e vagas
            </Typography>
          </ul>
        </div>

        <div class="width-video">
          <video
            ref="secondColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/avm-datazap.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Descubra os valores estimados pela AVM do DataZap.
          </Typography>

          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor mínimo do imóvel e do m²
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor médio do imóvel e do m²
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor máximo do imóvel e do m²
            </Typography>
          </ul>
        </div>

        <div class="width-video">
          <video
            ref="thirdColumnVideo"
            muted
          >
            <source
              src="@/assets/videos/avm-asking.mp4"
              type="video/mp4"
            >
          </video>

          <Typography
            color="brand-dark"
            variant="title-medium"
            tag="h3"
          >
            Descubra os valores estimados do <i>asking price</i>.
          </Typography>
      
          <ul :class="styles.list().className">
            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor mínimo do imóvel e do m²
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor médio do imóvel e do m²
            </Typography>

            <Typography
              color="brand-dark"
              variant="body-medium"
              tag="li"
            >
              Valor máximo do imóvel e do m²
            </Typography>
          </ul>
        </div>
      </div>

      <div :class="styles.footer().className">
        <slot name="footer" />
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from '@/components/base/Modal';
import Typography from '@/components/base/Typography';

import playVideoSequence from '@/utils/playVideoSequence';

import { styles } from './HelpAvmModal.styles';

export default {

  name: 'HelpAvmModal',

  components: {
    Modal,
    Typography,
  },

  data () {

    return {
      videoSequence: null,
    };
  },

  created () {
    this.styles = styles;
  },

  mounted () {

    this.$nextTick(() => {

      this.videoSequence = playVideoSequence([
        this.$refs.firstColumnVideo,
        this.$refs.secondColumnVideo,
        this.$refs.thirdColumnVideo,
      ]);
    });
  },

  beforeUnmount () {
    this.videoSequence.destroy();
  },
};

</script>


<style lang="scss" scoped>
.width-video {
  width: 90%;
}
</style>