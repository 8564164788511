// eslint-disable-next-line import/prefer-default-export
export const backgroundCheck = {
  basicInfo: {
    name: 'João Silva Costa',
    document: '362.548.555-4',
    birthDate: '24/01/1985',
    status: 'Regular',
  },
  relatives: [
    {
      relationshipType: 'Mãe',
      name: 'Maria Eduarda',
      attributes: [{ key: 'Aposentada', value: 'N' }],
    },
  ],
  children: [
    {
      name: 'Antonio Silva',
    },
    {
      name: 'Luiza Lemes',
    },
  ],
  contact: [
    {
      type: 'phone',
      value: {
        type: 'landline',
        value: '(13) 4554-3021',
      },
    },
    {
      type: 'phone',
      value: {
        type: 'mobile',
        value: '(13) 99554-3021',
      },
    },
    {
      type: 'phone',
      value: {
        type: 'business',
        value: '(13) 4554-3021',
      },
    },
    {
      type: 'email',
      value: {
        type: 'personal',
        value: 'joao@gmail.com',
      },
    },
  ],
  addressess: [
    {
      address: 'Rua Duque Costa',
      houseNumber: 48,
      complementary: 'AP71 B2',
      district: 'Centro',
      city: 'São Paulo',
      state: 'SP',
      zipCode: '18170-000',
    },
    {
      address: 'Rua Armando Salles',
      houseNumber: 22,
      complementary: 'AP60 B1',
      district: 'Socorro',
      city: 'Araras',
      state: 'SP',
      zipCode: '54187-000',
    },
  ],
};

export const clients = [
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
  backgroundCheck,
];
