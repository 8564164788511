/**
 * Font sizes
 */
export default {
  // Display
  'display-xl': '36px',
  'display-xxl': '40px',
  'display-black': '40px',
  'display-large-secondary': '36px',
  'display-xl-secondary': '40px',

  // Header
  'header-large': '22px',
  'header-xl': '25.5px',
  'header-xxl': '28px',
  'header-black': '28px',
  'header-large-secondary': '22.5px',
  'header-xl-secondary': '25.5px',

  // Title
  'title-small': '13px',
  'title-medium': '16px',
  'title-large': '18px',
  'title-small-secondary': '13px',
  'title-medium-secondary': '18px',
  'title-large-secondary': '18px',

  // Body
  'body-xs': '11px',
  'body-small': '12px',
  'body-medium': '14px',
  'body-medium-bold': '14px',
  'body-large': '16px',
  'body-bold': '16px',
  'body-small-secondary': '12px',
  'body-medium-secondary': '14px',
  'body-bold-secondary': '14px',
  'body-large-secondary': '16px',
  'body-table': '14px',

  // Placeholder
  placeholder: '16px',

  // Button
  'button-large': '18px',
  'button-medium': '16px',
  'button-small': '14px',

  // Link
  link: '15px',
  'link-small': '14px',
  'link-menu': '15px',

  // Select
  'select-medium': '14px',
  'select-small': '13px',

  // Label
  'label-xxs': '8px',
  'label-xs': '11px',
  'label-small': '12px',
  'label-small-medium': '12px',
  'label-small-bold': '12px',
  'label-bold': '14px',
  'label-regular': '14px',
  'label-current': '16px',
  'label-status': '16px',
  'label-medium': '14px',
  'label-small-secondary': '10px',
  'label-medium-secondary': '14px',
  'label-large-secondary': '16px',
  'label-bold-secondary': '16px',

  // Helper
  helper: '12px',

  // Caption
  caption: '12px',

  // Tab
  'tab-large': '18px',
};
