<template>
  <base-card>
    <div class="flex flex-column gap-1">
      <Typography
          variant="header-large"
          color="neutral-grey-6"
        >
          Endereços comerciais
        </Typography>
        <Typography
          variant="body-xs"
          color="neutral-grey-5"
        >
          Endereços comerciais registrados
        </Typography>
    </div>
    <div v-if="detail.BusinessAddresses" class="w-full flex flex-column gap-4">
      <div
        v-for="company, id in groupCompanyAddresses(detail.BusinessAddresses)"
        :key="id"
      >
        <v-data-table
          :key="id"
          :headers="headers"
          :items-per-page="itemsPerPage"
          :items="company.addresses"
          sort-by="ranking"
          :sort-desc="true"
        >
          <template v-slot:header.address>
            <Typography
              variant="body-bold"
            >
              {{ company.name }}
            </Typography>
          </template>
          <template v-slot:[`item.ShortAlias`]="{ item }">
            <div class="py-4">
              <Button
                variant="primary"
                v-if="item"
                size="small"
                @click="linkToMaps(item)"
                class="button-width"
              >
              <div class="flex gap-2">
                <img
                  width="12px"
                  src="@/assets/images/map-button-pin.svg"
                >
                  Mapa
                </div>
              </Button>
            </div>
          </template>
          <template v-slot:[`item.ranking`]="{ item }">
            {{ formatYear(item.ranking) }}
          </template>
        </v-data-table>
      </div>
    </div>
    <div v-else class="noResult">Não há dados para serem exibidos.</div>
  </base-card>
</template>

<script>

import { mapState } from 'vuex';
import BaseCard from '@/components/cards/base-card.vue';
import { formatYear } from '../../utils';
import Typography from '../base/Typography';
import Button from '../base/Button';

export default {
  name: 'BusinessAddressTable',
  components: {
    BaseCard,
    Typography,
    Button
},
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        { 
          value: 'address',
          sortable: true,
          text: 'Nome da empresa',
        },
        {
          text: '',
          value: 'ShortAlias',
          sortable: false,
          width: 65,
        },
        { 
          value: 'ranking',
          sortable: true,
          align: 'center',
          text: 'Última Atualização',
          width: 220
        },
      ],
    };
  },
  computed: {
    ...mapState('person', ['detail']),
  },
  methods: {
    formatYear,
    cnpjFormat(cnpj) {
      const pad = (`00000${cnpj}`).slice(-14);
      return `${pad.substring(0, 2)}.${pad.substring(2, 5)}.${pad.substring(5, 8)}/${pad.substring(8, 12)}-${pad.substring(12, 14)}`;
    },
    groupCompanyAddresses(companyList) {
      const companies = new Set();
      companyList.forEach(company => companies.add(company.CompanyDocument));

      const uniqueCompanies = {};
      for (const companyDocument of companies) {
        uniqueCompanies[companyDocument] = { name: '', addresses: [] };
      }

      companyList.forEach(company => {
        uniqueCompanies[company.CompanyDocument].name = `${company.CompanyName.length >= 40 ? `${company.CompanyName.substring(0, 40)}...` : company.CompanyName} - ${this.cnpjFormat(company.CompanyDocument)}`;
        uniqueCompanies[company.CompanyDocument].addresses.push({
          address: company.Alias,
          ranking: company.Ranking,
        });
      });

      return uniqueCompanies;
    },
    linkToMaps (property) {
      window.open(`https://www.google.com/maps/place/${property?.address}`, '_blank')
    },
  },
};

</script>

<style lang="scss" scoped>
.base-card {
  display: flex;
  gap: 16px;
  padding: 26px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
}

:deep(.v-data-table .v-data-table__wrapper) {
  padding: 20px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--colors-highlight-lighter);
}

.button-width {
  min-width: 88px!important;
  height: 32px;
  padding: 10px 16px;
  gap: 10px;
}

:deep(.v-data-table thead tr th:nth-child(3) span) {
  padding-left: 16px;
}

:deep(.v-data-table .v-data-footer__select .v-select) {
  margin: 13px 0 13px 16px;
}
:deep(.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon) {
  font-size: 32px;
}

:deep(.v-data-table .v-data-footer__icons-before .v-btn:last-child){
  margin-right: 0px;
}

:deep(.v-data-table .v-data-footer__icons-after .v-btn:first-child){
  margin-left: 0px;
}

:deep(.v-data-table .v-data-footer__pagination) {
  margin: 0px 16px;
}

:deep(.v-data-table .v-data-footer) {
  display: flex;
  align-items: center;
  border-top: none!important;
  height: 32px;
  margin-top: 16px;
}

:deep(.v-data-table .v-data-footer .v-data-footer__select) {
  height: 32px;
  margin-right: 0px!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th) {
  border-bottom: none!important;
}

:deep(.v-data-table .v-data-table__wrapper table thead tr th i){
  margin-bottom: 5px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child)){
  margin: 15px 0px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child) {
  font-size: 14px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:first-child)){
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
</style>